import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix } from "../../../../labels";
import Swal from "sweetalert2";
import { Download } from "@mui/icons-material";
import ModelComForP from "../../../../pages/ModelComForP";

export default function UploadQuestionFile() {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location);
  const { id, status } = location.state;

  const [error, setError] = useState({});
  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot available in session storage");
  }

  useEffect(() => {
    FindByid(id);
  }, []);

  const [fdata, setFdata] = useState({
    title: "",
    sDate: "",
    startTime: "",
    endTime: "",
    subject: "",
    staff_name: "",
    description: "",
    batchName: "",
    category: "",
    course: "",
    classMode: "",
    medium: "",
    location: "",
    teams_url: "",
    batch_id: "",
    event_id: "",
    test_num: "",
    test_topic: "",
    tol_mark: "",
    no_ques: "",
    qpath: null,
    cut_off: "",
    original_que_paper_filepath: null,
  });

  const [oth_app_batch, setOth_app_batch] = useState([]);

  const [subTopic, setSubTopic] = useState([]);

  const [swalMsg, setSwalMsg] = useState({
    show: false,
    status: "",
    message: "",
    title: "",
    BottomOkBtn: "",
    BottomCancelBtn: "",
    BottomOkClose: "",
  });
  const validateUploadFile = (data) => {
    const errors = {};
    // console.log(data);
    if (data.qpath === "" || data.qpath === null) {
      errors.qpath = "Please Upload csv file";
    }
    if (data.cut_off === "") {
      errors.cut_off = "Please enter cut off";
    }
    return errors;
  };

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  const FindByid = (id) => {
    if (id) {
      AxiosInstance.post("api/event/findById", {
        id: parseInt(id),
        event_type: 1,
      })
        .then((res) => {
          // console.log(res);
          if (res.data.status === true) {
            let el = res.data.data;
            setFdata((prevFdata) => ({
              ...prevFdata,
              title: el.name,
              sDate:
                el.start_date !== null
                  ? moment(el.start_date).format("YYYY-MM-DD")
                  : "",
              startTime: el.start_time,
              endTime: el.end_time,
              subject: el.subject,
              staff_name: el.staff_name,
              description: el.description,
              batchName: el.batch_name,
              category: el.category,
              course: el.category_class,
              classMode: el.class_mode,
              medium: el.medium,
              location: el.location,
              teams_url: el.teams_url,
              batch_id: el.batch_id,
              event_id: el.id,
              test_num: el.test_no,
              test_topic: el.test_topic,
              tol_mark: el.tot_marks,
              cut_off: el.cut_off,
              no_ques: el.no_of_questions,
              qpath: null,
              original_que_paper_filepath: el.original_que_paper_filepath,
            }));

            const subTopics =
              el.subject_test_topics && el.subject_test_topics.length > 0
                ? el.subject_test_topics
                : [];

            setSubTopic(subTopics);

            const OtherApplicableBatches =
              el.oth_app_batch && el.oth_app_batch.length > 0
                ? el.oth_app_batch
                : [];

            setOth_app_batch(OtherApplicableBatches);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  };

  const handleUpload = (e) => {
    const errors = validateUploadFile(fdata);
    // console.log(errors, "errors");
    if (Object.keys(errors).length === 0) {
      try {
        const df = {
          file_path: fdata.qpath,
          original_que_paper_filepath: fdata.original_que_paper_filepath,
          event_id: fdata.event_id,
          cut_off: fdata.cut_off,
          updated_by: userid,
        };
        // console.log(df, "df");
        AxiosInstance.post("api/test/filePath/update", df)
          .then((response) => {
            if (response.data.status === true) {
              AxiosInstance.post("/api/test/questionUpload", {
                batch_id: fdata.batch_id,
                que_paper_filepath: response.data.data.file_path,
                event_id: response.data.data.event_id,
                no_of_questions: fdata.no_ques,
                created_by: response.data.data.updated_by,
                updated_by: response.data.data.updated_by,
                updated_dt: moment().format("YYYY-MM-DD HH:mm:ss"),
              })
                .then((response) => {
                  if (response.data.status === true) {
                    Swal.fire({
                      title: "Success",
                      text: response.data.message,
                      icon: "Success",
                      confirmButtonText: "Ok",
                    });
                    navigateToPreviousScreen();
                  } else {
                    Swal.fire({
                      title: "Warning",
                      text: response.data.message,
                      icon: "Warning",
                      confirmButtonText: "Ok",
                    });
                  }
                })
                .catch((error) => {
                  return error;
                });
            }
          })
          .catch((error) => {
            return error;
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };
  const handlechange = (e) => {
    const { name, value, files } = e.target;

    if (name === "qpath" && files && files.length > 0) {
      const question_bank = files[0];
      if (
        files[0].type == "text/csv" ||
        files[0].type == "application/vnd.ms-excel"
      ) {
        if (files[0].size < 10 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("question_bank", question_bank);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setFdata((prevState) => ({
                ...prevState,
                qpath: res.data.path,
                original_que_paper_filepath: res.data.originalname,
              }));
              setError({
                ...error,
                qpath: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 10MB.";
          setError({
            ...error,
            qpath: err,
          });
        }
      } else {
        let err = "csv files only allowed";
        setError({
          ...error,
          qpath: err,
        });
      }
    } else {
      setFdata((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
    }
  };

  const handleCloseModels_Succ = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };

  const handleCloseModel = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });
    navigateToPreviousScreen();
  };

  return (
    <div className="BathDet">
      <div className="row">
        <div className="col-md-12">
          {swalMsg.show === true && (
            <ModelComForP
              show={swalMsg.show}
              message={swalMsg.message}
              status={swalMsg.status}
              title={swalMsg.title}
              BottomOkBtn={swalMsg.BottomOkBtn}
              BottomCancelBtn={swalMsg.BottomCancelBtn}
              handleCloseModels={handleCloseModel}
              // handleClose_yes={handleSPayfee}
              BottomOkClose={swalMsg.BottomOkClose}
              handleCloseModels_Succ={handleCloseModels_Succ}
            />
          )}
          <section className=" StepOne">
            <div className="heading mt-3 d-flex justify-content-between ">
              <h6 className="Tit"> Upload New Question Paper</h6>
              <Link
                to="javascript:void(0)"
                onClick={navigateToPreviousScreen}
                className="CancelButton"
              >
                Back to View Test
              </Link>
              <button
                type="button"
                className="detailEventButton"
                onClick={handleUpload}
              >
                Save Question Paper
              </button>
            </div>
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Test Details</p>
                <div className="row mb-3">
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Event Name
                      </label>
                      <span className="ReviewLabel">
                        {fdata.title !== null ? fdata.title : "⎯"}
                      </span>
                    </div>
                  </div>

                  {/* <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Test Number
                      </label>
                      <span className="ReviewLabel">
                        {fdata.test_num !== null ? fdata.test_num : "⎯"}
                      </span>
                    </div>
                  </div> */}

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Date
                      </label>
                      <span className="ReviewLabel">
                        {fdata.sDate !== null ? fdata.sDate : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Start Timing
                      </label>
                      <span className="ReviewLabel">
                        {fdata.startTime !== null
                          ? moment(fdata.startTime, "HH:mm:ss").format("h:mm A")
                          : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        End Timing
                      </label>
                      <span className="ReviewLabel">
                        {fdata.endTime !== null
                          ? moment(fdata.endTime, "HH:mm:ss").format("h:mm A")
                          : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Total Number of Questions
                      </label>
                      <span className="ReviewLabel">
                        {fdata.no_ques !== null ? fdata.no_ques : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Total Marks
                      </label>
                      <span className="ReviewLabel">
                        {fdata.tol_mark !== null ? fdata.tol_mark : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Batch Name
                      </label>
                      <span className="ReviewLabel">
                        {fdata.batchName !== null ? fdata.batchName : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Category
                      </label>
                      <span className="ReviewLabel">
                        {fdata.category !== null ? fdata.category : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Course
                      </label>
                      <span className="ReviewLabel">
                        {fdata.course !== null ? fdata.course : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Subject
                      </label>
                      <span className="ReviewLabel">
                        {fdata.subject !== null ? fdata.subject : "⎯"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className=" StepOne">
            <div className="row box">
              <div className="col-md-12 mb-3">
                <p className="StepTitle">Subjects and Topics</p>
                {subTopic.length > 0 &&
                  subTopic.map((onk) => {
                    return (
                      <div className="row ">
                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Subject
                            </label>
                            <span className="ReviewLabel">
                              {onk.subject_name || "-"}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Test Topics
                            </label>
                            <span className="ReviewLabel">
                              {onk.test_topics
                                .map((el) => el.test_topic)
                                .join(", ")}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </section>

          <section className=" StepOne">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Other Applicable Batches</p>
                <div className="row mb-3 ">
                  <div className="col-md-6">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted"
                      >
                        Other Applicable Batches
                      </label>
                      {oth_app_batch.length > 0 ? (
                        <span className="ReviewLabel">
                          {oth_app_batch.map((lk, index) => (
                            <React.Fragment key={index}>
                              {index > 0 && ", "}{" "}
                              {/* Add comma and space for elements after the first one */}
                              {lk.name}({lk.section})
                            </React.Fragment>
                          ))}
                        </span>
                      ) : (
                        <span className="ReviewLabel">_</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className=" StepOne">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Upload New Question Paper</p>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="forms ">
                      <label htmlFor="inputName" className="form-label  ">
                        Update New Question Paper
                        {fdata.qpath !== "" && fdata.qpath !== null && (
                          <a
                            target="_blank"
                            href={`${ImageUrlPrefix}/${fdata.qpath}`}
                          >
                            <Download className="download_icon" />
                          </a>
                        )}
                      </label>
                      <div className="d-flex">
                        <div className="row">
                          <div
                            className={
                              fdata.qpath !== "" && fdata.qpath !== null
                                ? "col-md-3 "
                                : "col-md-12"
                            }
                          >
                            <input
                              type="file"
                              // className={
                              //   fdata.qpath !== "" && fdata.qpath !== null
                              //     ? "InputsFile nn_upload_width"
                              //     : "InputsFile  vv_upload_width"
                              // }
                              className="InputsFile"
                              id="qpath"
                              name="qpath"
                              onChange={handlechange}
                              placeholder="Enter question path"
                              required
                            />
                          </div>
                          {fdata.qpath !== "" && fdata.qpath !== null && (
                            <div
                              className={
                                "col-md-9 d-flex align-content-center p-0"
                              }
                            >
                              <span className="nn_upload_file">
                                {fdata.qpath !== "" && fdata.qpath !== null
                                  ? fdata.original_que_paper_filepath
                                  : ""}
                              </span>
                            </div>
                          )}
                        </div>
                        {/* <input
                        type="file"
                        class="InputsFile"
                        id="qpath"
                        name="qpath"
                        onChange={handlechange}
                        placeholder="Enter question path"
                        required
                      /> */}
                        {/* <button
                type="button"
                className="detailEventButton_upload  mt-2 ms-3"
                onClick={handleUpload}
              >
                Upload
              </button> */}
                      </div>
                      {error.qpath && (
                        <small className="error">{error.qpath}</small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="forms ">
                      <label htmlFor="inputName" className="form-label  ">
                        Cut-off
                      </label>
                      <input
                        type="number"
                        class="Inputs"
                        id="cut_off"
                        value={fdata.cut_off < 0 ? 0 : fdata.cut_off}
                        name="cut_off"
                        placeholder="Enter Cut-off Mark"
                        onChange={handlechange}
                      />
                      {error.cut_off && (
                        <small className="error">{error.cut_off}</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="heading me-4 mt-3 d-flex justify-content-end ">
              <Link to="/TestList" className="CancelButton">
                Back to View Test
              </Link>
              <button
                type="button"
                className="detailEventButton"
                onClick={handleUpload}
              >
                Save Question Paper
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
