import React, { useState, useContext, useEffect, useRef } from "react";
import { AxiosInstance } from "../../axios";
import { UserContext } from "../../context/UserDetails";

import "material-icons/iconfont/material-icons.css";

import $ from "jquery";

import "../AdmissionForm.css";
import { FormValidation } from "./FormValidation";
import SpecialPrefrence from "./SpecialPrefrence";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { ImageUrlPrefix } from "../../labels";
import moment from "moment/moment";
import SpecialPre from "./SpecialPre";
import DatePickerSample from "../../shared/Component/DatePicker";

function ProfileSetting() {
  /// focus field for all text fields

  const txtAdFname = useRef(null);
  const txtAdLname = useRef(null);
  const txtAdAge = useRef(null);
  const txtAdMDob = useRef(null);
  const txtAdMStatus = useRef(null);
  const txtAdGender = useRef(null);
  const txtAdFath = useRef(null);
  const txtAdMoth = useRef(null);
  const txtAdPSize = useRef(null);
  const txtAdAno = useRef(null);
  const txtAdApath = useRef(null);
  const txtAdSpc = useRef(null);
  const txtAdCno = useRef(null);
  const txtAdCpath = useRef(null);

  const txtAdPno = useRef(null);
  const txtAdWno = useRef(null);
  const txtAdFpo = useRef(null);
  const txtAdEmail = useRef(null);
  const txtAdPhno = useRef(null);
  const txtAdPsname = useRef(null);
  const txtAdParea = useRef(null);
  const txtAdPland = useRef(null);
  const txtAdPpost = useRef(null);
  const txtAdPtaluk = useRef(null);
  const txtAdPpincode = useRef(null);
  const txtAdPcity = useRef(null);
  const txtAdPstate = useRef(null);
  const txtAdPdistrict = useRef(null);
  const txtAdPerhno = useRef(null);
  const txtAdPersname = useRef(null);
  const txtAdPerarea = useRef(null);
  const txtAdPerland = useRef(null);
  const txtAdPererpost = useRef(null);
  const txtAdPertaluk = useRef(null);
  const txtAdPerpincode = useRef(null);
  const txtAdPercity = useRef(null);
  const txtAdPerstate = useRef(null);
  const txtAdPerdistrict = useRef(null);

  //usestates
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [listErrors, setListErrors] = useState({});
  const [cEOption, setCEOption] = useState([]);
  const [sPrefrence, setSPrefrence] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const [cityOptionPerment, setCityOptionPerment] = useState([]);

  //dropdown option get from api

  useEffect(() => {
    AxiosInstance.get("api/codevalues?codetype=CTGY")
      .then((res) => {
        const cEOption = res.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setCEOption(cEOption);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  //specialperfrence object state

  const [specialPre, setSpecialPre] = useState([
    // {
    //   id: 1,
    //   name: "",
    //   file:"",
    // },
  ]);

  const [specialPrePstm, setSpecialPrePstm] = useState([]);
  // personal , communication state

  const [fieldInput, setFieldInput] = useState({
    FirstName: "",
    LastName: "",
    Fathername: "",
    MotherName: "",
    Profilephoto: null,
    DOB: "",
    age: "",
    gender: "Male",
    MaritalStatus: "Single/Unmarried",
    aadharNo: "",
    aadharCardphoto: null,
    Cast: "",
    CastFile: null,
    Mobile: "",
    original_aadhar_path: null,
    original_profile_path: null,
    original_caste_path: null,
    WhatsAppNumber: "",
    ContactNumber: false,
    ParentMobileNo: "",
    emailaddress: "",
    SamePresentAddress: false,
    BuildingNumberPresent: "",
    StreetNamePresent: "",
    AreaPresent: "",
    LandmarkPresent: "",
    districtPresent: "",
    CityPresent: "",
    statePresent: "",
    PostPresent: "",
    TalukPresent: "",
    PincodePresent: "",
    BuildingNumberPermanent: "",
    StreetNamePermanent: "",
    AreaPermanent: "",
    LandmarkPermanent: "",
    CityPermanent: "",
    districtPermanent: "",
    statePermanent: "",
    PincodePermanent: "",
    PostPermanent: "",
    TalukPermanent: "",
    SPCcategory: "",
  });

  //pincode get respones from api

  useEffect(() => {
    if (fieldInput.PincodePresent !== "") {
      AxiosInstance.get(`api/all/getAddress/${fieldInput.PincodePresent}`)
        .then((res) => {
          let code = res.data.data;
          const cityOption = code.map((item, index) => (
            <option key={index.toString()} value={item.city}>
              {item.city}
            </option>
          ));
          setCityOption(cityOption);
          fieldInput.statePresent = code[0].state;
          fieldInput.districtPresent = code[0].district;
        })
        .catch((error) => {
          return error;
        });
    }
  }, [fieldInput.PincodePresent]);

  useEffect(() => {
    if (fieldInput.SPCcategory !== "") {
      let ValueAsp = "";
      if (fieldInput.SPCcategory === "UPSC") {
        ValueAsp = "UPSC_ASP";
      } else if (fieldInput.SPCcategory === "TNPSC") {
        ValueAsp = "TNPSC_ASP";
      } else if (fieldInput.SPCcategory === "TRB") {
        ValueAsp = "TRB_ASP";
      } else if (fieldInput.SPCcategory === "TET") {
        ValueAsp = "TET_ASP";
      } else if (fieldInput.SPCcategory === "TNUSRB") {
        ValueAsp = "TNUSRB_ASP";
      } else if (fieldInput.SPCcategory === "SSC") {
        ValueAsp = "SSC_ASP";
      } else if (fieldInput.SPCcategory === "Banking") {
        ValueAsp = "Banking_ASP";
      } else if (fieldInput.SPCcategory === "Others") {
        ValueAsp = "OTHERS_ASP";
      } else if (fieldInput.SPCcategory === "RRB") {
        ValueAsp = "RRB_ASP";
      }
      AxiosInstance.get(`api/codevalues?codetype=${ValueAsp}`)
        .then((res) => {
          const specialPrefrencess = res.data.map((item, index) => ({
            value: item.name,
            label: item.name,
          }));
          setSPrefrence(specialPrefrencess);
        })
        .catch((error) => {
          return error;
        });
    }
  }, [fieldInput.SPCcategory]);

  useEffect(() => {
    if (fieldInput.PincodePermanent !== "") {
      AxiosInstance.get(`/api/all/getAddress/${fieldInput.PincodePermanent}`)
        .then((res) => {
          let code = res.data.data;

          const cityOptionPerment = code.map((item, index) => (
            <option key={index.toString()} value={item.city}>
              {item.city}
            </option>
          ));
          setCityOptionPerment(cityOptionPerment);
          fieldInput.statePermanent = code[0].state;
          fieldInput.districtPermanent = code[0].district;
        })
        .catch((error) => {
          return error;
        });
    }
  }, [fieldInput.PincodePermanent]);

  //date and time convert to normal data
  const dobCon = (userdate) => {
    if (!userdate) {
      return ""; // Handle empty input
    }
    const date = new Date(userdate);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Handle invalid date
    }
    // Extract the year, month, and day components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  // get userdtails from api and load the front end

  useEffect(() => {
    const userJSON = sessionStorage.getItem("userDetails");
    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        const userid = user.user_id;

        //  AxiosInstance.post("api/user/personal_info", { user_id: "98fbcf294e94945c20d09ad61a36c1a2" })

        AxiosInstance.post("api/user/personal_info", { user_id: userid })
          .then((response) => {
            // Handle the response here
            let userinfo = response.data.data;
            let dob = userinfo.dob;
            const date = new Date(dob);
            const dateString = date.toLocaleDateString();
            const formattedDate = moment(dateString, "MM/DD/YYYY").format(
              "YYYY-MM-DD"
            );
            const basicdetails = {
              FirstName: userinfo.first_name,
              LastName: userinfo.last_name,
              Fathername: userinfo.father_name,
              MotherName: userinfo.mother_name,
              Profilephoto:
                userinfo.profile_path !== null ? userinfo.profile_path : null,
              original_profile_path: userinfo.original_profile_path,
              DOB:
                userinfo.dob != null && userinfo.dob != ""
                  ? dobCon(userinfo.dob)
                  : "",
              age: userinfo.age,
              gender: userinfo.gender,
              MaritalStatus: userinfo.marital_status,
              aadharNo: userinfo.aadhar_no,
              aadharCardphoto:
                userinfo.aadhar_path !== null ? userinfo.aadhar_path : null,
              Cast: userinfo.community,
              original_aadhar_path: userinfo.original_aadhar_path,
              original_caste_path: userinfo.original_caste_path,
              SPCcategory: userinfo.scp[0].category,
              CastFile:
                userinfo.community_path !== null
                  ? userinfo.community_path.split("/").pop()
                  : "",
              Mobile: userinfo.phone_no,
              WhatsAppNumber: userinfo.whatsapp_no,
              ContactNumber: userinfo.contact_type === 0 ? false : true,
              ParentMobileNo: userinfo.parent_mob_no,
              emailaddress: userinfo.email,
            };

            userinfo.address.map((obj) => {
              if (obj.address_type === 1) {
                basicdetails.SamePresentAddress =
                  obj.same_address === 0 ? true : false;
                basicdetails.BuildingNumberPresent = obj.address1;
                basicdetails.StreetNamePresent = obj.address2;
                basicdetails.AreaPresent = obj.address3;
                basicdetails.LandmarkPresent = obj.landmark;
                basicdetails.districtPresent = obj.district_name;
                basicdetails.CityPresent = obj.city;
                basicdetails.statePresent = obj.state_name;
                basicdetails.PostPresent = obj.post_name;
                basicdetails.TalukPresent = obj.taluk;
                basicdetails.PincodePresent = obj.postal_code;
              } else {
                basicdetails.SamePresentAddress =
                  obj.same_address === 0 ? true : false;
                basicdetails.BuildingNumberPermanent = obj.address1;
                basicdetails.StreetNamePermanent = obj.address2;
                basicdetails.AreaPermanent = obj.address3;
                basicdetails.LandmarkPermanent = obj.landmark;
                basicdetails.districtPermanent = obj.district_name;
                basicdetails.CityPermanent = obj.city;
                basicdetails.statePermanent = obj.state_name;
                basicdetails.PostPermanent = obj.post_name;
                basicdetails.TalukPermanent = obj.taluk;
                basicdetails.PincodePermanent = obj.postal_code;
              }
            });

            setFieldInput(basicdetails);
            const specialprefrence = userinfo.scp;
            setSpecialPre(specialprefrence);
          })
          .catch((error) => {
            // Handle errors here
            console.error(error, "personal info error");
          });
      } else {
        console.log("User Id cannot avaliable in session storage");
      }
    }
  }, []);

  //convert dob to age

  const DobCovertAge = (dob) => {
    var ageInYears = moment().diff(dob, "years", false);

    // const dobDate = new Date(dob);
    // const now = new Date();
    // const ageInMs = now - dobDate;
    // const ageInYears = Math.floor(ageInMs / 31536000000); // 1 year ≈ 31536000000 ms

    return ageInYears;
  };

  //handlechange event

  const handleChangeDate = (date, name) => {
    const dateDOB = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateDOB);
    const age = DobCovertAge(date);
    if (age >= 18 && age <= 60) {
      setFieldInput({
        ...fieldInput,
        [name]: dateDOB,
        ["age"]: age,
      });
      setListErrors({
        ...listErrors,
        [name]: "",
        ["age"]: "",
      });
    } else {
      const err =
        "Please select a DOB for autofill with an age between 18 and 60 years";
      setFieldInput({
        ...fieldInput,
        age: "",
      });
      setListErrors({
        ...listErrors,
        DOB: err,
      });
    }
  };

  const handleYearChange = (year, dname) => {
    if (dname === "DOB") {
      if (fieldInput.DOB !== null && fieldInput.DOB !== "") {
        const currentDate = new Date(fieldInput.DOB);
        const newYear = year;
        currentDate.setFullYear(newYear);
        const currentDate_moment = moment(currentDate).format("YYYY-MM-DD");
        const age = DobCovertAge(currentDate_moment);
        if (age >= 18 && age <= 60) {
          setFieldInput({
            ...fieldInput,
            ["DOB"]: currentDate_moment,
            ["age"]: age,
          });
          setListErrors({
            ...listErrors,
            ["DOB"]: "",
            ["age"]: "",
          });
        } else {
          const err =
            "Please select a DOB for autofill with an age between 18 and 60 years";
          setFieldInput({
            ...fieldInput,
            age: "",
          });
          setListErrors({
            ...listErrors,
            DOB: err,
          });
        }
      }
    }
    // else if(dname === "JoinGovt"){

    //   if(list.JoinGovt !==null && list.JoinGovt !=="" && list.JoinGovt !== undefined){
    //     const currentDate = new Date(list.JoinGovt);
    //     const newYear = year;
    //     currentDate.setFullYear(newYear);
    //     const currentDate_moment  =  moment(currentDate).format("YYYY-MM-DD");
    //     setList({
    //       ...list,
    //       ['JoinGovt']: currentDate_moment,
    //     });
    //   }else{
    //     const currentDate = new Date();
    //     const newYear = year;
    //     currentDate.setFullYear(newYear);
    //     const currentDate_moment  =  moment(currentDate).format("YYYY-MM-DD");
    //     setList({
    //       ...list,
    //       ['JoinGovt']: currentDate_moment,
    //     });
    //   }

    // }
  };

  const handleMonthChange = (month, dname) => {
    // console.log(month,"month");
    if (dname === "DOB") {
      if (fieldInput.DOB !== null && fieldInput.DOB !== "") {
        const currentDate = new Date(fieldInput.DOB);
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const currentDate_moment = moment(currentDate).format("YYYY-MM-DD");
        const age = DobCovertAge(currentDate_moment);
        if (age >= 18 && age <= 60) {
          setFieldInput({
            ...fieldInput,
            ["DOB"]: currentDate_moment,
            ["age"]: age,
          });
          setListErrors({
            ...listErrors,
            ["DOB"]: "",
            ["age"]: "",
          });
        } else {
          const err =
            "Please select a DOB for autofill with an age between 18 and 60 years";
          setFieldInput({
            ...fieldInput,
            age: "",
          });
          setListErrors({
            ...listErrors,
            DOB: err,
          });
        }
      }
    }
    // else if(dname === "JoinGovt"){
    //   if(list.JoinGovt !==null && list.JoinGovt !=="" && list.JoinGovt !== undefined){
    //     const currentDate = new Date(list.JoinGovt);
    //     let monthNumber = moment(month, 'MMMM').month();
    //     currentDate.setMonth(monthNumber);
    //     const currentDate_moment  =  moment(currentDate).format("YYYY-MM-DD");
    //     setList({
    //       ...list,
    //       ['JoinGovt']: currentDate_moment,
    //     });
    //   }else{
    //     const currentDate = new Date();
    //      let monthNumber = moment(month, 'MMMM').month();
    //      currentDate.setMonth(monthNumber);
    //      const currentDate_moment  =  moment(currentDate).format("YYYY-MM-DD");
    //      setList({
    //       ...list,
    //       ['JoinGovt']: currentDate_moment,
    //     });
    //   }
    // }
  };

  const handleChange = (event) => {
    const { name, value, files, checked } = event.target;
    if (name === "Profilephoto" && files && files.length > 0) {
      const profile_path = files[0];
      if (
        files[0].type == "image/png" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/jpeg"
      ) {
        const formData = new FormData();
        formData.append("profile_path", profile_path);
        AxiosInstance.post("api/upload/", formData)
          .then((res) => {
            setFieldInput({
              ...fieldInput,
              Profilephoto: res.data.path,
              original_profile_path: res.data.originalname,
            });
            setListErrors({
              ...listErrors,
              Profilephoto: "",
            });
          })
          .catch((error) => {
            return error;
          });
      } else {
        let err = "(Png , jpg ,jpeg )images only allowed";
        setListErrors({
          ...listErrors,
          Profilephoto: err,
        });
      }
    } else if (name === "aadharCardphoto" && files && files.length > 0) {
      const aadhar_path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("aadhar_path", aadhar_path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setFieldInput({
                ...fieldInput,
                aadharCardphoto: res.data.path,
                original_aadhar_path: res.data.originalname,
              });
              setListErrors({
                ...listErrors,
                aadharCardphoto: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            aadharCardphoto: err,
          });
        }
      } else {
        let err = "pdf files only allowed";
        setListErrors({
          ...listErrors,
          aadharCardphoto: err,
        });
      }
    } else if (name === "CastFile" && files && files.length > 0) {
      const community_path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("community_path", community_path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setFieldInput({
                ...fieldInput,
                CastFile: res.data.path,
                original_caste_path: res.data.originalname,
              });
              setListErrors({
                ...listErrors,
                CastFile: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            CastFile: err,
          });
        }
      } else {
        let err = "pdf files only allowed";
        setListErrors({
          ...listErrors,
          CastFile: err,
        });
      }
    } else if (name === "PincodePresent") {
      if (/^[1-9][0-9]{5}$/.test(value) || value.length === 0) {
        setFieldInput((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["PincodePresent"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setFieldInput((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["PincodePresent"]: "Please Enter valid Pincode",
        });
        return;
      }
    } else if (name === "PincodePermanent") {
      if (/^[1-9][0-9]{5}$/.test(value) || value.length === 0) {
        setFieldInput((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["PincodePermanent"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setFieldInput((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["PincodePermanent"]: "Please Enter valid Pincode",
        });
        return;
      }
    } else if (name === "Mobile") {
      if (value.length === 10 || value.length === 0) {
        setFieldInput((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["Mobile"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setFieldInput((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["Mobile"]: "Please Enter valid 10 digit number",
        });
        return;
      }
    } else if (name === "WhatsAppNumber") {
      if (value.length === 10 || value.length === 0) {
        setFieldInput((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["WhatsAppNumber"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setFieldInput((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["WhatsAppNumber"]: "Please Enter valid 10 digit number",
        });
        return;
      }
    } else if (name === "ParentMobileNo") {
      if (value.length === 10 || value.length === 0) {
        setFieldInput((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["ParentMobileNo"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setFieldInput((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["ParentMobileNo"]: "Please Enter valid 10 digit number",
        });
        return;
      }
    }
    // else if (name === "DOB") {
    //   DobCovertAge(value);
    //   setFieldInput({
    //     ...fieldInput,
    //     [name]: value,
    //   });
    // }
    else if (name === "ContactNumber") {
      setFieldInput({
        ...fieldInput,
        [name]: checked,
      });
      if (checked === true) {
        let Mobile = fieldInput.Mobile;
        fieldInput.WhatsAppNumber = Mobile;
      }
    } else if (name === "SamePresentAddress") {
      setFieldInput({
        ...fieldInput,
        [name]: checked,
      });
      if (checked) {
        let BuildingNumber = fieldInput.BuildingNumberPresent;
        let area = fieldInput.AreaPresent;
        let post = fieldInput.PostPresent;
        let Taluk = fieldInput.TalukPresent;
        let Landmark = fieldInput.LandmarkPresent;
        let street = fieldInput.StreetNamePresent;
        let pincode = fieldInput.PincodePresent;
        let state = fieldInput.statePresent;
        let district = fieldInput.districtPresent;
        let city = fieldInput.CityPresent;
        fieldInput.BuildingNumberPermanent = BuildingNumber;
        fieldInput.AreaPermanent = area;
        fieldInput.LandmarkPermanent = Landmark;
        fieldInput.StreetNamePermanent = street;
        fieldInput.PostPermanent = post;
        fieldInput.TalukPermanent = Taluk;
        fieldInput.PincodePermanent = pincode;
        fieldInput.statePermanent = state;
        fieldInput.districtPermanent = district;
        fieldInput.CityPermanent = city;
      }
    } else if (name === "SPCcategory") {
      setFieldInput({
        ...fieldInput,
        [name]: value,
      });
      setSpecialPre((prevState) => {
        return prevState.map((q) => {
          return { ...q, ["name"]: "" };
        });
      });
      setListErrors({
        ...listErrors,
        SPCcategory: "",
      });
    } else {
      setFieldInput({
        ...fieldInput,
        [name]: value,
      });

      setListErrors({
        ...listErrors,
        [name]: "",
      });
    }
  };
  const specialAdmissionHandleChanges = (option, id, spename) => {
    // console.log(option, spename);
    setSpecialPre((prevState) => {
      // Check if name already exists in prevState
      const isNameExists = prevState.some(
        (q) => q.id !== id && q.name === option.value
      );

      if (isNameExists) {
        alert("Name already exists");
        return prevState;
      }
      // Update the name if it doesn't already exist
      return prevState.map((q) => {
        if (q.id === id) {
          return { ...q, ["name"]: option.value, ["error_name"]: "" };
        }
        return q;
      });
    });
    // setListErrors({
    //   ...listErrors,
    //   ["spc"]:"",
    // });
  };

  //specialprefrence handle change

  const specialHandleChange = (e, id) => {
    const { name, value, files, checked } = e.target;
    if (name === "file" && files && files.length > 0) {
      const pref_path = files[0];
      if (files[0].type == "application/pdf") {
        const formData = new FormData();
        formData.append("pref_path", pref_path);
        AxiosInstance.post("api/upload/", formData)
          .then((res) => {
            setSpecialPre((prevState) => {
              return prevState.map((q) => {
                if (q.id === id) {
                  return {
                    ...q,
                    file: res.data.path,
                    original_pref_path: res.data.originalname,
                  };
                }
                return q;
              });
            });
            // Set specialPrePstm to [0] here
          })
          .catch((error) => {
            return error;
          });
      }
    } else if (name === "path_sslc" && files && files.length > 0) {
      // console.log("sdsf");
      const scp_path_sslc = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("scp_path_sslc", scp_path_sslc);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSpecialPre((prevState) => {
                return prevState.map((q) => {
                  if (q.id === id) {
                    return {
                      ...q,
                      path_sslc: res.data.path,
                      original_path_sslc: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          alert("File must be less than 2 mb");
        }
      }
    } else if (name === "path_hsc" && files && files.length > 0) {
      const scp_path_hsc = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("scp_path_hsc", scp_path_hsc);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSpecialPre((prevState) => {
                return prevState.map((q) => {
                  if (q.id === id) {
                    return {
                      ...q,
                      path_hsc: res.data.path,
                      original_path_hsc: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          alert("File must be less than 2 mb");
        }
      }
    } else if (name === "path_ug" && files && files.length > 0) {
      const scp_path_ug = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("scp_path_ug", scp_path_ug);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSpecialPre((prevState) => {
                return prevState.map((q) => {
                  if (q.id === id) {
                    return {
                      ...q,
                      path_ug: res.data.path,
                      original_path_ug: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          alert("File must be less than 2 mb");
        }
      }
    } else if (name === "path_pg" && files && files.length > 0) {
      const scp_path_pg = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("scp_path_pg", scp_path_pg);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSpecialPre((prevState) => {
                return prevState.map((q) => {
                  if (q.id === id) {
                    return {
                      ...q,
                      path_pg: res.data.path,
                      original_path_pg: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          alert("File must be less than 2 mb");
        }
      }
    } else if (name === "pstm_type") {
      // console.log(value, name , checked);
      if (checked) {
        const psctm = [];
        setSpecialPre((prevState) => {
          return prevState.map((q) => {
            if (q.id === id) {
              // console.log(value, "0 type");
              if (value == 0) {
                psctm.push = value;
                // console.log("0 type", psctm);
                return {
                  ...q,
                  [name]: psctm,
                  pstm_type: [0, ...q.pstm_type],
                  error_path_sslc: "",
                };
              } else if (value == 1) {
                if (q.pstm_type.includes(0)) {
                  psctm.push = value;
                  return {
                    ...q,
                    [name]: psctm,
                    pstm_type: [1, ...q.pstm_type],
                    error_path_sslc: "",
                  };
                }
                return {
                  ...q,
                  [name]: psctm,
                  error_path_sslc: "Please select sslc",
                };
              } else if (value == 2) {
                if (q.pstm_type.includes(0, 1)) {
                  psctm.push = value;
                  return {
                    ...q,
                    [name]: psctm,
                    pstm_type: [2, ...q.pstm_type],
                    error_path_sslc: "",
                  };
                }
                return {
                  ...q,
                  [name]: psctm,
                  error_path_sslc: "Please select sslc , hsc",
                };
              } else if (value == 3) {
                // console.log(q.pstm_type,"q.pstm_type");
                const valueTocheck = [0, 1, 2];
                if (
                  valueTocheck.every((values) => q.pstm_type.includes(values))
                ) {
                  psctm.push = value;
                  return {
                    ...q,
                    [name]: psctm,
                    pstm_type: [3, ...q.pstm_type],
                    error_path_sslc: "",
                  };
                }
                return {
                  ...q,
                  [name]: psctm,
                  error_path_sslc: "Please select sslc , hsc , ug",
                };
              }
            }
            return q;
          });
        });
      } else {
        const psctm = [];
        psctm.push = value;
        setSpecialPre((prevState) => {
          return prevState.map((q) => {
            if (q.id === id) {
              if (value == 0) {
                return {
                  ...q,
                  [name]: psctm,
                  pstm_type: [],
                };
              } else if (value == 1) {
                return {
                  ...q,
                  [name]: psctm,
                  pstm_type: q.pstm_type.filter(
                    (item) => item !== 2 && item !== 1 && item !== 3
                  ),
                };
              } else if (value == 2) {
                return {
                  ...q,
                  [name]: psctm,
                  pstm_type: q.pstm_type.filter(
                    (item) => item !== 2 && item !== 3
                  ),
                };
              } else {
                return {
                  ...q,
                  [name]: psctm,
                  pstm_type: q.pstm_type.filter(
                    (item) => item !== parseInt(value)
                  ),
                };
              }
            }
            return q;
          });
        });
      }
    } else {
      setSpecialPre((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value };
          }
          return q;
        });
      });
    }
  };

  //special prefrence delete function
  const Deletespre = (id, name) => {
    const userJSON = sessionStorage.getItem("userDetails");
    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        const userid = user.user_id;
        AxiosInstance.post("api/course/admission/delete/scp", {
          user_id: userid,
          name: name,
          id: id,
        })
          .then((res) => {
            if (res.status === 200) {
              setSpecialPre((prevState) =>
                prevState
                  .filter((item) => item.id !== id)
                  .map((item, index) => ({ ...item, id: index + 1 }))
              );

              // Swal.fire({
              //   title: "success",
              //   text: "Deleted Successfully",
              //   icon: "success",
              //   confirmButtonText: "Ok",
              // }).then((result) => {
              //   if (result.isConfirmed) {

              //   }
              // });
            }
          })
          .catch((error) => {
            return error;
          });
      }
    } else {
      console.log("User Id cannot avaliable in session storage");
    }
  };
  //special prefrence add function
  const AddPrefrence = () => {
    if (sPrefrence.length > specialPre.length) {
      setSpecialPre((prevState) => [
        ...prevState,
        {
          id: prevState.length > 0 ? prevState.length + 1 : 1,
          name: "",
          file: "",
          path_sslc: "",
          path_hsc: "",
          path_ug: "",
          path_pg: "",
          original_pref_path: null,
          original_path_sslc: null,
          original_path_hsc: null,
          original_path_ug: null,
          original_path_pg: null,
          error_name: "",
          error_path_sslc: "",
          error_path_hsc: "",
          error_path_ug: "",
          error_path_pg: "",
          pstm_type: [],
        },
      ]);
    } else {
      Swal.fire({
        title: "Warning",
        text: "you reach more Special Preferences",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
  };

  // const handleSubmitss = (e) => {
  //   let mod = specialPre.map((el)=>{

  //     if(el.name === ""){
  //       el.errorName="Please enter name";
  //       return el;
  //     }else if(el.file ===""){
  //       el.errorFile="Please choose File";
  //       return el;
  //     }
  //    });
  //    setSpecialPre(mod);
  // };

  //handlesubmit function

  const useref_validation = {
    firstName: txtAdFname.current,
    lastName: txtAdLname.current,
    age: txtAdAge.current,
    mdob: txtAdMDob.current,
    mStatus: txtAdMStatus.current,
    gender: txtAdGender.current,
    father: txtAdFath.current,
    mother: txtAdMoth.current,
    pSize: txtAdPSize.current,
    ano: txtAdAno.current,
    apath: txtAdApath.current,
    cno: txtAdCno.current,
    cpath: txtAdCpath.current,
    SPCcategory: txtAdSpc,
    pno: txtAdPno.current,
    wno: txtAdWno.current,
    fpo: txtAdFpo.current,
    email: txtAdEmail.current,
    phno: txtAdPhno.current,
    psname: txtAdPsname.current,
    parea: txtAdParea.current,
    pland: txtAdPland.current,
    ppost: txtAdPpost.current,
    ptaluk: txtAdPtaluk.current,
    ppincode: txtAdPpincode.current,
    pcity: txtAdPcity.current,
    pstate: txtAdPstate.current,
    pdistrict: txtAdPdistrict.current,
    perhno: txtAdPerhno.current,
    persname: txtAdPersname.current,
    perarea: txtAdPerarea.current,
    perland: txtAdPerland.current,
    pererpost: txtAdPererpost.current,
    pertaluk: txtAdPertaluk.current,
    perpincode: txtAdPerpincode.current,
    percity: txtAdPercity.current,
    perstate: txtAdPerstate.current,
    perdistrict: txtAdPerdistrict.current,
  };

  const handleSubmit = (e) => {
    // console.log(specialPre,specialPre.map(el => el.original_path_hsc),"sdafafaf");
    const errors = FormValidation(
      fieldInput,
      useref_validation,
      specialPre,
      setSpecialPre
    );
    if (
      Object.keys(errors).length === 0 &&
      specialPre.length > 0 &&
      specialPre.every(
        (el) =>
          el.name !== "" ||
          (el.name.trim() === "Tamil Medium (PSTM)" && el.pstm_type.length > 0)
      )
    ) {
      const address = [
        {
          address1: fieldInput.BuildingNumberPresent,
          address2: fieldInput.StreetNamePresent,
          address3: fieldInput.AreaPresent,
          landmark: fieldInput.LandmarkPresent,
          post_name: fieldInput.PostPresent,
          taluk: fieldInput.TalukPresent,
          postal_code: fieldInput.PincodePresent,
          city: fieldInput.CityPresent,
          district_name: fieldInput.districtPresent,
          state_name: fieldInput.statePresent,
          same_address: fieldInput.SamePresentAddress == true ? "0" : "1",
          address_type: "1",
        },
        {
          address1:
            fieldInput.SamePresentAddress == true
              ? fieldInput.BuildingNumberPresent
              : fieldInput.BuildingNumberPermanent,
          address2:
            fieldInput.SamePresentAddress == true
              ? fieldInput.StreetNamePresent
              : fieldInput.StreetNamePermanent,
          address3:
            fieldInput.SamePresentAddress == true
              ? fieldInput.AreaPresent
              : fieldInput.AreaPermanent,
          landmark:
            fieldInput.SamePresentAddress == true
              ? fieldInput.LandmarkPresent
              : fieldInput.LandmarkPermanent,
          post_name:
            fieldInput.SamePresentAddress == true
              ? fieldInput.PostPresent
              : fieldInput.PostPermanent,
          taluk:
            fieldInput.SamePresentAddress == true
              ? fieldInput.TalukPresent
              : fieldInput.TalukPermanent,
          postal_code:
            fieldInput.SamePresentAddress == true
              ? fieldInput.PincodePresent
              : fieldInput.PincodePermanent,
          city:
            fieldInput.SamePresentAddress == true
              ? fieldInput.CityPresent
              : fieldInput.CityPermanent,
          district_name:
            fieldInput.SamePresentAddress == true
              ? fieldInput.districtPresent
              : fieldInput.districtPermanent,
          state_name:
            fieldInput.SamePresentAddress == true
              ? fieldInput.statePresent
              : fieldInput.statePermanent,
          same_address: fieldInput.SamePresentAddress == true ? "0" : "1",
          address_type: "2",
        },
      ];
      let SP = specialPre.map((item) => {
        return {
          id: item.id,
          name: item.name,
          category: fieldInput.SPCcategory,
          file: item.file !== null ? item.file : null,
          path_sslc: item.path_sslc !== null ? item.path_sslc : null,
          path_hsc: item.path_hsc !== null ? item.path_hsc : null,
          path_ug: item.path_ug !== null ? item.path_ug : null,
          path_pg: item.path_pg !== null ? item.path_pg : null,
          original_pref_path:
            item.original_pref_path !== null ? item.original_pref_path : null,
          original_path_sslc:
            item.original_path_sslc !== null ? item.original_path_sslc : null,
          original_path_hsc:
            item.original_path_hsc !== null ? item.original_path_hsc : null,
          original_path_ug:
            item.original_path_ug !== null ? item.original_path_ug : null,
          original_path_pg:
            item.original_path_pg !== null ? item.original_path_pg : null,
          pstm_type: item.pstm_type,
        };
      });
      const Form_value = {
        user_id: user.user_id,
        first_name: fieldInput.FirstName,
        last_name: fieldInput.LastName,
        father_name: fieldInput.Fathername,
        mother_name: fieldInput.MotherName,
        dob: fieldInput.DOB,
        age: fieldInput.age,
        gender: fieldInput.gender,
        marital_status: fieldInput.MaritalStatus,
        profile_path: fieldInput.Profilephoto,
        aadhar_no: fieldInput.aadharNo,
        aadhar_path: fieldInput.aadharCardphoto,
        community_name: fieldInput.Cast,
        community_path: fieldInput.CastFile,
        original_aadhar_path: fieldInput.original_aadhar_path,
        original_profile_path: fieldInput.original_profile_path,
        original_caste_path: fieldInput.original_caste_path,
        scp: SP,
        email: fieldInput.emailaddress,
        phone_no: fieldInput.Mobile,
        whatsapp_no:
          fieldInput.ContactNumber === true
            ? fieldInput.Mobile
            : fieldInput.WhatsAppNumber,
        parent_mob_no: fieldInput.ParentMobileNo,
        contact_type: fieldInput.ContactNumber === true ? "1" : "0",
        address: address,
        updated_by: user.user_id,
      };

      AxiosInstance.post("api/user/basic_info", Form_value)
        .then((res) => {
          Swal.fire({
            title: "success",
            text: res.data.message,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
            }
          });
        })
        .catch((error) => {
          return error;
        });
    } else {
      setListErrors(errors);
    }
  };

  //return function start
  return (
    <div>
      <div className="AdmissionForm AdmissionForm_section">
        <div className="row">
          <div className="col-md-12">
            <section className="StepOne ">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Basic Details</p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="forms">
                        <label htmlFor="FirstName">First Name</label>
                        <input
                          ref={txtAdFname}
                          id="FirstName"
                          type="text"
                          name="FirstName"
                          className="Inputs"
                          onChange={handleChange}
                          value={fieldInput.FirstName}
                          placeholder="First Name"
                          autoComplete="FirstName"
                          minLength={5}
                          maxLength={15}
                          required
                        />
                        {listErrors.FirstName && (
                          <small className="errors fw-semibold">
                            {listErrors.FirstName}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="LastName">Last Name</label>
                        <input
                          ref={txtAdLname}
                          id="LastName"
                          type="text"
                          name="LastName"
                          placeholder=" Last Name"
                          onChange={handleChange}
                          value={fieldInput.LastName}
                          className="Inputs"
                          minLength={5}
                          maxLength={15}
                          autoComplete="LastName"
                          required
                        />
                        {listErrors.LastName && (
                          <small className="errors fw-semibold">
                            {listErrors.LastName}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="Fathername">
                          Father's Name/Guardian's Name
                        </label>
                        <input
                          ref={txtAdFath}
                          id="Fathername"
                          type="text"
                          name="Fathername"
                          onChange={handleChange}
                          value={fieldInput.Fathername}
                          placeholder=" Fathers Name"
                          className="Inputs"
                          minLength={5}
                          maxLength={15}
                          autoComplete="Fathername"
                          required
                        />
                        {listErrors.Fathername && (
                          <small className="errors fw-semibold">
                            {listErrors.Fathername}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="MotherName">Mother's Name</label>
                        <input
                          ref={txtAdMoth}
                          id="MotherName"
                          type="text"
                          name="MotherName"
                          onChange={handleChange}
                          value={fieldInput.MotherName}
                          placeholder=" Mother Name"
                          className="Inputs"
                          minLength={5}
                          maxLength={15}
                          autoComplete="MotherName"
                          required
                        />
                        {listErrors.MotherName && (
                          <small className="errors fw-semibold">
                            {listErrors.MotherName}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="DOB">Date of Birth</label>
                        {/* <input
                         ref={txtAdMDob}
                          id="DOB"
                          type="date"
                          name="DOB"
                          onChange={handleChange}
                          value={fieldInput.DOB}
                          className="Inputs"
                          autoComplete="DOB"
                          required
                        /> */}
                        <DatePickerSample
                          handleYearChange={handleYearChange}
                          handleMonthChange={handleMonthChange}
                          txtAdMDob={txtAdMDob}
                          name={"DOB"}
                          handleChange={handleChangeDate}
                          fieldInput={fieldInput.DOB}
                        />
                        {listErrors.DOB && (
                          <small className="errors fw-semibold">
                            {listErrors.DOB}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="age">Age</label>
                        <input
                          ref={txtAdAge}
                          id="age"
                          type="text"
                          readOnly
                          name="age"
                          onChange={handleChange}
                          value={fieldInput.age}
                          className="Inputs"
                          placeholder="Age"
                          autoComplete="age"
                          required
                        />
                        {listErrors.age && (
                          <small className="errors fw-semibold">
                            {listErrors.age}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <div className="forms row  ">
                        <label htmlFor="Gender">Gender</label>
                        <div className="d-flex  col-md-12" id="radiobuttons">
                          <div className="form-check form-check-inline Mobileform-check-inline">
                            <input
                              ref={txtAdGender}
                              className="checkbox_radio"
                              type="radio"
                              name="gender"
                              onChange={handleChange}
                              checked={
                                fieldInput.gender === "Male" ? true : false
                              }
                              id="Male"
                              value="Male"
                            />
                            <label className="checkbox-label" htmlFor="Male">
                              Male
                            </label>
                          </div>
                          <div className="form-check form-check-inline Mobileform-check-inline">
                            <input
                              className="checkbox_radio"
                              type="radio"
                              name="gender"
                              id="Female"
                              onChange={handleChange}
                              checked={
                                fieldInput.gender === "Female" ? true : false
                              }
                              value="Female"
                            />
                            <label className="checkbox-label" htmlFor="Female">
                              Female
                            </label>
                          </div>
                          <div className="form-check form-check-inline Mobileform-check-inline">
                            <input
                              className="checkbox_radio"
                              type="radio"
                              name="gender"
                              id="Transgender"
                              onChange={handleChange}
                              checked={
                                fieldInput.gender === "Transgender"
                                  ? true
                                  : false
                              }
                              value="Transgender"
                            />
                            <label
                              className="checkbox-label"
                              htmlFor="Transgender"
                            >
                              Transgender
                            </label>
                          </div>
                        </div>
                      </div>
                      {listErrors.gender && (
                        <small className="errors fw-semibold">
                          {listErrors.gender}
                        </small>
                      )}
                    </div>

                    <div className="col-md-6">
                      <div className="forms row  ">
                        <label htmlFor="MaritalStatus">Marital Status</label>
                        <div
                          className="d-flex  ms-4 col-md-12"
                          id="radiobuttons"
                        >
                          <div className="form-checks form-check-inline Mobileform-check-inline">
                            <input
                              ref={txtAdMStatus}
                              className="checkbox_radio"
                              type="radio"
                              name="MaritalStatus"
                              onChange={handleChange}
                              checked={
                                fieldInput.MaritalStatus === "Single/Unmarried"
                                  ? true
                                  : false
                              }
                              id="Single/Unmarried"
                              value="Single/Unmarried"
                            />
                            <label
                              className="checkbox-label"
                              htmlFor="Single/Unmarried"
                            >
                              Single/Unmarried
                            </label>
                          </div>
                          <div className="form-checks form-check-inline Mobileform-check-inline">
                            <input
                              className="checkbox_radio"
                              type="radio"
                              name="MaritalStatus"
                              id="Married"
                              onChange={handleChange}
                              checked={
                                fieldInput.MaritalStatus === "Married"
                                  ? true
                                  : false
                              }
                              value="Married"
                            />
                            <label className="checkbox-label" htmlFor="Married">
                              Married
                            </label>
                          </div>

                          <div className="form-checks form-check-inline Mobileform-check-inline">
                            <input
                              className="checkbox_radio"
                              type="radio"
                              name="MaritalStatus"
                              id="Widowed"
                              onChange={handleChange}
                              checked={
                                fieldInput.MaritalStatus === "Widowed"
                                  ? true
                                  : false
                              }
                              value="Widowed"
                            />
                            <label className="checkbox-label" htmlFor="Widowed">
                              Widowed
                            </label>
                          </div>
                        </div>
                      </div>
                      {listErrors.MaritalStatus && (
                        <small className="errors fw-semibold">
                          {listErrors.MaritalStatus}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="sectionTwo ">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Passport Size Photograph</p>
                  <div className="row">
                    <div className="col-md-6 d-flex  justify-content-center">
                      <div className="forms">
                        <img
                          className="ProfilePhoto  bg-opacity-75"
                          src={`${ImageUrlPrefix}/${fieldInput.Profilephoto}`}
                          // alt="profile"
                        />

                        {!fieldInput.Profilephoto && (
                          <span id="text">Profile Picture</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="Profilephoto">
                          Upload Passport Size Photograph
                        </label>
                        <input
                          ref={txtAdPSize}
                          id="Profilephoto"
                          type="file"
                          name="Profilephoto"
                          onChange={handleChange}
                          className="InputsFile"
                          autoComplete="Profilephoto"
                          required
                        />
                        {listErrors.Profilephoto && (
                          <small className="errors fw-semibold">
                            {listErrors.Profilephoto}
                          </small>
                        )}
                      </div>

                      <div className="forms mt-4 ms-md-3 mb-4">
                        <h6 className="profileText">Note:</h6>
                        <p className="warningOne my-2">
                          Image format: The recommended image format for profile
                          pictures on Anna IAS Academy is JPEG or PNG.
                        </p>
                        <h6 className="warningTwo">
                          <span className="fw-semibold">Image size</span>:
                          Profile pictures should be a minimum of 400x400 pixels
                          and a maximum of 1024x1024 pixels. The file size
                          should not exceed 2 MB.
                        </h6>
                        <h6 className="warningTwo">
                          <span className="fw-semibold">
                            Image quality and content
                          </span>
                          : Choose a clear and high-quality image for your
                          profile picture. Your profile picture should be
                          appropriate and professional.
                        </h6>
                        <h6 className="warningTwo">
                          <span className="fw-semibold">Image orientation</span>
                          :Profile pictures should be in a square format, as
                          they will be displayed as a circle on the platform.
                          Make sure your image is not stretched or distorted.
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="sectionTwo">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">ID Proofs</p>

                  <div className="row mb-4">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="aadharNo">
                          AADHAR Number
                          <span data-required="true" aria-hidden="true"></span>
                        </label>
                        <input
                          ref={txtAdAno}
                          id="aadharNo"
                          type="number"
                          name="aadharNo"
                          onChange={handleChange}
                          value={
                            fieldInput.aadharNo < 0 ? 0 : fieldInput.aadharNo
                          }
                          className="Inputs"
                          placeholder="Aadhar Number"
                          autoComplete="aadharNo"
                          required
                        />
                        {listErrors.aadharNo && (
                          <small className="errors fw-semibold">
                            {listErrors.aadharNo}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="forms ">
                        <label htmlFor="aadharCardphoto">
                          Upload AADHAR Card (In pdf/Img format)
                          {fieldInput.aadharCardphoto !== null && (
                            <a
                              href={`${ImageUrlPrefix}/${fieldInput.aadharCardphoto}`}
                              target="_blank"
                              download
                            >
                              <span className="material-icons align-middle download_icon">
                                file_download
                              </span>
                            </a>
                          )}
                        </label>
                        <div className="row">
                          <div
                            className={
                              fieldInput.aadharCardphoto !== "" &&
                              fieldInput.aadharCardphoto !== null
                                ? "col-md-3 "
                                : "col-md-12"
                            }
                          >
                            <input
                              ref={txtAdApath}
                              id="aadharCardphoto"
                              type="file"
                              name="aadharCardphoto"
                              onChange={handleChange}
                              placeholder="Upload AADHAR Card"
                              className={
                                fieldInput.aadharCardphoto !== "" &&
                                fieldInput.aadharCardphoto !== null
                                  ? "InputsFile nn_upload_width"
                                  : "InputsFile  vv_upload_width"
                              }
                              autoComplete="aadharCardphoto"
                              required
                            />
                          </div>
                          {fieldInput.aadharCardphoto !== "" &&
                            fieldInput.aadharCardphoto !== null && (
                              <div
                                className={
                                  "col-md-9 d-flex align-content-center p-0"
                                }
                              >
                                <span className="nn_upload_file">
                                  {fieldInput.aadharCardphoto !== "" &&
                                  fieldInput.aadharCardphoto !== null
                                    ? fieldInput.original_aadhar_path
                                    : ""}
                                </span>
                                <span className="material-icons align-middle text-danger verified_upload">
                                  verified
                                </span>
                              </div>
                            )}
                        </div>

                        {listErrors.aadharCardphoto && (
                          <small className="errors fw-semibold">
                            {listErrors.aadharCardphoto}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="sectionTwo">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Communal Reservation Category</p>

                  <div className="row mb-4">
                    <div className="col-lg-6 col-md-12">
                      <div className="forms ">
                        <label htmlFor="Cast">Category</label>

                        <select
                          ref={txtAdCno}
                          id="Cast"
                          name="Cast"
                          className="Inputs"
                          onChange={handleChange}
                          value={fieldInput.Cast}
                          required
                        >
                          <option value="" disabled>
                            Please select
                          </option>
                          {cEOption}
                        </select>
                        {listErrors.Cast && (
                          <small className="errors fw-semibold">
                            {listErrors.Cast}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="forms ">
                        <label htmlFor="CastFile">
                          Upload Certificate (In pdf format){" "}
                          <span className="optionFields">(Optional)</span>
                          {fieldInput.CastFile !== null &&
                            fieldInput.CastFile !== "" && (
                              <a
                                href={`${ImageUrlPrefix}/${fieldInput.CastFile}`}
                                target="_blank"
                                download
                              >
                                <span className="material-icons align-middle download_icon">
                                  file_download
                                </span>
                              </a>
                            )}
                        </label>
                        <div className="row">
                          <div
                            className={
                              fieldInput.CastFile !== "" &&
                              fieldInput.CastFile !== null
                                ? "col-md-3 "
                                : "col-md-12"
                            }
                          >
                            <input
                              ref={txtAdCpath}
                              id="CastFile"
                              type="file"
                              name="CastFile"
                              onChange={handleChange}
                              className={
                                fieldInput.CastFile !== "" &&
                                fieldInput.CastFile !== null
                                  ? "InputsFile nn_upload_width"
                                  : "InputsFile  vv_upload_width"
                              }
                              placeholder="Upload Certificate"
                              autoComplete="CastFile"
                            />
                          </div>
                          {fieldInput.CastFile !== "" &&
                            fieldInput.CastFile !== null && (
                              <div
                                className={
                                  "col-md-9 d-flex align-content-center p-0"
                                }
                              >
                                <span className="nn_upload_file">
                                  {fieldInput.CastFile !== "" &&
                                  fieldInput.CastFile !== null
                                    ? fieldInput.original_caste_path
                                    : ""}
                                </span>
                                <span className="material-icons align-middle text-danger verified_upload">
                                  verified
                                </span>
                              </div>
                            )}
                        </div>
                        {listErrors.CastFile && (
                          <small className="errors fw-semibold">
                            {listErrors.CastFile}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="sectionTwo">
              {specialPre.map((item, index) => (
                <SpecialPre
                  key={index.toString()}
                  record={item}
                  length={specialPre.length}
                  specialAdmissionHandleChanges={specialAdmissionHandleChanges}
                  specialHandleChange={specialHandleChange}
                  sPrefrence={sPrefrence}
                  Deletespre={Deletespre}
                  AddPrefrence={AddPrefrence}
                  txtAdSpc={txtAdSpc}
                  handleChange={handleChange}
                  list={fieldInput.SPCcategory}
                  listErrors={listErrors.SPCcategory}
                />
              ))}
            </section>

            <section className="sectionThree">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Contact Details</p>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="Mobile">Phone Number</label>
                        <input
                          ref={txtAdPno}
                          id="Mobile"
                          type="tel"
                          name="Mobile"
                          onChange={handleChange}
                          value={fieldInput.Mobile}
                          className="Inputs"
                          placeholder="Phone Number"
                          autoComplete="Mobile"
                          required
                          maxLength={10}
                          inputMode="tel"
                        />
                        {listErrors.Mobile && (
                          <p className="errors">{listErrors.Mobile}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="forms ">
                        <div className="d-flex justify-content-between">
                          <label htmlFor="WhatsAppNumber ">
                            WhatsApp Number
                          </label>
                          <label>
                            <input
                              ref={txtAdWno}
                              className="form-check-input me-2 align-text-center"
                              type="checkbox"
                              id="ContactNumber"
                              name="ContactNumber"
                              onChange={handleChange}
                              value={fieldInput.ContactNumber}
                              checked={
                                fieldInput.ContactNumber === true ? true : false
                              }
                            />
                            <span
                              className="form-check-label"
                              htmlFor="ContactNumber"
                            >
                              Same as Contact Number
                            </span>
                          </label>
                        </div>
                        <input
                          ref={txtAdWno}
                          id="WhatsAppNumber"
                          type="tel"
                          name="WhatsAppNumber"
                          placeholder="WhatsApp Number"
                          maxLength={10}
                          className="Inputs"
                          onChange={handleChange}
                          value={
                            fieldInput.ContactNumber === true
                              ? fieldInput.Mobile
                              : fieldInput.WhatsAppNumber
                          }
                          autoComplete="WhatsAppNumber"
                          required
                        />
                        {listErrors.WhatsAppNumber && (
                          <p className="errors">{listErrors.WhatsAppNumber}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="ParentMobileNo">
                          Father/Guardian Phone Number
                        </label>
                        <input
                          ref={txtAdFpo}
                          id="ParentMobileNo"
                          type="text"
                          name="ParentMobileNo"
                          className="Inputs"
                          onChange={handleChange}
                          value={fieldInput.ParentMobileNo}
                          placeholder="MobileNo"
                          autoComplete="ParentMobileNo"
                          required
                        />
                        {listErrors.ParentMobileNo && (
                          <p className="errors">{listErrors.ParentMobileNo}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="emailaddress">Email ID</label>
                        <input
                          ref={txtAdEmail}
                          id="emailaddress"
                          type="email"
                          readOnly
                          name="emailaddress"
                          className="Inputs"
                          onChange={handleChange}
                          value={fieldInput.emailaddress}
                          placeholder=" Email ID"
                          autoComplete="emailaddress"
                          required
                        />
                        {listErrors.emailaddress && (
                          <p className="errors">{listErrors.emailaddress}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="sectionFour">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Present Address</p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="BuildingNumberPresent">
                          House/Building Number
                        </label>
                        <input
                          ref={txtAdPhno}
                          id="BuildingNumberPresent"
                          type="text"
                          name="BuildingNumberPresent"
                          className="Inputs"
                          onChange={handleChange}
                          value={fieldInput.BuildingNumberPresent}
                          placeholder=" House/Building Number"
                          autoComplete="BuildingNumberPresent"
                          required
                        />
                        {listErrors.BuildingNumberPresent && (
                          <p className="errors">
                            {listErrors.BuildingNumberPresent}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="forms ">
                        <div className="forms ">
                          <label htmlFor="StreetNamePresent">Street Name</label>
                          <input
                            ref={txtAdPsname}
                            id="StreetNamePresent"
                            type="text"
                            name="StreetNamePresent"
                            className="Inputs"
                            onChange={handleChange}
                            value={fieldInput.StreetNamePresent}
                            placeholder="Street Name"
                            autoComplete="StreetNamePresent"
                            required
                          />
                          {listErrors.StreetNamePresent && (
                            <p className="errors">
                              {listErrors.StreetNamePresent}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="AreaPresent">Locality/Area</label>
                        <input
                          ref={txtAdParea}
                          id="AreaPresent"
                          type="text"
                          name="AreaPresent"
                          className="Inputs"
                          onChange={handleChange}
                          value={fieldInput.AreaPresent}
                          placeholder="Locality/Area"
                          autoComplete="AreaPresent"
                          required
                        />
                        {listErrors.AreaPresent && (
                          <p className="errors">{listErrors.AreaPresent}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="LandmarkPresent">Landmark</label>
                        <input
                          ref={txtAdPland}
                          id="LandmarkPresent"
                          type="text"
                          name="LandmarkPresent"
                          className="Inputs"
                          onChange={handleChange}
                          value={fieldInput.LandmarkPresent}
                          placeholder=" Landmark"
                          autoComplete="LandmarkPresent"
                          required
                        />
                        {listErrors.LandmarkPresent && (
                          <p className="errors">{listErrors.LandmarkPresent}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="PostPresent">Post</label>
                        <input
                          ref={txtAdPpost}
                          id="PostPresent"
                          type="text"
                          name="PostPresent"
                          className="Inputs"
                          onChange={handleChange}
                          value={fieldInput.PostPresent}
                          placeholder=" Post"
                          autoComplete="PostPresent"
                          required
                        />

                        {listErrors.PostPresent && (
                          <p className="errors">{listErrors.PostPresent}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="TalukPresent">Taluk</label>
                        <input
                          ref={txtAdPtaluk}
                          id="TalukPresent"
                          type="text"
                          name="TalukPresent"
                          className="Inputs"
                          onChange={handleChange}
                          value={fieldInput.TalukPresent}
                          placeholder=" Taluk"
                          autoComplete="TalukPresent"
                          required
                        />
                        {listErrors.TalukPresent && (
                          <p className="errors">{listErrors.TalukPresent}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="PincodePresent">Pin/Postal Code</label>
                        <input
                          ref={txtAdPpincode}
                          id="PincodePresent"
                          type="text"
                          onChange={handleChange}
                          value={fieldInput.PincodePresent}
                          name="PincodePresent"
                          className="Inputs"
                          placeholder="Pin/Postal Code"
                          autoComplete="PincodePresent"
                          required
                        />
                        {listErrors.PincodePresent && (
                          <p className="errors">{listErrors.PincodePresent}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="CityPresent">City/Town/Village</label>
                        <select
                          ref={txtAdPcity}
                          id="CityPresent"
                          name="CityPresent"
                          className="Inputs"
                          onChange={handleChange}
                          value={fieldInput.CityPresent}
                          required
                        >
                          <option value="" disabled selected>
                            Please select
                          </option>
                          {cityOption}
                        </select>

                        {listErrors.CityPresent && (
                          <p className="errors">{listErrors.CityPresent}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row  mb-4">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="districtPresent">Districts </label>
                        <input
                          ref={txtAdPdistrict}
                          id="districtPresent"
                          type="text"
                          value={fieldInput.districtPresent}
                          name="districtPresent"
                          className="Inputs"
                          placeholder="district"
                          disabled
                          required
                        />
                        {/* <select
                          id="districtPresent"
                          name="districtPresent"
                          className="Inputs"
                          onChange={handleChange}
                          value={fieldInput.districtPresent}
                          required
                        >
                          <option value="" disabled selected>
                            Please select state
                          </option>
                        </select> */}
                        {listErrors.districtPresent && (
                          <p className="errors">{listErrors.districtPresent}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="statePresent">
                          {" "}
                          State/Union Territory
                        </label>
                        <input
                          ref={txtAdPstate}
                          id="statePresent"
                          type="text"
                          value={fieldInput.statePresent}
                          name="statePresent"
                          className="Inputs"
                          placeholder=" State/Union Territory"
                          disabled
                          required
                        />

                        {listErrors.statePresent && (
                          <p className="errors">{listErrors.statePresent}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className=" sectionFour ">
              <div className="row box">
                <div className="col-md-12">
                  <p className="final">Permanent Address</p>

                  <div className="row row mt-4 ms-2">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between">
                        <label>
                          <input
                            className="form-check-input me-2 align-text-center"
                            type="checkbox"
                            id="SamePresentAddress"
                            name="SamePresentAddress"
                            onChange={handleChange}
                            value={fieldInput.SamePresentAddress}
                            checked={
                              fieldInput.SamePresentAddress === true
                                ? true
                                : false
                            }
                          />
                          <span
                            className="form-check-label"
                            htmlFor="SamePresentAddress"
                          >
                            Same as Present Address
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="BuildingNumberPermanent">
                          House/Building Number
                        </label>
                        <input
                          ref={txtAdPerhno}
                          id="BuildingNumberPermanent"
                          type="text"
                          name="BuildingNumberPermanent"
                          className="Inputs"
                          onChange={handleChange}
                          value={
                            fieldInput.SamePresentAddress !== true
                              ? fieldInput.BuildingNumberPermanent
                              : fieldInput.BuildingNumberPresent
                          }
                          placeholder=" House/Building Number"
                          autoComplete="BuildingNumberPermanent"
                          required
                        />
                        {listErrors.BuildingNumberPermanent && (
                          <p className="errors">
                            {listErrors.BuildingNumberPermanent}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="forms ">
                        <div className="forms ">
                          <label htmlFor="StreetNamePermanent">
                            Street Name
                          </label>
                          <input
                            ref={txtAdPersname}
                            id="StreetNamePermanent"
                            type="text"
                            name="StreetNamePermanent"
                            className="Inputs"
                            onChange={handleChange}
                            value={
                              fieldInput.SamePresentAddress !== true
                                ? fieldInput.StreetNamePermanent
                                : fieldInput.StreetNamePresent
                            }
                            placeholder="Street Name"
                            autoComplete="StreetNamePermanent"
                            required
                          />
                          {listErrors.StreetNamePermanent && (
                            <p className="errors">
                              {listErrors.StreetNamePermanent}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="AreaPermanent">Locality/Area</label>
                        <input
                          ref={txtAdPerarea}
                          id="AreaPermanent"
                          type="text"
                          name="AreaPermanent"
                          onChange={handleChange}
                          value={
                            fieldInput.SamePresentAddress !== true
                              ? fieldInput.AreaPermanent
                              : fieldInput.AreaPresent
                          }
                          className="Inputs"
                          placeholder="Locality/Area"
                          autoComplete="AreaPermanent"
                          required
                        />
                        {listErrors.AreaPermanent && (
                          <p className="errors">{listErrors.AreaPermanent}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="LandmarkPermanent">Landmark</label>
                        <input
                          ref={txtAdPerland}
                          id="LandmarkPermanent"
                          type="text"
                          name="LandmarkPermanent"
                          className="Inputs"
                          onChange={handleChange}
                          value={
                            fieldInput.SamePresentAddress !== true
                              ? fieldInput.LandmarkPermanent
                              : fieldInput.LandmarkPresent
                          }
                          placeholder="Land marks"
                          autoComplete="LandmarkPermanent"
                          required
                        />
                        {listErrors.LandmarkPermanent && (
                          <p className="errors">
                            {listErrors.LandmarkPermanent}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="PostPermanent">Post</label>
                        <input
                          ref={txtAdPererpost}
                          id="PostPermanent"
                          type="text"
                          name="PostPermanent"
                          className="Inputs"
                          onChange={handleChange}
                          value={
                            fieldInput.SamePresentAddress !== true
                              ? fieldInput.PostPermanent
                              : fieldInput.PostPresent
                          }
                          placeholder=" Post"
                          autoComplete="PostPermanent"
                          required
                        />

                        {listErrors.PostPermanent && (
                          <p className="errors">{listErrors.PostPermanent}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="TalukPermanent">Taluk</label>
                        <input
                          ref={txtAdPertaluk}
                          id="TalukPermanent"
                          type="text"
                          name="TalukPermanent"
                          className="Inputs"
                          onChange={handleChange}
                          value={
                            fieldInput.SamePresentAddress !== true
                              ? fieldInput.TalukPermanent
                              : fieldInput.TalukPresent
                          }
                          placeholder=" Taluk"
                          autoComplete="TalukPermanent"
                          required
                        />
                        {listErrors.TalukPermanent && (
                          <p className="errors">{listErrors.TalukPermanent}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="PincodePermanent">
                          Pin/Postal Code
                        </label>
                        <input
                          ref={txtAdPerpincode}
                          id="PincodePermanent"
                          type="text"
                          onChange={handleChange}
                          value={
                            fieldInput.SamePresentAddress !== true
                              ? fieldInput.PincodePermanent
                              : fieldInput.PincodePresent
                          }
                          name="PincodePermanent"
                          className="Inputs"
                          placeholder="Pin/Postal Code"
                          autoComplete="PincodePermanent"
                          required
                        />
                        {listErrors.PincodePermanent && (
                          <p className="errors">
                            {listErrors.PincodePermanent}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="CityPermanent">City/Town/Village</label>

                        {fieldInput.SamePresentAddress !== true ? (
                          <select
                            ref={txtAdPercity}
                            id="CityPermanent"
                            name="CityPermanent"
                            className="Inputs"
                            onChange={handleChange}
                            value={fieldInput.CityPermanent}
                            required
                          >
                            <option value="" disabled>
                              Please select
                            </option>
                            {cityOptionPerment}
                          </select>
                        ) : (
                          <input
                            ref={txtAdPercity}
                            id="CityPermanent"
                            type="text"
                            onChange={handleChange}
                            value={fieldInput.CityPermanent}
                            name="CityPermanent"
                            className="Inputs"
                            placeholder="City"
                            autoComplete="CityPermanent"
                            required
                          />
                        )}

                        {listErrors.CityPermanent && (
                          <p className="errors">{listErrors.CityPermanent}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row  mb-4">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="districtPermanent">Districts</label>
                        <input
                          ref={txtAdPerdistrict}
                          id="districtPermanent"
                          type="text"
                          onChange={handleChange}
                          value={
                            fieldInput.SamePresentAddress !== true
                              ? fieldInput.districtPermanent
                              : fieldInput.districtPresent
                          }
                          name="districtPermanent"
                          className="Inputs"
                          placeholder="district"
                          autoComplete="districtPermanent"
                          required
                        />
                        {/* <select
                          id="districtPermanent"
                          name="districtPermanent"
                          className="Inputs"
                          onChange={handleChange}
                          // value={
                          //   fieldInput.SamePresentAddress !==true
                          //     ? fieldInput.districtPermanent
                          //     : fieldInput.districtPresent
                          // }
                          required
                        >
                          <option value="" disabled selected>
                            Please select state
                          </option>
                        </select> */}
                        {listErrors.districtPermanent && (
                          <p className="errors">
                            {listErrors.districtPermanent}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="forms ">
                        <label htmlFor="statePermanent">
                          State/Union Territory
                        </label>
                        <input
                          ref={txtAdPerstate}
                          id="statePermanent"
                          type="text"
                          onChange={handleChange}
                          value={
                            fieldInput.SamePresentAddress !== true
                              ? fieldInput.statePermanent
                              : fieldInput.statePresent
                          }
                          name="statePermanent"
                          className="Inputs"
                          placeholder="State/Union Territory"
                          autoComplete="statePermanent"
                          required
                        />

                        {listErrors.statePermanent && (
                          <p className="errors">{listErrors.statePermanent}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <section className="sectionTwo">
          <div className="row">
            <div className="col-md-12">
              <div className="heading mb-3  mt-3 d-flex justify-content-end ">
                <Link to="/" className="CancelButton">
                  Cancel
                </Link>

                <button
                  type="submit"
                  className="SaveButton"
                  value="SaveButton"
                  name="SaveButton"
                  onClick={handleSubmit}
                >
                  <a href="avascript:void(0)">Update and submit</a>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ProfileSetting;
