// ModalComponent.js
import React from "react";
import "../../../../pages/dashboard/Modelcss.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import moment from "moment";

function GenarateHallTicket(props) {
  // console.log(props, "propsprops");
  const currentDate = moment();
  const [formData, setFormData] = useState([
    {
      examTitle: "",
      Date: "",
      HallTStatus: "",
    },
  ]);
  const navigate = useNavigate();
  const [error, setError] = useState({});
  //   onHide={handleClose}

  const handleError = () => {
    setError({
      error_status: "Hall Ticket Generated already ",
    });
  };
  const handleError_inPro = () => {
    setError({
      error_status: "Hall tickets are being generated now",
    });
  };
  const handleErrors = () => {
    setError({
      error_status: "Last Date To Apply is less than the current Date ",
    });
  };
  return (
    <Modal show={props.show} onHide={props.handleHallClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 id="exampleModalLabel" className="HallModelTitle">
            Generate AIASA's Hall Ticket -{" "}
            {props.hallTicketData.length > 0 &&
            props.hallTicketData[0].paper_type === 0
              ? "Single Papers"
              : "Combined Papers"}
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-12 p-0">
              <span className="HallText">
                {props.hallTicketData.length > 0
                  ? props.hallTicketData[0].paper_type === 0
                    ? `Are you sure you want to generate the AIASA's hall ticket for
                the model exam titled ${
                  props.hallTicketData.length > 0 &&
                  props.hallTicketData[0].exam_title
                } scheduled for ${
                        props.hallTicketData.length > 0 &&
                        props.hallTicketData[0].started_dt
                          ? moment(props.hallTicketData[0].started_dt).format(
                              "DD/MM/YYYY"
                            )
                          : "_"
                      }?`
                    : `Are you sure you want to generate the AIASA's hall ticket for the model exam titled ${
                        props.hallTicketData.length > 0 &&
                        props.hallTicketData[0].exam_title
                      } ?`
                  : "_"}
              </span>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-3 ">
              <div className="forms ">
                <p htmlFor="examNumber" className="hallTLables">
                  {props.hallTicketData.length > 0 &&
                  props.hallTicketData[0].paper_type === 0
                    ? "Exam Title"
                    : "Model Exam Title"}
                </p>
                <p className="HallParaText">
                  {props.hallTicketData.length > 0
                    ? props.hallTicketData[0].paper_type === 0
                      ? `${
                          props.hallTicketData.length > 0 &&
                          props.hallTicketData[0].exam_title
                        }`
                      : `${
                          props.hallTicketData.length > 0 &&
                          props.hallTicketData[0].exam_title
                        }`
                    : "_"}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="forms ">
                <p htmlFor="examNumber" className="hallTLables">
                  {props.hallTicketData.length > 0 &&
                  props.hallTicketData[0].paper_type === 0
                    ? "Date"
                    : "Number of Papers"}
                </p>
                <p className="HallParaText">
                  {" "}
                  {props.hallTicketData.length > 0
                    ? props.hallTicketData[0].paper_type === 0
                      ? `${
                          props.hallTicketData.length > 0 &&
                          props.hallTicketData[0].started_dt
                            ? moment(props.hallTicketData[0].started_dt).format(
                                "DD/MM/YYYY"
                              )
                            : "_"
                        }`
                      : `${
                          props.hallTicketData.length > 0 &&
                          props.hallTicketData[0].no_of_paper
                        }`
                    : "_"}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="forms ">
                <p htmlFor="examNumber" className="hallTLables">
                  Hall Ticket Status
                </p>
                <p className="HallParaText">
                  {props.hallTicketData.length > 0
                    ? props.hallTicketData[0].hall_ticket_status === 0
                      ? "Not Yet Generated"
                      : props.hallTicketData[0].hall_ticket_status === 1
                      ? "Hall tickets are being generated now"
                      : "Generated"
                    : "_"}
                </p>
                {error.error_status && (
                  <span className="errors ms-0">{error.error_status}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link
          data-bs-dismiss="modal"
          onClick={props.handleHallClose}
          className="DashboardCancelButton"
        >
          Back to View Model Exam Batch
        </Link>

        <button
          type="button"
          className="DashboardButton"
          // onClick={
          //   props.hallTicketData.length > 0 &&
          //   props.hallTicketData[0].hall_ticket_status === 1
          //     ? () => handleError()
          //     : () =>
          //         props.handleMainHallThicket(
          //           props.hallTicketData.length > 0 &&
          //             props.hallTicketData[0].exam_detail_cstm_pk
          //         )
          // }
          // onClick={
          //   props.hallTicketData.length > 0 &&
          //   props.hallTicketData[0].hall_ticket_status === 1
          //     ? () => handleError()
          //     : moment(props.hallTicketData[0].last_dt_apply).isBefore(
          //         currentDate
          //       )
          //     ? () => handleError()
          //     : () =>
          //         props.handleMainHallThicket(
          //           props.hallTicketData.length > 0 &&
          //             props.hallTicketData[0].exam_detail_cstm_pk
          //         )
          // }

          onClick={
            props.hallTicketData.length > 0 &&
            props.hallTicketData[0].hall_ticket_status === 2
              ? props.hallTicketData[0].hall_ticket_status === 1
                ? () => handleError_inPro()
                : () => handleError()
              : props.hallTicketData.length > 0 &&
                moment(props.hallTicketData[0].last_dt_apply).isAfter(
                  currentDate
                ) === true
              ? () => handleErrors()
              : () =>
                  props.handleMainHallThicket(
                    props.hallTicketData.length > 0 &&
                      props.hallTicketData[0].exam_detail_cstm_pk
                  )
          }
        >
          <a href="javascript:void(0)">Generate Now</a>
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default GenarateHallTicket;
