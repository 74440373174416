import React, { useState, useEffect } from "react";
import ExamPad from "../../../../img/testmodule/ExamPad.png";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location;
  const {
    batchID,
    eventID,
    userID,
    finishTime,
    eventName,
    user_name,
    startDate,
  } = state;
  const [showPage2, setShowPage2] = useState(false);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const finishingMoment = moment(finishTime, "HH:mm:ss A");
  const [remainingTime, setRemainingTime] = useState({
    hours: finishingMoment.hour(),
    minutes: finishingMoment.minute(),
    seconds: finishingMoment.second(),
  });
  // ------------Back Button on Browser(alert) ----
  // useEffect(() => {
  //   const preventBack = () => {
  //     window.history.pushState(null, null, window.location.href);
  //     window.addEventListener('popstate', handlePopState);
  //   };

  //   const handlePopState = (event) => {
  //     window.history.pushState(null, null, window.location.href);
  //     alert("You can't go back, stay to attend exam");
  //   };

  //   preventBack();

  //   return () => {
  //     window.removeEventListener('popstate', handlePopState);
  //   };
  // }, []);
  // ----------Back button clikc home nagivate-----
  useEffect(() => {
    const handlePopState = (event) => {
      // window.location.replace('/HomeFull');
      // ------chrome work -------
      // window.open('/','_blank');
      // alert("please close this tab manually")
      // window.close();
      //--------new(try) all browser--
      window.opener = null;
      window.open("/", "_self");
      window.close();
    };

    // Push a new state to the history stack
    window.history.pushState(null, null, window.location.href);

    // Add event listener to handle the back button
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  // -----------------end------
  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prevTime) => {
        let { hours, minutes, seconds } = prevTime;

        if (hours === 0 && minutes === 0 && seconds === 0) {
          setShowPage2(true);
          clearInterval(interval);
          return prevTime;
        } else {
          if (seconds === 0) {
            if (minutes === 0) {
              hours -= 1;
              minutes = 59;
              seconds = 59;
            } else {
              minutes -= 1;
              seconds = 59;
            }
          } else {
            seconds -= 1;
          }
          return { hours, minutes, seconds };
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  // let minutes = finishingMoment.minute();
  // let seconds = finishingMoment.second();
  // let Hours = finishingMoment.hour();

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (minutes === 0 && seconds === 0) {
  //       setShowPage2(true);
  //       clearInterval(interval);
  //     } else {
  //       if (seconds === 0) {
  //         minutes -= 1;
  //         seconds = 59;
  //       } else {
  //         seconds -= 1;
  //       }
  //     }
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [minutes, seconds]);

  const handleViewTestPerfomance = () => {
    navigate("/TestPerformanceListView");
  };

  return (
    <>
      {!showPage2 && (
        <div className="BathDet">
          <div className="row">
            <div className="col-md-12">
              <section className="StepOne">
                <div className="heading dflexbetww">
                  <div className="mt-2 dbetw">
                    <h6 className="Title">
                      {eventName} -{" "}
                      {startDate ? moment(startDate).format("DD/MM/YYYY") : "_"}
                    </h6>
                  </div>
                </div>
                <section className="StepOne mt-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row mt-5">
                        <div className="col-lg-4 col-md-4 payment-col-img">
                          <div>
                            <img
                              className="payment-brand mb-4"
                              src={ExamPad}
                              alt="LogoImage"
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-10 ">
                          <p className="payment-name">Hi {user_name}</p>
                          <p className="payment-paragraph">
                            Your answer sheet has been submitted successfully.
                          </p>
                          <p className="payment-paragraph">
                            Your evaluated results will be accessible, and the
                            View Your Test Performance button will be enabled in{" "}
                            <span className="payment-span">
                              {remainingTime.hours}h : {remainingTime.minutes}m
                              :{remainingTime.seconds}s
                            </span>
                            , allowing you to view your results.
                          </p>
                          <p className="payment-paragraph">
                            Additionally, you can view your test performance
                            from the{" "}
                            <span className="payment-color">
                              Test Performance
                            </span>{" "}
                            page.
                          </p>
                          <button
                            type="submit"
                            className="payment-button"
                            disabled
                            style={{ cursor: "none" }}
                            onClick={handleViewTestPerfomance}
                          >
                            View Your Test Performance
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </div>
          </div>
        </div>
      )}

      {showPage2 && (
        <div className="BathDet">
          <div className="row">
            <div className="col-md-12">
              <section className="StepOne">
                <div className="heading dflexbetww">
                  <div className="mt-2 dbetw">
                    <h6 className="Title">
                      {eventName} -{" "}
                      {startDate ? moment(startDate).format("DD/MM/YYYY") : "_"}
                    </h6>
                  </div>
                </div>
                <section className="StepOne mt-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row mt-5">
                        <div className="col-lg-4 col-md-4 payment-col-img">
                          <div>
                            <img
                              className="payment-brand mb-4"
                              src={ExamPad}
                              alt="LogoImage"
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-10 ">
                          <p className="payment-name">Hi {user_name}</p>
                          <p className="payment-paragraph">
                            Your answer sheet has been submitted successfully.
                          </p>
                          <p className="payment-paragraph">
                            You can view your test results and analytics by
                            clicking the button below
                          </p>
                          <button type="submit" className="DashboardButton">
                            <Link
                              className="text-white"
                              to={"/TestPerformanceListView"}
                            >
                              View Your Test Performance{" "}
                            </Link>
                          </button>
                          <p className="payment-paragraph mt-3">
                            Additionally, you can view your test performance
                            from the{" "}
                            <span className="payment-color">
                              Test Performance
                            </span>{" "}
                            page.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentSuccess;
