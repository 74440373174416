import React from "react";
// import img from "../../../../img/AIASA.png";
import ICON_RUPPEE from "@mui/icons-material/CurrencyRupee";
import ICON_A from "@mui/icons-material/FormatShapes";
import ICON_SYLLABUS from "@mui/icons-material/Assignment";
import ICON_TIMER from "@mui/icons-material/TimerOutlined";
import ICON_EVENT from "@mui/icons-material/Event";
import "../StudentConfirm.css";
import { ImageUrlPrefix } from "../../../../labels";
import moment from "moment";
import { Link } from "react-router-dom";

export default function StudentConfirmType4(props) {
  const convertSecondsToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const remainingSeconds = totalSeconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    if (minutes > 0) {
      return `${hours}:${minutes.toString().padStart(2, "0")}`;
    } else {
      return `${hours}`;
    }
  };
  return (
    <div className="st_confirm_outer">
      <div className="row  m-0 st_confirm_top">
        <div className="col-6 col-md-3 col-lg-2 p-0 m-0">
          <img
            className="img-fluid st_confirm_top_img"
            alt="exam details"
            src={`${ImageUrlPrefix}/${
              props.type === 1 && props.batchdetails !== ""
                ? props.batchdetails[0].img_url
                : props.batchdetails.img_url
            }`}
          />
        </div>
        <div className="col-6 col-md-3 col-lg-3 p-0 m-0">
          <p className="title">
            {props.type === 1 && props.batchdetails !== ""
              ? `${props.batchdetails[0].course_name} - ${props.batchdetails[0].name}`
              : `${props.batchdetails.course_name} - ${props.batchdetails.name}`}
          </p>
        </div>
        <div className="col-12 col-md-6 col-lg-3 p-0 m-0">
          <div className="amount_details">
            <ICON_RUPPEE className="st_confirm_icon st_confirm_icon_color" />
            <div>
              <p className="st_confirm_label" style={{ marginBottom: "0px" }}>
                Fee Details
              </p>
              <p className="st_confirm_value">
                {props.type === 1 &&
                  props.batchdetails !== "" &&
                  props.batchdetails[0].exam_fees &&
                  props.batchdetails[0].exam_fees.length > 0 &&
                  props.batchdetails[0].exam_fees
                    .reverse()
                    .filter(
                      (ell) =>
                        ell.fees_count ===
                        props.batchdetails[0].exam_details.filter(
                          (eop) => eop.is_selected_exam === true
                        ).length
                    )
                    .map((fee, index) => (
                      <React.Fragment key={index}>
                        <span className="batch_fees_text">
                          {fee.fees_count} Exams - {fee.fees}
                        </span>
                        {(index + 1) % 2 === 0 && <br />}
                      </React.Fragment>
                    ))}

                {props.type === 2 &&
                  props.batchdetails !== "" &&
                  props.batchdetails.exam_details.exam_fees &&
                  props.batchdetails.exam_details.exam_fees.length > 0 &&
                  props.batchdetails.exam_details.exam_fees
                    .reverse()
                    .filter(
                      (ell) =>
                        ell.fees_count ===
                        props.batchdetails.exam_details.exam_details.filter(
                          (eop) => eop.is_selected_exam === 1
                        ).length
                    )
                    .map((fee, index) => (
                      <React.Fragment key={index}>
                        <span className="batch_fees_text">
                          {fee.fees_count} Exams - {fee.fees}
                        </span>
                        {(index + 1) % 2 === 0 && <br />}
                      </React.Fragment>
                    ))}
              </p>
            </div>
          </div>
        </div>
      </div>
      {props.type === 1 &&
        props.paper_type === 1 &&
        props.batchdetails !== "" &&
        props.batchdetails[0].exam_details &&
        props.batchdetails[0].exam_details.length > 0 &&
        props.batchdetails[0].exam_details
          .filter((eop) => eop.is_selected_exam === true)
          ?.sort(
            (a, b) => new Date(a.last_dt_apply) - new Date(b.last_dt_apply)
          )
          ?.map((klkl) => (
            <div className="st_confirm_bottom">
              <div className="row p-0 m-0 ">
                <div className="col-12 col-md-6 col-lg-3">
                  <p
                    className="st_confirm_label"
                    style={{ marginBottom: "0px" }}
                  >
                    Model Exam Title
                  </p>
                  <p className="st_confirm_value">{klkl.exam_title}</p>
                </div>
                <div className="col-12 col-md-6 col-lg-2">
                  <p
                    className="st_confirm_label"
                    style={{ marginBottom: "0px" }}
                  >
                    Number of Papers
                  </p>
                  <p className="st_confirm_value">{klkl.no_of_papers}</p>
                </div>
                <div className="col-12 col-md-6 col-lg-2">
                  <p
                    className="st_confirm_label"
                    style={{ marginBottom: "0px" }}
                  >
                    Last Date to Apply
                  </p>
                  <p className="st_confirm_value">
                    {klkl.last_dt_apply
                      ? moment(klkl.last_dt_apply).format("DD MMM YYYY")
                      : "_"}
                  </p>
                </div>
              </div>

              <div className="st_confirm_inner">
                {klkl.papers.length > 0 &&
                  klkl.papers
                    ?.sort((a, b) => {
                      // Sort by start date first
                      const dateComparison = moment(a.start_dt).diff(
                        moment(b.start_dt)
                      );
                      if (dateComparison !== 0) return dateComparison;

                      // If start dates are the same, sort by test time using Moment.js
                      const timeA = moment(
                        `${a.start_dt.split("T")[0]} ${
                          a.test_time.split(" to ")[0]
                        }`,
                        "YYYY-MM-DD hh:mm A"
                      );
                      const timeB = moment(
                        `${b.start_dt.split("T")[0]} ${
                          b.test_time.split(" to ")[0]
                        }`,
                        "YYYY-MM-DD hh:mm A"
                      );
                      return timeA.diff(timeB);
                    })
                    ?.map((bh, index) => (
                      <div
                        className={`row align-items-center ${
                          bh.paper_id > 0 && "inner_box_list_item  mt-2 "
                        }`}
                        style={{ paddingTop: "0px" }}
                      >
                        <div className="col-12 col-md-4 col-lg-3  m-0">
                          <div className="amount_details">
                            <ICON_A className="st_confirm_icon st_confirm_icon_color" />
                            <div>
                              <p
                                className="st_confirm_label"
                                style={{ marginBottom: "0px" }}
                              >
                                Paper Title
                              </p>
                              <p className="st_confirm_value">
                                {bh.paper_title}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-2 p-0 m-0">
                          <div className="amount_details">
                            <ICON_SYLLABUS className="st_confirm_icon st_confirm_icon_color" />
                            <div>
                              <p
                                className="st_confirm_label"
                                style={{ marginBottom: "0px" }}
                              >
                                Syllabus
                              </p>
                              <button className="st_confirm_link">
                                <Link
                                  target="_blank"
                                  to={`${ImageUrlPrefix}/${bh.syllabus_path}`}
                                >
                                  Click here to view syllabus
                                </Link>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-2 col-lg-2 p-0 m-0">
                          <div className="amount_details">
                            <ICON_TIMER className="st_confirm_icon st_confirm_icon_color" />
                            <div>
                              <p
                                className="st_confirm_label"
                                style={{ marginBottom: "0px" }}
                              >
                                Duration
                              </p>
                              <p className="st_confirm_value">
                                {convertSecondsToTime(bh.duration)} hours
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-2 p-0 m-0">
                          <div className="amount_details">
                            <ICON_EVENT className="st_confirm_icon st_confirm_icon_color" />
                            <div>
                              <p
                                className="st_confirm_label"
                                style={{ marginBottom: "0px" }}
                              >
                                Date
                              </p>
                              <p className="st_confirm_value">
                                {bh.start_dt
                                  ? moment(bh.start_dt).format("DD MMM YYYY")
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 p-0 m-0">
                          <div className="amount_details">
                            <ICON_TIMER className="st_confirm_icon st_confirm_icon_color" />
                            <div>
                              <p
                                className="st_confirm_label"
                                style={{ marginBottom: "0px" }}
                              >
                                Time
                              </p>
                              <p className="st_confirm_value">
                                {bh.test_time ? bh.test_time : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          ))}

      {props.type === 2 &&
        props.paper_type === 1 &&
        props.batchdetails !== "" &&
        props.batchdetails.exam_details &&
        props.batchdetails.exam_details.exam_details.length > 0 &&
        props.batchdetails.exam_details.exam_details
          .filter((eop) => eop.is_selected_exam === 1)
          ?.sort(
            (a, b) => new Date(a.last_dt_apply) - new Date(b.last_dt_apply)
          )
          ?.map((klkl) => (
            <div className="st_confirm_bottom">
              <div className="row p-0 m-0 ">
                <div className="col-12 col-md-6 col-lg-3">
                  <p
                    className="st_confirm_label"
                    style={{ marginBottom: "0px" }}
                  >
                    Model Exam Title
                  </p>
                  <p className="st_confirm_value">{klkl.exam_title}</p>
                </div>
                <div className="col-12 col-md-6 col-lg-2">
                  <p
                    className="st_confirm_label"
                    style={{ marginBottom: "0px" }}
                  >
                    Number of Papers
                  </p>
                  <p className="st_confirm_value">{klkl.no_of_paper}</p>
                </div>
                <div className="col-12 col-md-6 col-lg-2">
                  <p
                    className="st_confirm_label"
                    style={{ marginBottom: "0px" }}
                  >
                    Last Date to Apply
                  </p>
                  <p className="st_confirm_value">
                    {klkl.last_dt_apply
                      ? moment(klkl.last_dt_apply).format("DD MMM YYYY")
                      : "_"}
                  </p>
                </div>
              </div>

              <div className="st_confirm_inner">
                {klkl.papers.length > 0 &&
                  klkl.papers
                    ?.sort((a, b) => {
                      // Sort by start date first
                      const dateComparison = moment(a.start_dt).diff(
                        moment(b.start_dt)
                      );
                      if (dateComparison !== 0) return dateComparison;

                      // If start dates are the same, sort by test time using Moment.js
                      const timeA = moment(
                        `${a.start_dt.split("T")[0]} ${
                          a.test_time.split(" to ")[0]
                        }`,
                        "YYYY-MM-DD hh:mm A"
                      );
                      const timeB = moment(
                        `${b.start_dt.split("T")[0]} ${
                          b.test_time.split(" to ")[0]
                        }`,
                        "YYYY-MM-DD hh:mm A"
                      );
                      return timeA.diff(timeB);
                    })
                    ?.map((bh, index) => (
                      <div
                        className={`row align-items-center ${
                          bh.paper_id > 0 && "inner_box_list_item  mt-2 "
                        } `}
                        style={{ paddingTop: "0px" }}
                      >
                        <div className="col-12 col-md-4 col-lg-3  m-0">
                          <div className="amount_details">
                            <ICON_A className="st_confirm_icon st_confirm_icon_color" />
                            <div>
                              <p
                                className="st_confirm_label"
                                style={{ marginBottom: "0px" }}
                              >
                                Paper Title
                              </p>
                              <p className="st_confirm_value">
                                {bh.paper_title}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-2 p-0 m-0">
                          <div className="amount_details">
                            <ICON_SYLLABUS className="st_confirm_icon st_confirm_icon_color" />
                            <div>
                              <p
                                className="st_confirm_label"
                                style={{ marginBottom: "0px" }}
                              >
                                Syllabus
                              </p>
                              <button className="st_confirm_link">
                                <Link
                                  target="_blank"
                                  to={`${ImageUrlPrefix}/${bh.syllabus_path}`}
                                >
                                  Click here to view syllabus
                                </Link>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-2 col-lg-2 p-0 m-0">
                          <div className="amount_details">
                            <ICON_TIMER className="st_confirm_icon st_confirm_icon_color" />
                            <div>
                              <p
                                className="st_confirm_label"
                                style={{ marginBottom: "0px" }}
                              >
                                Duration
                              </p>
                              <p className="st_confirm_value">
                                {convertSecondsToTime(bh.duration)} hours
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-2 p-0 m-0">
                          <div className="amount_details">
                            <ICON_EVENT className="st_confirm_icon st_confirm_icon_color" />
                            <div>
                              <p
                                className="st_confirm_label"
                                style={{ marginBottom: "0px" }}
                              >
                                Date
                              </p>
                              <p className="st_confirm_value">
                                {bh.start_dt
                                  ? moment(bh.start_dt).format("DD MMM YYYY")
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 p-0 m-0">
                          <div className="amount_details">
                            <ICON_TIMER className="st_confirm_icon st_confirm_icon_color" />
                            <div>
                              <p
                                className="st_confirm_label"
                                style={{ marginBottom: "0px" }}
                              >
                                Time
                              </p>
                              <p className="st_confirm_value">
                                {bh.test_time ? bh.test_time : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          ))}

      {props.type === 2 &&
        props.paper_type === 0 &&
        props.batchdetails !== "" && (
          <div className="st_confirm_inner">
            {props.batchdetails.exam_details &&
              props.batchdetails.exam_details.exam_details.length > 0 &&
              props.batchdetails.exam_details.exam_details
                .filter((eop) => eop.is_selected_exam === 1)
                ?.sort(
                  (a, b) => new Date(a.started_dt) - new Date(b.started_dt)
                )
                ?.map((el, index) => (
                  <div
                    className={`row mt-4 align-items-center ${
                      props.batchdetails.exam_details.exam_details.length > 1 &&
                      "inner_box_list_item"
                    }`}
                    style={{ paddingTop: "0px" }}
                  >
                    <div className="col-12 col-md-4 col-lg-2 p-0 m-0">
                      <div className="amount_details">
                        <ICON_A className="st_confirm_icon st_confirm_icon_color" />
                        <div>
                          <p
                            className="st_confirm_label"
                            style={{ marginBottom: "0px" }}
                          >
                            Exam Title
                          </p>
                          <p className="st_confirm_value">{el.exam_title}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-2 p-0 m-0">
                      <div className="amount_details">
                        <ICON_SYLLABUS className="st_confirm_icon st_confirm_icon_color" />
                        <div>
                          <p
                            className="st_confirm_label"
                            style={{ marginBottom: "0px" }}
                          >
                            Syllabus
                          </p>
                          <button className="st_confirm_link">
                            <Link
                              target="_blank"
                              to={`${ImageUrlPrefix}/${el.syllabus_url}`}
                            >
                              {" "}
                              Click here to view syllabus
                            </Link>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-2 col-lg-2 p-0 m-0">
                      <div className="amount_details">
                        <ICON_TIMER className="st_confirm_icon st_confirm_icon_color" />
                        <div>
                          <p
                            className="st_confirm_label"
                            style={{ marginBottom: "0px" }}
                          >
                            Duration
                          </p>
                          <p className="st_confirm_value">
                            {convertSecondsToTime(el.duration)} hours
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-2 p-0 m-0">
                      <div className="amount_details">
                        <ICON_EVENT className="st_confirm_icon st_confirm_icon_color" />
                        <div>
                          <p
                            className="st_confirm_label"
                            style={{ marginBottom: "0px" }}
                          >
                            Date
                          </p>
                          <p className="st_confirm_value">
                            {el.started_dt
                              ? moment(el.started_dt).format("DD MMM YYYY")
                              : "_"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-2 p-0 m-0">
                      <div className="amount_details">
                        <ICON_TIMER className="st_confirm_icon st_confirm_icon_color" />
                        <div>
                          <p
                            className="st_confirm_label"
                            style={{ marginBottom: "0px" }}
                          >
                            Time
                          </p>
                          <p className="st_confirm_value"> {el.batch_time}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-2 p-0 m-0">
                      <div className="amount_details">
                        <ICON_TIMER className="st_confirm_icon st_confirm_icon_color" />
                        <div>
                          <p
                            className="st_confirm_label"
                            style={{ marginBottom: "0px" }}
                          >
                            Last Date Apply
                          </p>
                          <p className="st_confirm_value">
                            {" "}
                            {el.last_dt_apply
                              ? moment(el.last_dt_apply).format("DD MMM YYYY")
                              : "_"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        )}

      {props.type === 1 &&
        props.paper_type === 0 &&
        props.batchdetails !== "" && (
          <div className="st_confirm_inner">
            {props.batchdetails[0].exam_details &&
              props.batchdetails[0].exam_details.length > 0 &&
              props.batchdetails[0].exam_details
                .filter((eop) => eop.is_selected_exam === true)
                ?.sort(
                  (a, b) => new Date(a.started_dt) - new Date(b.started_dt)
                )
                ?.map((el, index) => (
                  <div
                    className={`row mt-4 align-items-center ${
                      props.batchdetails[0].exam_details.length > 1 &&
                      "inner_box_list_item"
                    }`}
                    style={{ paddingTop: "0px" }}
                  >
                    <div className="col-12 col-md-4 col-lg-2 p-0 m-0">
                      <div className="amount_details">
                        <ICON_A className="st_confirm_icon st_confirm_icon_color" />
                        <div>
                          <p
                            className="st_confirm_label"
                            style={{ marginBottom: "0px" }}
                          >
                            Exam Title
                          </p>
                          <p className="st_confirm_value">{el.exam_title}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-2 p-0 m-0">
                      <div className="amount_details">
                        <ICON_SYLLABUS className="st_confirm_icon st_confirm_icon_color" />
                        <div>
                          <p
                            className="st_confirm_label"
                            style={{ marginBottom: "0px" }}
                          >
                            Syllabus
                          </p>
                          <button className="st_confirm_link">
                            <Link
                              target="_blank"
                              to={`${ImageUrlPrefix}/${el.syllabus_url}`}
                            >
                              {" "}
                              Click here to view syllabus
                            </Link>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-2 col-lg-2 p-0 m-0">
                      <div className="amount_details">
                        <ICON_TIMER className="st_confirm_icon st_confirm_icon_color" />
                        <div>
                          <p
                            className="st_confirm_label"
                            style={{ marginBottom: "0px" }}
                          >
                            Duration
                          </p>
                          <p className="st_confirm_value">
                            {convertSecondsToTime(el.duration_hrs)} hours
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-2 p-0 m-0">
                      <div className="amount_details">
                        <ICON_EVENT className="st_confirm_icon st_confirm_icon_color" />
                        <div>
                          <p
                            className="st_confirm_label"
                            style={{ marginBottom: "0px" }}
                          >
                            Date
                          </p>
                          <p className="st_confirm_value">
                            {el.started_dt
                              ? moment(el.started_dt).format("DD MMM YYYY")
                              : "_"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-2 p-0 m-0">
                      <div className="amount_details">
                        <ICON_TIMER className="st_confirm_icon st_confirm_icon_color" />
                        <div>
                          <p
                            className="st_confirm_label"
                            style={{ marginBottom: "0px" }}
                          >
                            Time
                          </p>
                          <p className="st_confirm_value"> {el.batch_time}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-2 p-0 m-0">
                      <div className="amount_details">
                        <ICON_TIMER className="st_confirm_icon st_confirm_icon_color" />
                        <div>
                          <p
                            className="st_confirm_label"
                            style={{ marginBottom: "0px" }}
                          >
                            Last Date Apply
                          </p>
                          <p className="st_confirm_value">
                            {" "}
                            {el.last_dt_apply
                              ? moment(el.last_dt_apply).format("DD MMM YYYY")
                              : "_"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        )}
    </div>
  );
}
