import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ICON_WARNING from "@mui/icons-material/Warning";
import SecondaryButton from "../../../../../ui/SecondaryButton";
import PrimaryButton from "../../../../../ui/PrimaryButton";
import NavigationButton from "../../../../../ui/NavigationButton";
import { ALLOWED_MINUTES, MEDIA_SIZE_LG } from "../../../../../utils/helper";
import ButtonContainer from "../../../../../ui/ButtonContainer";
import StyledTitle from "../../../../../ui/StyledTitle";
import Styles from "../../../../../ui/NavigationButton.module.css";
import { useNavigate } from "react-router-dom";

// warning-section
const WarningSectionContainer = styled.section`
  background-color: var(--color-brand-200);
  color: var(--color-brand-800);
  padding: 15px;
  margin-top: 10px;
  border: 1px solid var(--color-brand-800);
  border-radius: var(--border-radius-md);
  margin-bottom: 20px;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    padding: 30px;
    margin-bottom: 30px;
  }
`;

const WarningSectionPara = styled.p`
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
`;

export default function ViewTestAlert({
  type_batch,
  sDate = new Date(),
  startTime = new Date(),
  endTime = new Date(),
  onClickJoinTest,
  onClickViewtestPerfomance,
  onClickReviewSubmission,
  onClickDownloadAnswerSheet,
  isResultsPublished,
  attendenceStatus,
  attempt_no_status,
  ans_sheet_download_req,
}) {
  const navigate = useNavigate();

  const TODAY = new Date(sDate);

  const [stHour, stMinute, stSecond] = startTime.split(":");
  const [endHour, endMinute, endSecond] = endTime.split(":");
  const startDateTime = new Date(
    TODAY.getFullYear(),
    TODAY.getMonth(),
    TODAY.getDate(),
    stHour,
    stMinute,
    stSecond
  );
  const endDateTime = new Date(
    TODAY.getFullYear(),
    TODAY.getMonth(),
    TODAY.getDate(),
    endHour,
    endMinute,
    endSecond
  );
  const calculatedStartTime = new Date(
    startDateTime.getFullYear(),
    startDateTime.getMonth(),
    startDateTime.getDate(),
    startDateTime.getHours(),
    startDateTime.getMinutes() - ALLOWED_MINUTES,
    startDateTime.getSeconds()
  );
  const calculatedEndTime = new Date(
    startDateTime.getFullYear(),
    startDateTime.getMonth(),
    startDateTime.getDate(),
    startDateTime.getHours(),
    startDateTime.getMinutes() + ALLOWED_MINUTES,
    startDateTime.getSeconds()
  );

  const [isAllowed, setIsAllowed] = useState(false);
  const [isAfterCalculatedEnd, setIsAfterCalculatedEnd] = useState(false);
  const [isAfterCalculatedEndFuture, setIsAfterCalculatedEndFuture] =
    useState(false);
  const [isAfterEnd, setIsAfterEnd] = useState(false);
  const [isAfterCalculatedEndFM, setIsAfterCalculatedEndFM] = useState(false);
  const currentDate = getDateOnly(new Date());

  const endDate = getDateOnly(calculatedEndTime);

  function getDateOnly(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setIsAllowed(
        new Date() >= calculatedStartTime && new Date() <= calculatedEndTime
      );

      setIsAfterCalculatedEnd(new Date() > calculatedEndTime); //tempToday > tempCendTime
      setIsAfterEnd(new Date() > endDateTime); //tempToday > tempEndTime
      // Calculate the end time minus 15 minutes
      const fifteenMinutes = 15 * 60 * 1000; // 15 minutes in milliseconds
      const endTimeMinusFifteenMinutes = new Date(
        calculatedEndTime.getTime() - fifteenMinutes
      );
      // Check if the current time is within 15 minutes before the calculated end time
      const isWithinFifteenMinutesBeforeEnd =
        new Date() >= endTimeMinusFifteenMinutes &&
        new Date() <= calculatedEndTime;
      // Set the state based on whether the current time is within the 15-minute window
      setIsAfterCalculatedEndFM(isWithinFifteenMinutesBeforeEnd);
      // console.log(currentDate, endDate, "hhhhhh", currentDate < endDate);
      setIsAfterCalculatedEndFuture(currentDate < endDate);
      if (new Date().getTime() === new Date(startDateTime).getTime()) {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [startDateTime]);

  // console.log(isAfterCalculatedEndFuture, "isAfterCalculatedEndFuture");

  return (
    <div>
      <div className="row mb-4 justify-content-center text_align_sm_center align-items-center p-0 ">
        <div className="col-12 col-lg-1 p-0">
          <StyledTitle>View Test</StyledTitle>
        </div>
        <div className="col-12 col-lg-11">
          <ButtonContainer>
            <SecondaryButton onClick={() => navigate(-1)}>
              Back to Test Perfomance
            </SecondaryButton>

            {type_batch !== "4" &&
              attempt_no_status === 1 &&
              !isAfterCalculatedEnd &&
              isAfterCalculatedEndFuture === false && (
                <PrimaryButton disabled={isAllowed} onClick={onClickJoinTest}>
                  <span class="material-icons align-middle me-2">
                    event_available
                  </span>
                  Join Test
                </PrimaryButton>
              )}

            {type_batch !== "4" &&
              attempt_no_status === 0 &&
              !isAfterCalculatedEnd &&
              isAfterCalculatedEndFuture === false && (
                <PrimaryButton disabled={!isAllowed} onClick={onClickJoinTest}>
                  <span class="material-icons align-middle me-2">
                    event_available
                  </span>
                  Join Test
                </PrimaryButton>
              )}

            {isAfterCalculatedEnd && (
              <>
                {/* <NavigationButton
                  className={Styles.ViewTestAlert_viewTestPerfomance}
                  onClick={onClickViewtestPerfomance}
                >
                  View Test Perfomance
                </NavigationButton> */}
                <NavigationButton
                  className={`${Styles.ViewTestAlert_viewTestPerfomance} ${
                    attendenceStatus === 0 ? "DisabledListviS" : ""
                  }`}
                  onClick={onClickViewtestPerfomance}
                  disabled={
                    attendenceStatus === 0 || !isAfterEnd || !isResultsPublished
                  }
                >
                  View Test Performance
                </NavigationButton>

                {type_batch !== "4" && (
                  <NavigationButton
                    disabled={
                      !(attendenceStatus === 0 || attendenceStatus === 1) ||
                      !isAfterEnd
                    }
                    className={
                      attendenceStatus !== 0 && attendenceStatus !== 1
                        ? "DisabledListviS"
                        : ""
                    }
                    onClick={onClickJoinTest}
                  >
                    Retake My Test
                  </NavigationButton>
                )}

                {/* {type_batch !== "4" && isResultsPublished && (
                  <>
                    {attendenceStatus === 0 ? (
                      <>
                        <PrimaryButton onClick={onClickDownloadAnswerSheet}>
                          Download My Answer Sheet
                        </PrimaryButton>
                        <NavigationButton onClick={onClickReviewSubmission}>
                          Review Submission
                        </NavigationButton>
                      </>
                    ) : (
                      <>
                        <PrimaryButton
                          disabled
                          onClick={onClickDownloadAnswerSheet}
                        >
                          Download My Answer Sheet
                        </PrimaryButton>
                        <NavigationButton
                          disabled
                          onClick={onClickReviewSubmission}
                        >
                          Review Submission
                        </NavigationButton>
                      </>
                    )}
                  </>
                )} */}
                {type_batch !== "4" && isResultsPublished && (
                  <>
                    {attendenceStatus === 1 ? (
                      <>
                        <NavigationButton onClick={onClickReviewSubmission}>
                          Review Submission
                        </NavigationButton>
                      </>
                    ) : (
                      <>
                        <NavigationButton
                          disabled
                          onClick={onClickReviewSubmission}
                        >
                          Review Submission
                        </NavigationButton>
                      </>
                    )}
                  </>
                )}

                {type_batch !== "4" && isResultsPublished && (
                  <>
                    {attendenceStatus === 1 && ans_sheet_download_req === 1 ? (
                      <PrimaryButton onClick={onClickDownloadAnswerSheet}>
                        Download My Answer Sheet
                      </PrimaryButton>
                    ) : (
                      <PrimaryButton
                        disabled
                        onClick={onClickDownloadAnswerSheet}
                      >
                        Download My Answer Sheet
                      </PrimaryButton>
                    )}
                  </>
                )}
              </>
            )}
          </ButtonContainer>
        </div>
      </div>
      <div>
        {/* {isAfterCalculatedEnd && !isAfterEnd && ( */}
        {isAfterCalculatedEnd && !isAfterEnd && (
          <WarningSectionContainer>
            <WarningSectionPara style={{ fontWeight: 700 }}>
              <ICON_WARNING
                style={{
                  width: "21px",
                  height: "21px",
                  marginRight: "0px",
                  verticalAlign: "middle",
                }}
              />{" "}
              WARNING:
            </WarningSectionPara>
            <WarningSectionPara>
              {`The allotted time for attending the exam has ended${
                attempt_no_status === 0
                  ? `, and you were
              marked absent.`
                  : `.`
              }`}
            </WarningSectionPara>
            <WarningSectionPara>
              {" "}
              You now have the option to retake the exam by clicking the 'Retake
              Test' button. However, please be aware that the results will not
              be reflected in your performance.
            </WarningSectionPara>
          </WarningSectionContainer>
        )}
      </div>
    </div>
  );
}
