import React from "react";
import "../question/Question.css";
import parse from "html-react-parser";
import LatexProcessorComponent from "../../../../utils/LatexProcessorComponent";

export default function Rtypepara(props) {
  const { index } = props;

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  const EsplitLines = props.list.split("\n");

  return (
    <div className=" Question_temp_VQ ">
      <div className="row mt-4">
        <div className="col-md-12">
          <span className={props.classET}>
            {EsplitLines.length > 0 ? parse(EsplitLines[0]) : ""}
          </span>
        </div>
        {EsplitLines.map((line, index) => {
          if (line.startsWith("p1-")) {
            return (
              <div className="col-md-12" key={index}>
                <p className={props.classET}>
                  <LatexProcessorComponent originalString={line.substring(4)} />
                  {/* {parse(line.substring(4))} */}
                </p>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
