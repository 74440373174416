// import logo from './logo.svg';
import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import AddToCardTemplate from "./shared/Component/Card/AddToCardTemplate";
import AdmissionForm from "./pages/AdmissionForm";
import BookDetailPage from "./shared/Component/BookDetailPage/BookDetailPage";
import BookList from "./shared/Component/BookList/BookList";
// import CounsellingForm from "./pages/CounsellingForm";
import ForgetPassword from "./pages/ForgetPassword";
// import HeaderPage from "./features/Core/Header/HeaderPage";
import Newpassword from "./shared/Component/NewPassword/NewPassword";
import Register from "./shared/Component/Register/Register";
import RegisterSuccess from "./shared/Component/Registersuccess/Registersuccess";
import ResetPassword from "./pages/ResetPasswordEmail";
import Resetpassword from "./shared/Component/Resetpassword/Resetpassword";
import SampleForm from "./pages/SampleForm";
import TeamMeeting from "./pages/TeamsIntegration";
import ViewMeetingDetails from "./pages/ViewMeeting";
import Login from "./shared/Component/Login/Login";
import EmailReset from "./pages/ResetPasswordEmail";
import UserDetailsContextProvider from "./context/UserDetails";
import RequireAuth from "./auth/auth";
import Dashboard from "./pages/dashboard/Dashboard";
import IndexPage from "./pages/dashboard/IndexPage";
import ProfileSetting from "./pages/dashboard/ProfileSetting";
import Logout from "./pages/dashboard/Logout";
import AuthUser from "./auth/AuthUser";
import TestBatch from "./pages/TestBatch";
import SpecialClass from "./pages/SpecialClass";
import StudentConfirm from "./shared/Component/StudentConfirm/StudentConfirm";

import InvoiceTemplate from "./pages/InvoiceTemplate";
import MockInterview from "./shared/Component/MockInterview/MockInterview";
import Counselling from "./shared/Component/Counselling/Counselling";

// backoffice start
import Course from "./shared/Component/BackOffice/Course/Course";
import CourseEdit from "./shared/Component/BackOffice/Course/CourseEdit";
import Test from "./shared/Component/BackOffice/Test/Test";
import TestEdit from "./shared/Component/BackOffice/Test/TestEdit";
import BookCourse from "./shared/Component/BackOffice/BookCourse/BookCourse";
import BookCourseEdit from "./shared/Component/BackOffice/BookCourse/BookCourseEdit";
import Class from "./shared/Component/BackOffice/Class/Class";
import ClassEdit from "./shared/Component/BackOffice/Class/ClassEdit";
import Batch from "./shared/Component/BackOffice/BatchUpdate/BatchUpdate";
import BatchEdit from "./shared/Component/BackOffice/BatchUpdate/BatchUpdateEdit";
import Gallery from "./shared/Component/BackOffice/Gallery/Gallery";
import GalleryEdit from "./shared/Component/BackOffice/Gallery/GalleryEdit";
import TestBatchUpdateEdit from "./shared/Component/BackOffice/TestBatchUpdate/TestBatchUpdateEdit";
import TestBatchUpdate from "./shared/Component/BackOffice/TestBatchUpdate/TestBatchUpdate";
import BranchLocation from "./shared/Component/BackOffice/BranchLocation/BranchLocation";
import BranchLocationEdit from "./shared/Component/BackOffice/BranchLocation/BranchLocationEdit";
import CreateCourseBatch from "./shared/Component/BackOffice/CourseBatch/CreateCourseBatch";
import ViewCourseBatch from "./shared/Component/BackOffice/CourseBatch/CourseBatch";
import ViewTestCourseBatch from "./shared/Component/BackOffice/TestCourseBatch/TestCourseBatch";
import CreateTestCourseBatch from "./shared/Component/BackOffice/TestCourseBatch/CreateTestCourseBatch";
import Degree from "./shared/Component/BackOffice/Degree/Degree";
import DegreeEdit from "./shared/Component/BackOffice/Degree/DegreeEdit";
import ModelExamForm from "./shared/Component/ModelExam/ModelExamForm";
import ListviewBatch from "./shared/Component/BackOffice/CourseBatch/ListviewBatch";
import ReadViewBath from "./shared/Component/BackOffice/CourseBatch/ReadViewBath";
import CreateEvent from "./shared/Component/BackOffice/Event/CreateEvent";
import Calendar from "./shared/Component/BackOffice/CourseBatch/CalendarView";
import CalendarView from "./shared/Component/BackOffice/CourseBatch/CalendarView";
import MyCalendar from "./shared/Component/BackOffice/CourseBatch/CalendarView";
import NewFaculty from "./shared/Component/BackOffice/Faculty/NewFaculty";
import ReadFaculty from "./shared/Component/BackOffice/Faculty/ReadFaculty";
import ReviewFaculty from "./shared/Component/BackOffice/Faculty/ReviewFaculty";
import ListViewFaculty from "./shared/Component/BackOffice/Faculty/ListViewFaculty";
import FacultyProfile from "./shared/Component/BackOffice/Faculty/FacultyProfile";
import PurchaseListview from "./shared/Component/BackOffice/Purchase/PurchaseListview";
import ReadViewAdmissionHistory from "./shared/Component/BackOffice/Purchase/ReadViewAdmissionHistory";
import PaymentHistory from "./shared/Component/BackOffice/Purchase/PaymentHistory";
import ViewSchedule from "./shared/Component/BackOffice/Purchase/ViewSchdule";
import ViewCalendar from "./shared/Component/BackOffice/Purchase/ViewCalendar";
import DetailCalendar from "./shared/Component/BackOffice/Purchase/ViewCalendar";
import AddPanelMember from "./shared/Component/BackOffice/Faculty/AddPanelMember";
import ReadViewPMem from "./shared/Component/BackOffice/Faculty/ReadViewPMem";
import FacultyDetailview from "./shared/Component/BackOffice/Faculty/FacultyDetailview";
import AddSalary from "./shared/Component/BackOffice/Faculty/AddSalary";
import NonFacuityDetailProfile from "./shared/Component/BackOffice/Faculty/NonFacuityDetailProfile";
import NonFDetailview from "./shared/Component/BackOffice/Faculty/NonFDetailview";
import ReadViewSalary from "./shared/Component/BackOffice/Faculty/ReadViewSalary";
import DSchedule from "./shared/Component/BackOffice/CourseBatch/DSchedule";
import StaffDetailCalendar from "./shared/Component/BackOffice/Faculty/StaffDetailCalendar";
import FaculityView from "./shared/Component/BackOffice/Faculty/normalFaculity/FaculityView";
import PanelMemberView from "./shared/Component/BackOffice/Faculty/PanelMemberView";
import NonFacultyProfile from "./shared/Component/BackOffice/Faculty/NonFacultyProfile";
import ReviewTProgress from "./shared/Component/BackOffice/Faculty/ReviewTProgress";
import QtypeOne from "./shared/Component/BackOffice/question/QtypeOne";
import CentreSupervisor from "./shared/Component/BackOffice/CentreSupervisors/CentreSupervisor";

import QtypeTwo from "./shared/Component/BackOffice/question/QtypeTwo";
import Quiz from "./shared/Component/BackOffice/question/Quiz";
import TestQuestion from "./shared/Component/BackOffice/question/TestQuestion";
import QuestionList from "./shared/Component/BackOffice/question/QuestionList";
import TestListview from "./shared/Component/BackOffice/TestAdminWorkflow/TestListview";
import DetailTestView from "./shared/Component/BackOffice/TestAdminWorkflow/DetailTestView";
import UploadQuestionFile from "./shared/Component/BackOffice/TestAdminWorkflow/UploadQuestionFile";
import TestPerformance from "./shared/Component/BackOffice/StudentTestWorkflow/TestPerformance";
import ViewTestDetail from "./shared/Component/BackOffice/StudentTestWorkflow/ViewTestDetail";
import TestViewSchedule from "./shared/Component/BackOffice/StudentTestWorkflow/TestViewSchedule";
import ViewEventDetails from "./shared/Component/BackOffice/StudentTestWorkflow/ViewEventDetails";
import ViewQuestionPaper from "./shared/Component/BackOffice/TestAdminWorkflow/ViewQuestionPaper";
import JoinTestScreen from "./shared/Component/BackOffice/StudentTestWorkflow/JoinTestScreen";
import ReviewSub from "./shared/Component/BackOffice/StudentTestWorkflow/ReviewSub";
import Testlistdata from "./shared/Component/BackOffice/TestAdminWorkflow/Testlistdata";
import EmailVerfiy from "./shared/Component/OtpScreen/EmailVerfiy";
import PhoneNumberVerify from "./shared/Component/OtpScreen/PhoneNumberVerify";
import UserList from "./shared/Component/BackOffice/RegsisteredUser/UserList";
import ReviewCouresAdmission from "./pages/ReviewCouresAdmission";
import ReviewTestAdmission from "./pages/ReviewTestAdmission";
import ReviewSpecialAdmission from "./pages/ReviewSpecialAdmission";
import ReviewMockInterviewAdm from "./shared/Component/MockInterview/ReviewMockInterviewAdm";
import ReviewCounselling from "./shared/Component/Counselling/ReviewCounselling";
import ReviewModelexam from "./shared/Component/ModelExam/ReviewModelexam";
import ReviewUserDetail from "./shared/Component/BackOffice/RegsisteredUser/ReviewUserDetail";
import TestPerformanceTablist from "./shared/Component/BackOffice/StudentTestWorkflow/TestPerformanceTablist";
import PayFee from "./pages/PayFee";
import PaymentDueModel from "./pages/PaymentDueModel";
import CashListView from "./shared/Component/BackOffice/Cashpayment/CashListView";
import CashPayFee from "./shared/Component/BackOffice/Cashpayment/CashPayFee";
import PaymentSuccess from "./shared/Component/BackOffice/StudentTestWorkflow/PaymentSuccess";

// TEST MODULE-USER
// import ViewTestDetail from "./shared/Component/BackOffice/StudentTestWorkflow/pages/ViewTestDetail";
import JoinTest from "./shared/Component/BackOffice/StudentTestWorkflow/pages/JoinTest";
import ViewTestPerfomance from "./shared/Component/BackOffice/StudentTestWorkflow/pages/ViewTestPerfomance";
// ADDING GLOBAL THEMES FOR STYLED-COMPONENTS
import GlobalStyles from "./styles/GlobalStyles";

import DownloadHallTicket from "./shared/Component/ModelExam/DownloadHallTicket";
import ViewModelExamAnalytics from "./shared/Component/BackOffice/ModelExamBatch/ViewModelExamAnalytics";
import ExamCentreByDistrict from "./shared/Component/BackOffice/CourseBatch/ExamCentreByDistrict";
import ModelExamAnalytics from "./shared/Component/BackOffice/ModelExamBatch/ModelExamAnalytics";
// import TableAnalytic from "./shared/Component/BackOffice/ModelExamBatch/TableAnalytic";
import ModelExamCenter from "./shared/Component/BackOffice/ModelExamBatch/ModelExamCenter";
import ViewModelExam from "./shared/Component/BackOffice/ModelExamBatch/ViewModelExam";
import UploadMarkSheet from "./shared/Component/BackOffice/ModelExamBatch/UploadMarkSheet";
import ToggleButton from "./shared/Component/BackOffice/ModelExamBatch/ToggleButton";
import GenarateReportListView from "./shared/Component/BackOffice/GenerateReport/GenarateReportListView";

import {
  AdminFacultyRoleAccess,
  AdminRoleAccess,
  AdminStudentRoleAccess,
  FacultyRoleAccess,
  MenuRoleAccess,
  NonTeachingRoles,
  StudentRoleAccess,
} from "./labels";
import Listview from "./shared/Component/StudentConfirm/Listview";

//backoffice end

function App() {
  return (
    <div className="App">
      <UserDetailsContextProvider>
        <GlobalStyles />
        <BrowserRouter>
          <Routes>
            <Route path="/booklist" element={<BookList />}></Route>
            <Route path="products/:id" element={<BookDetailPage />} />
            <Route path="/Teams" element={<TeamMeeting />} />
            <Route path="/view" element={<ViewMeetingDetails />} />

            {/* backoffice start */}
            {/* <Route path="/course" element={<Course />}></Route>
            <Route path="/courseedit" element={<CourseEdit />}></Route>
            <Route path="/courseedit/:id" element={<CourseEdit />}></Route> */}

            {/* <Route path="/Test" element={<Test />}></Route>
            <Route path="/Testedit" element={<TestEdit />}></Route>
            <Route path="/Testedit/:id" element={<TestEdit />}></Route> */}

            {/* <Route path="/bookcourse" element={<BookCourse />}></Route>
            <Route path="/bookcourseedit" element={<BookCourseEdit />}></Route> */}
            {/* <Route
              path="/bookcourseedit/:id"
              element={<BookCourseEdit />}
            ></Route> */}
            {/* <Route path="/class" element={<Class/>}></Route> */}
            {/* <Route path="/classedit" element={<ClassEdit/>}></Route> */}
            {/* <Route path="/classedit/:id" element={<ClassEdit/>}></Route> */}

            {/* <Route path="/batch" element={<Batch/>}></Route>
        <Route path="/batchedit" element={<BatchEdit/>}></Route> */}

            <Route path="/gallery" element={<Gallery />}></Route>
            <Route path="/galleryedit" element={<GalleryEdit />}></Route>
            {/* <Route path="/galleryedit/:id" element={<GalleryEdit/>}></Route> */}
            <Route path="/testbatch" element={<TestBatchUpdate />}></Route>
            <Route
              path="/testbatchedit"
              element={<TestBatchUpdateEdit />}
            ></Route>
            <Route path="/branch" element={<BranchLocation />}></Route>
            <Route path="/branchedit" element={<BranchLocationEdit />}></Route>
            <Route
              path="/branchedit/:id"
              element={<BranchLocationEdit />}
            ></Route>
            {/* <Route path="/ViewCourseBatch" element={<ViewCourseBatch/>}></Route>
        <Route path="/createCourseBatch" element={<CreateCourseBatch/>}></Route>
        <Route path="/createCourseBatch/:id" element={<CreateCourseBatch/>}></Route> */}
            <Route
              path="/ViewTestcoursebatch"
              element={<ViewTestCourseBatch />}
            ></Route>
            <Route
              path="/createTestcoursebatch"
              element={<CreateTestCourseBatch />}
            ></Route>
            <Route
              path="/createTestcoursebatch/:id"
              element={<CreateTestCourseBatch />}
            ></Route>
            <Route path="/ViewDegree" element={<Degree />}></Route>
            <Route path="/createDegree" element={<DegreeEdit />}></Route>
            <Route path="/createDegree/:id" element={<DegreeEdit />}></Route>
            {/* backoffice end */}
          </Routes>
          <Routes>
            <Route path="/add_to_cart" element={<AddToCardTemplate />} />
            {/* <Route path="/InterviewLogin" element={<InterviewLogin />} />
            <Route path="/CounselingLogin" element={<CounsellingForm />} />
            <Route path="/ModelExamLogin" element={<ModelExamForm />} /> */}

            {/* <Route
              path="/StudentRegistration"
              element={<StudentRegistration />}
            /> */}
            {/* <Route path="/StaffRegistration" element={<StaffRegisternew />} /> */}

            {/* <Route path="/" element={<SampleForm />}></Route> */}

            <Route path="/forgetPassword" element={<ForgetPassword />} />
            <Route path="/pwdmail/:email" element={<ResetPassword />} />
            <Route path="/newpassword/:id" element={<Newpassword />}></Route>
            <Route path="/resetpwd" element={<Resetpassword />}></Route>
            <Route
              path="/registersuccess"
              element={<RegisterSuccess />}
            ></Route>
            <Route path="/viewformapp" element={<Register />}></Route>
            <Route path="/register" element={<Login />} />
            <Route path="/resetemail" element={<EmailReset />} />

            <Route path="/login" element={<SampleForm />}></Route>
            {/* ------------Fullscreen----------- */}
            <Route
              path="/JoinTest"
              element={
                <RequireAuth allowedRoles={StudentRoleAccess}>
                  <JoinTestScreen />{" "}
                </RequireAuth>
              }
            />

            <Route
              path="/RegisterUser/EmailVerify"
              element={<EmailVerfiy />}
            ></Route>
            <Route
              path="/RegisterUser/PhoneNumberVerify"
              element={<PhoneNumberVerify />}
            ></Route>

            {/* <Route path="/head" element={<HeaderPage />} /> */}

            {/* <Route path="/pay" element={<Razarpay />} /> */}
            <Route path="/" element={<Dashboard />}>
              <Route index element={<IndexPage />} />
              <Route
                path="Admission/Course"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <AdmissionForm />
                  </RequireAuth>
                }
              />
              {/* ------------------- New Start ----------------------------- */}

              <Route
                path="ToggleButton"
                element={
                  <RequireAuth allowedRoles={MenuRoleAccess}>
                    <ToggleButton />
                  </RequireAuth>
                }
              />
              <Route
                path="UploadMarkSheet"
                element={
                  <RequireAuth allowedRoles={AdminFacultyRoleAccess}>
                    <UploadMarkSheet />
                  </RequireAuth>
                }
              />

              {/*New POC Added- DummyComponent */}
              {/* <Route
                path="DummyComponent"
                element={
                  <RequireAuth>
                    <DummyComponent />
                  </RequireAuth>
                }
              /> */}
              {/*New POC Added- ClickHereDummy */}
              {/* <Route
                path="ClickHereDummy"
                element={
                  <RequireAuth>
                    <ClickHereDummy />
                  </RequireAuth>
                }
              /> */}

              {/*New POC Added- TestDummy */}
              {/* <Route
                path="TestDummy"
                element={
                  <RequireAuth>
                    <TestDummy />
                  </RequireAuth>
                }
              /> */}
              <Route
                path="/ModelExamAnalytics/:type"
                element={
                  <RequireAuth allowedRoles={AdminFacultyRoleAccess}>
                    <ModelExamAnalytics />
                  </RequireAuth>
                }
              />
              {/* ------------------- New End ----------------------------- */}

              <Route
                path="Admission/TestBatch"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <TestBatch />
                  </RequireAuth>
                }
              />
              <Route
                path="Admission/SpecialClasses"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <SpecialClass />
                  </RequireAuth>
                }
              />
              <Route
                path="Admission/Modelexam"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    {" "}
                    <ModelExamForm />
                  </RequireAuth>
                }
              />
              <Route
                path="Admission/Mockinterview"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <MockInterview />
                  </RequireAuth>
                }
              />
              <Route
                path="Admission/Counselling"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <Counselling />
                  </RequireAuth>
                }
              />

              <Route
                path="Admission/Course/:edit/:user_id/:admission_id"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <ReviewCouresAdmission />
                  </RequireAuth>
                }
              />
              <Route
                path="Admission/TestBatch/:edit/:user_id/:admission_id"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <ReviewTestAdmission />
                  </RequireAuth>
                }
              />
              <Route
                path="Admission/SpecialClasses/:edit/:user_id/:admission_id"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <ReviewSpecialAdmission />
                  </RequireAuth>
                }
              />
              <Route
                path="Admission/Modelexam/:edit/:user_id/:admission_id"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    {" "}
                    <ReviewModelexam />
                  </RequireAuth>
                }
              />
              <Route
                path="Admission/Mockinterview/:edit/:user_id/:admission_id"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <ReviewMockInterviewAdm />
                  </RequireAuth>
                }
              />

              <Route
                path="Admission/Counselling/:edit/:user_id/:admission_id"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <ReviewCounselling />
                  </RequireAuth>
                }
              />

              <Route
                path="ProfileSetting"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    {" "}
                    <ProfileSetting />
                  </RequireAuth>
                }
              />
              <Route
                path="Invoice"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    {" "}
                    <InvoiceTemplate />
                  </RequireAuth>
                }
              />
              <Route
                path="classedit"
                element={
                  <RequireAuth allowedRoles={MenuRoleAccess}>
                    {" "}
                    <ClassEdit />
                  </RequireAuth>
                }
              />
              <Route
                path="class"
                element={
                  <RequireAuth allowedRoles={MenuRoleAccess}>
                    {" "}
                    <Class />
                  </RequireAuth>
                }
              />
              <Route
                path="classedit/:id"
                element={
                  <RequireAuth allowedRoles={MenuRoleAccess}>
                    {" "}
                    <ClassEdit />
                  </RequireAuth>
                }
              />
              <Route
                path="ViewCourseBatch"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    {" "}
                    <ListviewBatch />{" "}
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="ViewCourseBatch/:active_tab"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    {" "}
                    <ListviewBatch />{" "}
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="DetailViewBatch/:id/:type"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    {" "}
                    <ReadViewBath />{" "}
                  </RequireAuth>
                }
              ></Route>
              <Route
                // path="/CentreSupervisors"
                path="/CentreSupervisors/:batch_id"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    {" "}
                    <CentreSupervisor />{" "}
                  </RequireAuth>
                }
              ></Route>

              <Route
                path="viewmodelexamanalytics"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <ViewModelExamAnalytics />
                  </RequireAuth>
                }
              />
              <Route
                path="exam_centre"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <ExamCentreByDistrict />
                  </RequireAuth>
                }
              />

              <Route
                path="CreateCourseBatch/:Type"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    {" "}
                    <CreateCourseBatch />{" "}
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="CreateCourseBatch/:Type/:id"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    {" "}
                    <CreateCourseBatch />{" "}
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/batch"
                element={
                  <RequireAuth allowedRoles={MenuRoleAccess}>
                    <Batch />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/batchedit"
                element={
                  <RequireAuth allowedRoles={MenuRoleAccess}>
                    {" "}
                    <BatchEdit />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/ListViewStudentConfirm"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <Listview />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/ListViewStudentConfirm/:typeFilter"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <Listview />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/studentConfirm/:userId/:admissId/:appliedOn/:type"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <StudentConfirm />{" "}
                  </RequireAuth>
                }
              />

              <Route
                // path="/CreateEvent/:sDate/:batch_id/:category/:startTime/:endTime"
                path="/CreateEvent"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <CreateEvent />{" "}
                  </RequireAuth>
                }
              />

              <Route
                // path="/CreateEvent/:sDate/:batch_id/:category/:startTime/:endTime"
                path="/Question"
                element={
                  <RequireAuth allowedRoles={MenuRoleAccess}>
                    <QtypeOne />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/addfaculty/:facId"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <NewFaculty />{" "}
                  </RequireAuth>
                }
              />
              {/*               
                <Route
                path="/Faculties/addfaculty/:Faculty"
                element={
                  <RequireAuth>
                    <NewFaculty />{" "}
                  </RequireAuth>
                }
              />   */}
              <Route
                path="/Faculties/addfaculty/:facId/:userId"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <NewFaculty />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/readfaculty/:userId"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <ReadFaculty />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/ReviewFacultyProfile/:userId"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <ReviewFaculty />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/Faculties"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <ListViewFaculty />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/:active_tab"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <ListViewFaculty />{" "}
                  </RequireAuth>
                }
              />

              {/* ----------ModeExamCenter-------- */}
              <Route
                path="/ModelExamCenter"
                element={
                  <RequireAuth allowedRoles={FacultyRoleAccess}>
                    <ModelExamCenter />
                  </RequireAuth>
                }
              />
              {/* ----------ViewModelExam-------- */}
              <Route
                path="/ViewModelExam"
                element={
                  <RequireAuth allowedRoles={FacultyRoleAccess}>
                    <ViewModelExam />
                  </RequireAuth>
                }
              />
              <Route
                path="/Faculties/AddPanelMember"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <AddPanelMember />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/AddPanelMember/:userId"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <AddPanelMember />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/Faculties/ReadViewPanelMember/:userId"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <ReadViewPMem />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/FacultyDetailView/:userId/:staff_id"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <FacultyDetailview />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/FacultyDetailView/:userId/:staff_id/:active_tab"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <FacultyDetailview />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/NonTeachingDetailView/:userId/:staff_id"
                element={
                  <RequireAuth allowedRoles={AdminFacultyRoleAccess}>
                    <NonFDetailview />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/Faculties/NonTeachingDetailView/:userId/:staff_id/:active_tab"
                element={
                  <RequireAuth allowedRoles={AdminFacultyRoleAccess}>
                    <NonFDetailview />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/AddSalary/:userId/:staff_id/:staff_type/:amount"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <AddSalary />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/AddSalary/:userId/:staff_id/:staff_type/:amount/:id"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <AddSalary />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/ReadViewSalary/:userId/:id/:staff_id/:staff_type"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <ReadViewSalary />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/AddSalary/:staff_id/:userId"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <AddSalary />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/Faculties/PanelMember/:userId/:staff_id"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <PanelMemberView />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/Faculties/PanelMember/:userId/:staff_id/:active_tab"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <PanelMemberView />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/facultyProfile"
                element={
                  <RequireAuth allowedRoles={AdminFacultyRoleAccess}>
                    <FacultyProfile />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/facultyProfile/:userId"
                element={
                  <RequireAuth allowedRoles={AdminFacultyRoleAccess}>
                    <FacultyProfile />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/NonFacultyProfile"
                element={
                  <RequireAuth allowedRoles={AdminFacultyRoleAccess}>
                    <NonFacultyProfile />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/NonFacultyProfile/:userId"
                element={
                  <RequireAuth allowedRoles={AdminFacultyRoleAccess}>
                    <NonFacultyProfile />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/TransactionHistory"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <PurchaseListview />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/TransactionHistory/:active_tab"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <PurchaseListview />{" "}
                  </RequireAuth>
                }
              />

              {/* ----------- */}
              <Route
                path="/PaymentSuccess"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <PaymentSuccess />
                  </RequireAuth>
                }
              />
              <Route
                path="/TransactionHistory/ViewAdmission/:user_id/:batch_id/:add_id/:type/:label"
                element={
                  <RequireAuth allowedRoles={AdminStudentRoleAccess}>
                    <ReadViewAdmissionHistory />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/TransactionHistory/ViewAdmission/ViewPaymentHistory/:type/:adm_id"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <PaymentHistory />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/TransactionHistory/ViewAdmission/ViewSchedule/:batch_id/:type"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <ViewSchedule />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/TestPerformanceListView"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <TestPerformanceTablist />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/TestPerformanceListView/:active_tab/:batchIds/:batch_type"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <TestPerformanceTablist />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/DetailCalendar"
                element={
                  <RequireAuth allowedRoles={MenuRoleAccess}>
                    <DetailCalendar />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/ViewEventDetails"
                element={
                  <RequireAuth allowedRoles={MenuRoleAccess}>
                    <ViewEventDetails />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/AdminDetailCalendar"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <DSchedule />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/ViewQuestionPaper"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <ViewQuestionPaper />{" "}
                  </RequireAuth>
                }
              />

              {/* <Route
                path="/JoinTest"
                element={
                  <RequireAuth>
                    <JoinTestScreen />{" "}
                  </RequireAuth>
                }
              /> */}

              <Route
                path="/StaffDetailCalendar"
                element={
                  <RequireAuth allowedRoles={MenuRoleAccess}>
                    <StaffDetailCalendar />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/ProfileFacultyView"
                element={
                  <RequireAuth allowedRoles={FacultyRoleAccess}>
                    <FaculityView />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/ProfileFacultyView/:active_tab"
                element={
                  <RequireAuth allowedRoles={FacultyRoleAccess}>
                    <FaculityView />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/ReviewTeachingProgress"
                element={
                  <RequireAuth allowedRoles={MenuRoleAccess}>
                    <ReviewTProgress />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/ViewSchedule/joinTest"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <JoinTest />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/TestQuestion"
                element={
                  <RequireAuth allowedRoles={MenuRoleAccess}>
                    <TestQuestion />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/TestPermeance"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <QuestionList />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/TestList"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <TestListview />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/ReviewSubmission/:batch_id/:event_id"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <ReviewSub />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/TestPerformance/:batch_id"
                Insights
                and
                Proposals
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <TestPerformance />{" "}
                  </RequireAuth>
                }
              />
              {/* testperfomance */}
              <Route
                path="/viewTestPerformance"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <ViewTestPerfomance />{" "}
                  </RequireAuth>
                }
              />
              <Route
                path="/ViewTestDetail/:batch_id/:event_id/:status/:event_type/:type_batch"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <ViewTestDetail />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/TestViewSchedule/:batch_id/:batch_type"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <TestViewSchedule />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/RegisterUser/ViewUser/:user_id"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <ReviewUserDetail />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/UploadQuestionFile"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <UploadQuestionFile />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/RegisteredUser"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <UserList />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/GenerateReport/:batch_id"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <GenarateReportListView />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="/GenerateReport/:batch_id/:active_tab"
                element={
                  <RequireAuth allowedRoles={AdminRoleAccess}>
                    <GenarateReportListView />{" "}
                  </RequireAuth>
                }
              />

              {/* <Route
                path="/ExpandTable"
                element={
                  <CollapsibleTable />
                }
              /> */}

              <Route path="/PaymentDue" element={<PaymentDueModel />} />

              <Route
                path="/PayFee"
                element={
                  <RequireAuth allowedRoles={StudentRoleAccess}>
                    <PayFee />
                  </RequireAuth>
                }
              />

              <Route
                path="/CashPayment"
                element={
                  <RequireAuth allowedRoles={NonTeachingRoles}>
                    <CashListView />
                  </RequireAuth>
                }
              />

              <Route
                path="/CashPayment/:active_tab"
                element={
                  <RequireAuth allowedRoles={NonTeachingRoles}>
                    <CashListView />
                  </RequireAuth>
                }
              />

              <Route
                path="/CashPayment/payFee"
                element={
                  <RequireAuth allowedRoles={NonTeachingRoles}>
                    <CashPayFee />
                  </RequireAuth>
                }
              />

              <Route
                path="/DetailTestView/:id/:status/:type"
                element={
                  <RequireAuth allowedRoles={MenuRoleAccess}>
                    <DetailTestView />{" "}
                  </RequireAuth>
                }
              />

              <Route
                path="DownloadHallTicket"
                element={
                  <RequireAuth allowedRoles={AdminStudentRoleAccess}>
                    <DownloadHallTicket />
                  </RequireAuth>
                }
              />
            </Route>

            {/* <Route path="*" element={<PageNotFound />} /> */}
          </Routes>
        </BrowserRouter>
      </UserDetailsContextProvider>
    </div>
  );
}

export default App;
