import "./AdmissionForm.css";
import Razorpay from "react-razorpay";
import React, { useState, useEffect, useRef } from "react";
import {
  Alarm,
  CurrencyRupee,
  LaptopChromebook,
  Today,
} from "@mui/icons-material";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import { AxiosInstance } from "../axios";
import { ImageUrlPrefix, PaymentSKey } from "../labels";
import moment from "moment";
import Swal from "sweetalert2";
// import { toWords } from "number-to-words";
import { ToWords } from "to-words";
import { Dialog, DialogContent } from "@mui/material";

function PayFee() {
  const location = useLocation();

  const navigate = useNavigate();
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });
  const { CourseDetail } = location.state;
  const txtAdPPayment = useRef(null);

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("UserId Not available");
  }

  const [dis, setDis] = useState(null);

  const [admissionDetails, setAdmissionDetails] = useState({});

  const [listErrors, setListErrors] = useState({});
  const [batchDetails, setBatchDetails] = useState([]);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  useEffect(() => {
    if (CourseDetail.type != "4") {
      AxiosInstance.post("api/purchase/view", {
        user_id: userid,
        admission_id: CourseDetail.adm_pk,
        type: CourseDetail.type,
      })
        .then((res) => {
          const userinfo = res.data.data;

          const PersonDetails = {
            FirstName: userinfo.first_name,
            LastName: userinfo.last_name,
            mobile: userinfo.phone_no,
            email: userinfo.email,
            Profilephoto:
              userinfo.profile_path !== null ? userinfo.profile_path : null,
          };

          const PartialPayment = userinfo.Admission_details[0]
            ? userinfo.Admission_details[0] &&
              userinfo.Admission_details[0].partial_payment !== null
              ? userinfo.Admission_details[0].partial_payment === 1
                ? "1"
                : "0"
              : "0"
            : userinfo.Admission_details &&
              userinfo.Admission_details.partial_payment !== null
            ? userinfo.Admission_details.partial_payment === 1
              ? "1"
              : "0"
            : "0";

          const student_id = userinfo.Admission_details[0]
            ? userinfo.Admission_details[0] &&
              userinfo.Admission_details[0].student_id !== null
              ? userinfo.Admission_details[0].student_id
              : null
            : userinfo.Admission_details &&
              userinfo.Admission_details.student_id !== null
            ? userinfo.Admission_details[0].student_id
            : null;

          PersonDetails.PPayment = PartialPayment;
          PersonDetails.student_id = student_id;

          setList(PersonDetails);

          const admissiondetails =
            userinfo.Admission_details.length > 0
              ? userinfo.Admission_details[0]
              : {};

          setAdmissionDetails(admissiondetails);

          userinfo.Courses.batch_details !== null
            ? getDisocount(userinfo.Courses.batch_details.batch_id)
            : getDisocount(null);

          const batchDe =
            userinfo.Courses.batch_details !== null
              ? {
                  batch_id: userinfo.Courses.batch_details.batch_id,
                  id: userinfo.Courses.batch_details.batch_id,
                  img_url: userinfo.Courses.batch_details.img_url,
                  course_name: userinfo.Courses.batch_details.course_name,
                  batch_name: userinfo.Courses.batch_details.batch_name,
                  course_id: userinfo.Courses.batch_details.course_id,
                  batch_details: userinfo.Courses.batch_details.batch_details,
                  class_mode: userinfo.Courses.batch_details.class_mode,
                  start_on: userinfo.Courses.batch_details.start_on
                    ? dobCon(userinfo.Courses.batch_details.start_on)
                    : "",
                  duration: userinfo.Courses.batch_details.duration,
                  act_c_fee: userinfo.Courses.batch_details.act_c_fee,
                  offer_spl: userinfo.Courses.batch_details.offer_spl,
                  dis_amt: userinfo.Courses.batch_details.dis_amt,
                  medium: userinfo.Courses.batch_details.medium,
                  is_selected: userinfo.Courses.batch_details.is_selected,
                  exam_details:
                    userinfo.Courses.batch_details.exam_details &&
                    userinfo.Courses.batch_details.exam_details.exam_details &&
                    userinfo.Courses.batch_details.exam_details.exam_details
                      .length > 0
                      ? userinfo.Courses.batch_details.exam_details.exam_details
                      : [],
                  panel_details:
                    Object.keys(userinfo.Courses.batch_details.panel_details)
                      .length > 0
                      ? userinfo.Courses.batch_details.panel_details.panel_list
                      : [],
                  exam_fees:
                    Object.keys(userinfo.Courses.batch_details.exam_details)
                      .length > 0 &&
                    userinfo.Courses.batch_details.exam_details.exam_fees
                      .length > 0
                      ? userinfo.Courses.batch_details.exam_details.exam_fees
                      : Object.keys(
                          userinfo.Courses.batch_details.panel_details
                        ).length > 0
                      ? userinfo.Courses.batch_details.panel_details.exam_fees
                      : [],
                }
              : null;
          let ExamObj = [];
          batchDe.exam_details.map((el) => {
            ExamObj.push({
              pk: el.exam_detail_id,
              batch_id: el.batch_id,
              exam_title: el.exam_title,
              exam_title_id: el.exam_detail_id,
              started_dt: el.started_dt,
              batch_time: el.batch_time,
              duration_hrs: el.duration,
              syllabus_url: el.syllabus_url,
              is_selected_exam: el.is_selected_exam === 1 ? true : false,
            });
          });
          batchDe.exam_details = ExamObj;
          let PaneldetailsObj = [];
          batchDe.panel_details.map((el) => {
            PaneldetailsObj.push({
              pk: el.panel_pk,
              batch_id: el.batch_id,
              batch_type: el.batch_type,
              panel_name: el.panel_name,
              available_dates: el.available_dates,
              event_time: el.event_time,
              is_selected_panel: el.is_selected_panel == 1 ? true : false,
            });
          });
          batchDe.panel_details =
            PaneldetailsObj.length > 0 ? PaneldetailsObj : {};
          setBatchDetails([batchDe]);
        })
        .catch((error) => {
          return error;
        });
    } else {
      AxiosInstance.post("api/modelexam/view", {
        user_id: userid,
        admission_id: CourseDetail.adm_pk,
        type: CourseDetail.type,
      })
        .then((res) => {
          if (res.data.status === true) {
            // console.log(res.data.data, "modelexam view");
            const userinfo = res.data.data;
            const PersonDetails = {
              FirstName: userinfo.first_name,
              LastName: userinfo.last_name,
              mobile: userinfo.phone_no,
              email: userinfo.email,
            };

            const PartialPayment = userinfo.Admission_details[0]
              ? userinfo.Admission_details[0] &&
                userinfo.Admission_details[0].partial_payment !== null
                ? userinfo.Admission_details[0].partial_payment === 1
                  ? "1"
                  : "0"
                : "0"
              : userinfo.Admission_details &&
                userinfo.Admission_details.partial_payment !== null
              ? userinfo.Admission_details.partial_payment === 1
                ? "1"
                : "0"
              : "0";

            PersonDetails.PPayment = PartialPayment;

            setList(PersonDetails);

            const admissiondetails =
              userinfo.Admission_details.length > 0
                ? userinfo.Admission_details[0]
                : {};

            setAdmissionDetails(admissiondetails);

            const batchDe =
              userinfo.Courses.batch_details !== null
                ? {
                    batch_id: userinfo.Courses.batch_details.batch_id,
                    id: userinfo.Courses.batch_details.batch_id,
                    img_url: userinfo.Courses.batch_details.img_url,
                    course_name: userinfo.Courses.batch_details.course_name,
                    batch_name: userinfo.Courses.batch_details.batch_name,
                    course_id: userinfo.Courses.batch_details.course_id,
                    batch_details: userinfo.Courses.batch_details.batch_details,
                    class_mode: userinfo.Courses.batch_details.class_mode,
                    start_on: userinfo.Courses.batch_details.start_on
                      ? dobCon(userinfo.Courses.batch_details.start_on)
                      : "",
                    duration: convertSecondsToTime(
                      userinfo.Courses.batch_details.duration
                    ),
                    act_c_fee: userinfo.Courses.batch_details.act_c_fee,
                    offer_spl: userinfo.Courses.batch_details.offer_spl,
                    dis_amt: userinfo.Courses.batch_details.dis_amt,
                    medium: userinfo.Courses.batch_details.medium,
                    is_selected: userinfo.Courses.batch_details.is_selected,
                    exam_details:
                      userinfo.Courses.batch_details.exam_details &&
                      userinfo.Courses.batch_details.exam_details
                        .exam_details &&
                      userinfo.Courses.batch_details.exam_details.exam_details
                        .length > 0
                        ? userinfo.Courses.batch_details.exam_details
                            .exam_details
                        : [],
                    panel_details:
                      Object.keys(userinfo.Courses.batch_details.panel_details)
                        .length > 0
                        ? userinfo.Courses.batch_details.panel_details
                            .panel_list
                        : [],
                    exam_fees:
                      Object.keys(userinfo.Courses.batch_details.exam_details)
                        .length > 0 &&
                      userinfo.Courses.batch_details.exam_details.exam_fees
                        .length > 0
                        ? userinfo.Courses.batch_details.exam_details.exam_fees
                        : Object.keys(
                            userinfo.Courses.batch_details.panel_details
                          ).length > 0
                        ? userinfo.Courses.batch_details.panel_details.exam_fees
                        : [],
                  }
                : null;
            let ExamObj = [];
            batchDe.exam_details.map((el) => {
              ExamObj.push({
                pk: el.exam_detail_id,
                batch_id: el.batch_id,
                exam_title: el.exam_title,
                exam_title_id: el.exam_detail_id,
                started_dt: el.started_dt,
                batch_time: el.batch_time,
                duration_hrs: el.duration,
                syllabus_url: el.syllabus_url,
                is_selected_exam: el.is_selected_exam === 1 ? true : false,
              });
            });
            batchDe.exam_details = ExamObj;
            let PaneldetailsObj = [];
            batchDe.panel_details.map((el) => {
              PaneldetailsObj.push({
                pk: el.panel_pk,
                batch_id: el.batch_id,
                batch_type: el.batch_type,
                panel_name: el.panel_name,
                available_dates: el.available_dates,
                event_time: el.event_time,
                is_selected_panel: el.is_selected_panel == 1 ? true : false,
              });
            });
            batchDe.panel_details =
              PaneldetailsObj.length > 0 ? PaneldetailsObj : {};
            setBatchDetails([batchDe]);
          } else {
            Swal.fire({
              title: "Warning",
              text: res.data.message,
              icon: "Warning",
              confirmButtonText: "Ok",
            });
            navigateToPreviousScreen();
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, []);

  const [list, setList] = useState({
    PPayment: "",
    FirstName: "",
    LastName: "",
    email: "",
    mobile: "",
    Profilephoto: null,
    student_id: null,
  });

  const dobCon = (userdate) => {
    if (!userdate) {
      return ""; // Handle empty input
    }
    const date = new Date(userdate);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Handle invalid date
    }
    // Extract the year, month, and day components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const validateFieldsPayments = (data) => {
    // console.log(data, "data");
    const errors = {};
    if (!data.PPayment) {
      errors.PPayment = "please select Payment Type";
    }
    validateFieldsPaymentF(data);
    return errors;
  };

  const validateFieldsPaymentF = (data) => {
    if (!data.PPayment) {
      txtAdPPayment.current.focus();
    }
  };

  const handleChange = (event) => {
    console.log(event);
    const { name, value, files, checked } = event.target;
    if (checked) {
      setList((prevList) => ({
        ...prevList,
        [name]: value,
      }));
      setListErrors({
        ...listErrors,
        ["PPayment"]: "",
      });
    } else {
      setList((prevList) => ({
        ...prevList,
        [name]: "",
      }));
    }
  };

  const getDisocount = (batchId) => {
    const df = {
      user_id: userid,
      batch_id: batchId,
    };
    AxiosInstance.post("api/all/getOldStudDisc", df)
      .then((res) => {
        // console.log(res, "discount batch");
        if (res.data.status === true) {
          let discount = res.data.old_stud_disc;
          // console.log(discount, "discount");
          setDis(discount);
        } else {
          setDis(null); // or set a default value for no discount
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSPayfee = (details, elements, Payamount) => {
    const errorss = validateFieldsPayments(list);
    if (Object.keys(errorss).length === 0) {
      const courseAmo = Payamount;
      const receipt = "Order_" + Math.floor(Math.random() * 900 + 100);
      if (courseAmo > 0) {
        AxiosInstance.post("api/payemnt/create_order", {
          amount: courseAmo * 100,
          currency: "INR",
          partial_payment: true,
          receipt: receipt,
        })
          .then((res) => {
            if (res) {
              const { data } = res;
              const stepData = {
                id: "",
                type: details.type,
                user_id: userid,
                admission_history_id: details.adm_his_id,
                entity: "",
                amount: data.amount / 100,
                currency: "INR",
                status: 1,
                order_id: data.id,
                invoice_id: "",
                international: "",
                method: "",
                amount_refunded: 0,
                refund_status: "",
                captured: "",
                description: "",
                card_id: "",
                bank: "",
                wallet: "",
                vpa: "",
                email: list.email,
                contact: list.mobile,
                notes: "",
                fee: 0,
                tax: 0,
                error_code: "",
                error_description: "",
                error_source: "",
                error_step: "",
                error_reason: "",
                acquirer_data: {
                  rrn: "",
                  upi_transaction_id: "",
                },
                created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
                created_by: userid,
                updated_by: userid,
                payment_type: 1, //1 → online ,0 → offline // we use online now
                installment_type:
                  list.PPayment === "1"
                    ? details.instment_pay[0].payment_status !== "1"
                      ? [2]
                      : [3]
                    : [2, 3],
              };
              AxiosInstance.post(
                "api/course/admission/create_order_partialpayment",
                stepData
              )
                .then((resss) => {
                  setIsPaymentLoading(true);
                  if (resss.data.status === true) {
                    setIsPaymentLoading(false);
                    const options = {
                      key: PaymentSKey,
                      amount: resss.data.data.amount,
                      currency: "INR",
                      name: details.batch_name,
                      prefill: {
                        name:
                          list.FirstName !== null && list.FirstName !== ""
                            ? list.FirstName
                            : "Anna iasa",
                        email:
                          list.email !== null && list.email !== ""
                            ? list.email
                            : "info@annaiasacademy.com",
                        contact:
                          list.mobile !== null && list.mobile !== ""
                            ? list.mobile
                            : "1234567890",
                      },
                      description: "Admission Pending Fees",
                      image: "B",
                      order_id: resss.data.data.order_id,
                      handler: function (response) {
                        if (response) {
                          setIsPaymentLoading(true);
                          AxiosInstance.get(
                            `api/payments/${response.razorpay_payment_id}`
                          )
                            .then((res) => {
                              if (res.status === 200) {
                                const payInfo = res.data;
                                let payment_status_info = "";
                                if (payInfo.status === "failed") {
                                  payment_status_info = 0;
                                } else if (payInfo.status === "created") {
                                  payment_status_info = 1;
                                } else if (payInfo.status === "authenticated") {
                                  payment_status_info = 2;
                                } else if (payInfo.status === "authorized") {
                                  payment_status_info = 3;
                                } else if (payInfo.status === "captured") {
                                  payment_status_info = 4;
                                } else if (payInfo.status === "refunded") {
                                  payment_status_info = 5;
                                }

                                const paymentHistory = {
                                  id: payInfo.id,
                                  admission_history_id: details.adm_his_id,
                                  entity: payInfo.entity,
                                  amount: payInfo.amount / 100,
                                  currency: payInfo.currency,
                                  status: payment_status_info,
                                  order_id: payInfo.order_id,
                                  invoice_id: payInfo.invoice_id,
                                  international: payInfo.international,
                                  method: payInfo.method,
                                  amount_refunded: payInfo.amount_refunded,
                                  refund_status: payInfo.refund_status,
                                  captured: payInfo.captured,
                                  description: payInfo.description,
                                  card_id: payInfo.card_id,
                                  bank: payInfo.bank,
                                  wallet: payInfo.wallet,
                                  vpa: payInfo.vpa,
                                  email: payInfo.email,
                                  contact: payInfo.contact,
                                  notes:
                                    payInfo.notes.length > 0
                                      ? payInfo.notes.toString()
                                      : null,
                                  fee: payInfo.fee,
                                  tax: payInfo.tax,
                                  error_code: payInfo.error_code,
                                  error_description: payInfo.error_description,
                                  error_source: payInfo.error_source,
                                  error_step: payInfo.error_step,
                                  error_reason: payInfo.error_reason,
                                  acquirer_data: {
                                    rrn: payInfo.acquirer_data.rrn,
                                    upi_transaction_id:
                                      payInfo.acquirer_data.upi_transaction_id,
                                  },
                                  created_at: moment
                                    .unix(payInfo.created_at)
                                    .format("YYYY-MM-DD hh:mm:ss"),
                                  created_by: userid,
                                  payment_type: 1, //1 → online ,0 → offline // we use online now
                                  installment_type:
                                    list.PPayment === "1"
                                      ? details.instment_pay[0]
                                          .payment_status !== "1"
                                        ? [2]
                                        : [3]
                                      : [2, 3],
                                };

                                AxiosInstance.post(
                                  "api/course/admission/create_order_partialpayment",
                                  paymentHistory
                                )
                                  .then((res_d) => {
                                    setIsPaymentLoading(false);
                                    if (res_d.data.status === true) {
                                      navigate("/TransactionHistory");
                                    }
                                  })
                                  .catch((error) => {
                                    setIsPaymentLoading(false);
                                    return error;
                                  });
                              }
                            })
                            .catch((error) => {
                              setIsPaymentLoading(false);
                              return error;
                            });
                        } else {
                          setIsPaymentLoading(false);
                        }
                      },
                    };
                    const rzp1 = new window.Razorpay(options);
                    rzp1.open();
                  } else {
                    setIsPaymentLoading(false);
                    Swal.fire({
                      title: "warning",
                      text: resss.data.message,
                      icon: "warning",
                      confirmButtonText: "Ok",
                    });
                    navigate("/TransactionHistory");
                  }
                })
                .catch((error) => {
                  setIsPaymentLoading(false);
                  return error;
                });
            } else {
              Swal.fire({
                title: "warning",
                text: "Something Went Wrong",
                icon: "warning",
                confirmButtonText: "Ok",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: error,
              icon: "warning",
              confirmButtonText: "Ok",
            });
            console.log(error, "error");
            return error;
          });
      } else {
        Swal.fire({
          title: "warning",
          text: "Your fee should be a minimum of 1 rupee. Kindly reach out to the administrator for assistance with this matter.",
          icon: "warning",
          confirmButtonText: "Ok",
        });
      }
    } else {
      setListErrors(errorss);
    }
  };

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  const convertSecondsToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const remainingSeconds = totalSeconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);

    if (minutes > 0) {
      return `${hours}:${minutes.toString().padStart(2, "0")}`;
    } else {
      return `${hours}`;
    }
  };

  return (
    <div className="AdmissionForm AdmissionForm_section">
      {batchDetails.length > 0 &&
        batchDetails.map((el, index) => (
          <form>
            <div className="sectionSix">
              <>
                <div className="heading mb-4 dflexbetww align-items-center">
                  <h4 className="Title">Pay Fee</h4>
                  <div className="d-flex smButton mobiledown">
                    <Link to="/TransactionHistory" className="CancelButton">
                      Back to Transaction History
                    </Link>
                    <button
                      type="button"
                      className={
                        admissionDetails.old_stud_disc !== 0 &&
                        CourseDetail.instment_pay[1].due_amount < 0 &&
                        list.PPayment === "1"
                          ? `SaveButton disabledButton`
                          : `SaveButton`
                      }
                      disabled={
                        admissionDetails.old_stud_disc !== 0 &&
                        CourseDetail.instment_pay[1].due_amount < 0 &&
                        list.PPayment === "1"
                          ? true
                          : false
                      }
                      onClick={() =>
                        handleSPayfee(
                          CourseDetail,
                          el,
                          CourseDetail.instment_pay[0].payment_status !== "1"
                            ? list.PPayment === "1"
                              ? CourseDetail.instment_pay[0].due_amount
                              : Number(
                                  CourseDetail.instment_pay[0].due_amount
                                ) +
                                Number(CourseDetail.instment_pay[1].due_amount)
                            : CourseDetail.instment_pay[1].due_amount
                        )
                      }
                    >
                      <a href="javascript:void(0)">
                        Save and Continue to Payment Gateway
                      </a>
                    </button>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="card h-100">
                          <div className="row">
                            <div className="col-md-6 d-flex flex-column  justify-content-center align-center mt-4">
                              <img
                                title={el.img_url}
                                src={`${ImageUrlPrefix}/${el.img_url}`}
                                id={el.id}
                                alt="Imge"
                                style={{
                                  width: "130px",
                                  height: "130px",
                                  alignSelf: "center",
                                }}
                              />
                              <h5 className="card-title payment_course_name">
                                {el.course_name || ""} {"-"} {el.name || ""}
                              </h5>
                              <p className=" ms-3 text-center payment_batch_name">
                                {el.batch_details}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <div className="d-flex mt-4 flex-column align-items-start payment">
                                <div className="">
                                  <LaptopChromebook className="payment_icon" />
                                  <div className="innerflex_payment">
                                    <h6 className="payment flex_title">
                                      Class Mode
                                    </h6>
                                    <h6 className="payment flex_subtitle">
                                      {el.class_mode}
                                    </h6>
                                  </div>
                                </div>

                                <div className="">
                                  <Today className="payment_icon" />
                                  <div className="innerflex_payment">
                                    <h6 className="payment flex_title">
                                      Starts On
                                    </h6>
                                    <h6 className="payment flex_subtitle">
                                      {moment
                                        .utc(el.start_on)
                                        .format("YYYY-MM-DD")}
                                    </h6>
                                  </div>
                                </div>
                                <div className="">
                                  <Alarm className="payment_icon" />
                                  <div className="innerflex_payment">
                                    <h6 className="payment flex_title">
                                      Duration
                                    </h6>
                                    <h6 className="payment flex_subtitle">
                                      {el.duration}
                                    </h6>
                                  </div>
                                </div>
                                <div className="">
                                  <span class="material-icons payment_icon">
                                    g_translate
                                  </span>
                                  <div className="innerflex_payment">
                                    <h6 className="payment flex_title">
                                      Medium of Instruction
                                    </h6>
                                    <h6 className="payment flex_subtitle">
                                      {el.medium}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="card h-100">
                          <div className="row mt-4">
                            <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                              <h6 className="Payment_labelInfo">
                                Actual Course Fee
                              </h6>
                            </div>
                            <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                              <h6 className="Payment_labelInfos">
                                <CurrencyRupee className="payment_currency" />
                                {el.act_c_fee}
                              </h6>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                              <h6 className="payment_fees_name">
                                Special Offer
                              </h6>
                            </div>
                            <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                              <h6 className="payment_fees">
                                <CurrencyRupee className="payment_currency" />
                                {CourseDetail.special_offer}
                              </h6>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                              <h6 className="payment_fees_name PymentCoun">
                                Coupon Code
                              </h6>
                            </div>
                            <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                              <h6 className="payment_fees">
                                <CurrencyRupee className="payment_currency" />
                                {CourseDetail.coupon_amount}
                              </h6>
                            </div>
                            {/* <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                              <div className="d-flex coupon_box">
                                <input
                                  type="text"
                                  id="CouponCode"
                                  name="CouponCode"
                                  // onChange={handleChange}
                                  // value={couponApplied.coupon_code}
                                  className="payment_fees payment_input gap-2 payment_fees_mobile"
                                  placeholder="Coupon Code"
                                />
                                &nbsp;&nbsp;
                                <button
                                  className="btn payment payment_coupen"
                                  type="button"
                                  // onClick={(e) =>
                                  //   handleCouponCode(e, itk.dis_amt, itk.id)
                                  // }
                                >
                                  Apply Coupon Code
                                </button>
                              </div>
                            </div> */}
                          </div>
                          {/* <div className="row">
                            <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                              {couponApplied.status === true && (
                      <h6 className="payment_fees_name">
                        Coupon Amount
                      </h6>
                    )}
                            </div>
                            <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                              {couponApplied.status === true && (
                      <h6 className="payment_fees">
                        <span className="text-danger  fw-bold">{couCode.CouponCode}</span>
                        <CurrencyRupee className="payment_currency" />
                        <span className="text-success fw-bold ">
                          {couponApplied.amount}
                        </span>

                        <span className="text-success ms-2">
                          <strike>Coupon applied</strike>
                        </span>
                      </h6>
                    )}
                            </div>
                          </div> */}
                          <div className="row">
                            <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                              <h6
                                className="Payment_labelInfo"
                                style={{ paddingTop: "9px" }}
                              >
                                Total Discounted Fee
                              </h6>
                            </div>
                            <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                              <h6 className="Payment_labelInfos pt-2">
                                <CurrencyRupee className="payment_currency" />
                                <span>
                                  {parseInt(CourseDetail.special_offer) +
                                    parseInt(CourseDetail.coupon_amount)}
                                </span>
                              </h6>
                            </div>
                          </div>
                          {/* <div className="row">
                            <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                              <h6 className="payment_fees_name">GST(0%)</h6>
                            </div>
                            <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                              <h6 className="payment_fees ">
                                <CurrencyRupee className="payment_currency" />
                                <span>{CourseDetail.gst_amt}</span>
                              </h6>
                            </div>
                          </div> */}
                          <div className="row">
                            <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                              <h6 className="payment_fees_name">
                                Final Course Fee
                              </h6>
                            </div>
                            <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                              <h6 className="payment_fees">
                                <CurrencyRupee className="payment_currency" />
                                <span className="">{CourseDetail.tot_amt}</span>
                              </h6>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                              <h6 className="payment_fees_name" id="remain_fee">
                                Fee Paid
                              </h6>
                            </div>
                            <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                              <h6 className="payment_fees">
                                <CurrencyRupee className="payment_currency" />
                                <span>{CourseDetail.paid_amount}</span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>

            {admissionDetails.old_stud_disc !== 0 && (
              <section className="sectionSix">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Old Student Discount</p>

                    <div className="row mb-3">
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Old Student Discount
                          </label>
                          <span className="ReviewLabel ms-3">
                            <CurrencyRupee className="payment_currency" />
                            {dis}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-lg-12 col-md-12">
                        <div className="forms">
                          <span className="ReviewLabel_note alert_info">
                            <strong> Note :</strong> The Old Student Discount
                            will be applied to the final installment only if you
                            select the Partial Payment option. Alternatively, if
                            you choose the Full Payment option, the Old Student
                            Discount amount will be deducted from the payment
                            immediately.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            <section className="sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Payment Option</p>

                  <div className="row mb-3">
                    <div className="col-lg-7 ol-md-7">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Payment Option
                        </label>
                        {CourseDetail.instment_pay.length > 0 &&
                        CourseDetail.instment_pay[0].payment_status == 0 ? (
                          <div className="d-flex  col-md-12" id="radiobuttons">
                            <div className="form-check form-check-inline">
                              <input
                                ref={txtAdPPayment}
                                className="checkbox_radio"
                                type="radio"
                                name="PPayment"
                                id="full"
                                checked={list.PPayment === "0" ? true : false}
                                value="0"
                                onChange={handleChange}
                              />
                              <label
                                className="checkbox-label mt-1"
                                htmlFor="full"
                              >
                                Full Balance Payment (2nd + 3rd Payment)
                              </label>
                            </div>
                            <div className="form-check form-check-inline p-sm-0 me-sm-0">
                              <input
                                className="checkbox_radio"
                                type="radio"
                                name="PPayment"
                                id="Partial"
                                value="1"
                                checked={list.PPayment === "1" ? true : false}
                                onChange={handleChange}
                              />
                              <label
                                className="checkbox-label mt-1"
                                htmlFor="Partial"
                              >
                                Partial Payment (2nd Payment)
                              </label>
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex  col-md-12" id="radiobuttons">
                            <div className="form-check form-check-inline">
                              <input
                                ref={txtAdPPayment}
                                className="checkbox_radio"
                                type="radio"
                                name="PPayment"
                                id="Partial"
                                value="1"
                                checked={list.PPayment === "1" ? true : false}
                                onChange={handleChange}
                              />
                              <label
                                className="checkbox-label mt-1"
                                htmlFor="full"
                              >
                                Final Payment
                              </label>
                            </div>
                          </div>
                        )}

                        {listErrors.PPayment && (
                          <p className="errors">{listErrors.PPayment}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-5 col-md-5">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Amount To Pay{" "}
                          {CourseDetail.instment_pay[0].payment_status !== "1"
                            ? admissionDetails.old_stud_disc !== 0
                              ? list.PPayment === "1"
                                ? `(${CourseDetail.instment_pay[0].due_perc}% of Final Course Fee)`
                                : `(${
                                    CourseDetail.instment_pay[0].due_perc +
                                    CourseDetail.instment_pay[1].due_perc
                                  }% of Final Course Fee - Old Student
                                  Discount)`
                              : list.PPayment === "1"
                              ? `(${CourseDetail.instment_pay[0].due_perc}% of Final Course Fee )`
                              : `(${
                                  CourseDetail.instment_pay[0].due_perc +
                                  CourseDetail.instment_pay[1].due_perc
                                }% of Final Course Fee )`
                            : admissionDetails.old_stud_disc !== 0
                            ? list.PPayment === "1"
                              ? `(${CourseDetail.instment_pay[1].due_perc}% of Final Course Fee - Old Student
                                  Discount)`
                              : `(${CourseDetail.instment_pay[1].due_perc}% of Final Course Fee - Old Student
                                    Discount)`
                            : list.PPayment === "1"
                            ? `(${CourseDetail.instment_pay[1].due_perc}% of Final Course Fee)`
                            : `(${CourseDetail.instment_pay[1].due_perc}% of Final Course Fee)`}
                        </label>
                        <span className="ReviewLabel mt-md-1 mt-sm-4">
                          <CurrencyRupee className="payment_currency" />
                          {CourseDetail.instment_pay[0].payment_status !== "1"
                            ? list.PPayment === "1"
                              ? CourseDetail.instment_pay[0].due_amount
                              : Number(
                                  CourseDetail.instment_pay[0].due_amount
                                ) +
                                Number(CourseDetail.instment_pay[1].due_amount)
                            : CourseDetail.instment_pay[1].due_amount}
                        </span>
                      </div>
                    </div>
                  </div>
                  {admissionDetails.old_stud_disc !== 0 &&
                    CourseDetail.instment_pay[1].due_amount < 0 &&
                    list.PPayment === "1" && (
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="forms ">
                            <span className="ReviewLabel_warning alert_warning ">
                              <div className="">
                                <span class="material-icons ">warning</span>
                                <strong className="waning_message">
                                  WARNING
                                </strong>
                                <span className="">
                                  {" "}
                                  : If you choose{" "}
                                  <b style={{ color: "black" }}>
                                    Partial Payment (2nd Payment)
                                  </b>
                                  , the remaining fee will be less than the Old
                                  Student Discount. We request you to select
                                  <b
                                    style={{ color: "black" }}
                                    className="ms-1"
                                  >
                                    Full Balance Payment (2nd + 3rd Payment)
                                  </b>{" "}
                                  to apply the discount and pay the fees.
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </section>

            {list.PPayment === "1" &&
              CourseDetail.instment_pay[0].payment_status !== "1" && (
                <section className="sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">
                        Partial Payment Schedule Details
                      </p>

                      <div className="row">
                        <div className="col-lg-6 ol-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              {" "}
                              2nd Payment Date (On or Before)
                            </label>
                            <span className="ReviewLabel">
                              {CourseDetail.instment_pay[0].due_date
                                ? moment(
                                    CourseDetail.instment_pay[0].due_date,
                                    "DD/MM/YYYY",
                                    true
                                  ).isValid()
                                  ? moment(
                                      CourseDetail.instment_pay[0].due_date,
                                      "DD/MM/YYYY"
                                    ).format("YYYY-MM-DD")
                                  : moment(
                                      CourseDetail.instment_pay[0].due_date
                                    ).format("YYYY-MM-DD")
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              2nd Payment Amount{" "}
                              {`(${CourseDetail.instment_pay[0].due_perc}% of Final Course Fee)`}
                            </label>
                            <span className="ReviewLabel  mt-md-1 mt-sm-4">
                              <CurrencyRupee className="payment_currency" />
                              {CourseDetail.instment_pay[0].due_amount}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-lg-6 ol-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              3rd Payment Date (On or Before)
                            </label>
                            <span className="ReviewLabel">
                              {CourseDetail.instment_pay[1].due_date
                                ? moment(
                                    CourseDetail.instment_pay[1].due_date,
                                    "DD/MM/YYYY",
                                    true
                                  ).isValid()
                                  ? moment(
                                      CourseDetail.instment_pay[1].due_date,
                                      "DD/MM/YYYY"
                                    ).format("YYYY-MM-DD")
                                  : moment(
                                      CourseDetail.instment_pay[1].due_date
                                    ).format("YYYY-MM-DD")
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              3rd Payment Amount{" "}
                              {admissionDetails.old_stud_disc !== 0
                                ? `(${CourseDetail.instment_pay[1].due_perc}% of Final Course Fee -
                   Old Student Discount)`
                                : `(${CourseDetail.instment_pay[1].due_perc}% of Final Course Fee)`}
                            </label>
                            <span className="ReviewLabel mt-md-1 mt-sm-4">
                              <CurrencyRupee className="payment_currency" />
                              {CourseDetail.instment_pay[1].due_amount}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                          <div className="forms ">
                            <span className="ReviewLabel_warning alert_warning ">
                              <div className="blink">
                                <span class="material-icons ">warning</span>
                                <strong className="waning_message">
                                  WARNING
                                </strong>
                                <span className="">
                                  : Please pay your fees by the given date to
                                  keep your schedule active. If you don't pay on
                                  time, you will not be allowed to attend
                                  classes until the fees are paid.
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            <div className="sectionSix">
              <div className="heading mb-4 dflexbetwwend align-items-center">
                <div className="d-flex smButton mobiledown">
                  <Link to="/TransactionHistory" className="CancelButton">
                    Back to Transaction History
                  </Link>
                  <button
                    type="button"
                    className={
                      admissionDetails.old_stud_disc !== 0 &&
                      CourseDetail.instment_pay[1].due_amount < 0 &&
                      list.PPayment === "1"
                        ? `SaveButton disabledButton`
                        : `SaveButton`
                    }
                    disabled={
                      admissionDetails.old_stud_disc !== 0 &&
                      CourseDetail.instment_pay[1].due_amount < 0 &&
                      list.PPayment === "1"
                        ? true
                        : false
                    }
                    onClick={() =>
                      handleSPayfee(
                        CourseDetail,
                        el,
                        CourseDetail.instment_pay[0].payment_status !== "1"
                          ? list.PPayment === "1"
                            ? CourseDetail.instment_pay[0].due_amount
                            : Number(CourseDetail.instment_pay[0].due_amount) +
                              Number(CourseDetail.instment_pay[1].due_amount)
                          : CourseDetail.instment_pay[1].due_amount
                      )
                    }
                  >
                    <a href="javascript:void(0)">
                      Save and Continue to Payment Gateway
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </form>
        ))}
      {/* loader */}
      {isPaymentLoading && (
        <Dialog open={isPaymentLoading}>
          <DialogContent>
            <div style={{ margin: "15px" }}>
              {/* <div className="spinner-border text-danger"></div>{" "} */}
              <p> We are processing your payment. Please wait...</p>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
export default PayFee;
