import React, { useState, useRef, useEffect } from "react";
import html2pdf from "html2pdf.js";
import $ from "jquery";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Chip from "@mui/material/Chip";
import { CloseOutlined, Scale } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { AxiosInstance, ReactBaseUrl } from "../../../../axios";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import { styled } from "@mui/material/styles";
// import { toWords } from "number-to-words";
import DatePickerSample from "../../DatePicker";
import { ToWords } from "to-words";
import Loader from "../../Loader";
import InfoIcon from "@mui/icons-material/Info";
function PaymentHistory(props) {
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const moment = require("moment-timezone");
  // let numberToWords = require('number-to-words');
  const [listData, setListData] = useState([]);
  const [base64Imagess, setBase64Imagess] = useState("");
  const [base64ImagessTrue, setBase64ImagessTrue] = useState(false);
  // List data load table
  let page = R_PER_PAGE;

  const { type, adm_id } = useParams();

  //  const imageToBase64 = require('image-to-base64');

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("UserId Not available");
  }

  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState([]);
  const [updateListData, setUpdateListData] = useState(listData);
  const [isOpen, setIsOpen] = useState({
    startDate: false,
    classMode: false,
  });
  const [appData, setAppData] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });

  const [updateChipData, setUpdateChipData] = useState([]);
  const [appOn, setAppOn] = useState([]);

  const [classModes, setClassModes] = useState([]);

  let filterClassMode = [];

  let filterApplyed = [];
  let filterSearch = [];

  const [htmlContent, setHtmlContent] = useState("");
  const contentRef = useRef();

  // useEffect(() => {
  //   loadChipdata();
  // }, []);

  useEffect(() => {
    filterClassMode = classModes;
  }, [classModes]);

  useEffect(() => {
    filterApplyed = appOn;
  }, [appData]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }, [searchTerm]);

  useEffect(() => {
    loadChipdata();
  }, []);

  useEffect(() => {
    loadListdata();
  }, [updateChipData]);

  function updateStateData() {
    filterApplyed = appOn;

    filterClassMode = classModes;

    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }
  const convertUrlsToBase64InHtml = async (html) => {
    const imgUrlRegex = /<img[^>]+src="([^">]+)"/g;
    let match;
    let modifiedHtml = html;

    while ((match = imgUrlRegex.exec(html)) !== null) {
      const url = match[1];
      try {
        const base64 = await convertImageUrlToBase64(url);
        modifiedHtml = modifiedHtml.replace(url, base64);
      } catch (err) {
        // setError(`Failed to convert image URL to Base64: ${url}`);
        console.log("err", url);
      }
    }
    const bgUrlRegex = /background-image:\s*url\(['"]?([^'"\)]+)['"]?\)/g;
    // let match;
    // let modifiedHtml = html;

    while ((match = bgUrlRegex.exec(html)) !== null) {
      const url = match[1];
      try {
        const base64 = await convertImageUrlToBase64(url);
        modifiedHtml = modifiedHtml.replace(url, base64);
      } catch (err) {
        // setError(`Failed to convert image URL to Base64: ${url}`);
        console.log("err", url);
      }
    }

    return modifiedHtml;
  };
  // Function to convert a single image URL to Base64
  const convertImageUrlToBase64 = (url) => {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result); // Base64 string
          };
          reader.onerror = () => {
            reject("Error reading blob as Base64");
          };
          reader.readAsDataURL(blob);
        })
        .catch((err) => {
          reject("err");
        });
    });
  };
  const imageUrlToBase64 = (url) => {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = reader.result.split(",")[1];
            resolve(base64String);
          };
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(blob);
        })
        .catch((error) => reject(error));
    });
  };

  const handleImageConversion = (imageUrl) => {
    imageUrlToBase64(imageUrl)
      .then((base64String) => {
        setBase64Imagess(base64String);
        setBase64ImagessTrue(true); // Set the flag to indicate image conversion is completed
      })
      .catch((error) => {
        console.error("Error converting image URL to base64:", error);
      });
  };

  //sorting function

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  const handleCheckboxChange = (event, names, typename) => {
    const { value, checked, name } = event.target;
    if (name === "Cash" || name === "Online") {
      if (checked) {
        setClassModes([
          ...classModes,
          { id: value, name: name, type: "classMode" },
        ]);
      } else {
        setClassModes(classModes.filter((item) => item.id !== value));
      }
    }
  };

  // handle search function
  const handleSearch = (event) => {
    // console.log(event);
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      updateStateData();
      setSearchTerm(val);
      if (val.length > 0) {
        filterSearch = [{ name: "" + val, type: "search" }];
      } else {
        filterSearch = [];
      }
      loadChipdata();
    }
  };
  //list design for chips
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  //chips overall clear button
  const handleAllClear = () => {
    setSearchTerm("");

    setClassModes([]);
    setUpdateChipData([]);
    setAppOn([]);

    setAppData({
      FDate: "",
      TDate: "",
      Days: "",
    });
    setUpdateListData(listData);
  };

  // filter apply button

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "appliedOn") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "classMode") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        classMode: false,
      });
    }
  };
  //load data to table
  function loadListdata() {
    let tempclassMode = [];

    classModes.map((obj) => {
      tempclassMode.push(obj.id);
    });

    let statusFilter = {
      // class_mode: classModes.length === 1 ? parseInt(tempclassMode[0]) : "",
      payment_type: classModes.length === 1 ? parseInt(tempclassMode[0]) : "",
      start_date: appOn.length > 0 ? appOn[0].fdate + " 00:00:00" : "",
      end_date: appOn.length > 0 ? appOn[0].edate + " 23:59:00" : "",
      search: searchTerm.length > 0 ? searchTerm : "",
      user_id: userid,
      admission_id: adm_id,
    };
    setIsLoading(true);
    AxiosInstance.post("api/purchase/payment/list", statusFilter)
      .then((res) => {
        // console.log("editadasd ", res);
        if (res.data.status === true) {
          setIsLoading(false);
          const fiata = res.data.data;
          setUpdateListData(fiata);
          handleImageConversion(
            `${ImageUrlPrefix}/${res.data.data[0].profile_path}`
          );
        } else if (res.data.status === false) {
          setIsLoading(false);
          setUpdateListData([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setUpdateListData([]);
        return error;
      });
  }
  //load data to chips
  function loadChipdata() {
    //setUpdateChipData([]);
    let tempArr = [];
    // setUpdateChipData(status);
    filterSearch.map((obj) => {
      tempArr.push(obj);
    });

    filterApplyed.map((obj) => {
      tempArr.push(obj);
    });

    filterClassMode.map((obj) => {
      tempArr.push(obj);
    });

    // console.log(tempArr, "tempArr");
    setUpdateChipData(tempArr);
  }

  // const handleConvertToBase64 = async (imageUrl) => {
  //   alert(imageUrl);
  //   try {
  //     const response = await fetch(
  //       'https://codebeautify.org/image-to-base64-converter',
  //       {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/x-www-form-urlencoded',
  //         },
  //         body: `imageurl=${encodeURIComponent(imageUrl)}`,
  //       }
  //     );
  //     console.log(response.ok);
  //     if (response.ok) {
  //       console.log(response,"response")

  //       const result = await response.json();
  //       if (result.base64) {
  //         return result.base64
  //       }
  //     } else {
  //       console.error('Failed to convert the image to Base64.');
  //     }
  //   } catch (error) {
  //     console.error('An error occurred while converting the image to Base64:', error);
  //   }
  // };

  //date filter box apply function
  const handleAppliedOn = (e) => {
    const { name, value, checked } = e.target;
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: value }));
      const from = name === "FDate" ? value : appData.FDate;
      const to = name === "TDate" ? value : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    } else if (name === "Days") {
      if (checked) {
        setAppData((prevState) => ({ ...prevState, [name]: value }));
      }
      // else {
      //   setAppOn(appOn.filter((item) => item !== value));
      // }
      if (value === "Today") {
        const today = new Date();
        setAppOn([
          {
            name: value,
            fdate: moment(today.toDateString()).format("YYYY-MM-DD"),
            edate: moment(today.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Yesterday") {
        const today = new Date(); // Get today's date
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setAppOn([
          {
            name: value,
            fdate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            edate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tweek") {
        const today = new Date(); // Get today's date
        const thisWeekStartDate = moment(today)
          .subtract(0, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const thisWeekEndDate = moment(today)
          .subtract(0, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "This week",
            fdate: moment(thisWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lweek") {
        const today = new Date(); // Get today's date
        const lastWeekStartDate = moment(today)
          .subtract(1, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const lastWeekEndDate = moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "Last week",
            fdate: moment(lastWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tmonth") {
        const today = new Date(); // Get today's date
        const thisMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ); // Get start date of this month
        const thisMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setAppOn([
          {
            name: "This month",
            fdate: moment(thisMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lmonth") {
        const today = new Date();
        const lastMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        setAppOn([
          {
            name: "Last month",
            fdate: moment(lastMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      }
    }
  };
  // filter clear button
  const handleClear = (name) => {
    updateStateData();
    if (name === "appliedOn") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "classMode") {
      setClassModes([]);
      filterClassMode = [];

      setIsOpen({
        ...isOpen,
        classMode: false,
      });
    }
    loadChipdata();
  };

  // //   checking once again  -  handle delete function
  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
    } else if (chipToDelete.type === "dayRange") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
    } else if (chipToDelete.type === "classMode") {
      setClassModes(classModes.filter((item) => item.id !== chipToDelete.id));
      filterClassMode = classModes.filter(
        (item) => item.id !== chipToDelete.id
      );
    } else {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
    }
    loadChipdata();
  };

  const handleChangeDate = (date, name) => {
    const dateFilter = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateFilter);
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: dateFilter }));
      const from = name === "FDate" ? dateFilter : appData.FDate;
      const to = name === "TDate" ? dateFilter : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    }
  };

  //  filter box tootle function

  const handleFilter = (e, boxName) => {
    if (boxName === "startDate") {
      setIsOpen((prevState) => ({
        classMode: false,
        startDate: !prevState.startDate,
      }));
    } else if (boxName === "classMode") {
      setIsOpen((prevState) => ({
        startDate: false,
        classMode: !prevState.classMode,
      }));
    }
  };

  // generate pdf when user click download button
  const generatePDF = () => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Add a title to the PDF document
    doc.text("Table Data", 10, 10);

    // Convert the table to a JavaScript array
    const tableData = [];

    // Add header row to tableData array
    const headers = [];
    const tableHeader = document.querySelectorAll("#example th");
    for (const header of tableHeader) {
      headers.push(header.innerText);
    }
    tableData.push(headers);

    // Add data rows to tableData array
    const tableRows = document.querySelectorAll("#example tbody tr");
    for (const row of tableRows) {
      const rowData = [];
      const rowCells = row.querySelectorAll("td");
      for (const cell of rowCells) {
        rowData.push(cell.innerText);
      }
      tableData.push(rowData);
    }

    // Add the table to the PDF document
    doc.autoTable({
      head: [tableData[0]], // Use the first row as the table header
      body: tableData.slice(1), // Use the remaining rows as the table body
    });
    // Save the PDF document
    doc.save("AdmissionData.pdf");
  };

  // total table data  with pages
  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;
    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      setUpdateListData([]);
    }
    loadListdata();
  };
  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = updateListData.slice(start, end);

  //download Invoice hand le event
  const handleDownload = (trans_id) => {
    const data = {
      trans_id: trans_id,
      type: parseInt(type),
    };
    setIsLoading(true);
    AxiosInstance.post("api/purchase/payment/download", data)
      .then(async (res) => {
        const path = res.data.data;
        if (path) {
          const modifiedHtml = await convertUrlsToBase64InHtml(path);
          setHtmlContent(modifiedHtml);
          // console.log("Something Went Wrong modifiedHtml ", modifiedHtml);
          // After setting the HTML content, generate the PDF
          setTimeout(() => {
            setIsLoading(false);
            const content = contentRef.current;
            const options = {
              margin: [1, 1, 0, 1],
              filename: "PaymentReceipt.pdf",
              image: { type: "jpeg", quality: 0.7 },
              html2canvas: { width: 2480, height: 3508, scale: 1, dpi: 300 },
              jsPDF: { unit: "pt", format: "A4", orientation: "portrait" },
            };

            html2pdf().from(content.innerHTML).set(options).save();
          }, 2000);
        } else {
          setIsLoading(false);
          console.log("Something Went Wrong");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        return error;
      });
  };

  // const  convertImageToBase64 = (imageUrl)  => {
  //   // Create a canvas element to draw the image
  //   const canvas = document.createElement('canvas');
  //   const ctx = canvas.getContext('2d');

  //   // Create an Image object
  //   const img = new Image();

  //   // Set up an event listener to handle the image load
  //   img.onload = function () {
  //       // Set the canvas dimensions to match the image
  //       canvas.width = img.width;
  //       canvas.height = img.height;

  //       // Draw the image onto the canvas
  //       ctx.drawImage(img, 0, 0);

  //       // Get the base64-encoded data URI from the canvas
  //       const base64 = canvas.toDataURL('image/png'); // You can change 'image/png' to 'image/jpeg' for JPEG format

  //       // Use the base64 data URI as needed (e.g., save it, display it, etc.)
  //       console.log(base64);
  //   };

  //   // Set the image source to the URL
  //   img.src = imageUrl;
  //  }

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  // className="row tab-content mt-4"
  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {" "}
      <div
        ref={contentRef}
        id="html-content"
        style={{ display: "none" }}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      ></div>
      {isLoading && <Loader />}
    </div>
  ) : (
    <div className=" BathDet ">
      <div
        ref={contentRef}
        id="html-content"
        style={{ display: "none" }}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      ></div>
      <div className="row sectionSix">
        <div className="heading dflexbetww flex_rowcolum">
          <div className="dbetw">
            <h4 className="Title">View Payment History</h4>
            <Link
              to="javascript:void(0)"
              onClick={navigateToPreviousScreen}
              className="CancelButton MobileShow mt-0"
            >
              Back to view Admission
            </Link>
          </div>
          <div className="d-flex smButton mt-lg-2 mt-md-2 mobilddown">
            <Link
              to="javascript:void(0)"
              onClick={navigateToPreviousScreen}
              className="CancelButton MobileHide"
            >
              Back to view Admission
            </Link>
            <button type="button" className="MakeBtn ">
              <span class="material-icons me-2">send</span>
              <Link className="" to="javascript:void(0)">
                Pay Now
              </Link>
            </button>
          </div>
        </div>
      </div>
      <div className="row tab-content mt-4" id="ex2-content">
        <div className="tab-pane fade show active col-md-12">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div class="input-group">
                <input
                  type="text"
                  class="Inputs"
                  id="SearchInput"
                  name="SearchInput"
                  placeholder="Search by ID"
                  // onChange={handleSearch}
                  onKeyDown={handleSearch}
                />
                <span class="input-group-text" id="SearchInput">
                  <span className="material-icons">search</span>
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4"></div>
            <div className="col-lg-6  d-flex justify-content-evenly col-md-6"></div>
          </div>
          {updateChipData.length > 0 ? (
            <div className="row mt-3">
              <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
                <Paper
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    listStyle: "none",
                    p: 0.5,
                    m: 0,
                  }}
                  component="ul"
                >
                  {updateChipData.map((data, index) => {
                    // console.log(data, " - data - ", index);
                    let icon;
                    let key = "" + data.name;
                    return (
                      <ListItem key={data}>
                        <Chip
                          icon={icon}
                          label={key}
                          onDelete={handleDelete(data, index)}
                        />
                      </ListItem>
                    );
                  })}

                  <ListItem>
                    {/* <Chip
                      label=" Clear All Filters"
                      onClick={handleAllClear}
                    /> */}
                    <a className="allclear" href="#" onClick={handleAllClear}>
                      Clear All Filters
                    </a>
                  </ListItem>
                </Paper>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="row mt-3 ms-1 me-1">
            <div className="col-md-12 table-responsive p-0">
              <table className="table" id="example">
                <thead>
                  <tr>
                    <th id="th" style={{ width: "5%" }}>
                      <span className="text_th">S.No</span>
                    </th>
                    <th id="th">
                      <span
                        className="text_th"
                        onClick={(e) => handleSort(e, "trans_id")}
                      >
                        Transaction ID
                      </span>

                      <span
                        class="material-icons align-middle"
                        onClick={(e) => handleSort(e, "trans_id")}
                      >
                        import_export
                      </span>
                    </th>
                    <th id="th">
                      <span
                        className="text_th"
                        onClick={(e) => handleSort(e, "paid_amount")}
                      >
                        {" "}
                        Amount Paid
                      </span>

                      <span
                        class="material-icons align-middle"
                        onClick={(e) => handleSort(e, "paid_amount")}
                      >
                        import_export
                      </span>
                    </th>
                    <th id="th">
                      <span
                        className="text_th"
                        onClick={(e) => handleSort(e, "started_dt")}
                      >
                        Date
                      </span>
                      <span
                        class="material-icons align-middle"
                        onClick={(e) => handleSort(e, "started_dt")}
                      >
                        import_export
                      </span>{" "}
                      <span
                        onClick={(e) => handleFilter(e, "startDate")}
                        class={` align-middle material-icons  ${
                          appOn.length > 0 ? "active_icons" : ""
                        }`}
                      >
                        filter_list
                      </span>
                      {isOpen.startDate === true && (
                        <div className="filterBoxOne container p-1">
                          <div className="row mt-1">
                            <div className="col-md-12">
                              <div class="form-check p-1">
                                <label
                                  htmlFor="FDate"
                                  className="d-flex form-label"
                                >
                                  From Date
                                  <span
                                    data-required="true"
                                    aria-hidden="true"
                                  ></span>
                                </label>
                                {/* <input
                                  id="FDate"
                                  type="date"
                                  name="FDate"
                                  value={appData.FDate}
                                  className="FilterInputs"
                                  onChange={handleAppliedOn}
                                  placeholder="FDate"
                                  autoComplete="FDate"
                                  required
                                /> */}
                                <DatePickerSample
                                  IconInput={"filterIocnBoxInput"}
                                  class={"FilterInputs"}
                                  name={"FDate"}
                                  handleChange={handleChangeDate}
                                  fieldInput={appData.FDate}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-md-12">
                              <div class="form-check p-1">
                                <label
                                  htmlFor="TDate "
                                  className="d-flex form-label"
                                >
                                  To Date
                                  <span
                                    data-required="true"
                                    aria-hidden="true"
                                  ></span>
                                </label>
                                {/* <input
                                  id="TDate"
                                  type="date"
                                  name="TDate"
                                  value={appData.TDate}
                                  className="FilterInputs"
                                  placeholder="TDate"
                                  onChange={handleAppliedOn}
                                  autoComplete="TDate"
                                  required
                                /> */}
                                <DatePickerSample
                                  class={"FilterInputs"}
                                  IconInput={"filterIocnBoxInput"}
                                  name={"TDate"}
                                  handleChange={handleChangeDate}
                                  fieldInput={appData.TDate}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="Days"
                                  id="Today"
                                  value="Today"
                                  checked={appData.Days === "Today"}
                                  onChange={handleAppliedOn}
                                />
                                <span
                                  class="form-check-label d-flex"
                                  for="Today"
                                >
                                  Today
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="Days"
                                  value="Yesterday"
                                  checked={appData.Days === "Yesterday"}
                                  id="Yesterday"
                                  onChange={handleAppliedOn}
                                />
                                <span
                                  class="form-check-label d-flex"
                                  for="Yesterday"
                                >
                                  Yesterday
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="Days"
                                  value="Tweek"
                                  checked={appData.Days === "Tweek"}
                                  id="Tweek"
                                  onChange={handleAppliedOn}
                                />
                                <span
                                  class="form-check-label d-flex"
                                  for="Tweek"
                                >
                                  This week
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="Days"
                                  value="Lweek"
                                  checked={appData.Days === "Lweek"}
                                  id="Lweek"
                                  onChange={handleAppliedOn}
                                />
                                <span
                                  class="form-check-label d-flex"
                                  for="Lweek"
                                >
                                  Last week
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="Days"
                                  value="Tmonth"
                                  checked={appData.Days === "Tmonth"}
                                  id="Tmonth"
                                  onChange={handleAppliedOn}
                                />
                                <span
                                  class="form-check-label d-flex"
                                  for="Tmonth"
                                >
                                  This month
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="Days"
                                  checked={appData.Days === "Lmonth"}
                                  value="Lmonth"
                                  id="Lmonth"
                                  onChange={handleAppliedOn}
                                />
                                <span
                                  class="form-check-label d-flex"
                                  for="Lmonth"
                                >
                                  Last month
                                </span>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="row mt-3">
                            <div className="col-md-4  col-4 col-sm-4">
                              <span
                                className="clearFilter ms-2 "
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClear("appliedOn")}
                              >
                                Clear
                              </span>
                            </div>
                            <div className="col-md-8  col-8 col-sm-8">
                              <button
                                className="FilterButton"
                                onClick={() => handleApplyFilter("appliedOn")}
                              >
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </th>
                    <th id="th">
                      <span
                        className="text_th"
                        onClick={(e) => handleSort(e, "payment_type")}
                      >
                        Purchase Mode
                      </span>

                      <span
                        class="material-icons align-middle"
                        onClick={(e) => handleSort(e, "payment_type")}
                      >
                        import_export
                      </span>
                      <span
                        class={` align-middle material-icons  ${
                          classModes.length > 0 ? "active_icons" : ""
                        }`}
                        onClick={(e) => handleFilter(e, "classMode")}
                      >
                        filter_list
                      </span>
                      {isOpen.classMode === true && (
                        <div className="filterBoxs p-1">
                          <div className="row mt-4">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name="Online"
                                  value="1"
                                  id="Online"
                                  checked={classModes.some((element) => {
                                    if (element.name === "Online") {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "Online")
                                  }
                                />
                                <label
                                  class="form-check-label "
                                  for="defaultCheck1"
                                >
                                  Online
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name="Cash"
                                  value="0"
                                  id="Cash"
                                  checked={classModes.some((element) => {
                                    if (element.name === "Cash") {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "Cash")
                                  }
                                />
                                <label
                                  class="form-check-label "
                                  for="defaultCheck1"
                                >
                                  Cash
                                </label>
                              </div>
                            </div>
                          </div>

                          <hr />
                          <div className="row mt-3">
                            <div className="col-md-4  col-4 col-sm-4">
                              <span
                                className="clearFilter ms-2 "
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClear("classMode")}
                              >
                                Clear
                              </span>
                            </div>
                            <div className="col-md-8  col-8 col-sm-8">
                              <button
                                className="FilterButton"
                                onClick={() => handleApplyFilter("classMode")}
                              >
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </th>
                    <th id="th">
                      <span
                        className="text_th"
                        onClick={(e) => handleSort(e, "balance_amt")}
                      >
                        {" "}
                        Balance Fee
                      </span>
                      <span
                        class="material-icons align-middle"
                        onClick={(e) => handleSort(e, "balance_amt")}
                      >
                        import_export
                      </span>{" "}
                    </th>
                    <th id="th">
                      {" "}
                      <span className="text_th">Action</span>{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.length === 0 ? (
                    <tr>
                      <td colspan="10" style={{ textAlign: "center" }}>
                        No record to display
                      </td>
                    </tr>
                  ) : (
                    currentData.map((course, index) => (
                      <tr key={index} title={course.trans_id}>
                        <td id="td">{index + 1}</td>
                        <td id="td">{course.trans_id}</td>
                        <td id="td">{course.paid_amount}</td>
                        <td id="td">
                          {moment(course.applied_on).format("YYYY-MM-DD")}
                        </td>
                        <td id="td">
                          {course.payment_type === 0 ? "Cash" : "Online"}
                        </td>
                        <td id="td">
                          {" "}
                          {course.balance_amt !== null ? course.balance_amt : 0}
                        </td>

                        <td id="td">
                          <button
                            className="button"
                            onClick={() => handleDownload(course.trans_id)}
                          >
                            <span class="material-icons">get_app</span>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-3 ms-1 me-1">
            <div className="col-md-12">
              <div className="pag w-100 dflexcenter">
                <span className="ipage">Items Per Page</span>
                <select
                  name="pageCount"
                  id="pageCount"
                  defaultValue={rowsPerPage}
                  onChange={handlePageCount}
                  className="ms-2"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <span className="pagenumber ms-2">
                  {" "}
                  {updateListData.length > 0
                    ? currentPage * rowsPerPage + 1 - rowsPerPage
                    : 0}{" "}
                  -
                  {updateListData.length < currentPage * rowsPerPage
                    ? updateListData.length
                    : currentPage * rowsPerPage}{" "}
                  of {updateListData.length}
                </span>
                <button
                  className="prev_button ms-2 me-2"
                  onClick={(e) => handlePrev(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <span class="material-icons">chevron_left</span>
                </button>
                <button
                  className="prev_button"
                  onClick={() => handleNext(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <span class="material-icons">chevron_right</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentHistory;
