import React from "react";
import "./Pagination.css";
function Pagination(props) {
  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="offset-md-7 col-md-5 ">
          <div className="supPageListDrop w-100 paginatonDiv">
            <span className="itemPage">Items Per Page</span>
            <select
              id="modelExamList"
              name="modelExamList"
              className="pagecountDropdown"
              defaultValue={props.rowsPerPage}
              onChange={props.handlePageCount}
              required
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span className="pageNumber">
              {props.startPage}-{props.endPage}
              {"  "}
              of {props.totalRecords}
            </span>

            <button
              className={props.prevButton}
              onClick={props.handlePrev}
              disabled={props.currentPrevPage}
            >
              <span class="material-iconsRightAndLeft">chevron_left</span>
            </button>
            <button
              className={props.prevButton}
              onClick={props.handleNext}
              disabled={props.currentNextPage}
            >
              <span class="material-iconsRightAndLeft">chevron_right</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
