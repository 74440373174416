import React from "react";

export default function ReviewAnswerAll(props) {
  return (
    <div className="row Question_temp">
      {(props.list.lang_type === 1 || props.list.lang_type === 2) && (
        <>
          <div className="col-md-12 mt-3">
            <h6 className="user_answer_en">Your Answer</h6>
            <span className="user_label_answer">
              {props.type != "1"
                ? props.list.user_answer !== "null" &&
                  props.list.user_answer !== null
                  ? props.list.user_answer
                  : "_"
                : props.list.answer_en}
            </span>
          </div>
          <div className="col-md-12 mt-3">
            <h6 className="user_answer_en">Correct Answer</h6>
            <span className="user_label_answer">
              {props.type != "1"
                ? props.list.answer_en
                  ? props.list.answer_en
                  : "_"
                : props.list.answer_en}
            </span>
          </div>
        </>
      )}

      {(props.list.lang_type === 0 || props.list.lang_type === 2) && (
        <>
          <div className="col-md-12 mt-3">
            <h6 className="user_answer">உங்கள் விடை</h6>
            <span className="user_label_answer">
              {props.type != "1"
                ? props.list.user_answer !== "null" &&
                  props.list.user_answer !== null
                  ? props.list.user_answer
                  : "_"
                : props.list.answer_en}
            </span>
          </div>{" "}
          <div className="col-md-12 mt-3">
            <h6 className="user_answer">சரியான விடை</h6>
            <span className="user_label_answer">
              {props.type != "1"
                ? props.list.answer_en
                  ? props.list.answer_en
                  : "_"
                : props.list.answer_tm}
            </span>
          </div>
        </>
      )}
    </div>
  );
}
