import React from "react";

export default function ExplanationQuery(props) {
  // console.log(props.lang_type);
  return (
    <div className="row box explanationQ mt-1 ">
      <div className="col-md-12">
        <p className="StepTitle">Explanation</p>
        <div className={props.margin_yes === 1 ? `row` : `row`}>
          <div className="col-md-12 p-0">
            {(props.lang_type === 1 || props.lang_type === 2) && (
              <p className="explain_ques_english">
                {props.en_explan ? props.en_explan : "_"}
              </p>
            )}

            {(props.lang_type === 0 || props.lang_type === 2) && (
              <p className="explain_ques_tamil">
                {props.tm_expla ? props.tm_expla : "_"}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
