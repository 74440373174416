import React, { useState, useRef, useEffect } from "react";

import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Chip from "@mui/material/Chip";
import { CloseOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import { styled } from "@mui/material/styles";
import DatePickerSample from "../../DatePicker";
import { IconButton } from "@mui/material";
import ICON_VIEW from "@mui/icons-material/Visibility";
import ICON_DOWNLOAD from "@mui/icons-material/Download";
import Swal from "sweetalert2";

const ICON_SIZE = 18;

function ModelExamCenter() {
  const moment = require("moment-timezone");
  const [listData, setListData] = useState([]);

  // List data load table

  let page = R_PER_PAGE;

  const userJSON = sessionStorage.getItem("userDetails");
  let user_type = null;
  let staff_id = null;
  let userid = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
      staff_id = user.id;
    }
    if (user && user.user_type) {
      user_type = user.user_type;
    }
  } else {
    console.log("user not available in session storage");
  }

  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [dCourse, setDcourse] = useState([]);
  const [dCourses, setDcourses] = useState([]);
  const [ssubject, setSsubject] = useState([]);
  const [ssubjects, setSsubjects] = useState(ssubject);
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState([]);
  const [updateListData, setUpdateListData] = useState(listData);
  const [csvListData, setCsvListData] = useState([]);
  const [isOpen, setIsOpen] = useState({
    exam: false, //New code
    batch: false, //New code
    medium: false, //New code
    center: false, //New code
    startedDate: false, //New code
    stime: false, //New code
    etime: false, //New code
    status: false, //New code
  });
  const [appData, setAppData] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });
  const [appDatas, setAppDatas] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });
  const [timedata, setTimedate] = useState({
    STime: "",
    TTime: "",
    name: "",
  });

  const [updateChipData, setUpdateChipData] = useState([]);

  const [status, setStatus] = useState([]);

  const [location, setLocation] = useState([]);
  const [appOn, setAppOn] = useState([]);
  const [appOns, setAppOns] = useState([]);
  const [typeOn, setTypeOn] = useState([]);
  const [time, setTime] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [classModes, setClassModes] = useState([]);

  const [exams, setExam] = useState([]); //New Code
  const [batchs, setBatch] = useState([]); //New Code
  const [mediums, setMedium] = useState([]); //New Code
  const [centers, setCenter] = useState([]); //New Code
  const [startedDts, setStartedDt] = useState([]); //New Code
  const [stime, setStime] = useState([]); //New Code
  const [etime, setEtime] = useState([]); //New Code
  // ----------------------------
  const [showModelExamCenter, setShowModelExamCenter] = useState(false);

  // ----------------------------------
  let filterType = [];
  let filterTime = [];
  let filterSchedule = [];
  let filterClassMode = [];
  let filterLocation = [];
  let filterApplyed = [];
  let filterApplyeds = [];
  let filterSearch = [];

  let filterStatus = []; // New Code
  let filterExam = []; // New Code
  let filterBatch = []; // New Code
  let filterMedium = []; // New Code
  let filterCenter = []; // New Code
  let filterStartedDt = []; // New Code
  let filterStime = []; // New Code
  let filterEtime = []; // New Code

  useEffect(() => {
    filterStatus = status;
  }, [status]);
  useEffect(() => {
    filterLocation = location;
  }, [location]);
  useEffect(() => {
    filterClassMode = classModes;
  }, [classModes]);
  useEffect(() => {
    filterSchedule = schedule;
  }, [schedule]);

  useEffect(() => {
    filterMedium = mediums;
  }, [mediums]);
  useEffect(() => {
    filterBatch = batchs;
  }, [batchs]); //New code
  useEffect(() => {
    filterExam = exams;
  }, [exams]); //New code
  useEffect(() => {
    filterType = typeOn;
  }, [typeOn]);

  useEffect(() => {
    filterApplyed = appOn;
  }, [appData]);

  useEffect(() => {
    filterApplyeds = appOns;
  }, [appDatas]);

  useEffect(() => {
    filterTime = time;
  }, [time]);
  useEffect(() => {
    filterCenter = centers;
  }, [centers]);
  useEffect(() => {
    filterStartedDt = startedDts;
  }, [startedDts]);
  useEffect(() => {
    filterStime = stime;
  }, [stime]);
  useEffect(() => {
    filterEtime = etime;
  }, [etime]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }, [searchTerm]);

  useEffect(() => {
    loadChipdata();
  }, []);

  useEffect(() => {
    loadListdata();
  }, [updateChipData]);

  // useEffect(() => {
  //   console.log(" updateListData : ", updateListData);
  //   currentData = updateListData.slice(start, end);
  //   // total table data  with pages
  //   totalPages = Math.ceil(updateListData.length / rowsPerPage);
  // }, [updateListData]);

  function updateStateData() {
    filterStatus = status;
    filterTime = time;
    filterExam = exams; // New Code
    filterBatch = batchs; // New Code
    filterMedium = mediums; // New Code
    filterCenter = centers; // New Code
    filterStartedDt = startedDts; // New Code
    filterStime = stime; // New Code
    filterEtime = etime; // New Code

    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }

  //sorting function

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  // filter checkbox update

  const handleCheckboxChange = (event, names, typename) => {
    // console.log(names, " : ", names);
    const { value, checked, name } = event.target;
    if (name === "Upcoming" || name === "Completed" || name === "Ongoing") {
      if (checked) {
        setStatus([...status, { id: value, name: name, type: "status" }]);
      } else {
        setStatus(status.filter((item) => item.id !== value));
      }
    } else if (names === "location") {
      if (checked) {
        // setStatus([...status, value]);
        setLocation([...location, { id: value, name: name, type: "location" }]);
      } else {
        setLocation(location.filter((item) => item.id !== value));
      }
    } else if (typename === "type") {
      if (checked) {
        setTypeOn([...typeOn, { name: value, type: "type" }]);
      } else {
        setTypeOn(typeOn.filter((item) => item.name !== value));
      }
    } else if (name === "Weekend" || name === "Regular") {
      if (checked) {
        setSchedule([...schedule, { id: value, name: name, type: "schedule" }]);
      } else {
        setSchedule(schedule.filter((item) => item.id !== value));
      }
    } else if (name === "Tamil" || name === "English") {
      if (checked) {
        setMedium([...mediums, { id: value, name: name, type: "medium" }]);
      } else {
        setMedium(mediums.filter((item) => item.id !== value));
      }
    } else if (name === "Classroom" || name === "Online") {
      if (checked) {
        setClassModes([
          ...classModes,
          { id: value, name: name, type: "classMode" },
        ]);
      } else {
        setClassModes(classModes.filter((item) => item.id !== value));
      }
    }
  };

  const handleCheckboxChanges = (event, names, typename) => {
    // console.log(names, " : ", names);
    const { value, checked, name } = event.target;
    if (name === "English" || name === "Tamil") {
      if (checked) {
        setMedium([...mediums, { id: value, name: name, type: "medium" }]);
      } else {
        setMedium(mediums.filter((item) => item.id !== value));
      }
    }
    // Batch New Code
    else if (
      name === "Lion Batch" ||
      name === "Tiger Batch" ||
      name === "Police Batch"
    ) {
      if (checked) {
        setBatch([...batchs, { id: value, name: name, type: "batch" }]);
      } else {
        setBatch(batchs.filter((item) => item.id !== value));
      }
    }
    // Batch New Code
    else if (name === "Arunodhaya" || name === "Mary" || name === "Guru") {
      if (checked) {
        setExam([...exams, { id: value, name: name, type: "exam" }]);
      } else {
        setExam(exams.filter((item) => item.id !== value));
      }
    }
  };
  // handle search function
  const handleSearch = (event) => {
    // console.log(event);
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      updateStateData();
      setSearchTerm(val);
      if (val.length > 0) {
        filterSearch = [{ name: "" + val, type: "search" }];
      } else {
        filterSearch = [];
      }
      loadChipdata();
    }
  };

  const handleFilterCourseSearch = (event) => {
    const searchInput = event.target.value;

    if (searchInput != "") {
      const matchingObjects = dCourse.filter((item) =>
        item.toLowerCase().includes(searchInput.toLowerCase())
      );
      setDcourses(matchingObjects);
    } else {
      setDcourses(dCourse);
    }
  };

  const handleFilterSearch = (event) => {
    const searchInput = event.target.value;

    if (searchInput != "") {
      const matchingObjects = ssubject.filter((item) =>
        item.toLowerCase().includes(searchInput.toLowerCase())
      );
      // console.log(matchingObjects, searchInput);

      setSsubjects(matchingObjects);
    } else {
      setSsubjects(ssubject);
    }
  };

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  //chips overall clear button
  const handleAllClear = () => {
    setSearchTerm("");
    setStatus([]);
    setSchedule([]);
    setTime([]);
    setLocation([]);
    setClassModes([]);
    setUpdateChipData([]);
    setAppOn([]);
    setTypeOn([]);

    setExam([]); //New Code
    setBatch([]); //New Code
    setMedium([]); //New Code
    setCenter([]); //New Code
    setStartedDt([]); //New Code
    setStime([]); //New Code
    setEtime([]); //New Code
    setAppData({
      FDate: "",
      TDate: "",
      Days: "",
    });
    setAppDatas({
      FDate: "",
      TDate: "",
      Days: "",
    });
    setUpdateListData(listData);
  };

  // filter apply button

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "status") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        status: false,
      });
    } else if (name === "location") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        location: false,
      });
    } else if (name === "appliedOn") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "appliedOns") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        startDates: false,
      });
    } else if (name === "type") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        category: false,
      });
    } else if (name === "time") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        time: false,
      });
    } else if (name === "schedule") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        schedule: false,
      });
    } else if (name === "classMode") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        classMode: false,
      });
    } else if (name === "exam") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        exam: false,
      });
    } //New Code
    else if (name === "batch") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        batch: false,
      });
    } //New Code
    else if (name === "medium") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        medium: false,
      });
    } //New Code
    else if (name === "center") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        center: false,
      });
    } //New Code
    else if (name === "starteddt") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        starteddt: false,
      });
    } //New Code
    else if (name === "stime") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        stime: false,
      });
    } //New Code
    else if (name === "etime") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        etime: false,
      });
    }
  };

  function loadListdata() {
    let tempstatus = [];
    let temptypeon = [];
    let tempsche = [];
    let tempclassMode = [];
    let tempLocanum = [];
    let templocation = [];
    let tempMedium = [];
    let tempmed = [];
    let tempBatch = []; //New code
    let tempbat = []; //New code
    let tempExam = []; //New code
    let tempexamination = []; //New code
    let tempenrollanum = [];
    let tempbookanum = [];

    status.map((obj) => {
      tempstatus.push(obj.id);
    });

    location.map((obj) => {
      templocation.push(obj.id);
    });

    classModes.map((obj) => {
      tempclassMode.push(obj.name);
    });

    mediums.map((obj) => {
      tempMedium.push(obj.name);
    });
    batchs.map((obj) => {
      tempBatch.push(obj.id);
    }); //New Code
    exams.map((obj) => {
      tempExam.push(obj.id);
    }); //New Code

    schedule.map((obj) => {
      tempsche.push(obj.name);
    });

    typeOn.map((obj) => {
      temptypeon.push(obj.name);
    });

    tempLocanum = templocation.map(Number);
    // tempmed = tempMedium.map(Number); //New code
    tempbat = tempBatch.map(Number); //New code
    tempexamination = tempExam.map(Number); //New code
    tempbookanum = tempstatus.map(Number);

    let statusFilter = {
      center_staff_user_id: staff_id,
      batch_name: [],
      medium:
        mediums.length > 0
          ? tempMedium.length === 1
            ? tempMedium[0]
            : ""
          : "",
      exam_center: [],
      status: status.length > 0 ? tempbookanum : [],
    };

    AxiosInstance.post("api/batch/meSupervisor/list", statusFilter)
      .then((res) => {
        if (res.data.status === true) {
          const fiata = res.data.data;
          // const updatedFiata = fiata
          //   .sort((a, b) => {
          //     return new Date(a.started_dt) - new Date(b.started_dt);
          //   })
          //   .map((item, index) => {
          //     return { ...item, serialNumber: index + 1, status: 2 };
          //   });
          const updatedFiata = fiata.map((item, index) => {
            return { ...item, serialNumber: index + 1, status: 2 };
          });
          setUpdateListData(updatedFiata);
        } else if (res.data.status === false) {
          setUpdateListData([]);
        }
      })
      .catch((error) => {
        // console.log("Error res: ", error.response.data);
        setUpdateListData([]);
        return error;
      });
  }

  function loadChipdata() {
    //setUpdateChipData([]);
    // console.log(location , filterLocation);
    let tempArr = [];
    // setUpdateChipData(status);

    filterStatus.map((obj) => {
      tempArr.push(obj);
    });
    filterTime.map((obj) => {
      tempArr.push(obj);
    });
    filterCenter.map((obj) => {
      tempArr.push(obj);
    });
    filterMedium.map((obj) => {
      tempArr.push(obj);
    });
    filterBatch.map((obj) => {
      tempArr.push(obj);
    }); //New Code
    filterExam.map((obj) => {
      tempArr.push(obj);
    }); //New Code

    // console.log(tempArr, "tempArr");
    setUpdateChipData(tempArr);
  }

  const handleTime = (e) => {
    const { name, value, checked } = e.target;
    if (name === "STime" || name === "TTime") {
      setTimedate((prevState) => ({ ...prevState, [name]: value }));
      // console.log(timedata.STime);
      // console.log(timedata.TTime);
      const from = name === "STime" ? value : timedata.STime;
      const to = name === "TTime" ? value : timedata.TTime;
      const dateRange = `${from} - ${to}`;

      setTime([
        {
          name: dateRange,
          STime: from,
          TTime: to,
          type: "time",
        },
      ]);
    }
  };

  const handleChangeDate = (date, name) => {
    const dateFilter = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateFilter);
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: dateFilter }));
      const from = name === "FDate" ? dateFilter : appData.FDate;
      const to = name === "TDate" ? dateFilter : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    }
  };

  const handleChangeDate_app = (date, name) => {
    const dateFilter = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateFilter);
    if (name === "FDate" || name === "TDate") {
      setAppDatas((prevState) => ({ ...prevState, [name]: dateFilter }));
      const from = name === "FDate" ? dateFilter : appDatas.FDate;
      const to = name === "TDate" ? dateFilter : appDatas.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOns([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRanges",
        },
      ]);
    }
  };

  const handleAppliedOn = (e) => {
    const { name, value, checked } = e.target;
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: value }));
      const from = name === "FDate" ? value : appData.FDate;
      const to = name === "TDate" ? value : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    } else if (name === "Days") {
      if (checked) {
        setAppData((prevState) => ({ ...prevState, [name]: value }));
      }
      // else {
      //   setAppOn(appOn.filter((item) => item !== value));
      // }
      if (value === "Today") {
        const today = new Date();
        setAppOn([
          {
            name: value,
            fdate: moment(today.toDateString()).format("YYYY-MM-DD"),
            edate: moment(today.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Yesterday") {
        const today = new Date(); // Get today's date
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setAppOn([
          {
            name: value,
            fdate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            edate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tweek") {
        const today = new Date(); // Get today's date
        const thisWeekStartDate = moment(today)
          .subtract(0, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const thisWeekEndDate = moment(today)
          .subtract(0, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "This week",
            fdate: moment(thisWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lweek") {
        const today = new Date(); // Get today's date
        const lastWeekStartDate = moment(today)
          .subtract(1, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const lastWeekEndDate = moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "Last week",
            fdate: moment(lastWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tmonth") {
        const today = new Date(); // Get today's date
        const thisMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ); // Get start date of this month
        const thisMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setAppOn([
          {
            name: "This month",
            fdate: moment(thisMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lmonth") {
        const today = new Date();
        const lastMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        setAppOn([
          {
            name: "Last month",
            fdate: moment(lastMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      }
    }
  };

  const handleAppliedOns = (e) => {
    const { name, value, checked } = e.target;
    if (name === "FDate" || name === "TDate") {
      setAppDatas((prevState) => ({ ...prevState, [name]: value }));
      const from = name === "FDate" ? value : appDatas.FDate;
      const to = name === "TDate" ? value : appDatas.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOns([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRanges",
        },
      ]);
    } else if (name === "Days") {
      if (checked) {
        setAppDatas((prevState) => ({ ...prevState, [name]: value }));
      }
      // else {
      //   setAppOn(appOn.filter((item) => item !== value));
      // }
      if (value === "Today") {
        const today = new Date();
        setAppOns([
          {
            name: value,
            fdate: moment(today.toDateString()).format("YYYY-MM-DD"),
            edate: moment(today.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      } else if (value === "Yesterday") {
        const today = new Date(); // Get today's date
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setAppOns([
          {
            name: value,
            fdate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            edate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      } else if (value === "Tweek") {
        const today = new Date(); // Get today's date
        const thisWeekStartDate = moment(today)
          .subtract(0, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const thisWeekEndDate = moment(today)
          .subtract(0, "week")
          .endOf("week")
          .toDate();
        setAppOns([
          {
            name: "This week",
            fdate: moment(thisWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      } else if (value === "Lweek") {
        const today = new Date(); // Get today's date
        const lastWeekStartDate = moment(today)
          .subtract(1, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const lastWeekEndDate = moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate();
        setAppOns([
          {
            name: "Last week",
            fdate: moment(lastWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      } else if (value === "Tmonth") {
        const today = new Date(); // Get today's date
        const thisMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ); // Get start date of this month
        const thisMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setAppOns([
          {
            name: "This month",
            fdate: moment(thisMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      } else if (value === "Lmonth") {
        const today = new Date();
        const lastMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        setAppOns([
          {
            name: "Last month",
            fdate: moment(lastMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      }
    }
  };
  // filter clear button
  const handleClear = (name) => {
    updateStateData();
    if (name === "appliedOn") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "appliedOns") {
      setAppDatas({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOns([]);
      filterApplyeds = [];
      setIsOpen({
        ...isOpen,
        startDates: false,
      });
    } else if (name === "status") {
      // Clear the status array
      setStatus([]);
      filterStatus = [];
      setIsOpen({
        ...isOpen,
        status: false,
      });
      // setUpdateListData(listData);
    } else if (name === "type") {
      setTypeOn([]);
      filterType = [];

      setIsOpen({
        ...isOpen,
        type: false,
      });
    } else if (name === "time") {
      setTime([]);
      filterTime = [];

      setIsOpen({
        ...isOpen,
        time: false,
      });
    } else if (name === "schedule") {
      setSchedule([]);
      filterSchedule = [];

      setIsOpen({
        ...isOpen,
        schedule: false,
      });
    } else if (name === "classMode") {
      setClassModes([]);
      filterClassMode = [];

      setIsOpen({
        ...isOpen,
        classMode: false,
      });
    } else if (name === "medium") {
      setMedium([]);
      filterMedium = [];

      setIsOpen({
        ...isOpen,
        medium: false,
      });
    } else if (name === "batch") {
      setBatch([]);
      filterBatch = [];

      setIsOpen({
        ...isOpen,
        batch: false,
      });
    } //New code
    else if (name === "exam") {
      setExam([]);
      filterExam = [];

      setIsOpen({
        ...isOpen,
        exam: false,
      });
    } //New code
    else if (name === "location") {
      // Clear the status array
      setLocation([]);
      filterLocation = [];
      setIsOpen({
        ...isOpen,
        location: false,
      });
      // setUpdateListData(listData);
    }
    loadChipdata();
  };

  // //   checking once again  -  handle delete function
  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
    } else if (chipToDelete.type === "status") {
      setStatus(status.filter((item) => item.id !== chipToDelete.id));
      filterStatus = status.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "location") {
      setLocation(location.filter((item) => item.id !== chipToDelete.id));
      filterLocation = location.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "type") {
      setTypeOn(typeOn.filter((item) => item.name !== chipToDelete.name));
      filterType = typeOn.filter((item) => item.name !== chipToDelete.name);
    } else if (chipToDelete.type === "dayRange") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
    } else if (chipToDelete.type === "dayRanges") {
      setAppDatas({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOns([]);
      filterApplyeds = [];
    } else if (chipToDelete.type === "time") {
      setTimedate({
        STime: "",
        TTime: "",
        name: "",
      });
      setTime([]);
      filterTime = [];
    } else if (chipToDelete.type === "schedule") {
      setSchedule(schedule.filter((item) => item.id !== chipToDelete.id));
      filterSchedule = schedule.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "classMode") {
      setClassModes(classModes.filter((item) => item.id !== chipToDelete.id));
      filterClassMode = classModes.filter(
        (item) => item.id !== chipToDelete.id
      );
    } else if (chipToDelete.type === "medium") {
      setMedium(mediums.filter((item) => item.id !== chipToDelete.id));
      filterMedium = mediums.filter((item) => item.id !== chipToDelete.id);
    } //New Code
    else if (chipToDelete.type === "exam") {
      setExam(exams.filter((item) => item.id !== chipToDelete.id));
      filterExam = exams.filter((item) => item.id !== chipToDelete.id);
    } //New Code
    else if (chipToDelete.type === "batch") {
      setBatch(batchs.filter((item) => item.id !== chipToDelete.id));
      filterBatch = batchs.filter((item) => item.id !== chipToDelete.id);
    } else {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
      setAppDatas({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOns([]);
      filterApplyeds = [];
    }

    loadChipdata();
  };

  const handleFilter = (e, boxName) => {
    if (boxName === "status") {
      setIsOpen((prevState) => ({
        time: false,
        startDate: false,
        schedule: false,
        category: false,
        // course:false,
        medium: false,
        batch: false, //new code
        exam: false, //new code
        classMode: false,
        location: false,
        startDates: false,
        status: !prevState.status,
      }));
    } else if (boxName === "location") {
      setIsOpen((prevState) => ({
        time: false,
        startDate: false,
        schedule: false,
        category: false,
        // course:false,
        medium: false,
        batch: false, //new code
        exam: false, //new code
        classMode: false,
        startDates: false,
        status: false,
        location: !prevState.location,
      }));
    } else if (boxName === "time") {
      setIsOpen((prevState) => ({
        startDate: false,
        schedule: false,
        category: false,
        // course:false,
        medium: false,
        batch: false, //new code
        exam: false, //new code
        classMode: false,
        startDates: false,
        status: false,
        location: false,
        time: !prevState.time,
      }));
    } else if (boxName === "startDate") {
      setIsOpen((prevState) => ({
        schedule: false,
        category: false,
        // course:false,
        medium: false,
        batch: false, //new code
        exam: false, //new code
        classMode: false,
        startDates: false,
        status: false,
        location: false,
        time: false,
        startDate: !prevState.startDate,
      }));
    } else if (boxName === "startDates") {
      setIsOpen((prevState) => ({
        schedule: false,
        category: false,
        // course:false,
        medium: false,
        batch: false, //new code
        exam: false, //new code
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        startDates: !prevState.startDates,
      }));
    } else if (boxName === "schedule") {
      setIsOpen((prevState) => ({
        category: false,
        // course:false,
        medium: false,
        batch: false, //new code
        exam: false, //new code
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        startDates: false,
        schedule: !prevState.schedule,
      }));
    } else if (boxName === "category") {
      setIsOpen((prevState) => ({
        medium: false,
        batch: false, //new code
        exam: false, //new code
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        startDates: false,
        schedule: false,
        category: !prevState.category,
      }));
    } else if (boxName === "course") {
      setIsOpen((prevState) => ({
        ...prevState,
        course: !prevState.course,
      }));
    } else if (boxName === "medium") {
      setIsOpen((prevState) => ({
        classMode: false,
        status: false,
        location: false,
        time: false,
        batch: false, //new code
        exam: false, //new code
        startDate: false,
        startDates: false,
        schedule: false,
        category: false,
        medium: !prevState.medium,
      }));
    } else if (boxName === "batch") {
      setIsOpen((prevState) => ({
        classMode: false,
        status: false,
        location: false,
        medium: false,
        time: false,
        startDate: false,
        startDates: false,
        schedule: false,
        category: false,
        batch: !prevState.batch,
      })); //New Code
      // } else if (boxName === "classMode") {
      //   setIsOpen((prevState) => ({
      //     status: false,
      //     location: false,
      //     time: false,
      //     startDate: false,
      //     startDates: false,
      //     schedule: false,
      //     category: false,
      //     medium: false,
      //     batch: false,
      //     classMode: !prevState.classMode,
      //   }));
    } else if (boxName === "exam") {
      setIsOpen((prevState) => ({
        classMode: false,
        status: false,
        location: false,
        medium: false,
        batch: false, //new code
        time: false,
        startDate: false,
        startDates: false,
        schedule: false,
        category: false,
        exam: !prevState.exam,
      })); //New Code
    }
  };

  // generate pdf when user click download button
  // const generatePDF = () => {
  //   // Create a new jsPDF instance
  //   const doc = new jsPDF();

  //   // Add a title to the PDF document
  //   doc.text("Table Data", 10, 10);

  //   // Convert the table to a JavaScript array
  //   const tableData = [];

  //   // Add header row to tableData array
  //   const headers = [];
  //   const tableHeader = document.querySelectorAll("#example th");
  //   for (const header of tableHeader) {
  //     headers.push(header.innerText);
  //   }
  //   tableData.push(headers);

  //   // Add data rows to tableData array
  //   const tableRows = document.querySelectorAll("#example tbody tr");
  //   for (const row of tableRows) {
  //     const rowData = [];
  //     const rowCells = row.querySelectorAll("td");
  //     for (const cell of rowCells) {
  //       rowData.push(cell.innerText);
  //     }
  //     tableData.push(rowData);
  //   }

  //   // Add the table to the PDF document
  //   doc.autoTable({
  //     head: [tableData[0]], // Use the first row as the table header
  //     body: tableData.slice(1), // Use the remaining rows as the table body
  //   });
  //   // Save the PDF document
  //   doc.save("AdmissionData.pdf");
  // };
  const generatePDF = () => {
    // Create a new jsPDF instance
    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // Landscape orientation
      unit: "in", // Use inches as the unit of measurement
      format: [9.5, 11], // Set the dimensions to a standard letter size (9.5 x 11 inches)
    });

    doc.setFont("arabic"); // Set the font to 'arabic'
    doc.setFontSize(12); // Adjust the desired font size
    // Add a title to the PDF document
    doc.text("Register User data", 10, 10);

    // Convert the table to a JavaScript array
    const tableData = [];

    const headers = [
      "exam_title",
      "batch_name", // new code
      "medium", // new code
      "exam_center_name", //New code
      "started_dt", //New code
      "start_time", //New code
      "end_time",
      // "exam_status", //new code
      "email",
      "full_name",
      "phone_no",
      "registered_on",
      "last_login",

      "book_purchased",
    ]; // Assuming updateListData is your state variable
    tableData.push(headers);

    updateListData.forEach((item) => {
      const rowData = [];
      headers.forEach((header) => {
        rowData.push(item[header]);
      });
      tableData.push(rowData);
    });

    // Define the column widths for the header columns (adjust as needed)
    // Calculate column widths based on the content of header and tbody cells
    const headerColumnStyles = {};
    const tbodyColumnStyles = {};

    headers.forEach((header, index) => {
      const maxHeaderWidth = Math.max(
        ...tableData
          .slice(1) // Exclude the header row
          .map((row) => {
            const cellValue =
              row[index] !== null ? row[index].toString() : "None"; // Replace null with an empty string
            return doc.getStringUnitWidth(cellValue) * 1 + 6; // Adjust the multiplier as needed
          })
      );
      const maxTbodyWidth = Math.max(
        ...tableData
          .slice(1) // Exclude the header row
          .map((row) => {
            const cellValue =
              row[index] !== null ? row[index].toString() : "None"; // Replace null with an empty string
            return doc.getStringUnitWidth(cellValue) * 2 + 6; // Adjust the multiplier as needed
          }) // Adjust the multiplier as needed
      );

      headerColumnStyles[index] = { columnWidth: maxHeaderWidth };
      tbodyColumnStyles[index] = { columnWidth: maxTbodyWidth };
    });

    // Add data rows to tableData array
    const tableOptions = {
      head: [tableData[0]], // Use the first row as the table header
      body: tableData.slice(1), // Use the remaining rows as the table body
      // columnStyles: { ...headerColumnStyles, ...tbodyColumnStyles }, // Specify dynamic column widths
    };

    // Add the table to the PDF document
    doc.autoTable(tableOptions);

    // Save the PDF document
    doc.save("UserListData.pdf");
  };

  // total table data  with pages
  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;
    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      setUpdateListData([]);
    }
    loadListdata();
  };
  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = updateListData.slice(start, end);

  const hanldeAttnSheet = (deti) => {
    AxiosInstance.post("api/batch/attendance/dwld", {
      batch_id: deti.batch_id,
      exam_detail_cstm_pk: deti.exam_detail_cstm_pk,
      exam_center_cstm_pk: deti.exam_center_cstm_pk,
      center_staff_user_id: deti.center_staff_user_id,
      paper_type: deti.paper_type,
      exam_detail_paper_pk:
        deti.paper_type === 1 ? deti.exam_detail_paper_pk : "", // send the key only if the paper_type is 1 otherwise don't send
      created_by: userid,
      reattempt: deti.req_attendance_redwnld === 1 ? 1 : 0,
    })
      .then((res) => {
        if (res.data.status === true) {
          const path = res.data.data;
          if (path !== "") {
            // console.log(res.data.path,"Lalithfour")
            window.open(`${ImageUrlPrefix}/${path}`, "_blank");
          }
          loadListdata();
        } else {
          Swal.fire({
            title: "Warning",
            text: res.data.message,
            icon: "Warning",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const handleReadView = (df) => {
    console.log(df, "df");
    if (df.exam_center_cstm_pk) {
      let examDetailPaperPk = 0;
      if (df.exam_detail_paper_pk) {
        examDetailPaperPk = df.exam_detail_paper_pk;
      }
      const detailsio = {
        exam_center_cstm_pk: df.exam_center_cstm_pk,
        event_pk: df.event_pk,
        center_staff_user_id: staff_id,
        exam_detail_cstm_pk: df.exam_detail_cstm_pk,
        batch_id: df.batch_id,
        paper_type: df.paper_type,
        exam_detail_paper_pk: examDetailPaperPk,
        batch_status: df.batch_status,
      };
      sessionStorage.setItem("detailsio", JSON.stringify(detailsio));
      navigate("/ViewModelExam");
    }
  };

  // console.log(dCourse,"setFCourse" , ssubject , "ssubject");
  return (
    <div className=" BathDet ">
      <h6 className="Title">Exam Centers</h6>
      {updateChipData.length > 0 ? (
        <div className="row mt-3">
          <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
            <Paper
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {updateChipData.map((data, index) => {
                let icon;
                let key = "" + data.name;
                return (
                  <ListItem key={data}>
                    <Chip
                      icon={icon}
                      label={key}
                      onDelete={handleDelete(data, index)}
                    />
                  </ListItem>
                );
              })}
              <ListItem>
                <a className="allclear" href="#" onClick={handleAllClear}>
                  Clear All Filters
                </a>
              </ListItem>
            </Paper>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="row mt-3 ms-1 me-1">
        <div className="col-md-12 table-responsive p-0">
          <table className="table" id="example">
            <thead>
              <tr>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "serialNumber")}
                  >
                    S.No
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "serialNumber")}
                  >
                    {" "}
                    import_export{" "}
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "exam_title")}
                  >
                    Model Exam Name
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "exam_title")}
                  >
                    {" "}
                    import_export{" "}
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "batch_name")}
                  >
                    Batch
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "batch_name")}
                  >
                    import_export
                  </span>
                </th>

                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "medium")}
                  >
                    {" "}
                    Medium of Exam{" "}
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "medium")}
                  >
                    import_export
                  </span>{" "}
                  <span
                    class={` align-middle material-icons  ${
                      mediums.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "medium")}
                  >
                    filter_list
                  </span>
                  {isOpen.medium === true && (
                    <div className="filterBoxs p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Tamil"
                              value="1"
                              id="Tamil"
                              checked={mediums.some((element) => {
                                if (element.id === "1") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) => handleCheckboxChange(e, "Tamil")}
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              Tamil
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="English"
                              value="0"
                              id="English"
                              checked={mediums.some((element) => {
                                if (element.id === "0") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "English")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              English
                            </label>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("medium")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("medium")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>

                <th id="th" style={{ width: "15%" }}>
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "exam_center_name")}
                  >
                    Venue of Examination
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "exam_center_name")}
                  >
                    import_export
                  </span>{" "}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "started_dt")}
                  >
                    {" "}
                    Exam Date
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "started_dt")}
                  >
                    import_export
                  </span>
                </th>
                {/* -- */}
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "time")}
                  >
                    {" "}
                    Time
                  </span>
                </th>

                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "batch_status")}
                  >
                    Exam Status
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "batch_status")}
                  >
                    import_export
                  </span>{" "}
                  <span
                    class={` align-middle material-icons  ${
                      status.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "status")}
                  >
                    filter_list
                  </span>
                  {isOpen.status === true && (
                    <div className="filterBoxssss p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Upcoming"
                              value="0"
                              id="Upcoming"
                              checked={status.some((element) => {
                                if (element.id === "0") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Upcoming")
                              }
                            />
                            <label
                              class="form-check-label PinkText"
                              for="defaultCheck1"
                            >
                              Upcoming
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Ongoing"
                              value="1"
                              id="Ongoing"
                              checked={status.some((element) => {
                                if (element.id === "1") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Ongoing")
                              }
                            />
                            <label
                              class="form-check-label GreenText"
                              for="defaultCheck1"
                            >
                              Ongoing
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Completed"
                              value="2"
                              id="Completed"
                              checked={status.some((element) => {
                                if (element.id === "2") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Completed")
                              }
                            />
                            <label
                              class="form-check-label GreyText"
                              for="defaultCheck1"
                            >
                              Completed
                            </label>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("status")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("status")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  {" "}
                  <span className="text_th">Action</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData.length === 0 ? (
                <tr>
                  <td colspan="10" style={{ textAlign: "center" }}>
                    No record to display
                  </td>
                </tr>
              ) : (
                currentData.map((course, index) => (
                  <tr key={index} title={course.pk}>
                    <td id="td">{course.serialNumber} </td>
                    <td id="td">{course.exam_title} </td>
                    <td id="td">{course.batch_name}</td>
                    <td id="td"> {course.medium}</td>
                    <td id="td"> {course.exam_center_name}</td>
                    <td id="td">
                      {" "}
                      {course.started_dt
                        ? moment(course.started_dt).format("DD/MM/YYYY")
                        : "_"}
                    </td>
                    <td id="td">
                      {" "}
                      {`${moment(course.start_time, "HH:mm").format(
                        "h:mm A"
                      )} - ${moment(course.end_time, "HH:mm").format(
                        "h:mm A"
                      )}`}
                    </td>
                    {/* 0:Upcoming 1:Ongoing 2:Completed */}
                    <td id="td">
                      {course.batch_status === 0 && (
                        <span className="PinkText">Upcoming</span>
                      )}
                      {course.batch_status === 1 && (
                        <span className="GreenText">OnGoing</span>
                      )}
                      {course.batch_status === 2 && (
                        <span className="GreyText">Completed</span>
                      )}
                    </td>
                    <td id="td">
                      {/* <button
                        className="button d-flex"
                        onClick={() => handleReadView()}
                      >
                        {" "}
                        <span class="material-icons">visibility</span>
                      </button>
                      <button
                        className="button"
                        onClick={() => hanldeAttnSheet(course)}
                      >
                        {" "}
                        <span class="material-icons">download</span>
                      </button> */}
                      {/* new-change */}
                      <div className="d-flex">
                        <IconButton onClick={() => handleReadView(course)}>
                          <ICON_VIEW
                            className="MateWhite"
                            color="error"
                            fontSize={`${ICON_SIZE}px`}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => hanldeAttnSheet(course)}
                          disabled={
                            (course.attendance_file_path !== null &&
                              course.req_attendance_redwnld === 0 &&
                              course.redwnld_req_approved === 0) ||
                            (course.req_attendance_redwnld === 1 &&
                              course.redwnld_req_approved === 0)
                          }
                        >
                          <ICON_DOWNLOAD
                            // className={`MateWhite`}
                            className={`MateWhite ${
                              (course.attendance_file_path !== null &&
                                course.req_attendance_redwnld === 0 &&
                                course.redwnld_req_approved === 0) ||
                              (course.req_attendance_redwnld === 1 &&
                                course.redwnld_req_approved === 0)
                                ? "DisabledListvi"
                                : ""
                            }`}
                            color="error"
                            fontSize={`${ICON_SIZE}px`}
                          />
                        </IconButton>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row mt-2 ms-1 me-1 mb-3">
        <div className="col-md-12 p-0">
          <div className="pag pagssss w-100 dflexcenter">
            <span className="ipage">Items Per Page</span>
            <select
              name="pageCount"
              id="pageCount"
              defaultValue={rowsPerPage}
              onChange={handlePageCount}
              className="ms-2"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span className="pagenumber ms-2">
              {" "}
              {updateListData.length > 0
                ? currentPage * rowsPerPage + 1 - rowsPerPage
                : 0}{" "}
              -
              {updateListData.length < currentPage * rowsPerPage
                ? updateListData.length
                : currentPage * rowsPerPage}{" "}
              of {updateListData.length}
            </span>
            <button
              className="prev_button ms-2 me-2"
              onClick={(e) => handlePrev(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <span class="material-icons">chevron_left</span>
            </button>
            <button
              className="prev_button"
              onClick={() => handleNext(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <span class="material-icons">chevron_right</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModelExamCenter;
