import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PaginationTop10 from "../dashboard/PaginationTop10"
import { useState, useEffect } from 'react';
import {ImageUrlPrefix,} from "../../labels/index";
import separator_pattern_left from "../../img/dashboard/Bottom Separator Pattern - Left (1).png";
import red_undeline from "../../img/dashboard/Red Underline (1).png"
import moment from 'moment';
import { AxiosInstance } from '../../axios';

function Top10squad (){
    const [categoryIndex, setcategoryIndex] = useState(0);    
    const [rankHolderRes, setRankHolderRes] = useState([]);
    // prev button pagination
    const handlePrev = (val) => {
        if(val >= 0){
        console.log(" setcategoryIndex Prev ", val);
        setcategoryIndex(val);
        window.scrollTo({ top: "1350", behavior: 'smooth' }); // Scroll to top
      }
    };
    // next button pagination
    const handleNext = (val) => {
        if(val < 5 && rankHolderRes[categoryIndex]){
        console.log(" setcategoryIndex next ", val);
        setcategoryIndex(val);
        window.scrollTo({ top: "1350", behavior: 'smooth' }); // Scroll to top
        }
    };
    // Function to convert date format from 'dd-MM-yyyy' to 'dd/MM/yyyy'
    const formatDate = (dateString) => {
    if (!dateString) return ''; // Handle empty or undefined dateString
    // Parse and format the date
    return moment(dateString, 'DD-MM-YYYY').format('DD/MM/YYYY');
    };
    const startDate = rankHolderRes[categoryIndex]?.start_date || '';
    const formattedDate = formatDate(startDate);
    useEffect(() => {
        const cateRankData = async () => {
            AxiosInstance.get("api/all/toppers_list")
            .then((res) => {
              if(res.data.status){
                const data = res.data.data; 
                setRankHolderRes(data);
              }else{
                setRankHolderRes([]);
              }
            })
            .catch((error) => {
              return error;
            });
        };
        cateRankData();
    }, []);

    return (
    <>
    <div className="Top10SquadList m-lg-4 m-3">
       <h3 className="stitle text-center position-relative">We take pride of our champ<span><img className='course_underline_img' src={red_undeline} alt="word_underline" /></span>ions</h3> 
       <p className="subtitle">Our institute has a strong tradition of excellence, and our champions are a testament to that.</p>
       {rankHolderRes[categoryIndex] &&(
       <div className="marklist_container p-3">
      
        <div className='row'>
            <div className='col-12 col-lg-3'>
                <p className='p-0 m-0 SquadHeader'>Category</p>
                <p className='SquadText'>{rankHolderRes[categoryIndex].category}</p>
            </div>
            <div className='col-12 col-lg-3'>
                <p className='p-0 m-0 SquadHeader'>Course</p>
                <p className='SquadText'>{rankHolderRes[categoryIndex].subcategory}</p>
            </div>
            <div className='col-12 col-lg-3'>
                <p className='p-0 m-0 SquadHeader'>Exam/Paper Title</p>
                <p className='SquadText'>{rankHolderRes[categoryIndex].name}</p>
            </div>
            <div className='col-12 col-lg-3'>
                <p className='p-0 m-0 SquadHeader'>Date</p>
                <p className='SquadText'>{formattedDate}</p>
            </div>
        </div>

       <fieldset className='row m-0'>
       <legend className='w-auto'>Top 10 Squad</legend>
           { rankHolderRes[categoryIndex].topten.map((squad) => (
                <div className="SquadContainer col-12 col-md-6 mb-2 px-0 p-lg-1 p-md-1">
                    <div className="SquadContentBox">
                        <div className="row p-1 m-0 justify-content-center align-items-center">
                            <div className="col-6 col-lg-2">
                                <div className="d-flex justify-content-center align-items-center">
                                <p className="SquadRank">{squad.classrank} </p>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3">
                            <img
                                src={`${ImageUrlPrefix}/${squad.profile_path}`}
                                className="SquadImg"
                                alt="student profile"
                            />
                            </div>
                            <div className="col-12 col-lg-7">
                                <p className="SquadName">{`${squad.full_name}`}</p>
                                <div className="row p-0 m-0">
                                    <div className="col-6 p-0 m-0">
                                        <p className="SquadHeader p-0 m-0">Marks Obtained</p>
                                        <p className="SquadText">{`${squad.marks_obtained} / ${squad.tot_marks}`} </p>
                                    </div>
                                    <div className="col-6 p-0 m-0">
                                    {squad.duration_completed !== null && (
                                        <p className="SquadHeader p-0 m-0">Time Taken</p>
                                    )}
                                    {squad.duration_completed ? (
                                        <p className="SquadText">{squad.duration_completed}</p>
                                    ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>
            ))}
        </fieldset>
        <PaginationTop10  hPrev = {handlePrev} hNext= {handleNext} totCount= {rankHolderRes} currentPage = {categoryIndex} />
       </div>
       )}
    </div>
    <div className="separator_pattern_left my-lg-5 my-3">
        <img src={separator_pattern_left} alt="separator_pattern_left" className='separator_pattern_left'
          style={{ width: '50%', height: 'auto', objectFit: 'cover' }}/>
    </div>
    </>
    );
};
export default Top10squad;
