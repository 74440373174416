import React from "react";
import "../question/Question.css";
import parse from "html-react-parser";
import ImageDisplay from "./ImageDisplay";
import LatexProcessorComponent from "../../../../utils/LatexProcessorComponent";
//display type 7 means - multiple option  mcq design
// sample design
// பொருத்தமான விடையைத் தேர்க.
// a) எதிர் மறுத்துக் கூறல்	1. உற்றது உரைத்தல் விடை
// b) உடன்பட்டுக் கூறல்	2. இனமொழி விடை
// c) நேர்ந்ததைக் கூறல்	3. நேர்விடை
// d) இனமானதைக் கூறல்	4. மறைவிடை
// 	a	b	c	d
// A	4	1	2	3
// B	4	3	1	2
// C	4	2	3	1
// D	4	3	2	1
function RtypeEight(props) {
  const { index } = props;
  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }
  const splitLines = props.list.tm_question.split("\n");
  const EsplitLines = props.list.en_question.split("\n");
  const optionType = props.list.options_type;
  const generateOptionsEnglish = (optionType, index, props, userid) => {
    // Initialize an array to store option lists
    const optionLists = [];
    // Loop through the option types and populate optionLists array
    for (let i = 0; i < optionType; i++) {
      const optionKey = `options_en_${String.fromCharCode(65 + i)}`; // A, B, C, ...
      const optionList = props.list[optionKey]
        ? props.list[optionKey].split("-")
        : [];
      optionLists.push(optionList);
    }
    const labels = ["a", "b", "c", "d", "e"];
    // Generate JSX elements based on optionLists
    const elements = optionLists.map((optionList, i) => (
      <table key={i}>
        <tbody>
          {i === 0 && (
            <tr>
              <td></td>
              {labels.slice(0, optionType).map((label, index) => (
                <td className="abcd_label" key={index}>
                  {" "}
                  {label}
                </td>
              ))}
            </tr>
          )}

          <tr>
            <td style={{ width: "35%" }}>
              {props.type !== "1" && props.type !== "2" && (
                <input
                  type="radio"
                  className="radioInput1"
                  name={`q_answer_${props.index}_en`}
                  value={
                    props.list[`options_en_${String.fromCharCode(65 + i)}`]
                  }
                  checked={
                    props.list.user_answer === String.fromCharCode(65 + i)
                  }
                />
              )}
              {props.type === "2" && (
                <input
                  type="radio"
                  className="radioInput1"
                  name={`q_answer_${props.index}_en`}
                  value={
                    props.list[`options_en_${String.fromCharCode(65 + i)}`]
                  }
                  checked={props.list.answer_en === String.fromCharCode(65 + i)}
                />
              )}{" "}
              <span className="answer_label">{`${String.fromCharCode(
                65 + i
              )})`}</span>
            </td>
            {optionList.map((line, idx) => (
              <td key={idx} className="answer_label_forSeven">
                {/* {parse(line)} */}
                <LatexProcessorComponent originalString={line} />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    ));

    return elements;
  };

  const generateOptionsTamil = (optionType, index, props, userid) => {
    // Initialize an array to store option lists
    const optionLists = [];
    // Loop through the option types and populate optionLists array
    for (let i = 0; i < optionType; i++) {
      const optionKey = `options_tm_${String.fromCharCode(65 + i)}`; // A, B, C, ...
      const optionList = props.list[optionKey]
        ? props.list[optionKey].split("-")
        : [];
      optionLists.push(optionList);
    }
    const labels = ["a", "b", "c", "d", "e"];
    // Generate JSX elements based on optionLists
    const elements = optionLists.map((optionList, i) => (
      <table key={i}>
        <tbody>
          {i === 0 && (
            <tr>
              <td></td>
              {labels.slice(0, optionType).map((label, index) => (
                <td className="abcd_label" key={index}>
                  {" "}
                  {label}
                </td>
              ))}
            </tr>
          )}

          <tr>
            <td style={{ width: "35%" }}>
              {props.type !== "1" && props.type !== "2" && (
                <input
                  type="radio"
                  className="radioInput1"
                  name={`q_answer_${props.index}_tm`}
                  value={
                    props.list[`options_tm_${String.fromCharCode(65 + i)}`]
                  }
                  checked={
                    props.list.user_answer === String.fromCharCode(65 + i)
                  }
                />
              )}
              {props.type === "2" && (
                <input
                  type="radio"
                  className="radioInput1"
                  name={`q_answer_${props.index}_tm`}
                  value={
                    props.list[`options_tm_${String.fromCharCode(65 + i)}`]
                  }
                  checked={props.list.answer_en === String.fromCharCode(65 + i)}
                />
              )}{" "}
              <span className="answer_label">{`${String.fromCharCode(
                65 + i
              )})`}</span>
            </td>
            {optionList.map((line, idx) => (
              <td key={idx} className="answer_label_forSeven">
                {/* {parse(line)} */}
                <LatexProcessorComponent originalString={line} />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    ));

    return elements;
  };

  const generateABCDLabels = (optionType) => {
    const labels = ["a", "b", "c", "d", "e"];
    return labels.slice(0, optionType).map((label, index) => (
      <span key={index} className="abcd_label">
        {label}
      </span>
    ));
  };

  const parseLine = (line, lang) => {
    const parts = line.split(" - ").map((part) => part.trim());
    const length = parts.length; // Replace 'yourArray' with the actual array you're working with
    const mdSize = length === 2 ? 6 : length === 4 ? 3 : 3;
    let colSize;
    let colSmallSize;
    switch (parts.length) {
      case 2:
        colSize = 6;
        colSmallSize = 6;
        break;
      case 3:
        colSize = 4;
        colSmallSize = 6;
        break;
      default:
        colSize = 12 / parts.length;
        colSmallSize = 12;
    }
    return parts.map((part, index) => (
      <div
        className={`col-md-${colSize} col-sm-6 col-${colSmallSize} text-start mb-3`}
        key={index}
      >
        <span
          key={index}
          className={`${
            lang === 0 ? "question_labels_tamil" : "question_labels_english"
          }`}
        >
          {parse(part)}
        </span>
      </div>
    ));
  };

  const parseLineSOption = (line, lang) => {
    const parts = line.split(" - ").map((part) => part.trim());
    let colSize;
    let colSmallSize;
    switch (parts.length) {
      case 2:
        colSize = 6;
        colSmallSize = 6;
        break;
      case 3:
        colSize = 4;
        colSmallSize = 6;

        break;
      default:
        colSize = 12 / parts.length;
        colSmallSize = 12;
    }
    return parts.map((part, index) => {
      if (part.includes("image=")) {
        const imageUrl = part.split("image=")[1];
        const imageTxt = part.split("image=")[0];
        return (
          <div
            title={`${imageUrl}`}
            className={`col-md-${colSize} col-sm-6 col-${colSmallSize} text-start mb-3`}
            key={index}
          >
            <span
              key={index}
              className={`${
                lang === 0 ? "question_labels_tamil" : "question_labels_english"
              }`}
            >
              {/* {parse(imageTxt)} */}
              <LatexProcessorComponent originalString={imageTxt} />
            </span>
            <ImageDisplay className={"width70PX"} ImgUrl={`${imageUrl}`} />
          </div>
        );
      } else {
        return (
          <div
            className={`col-md-${colSize} col-sm-6 col-${colSmallSize} text-start mb-3`}
            key={index}
          >
            <span
              key={index}
              className={`${
                lang === 0 ? "question_labels_tamil" : "question_labels_english"
              }`}
            >
              {/* {parse(part)} */}
              <LatexProcessorComponent originalString={part} />
            </span>
          </div>
        );
      }
    });
  };

  return (
    <div className="Question_temp_VQ">
      {props.list.en_question !== null &&
        props.list.en_question !== "" &&
        (props.list.lang_type === 1 || props.list.lang_type === 2) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <span className="question_labels_english">
                {EsplitLines.length > 0 ? (
                  <LatexProcessorComponent originalString={EsplitLines[0]} />
                ) : (
                  ""
                )}
              </span>
            </div>

            {EsplitLines.length > 1 && (
              <div className="col-md-12 mt-2">
                <div className="row">
                  {EsplitLines.map((line, index) => {
                    if (index === 1) {
                      return <>{parseLine(line, 1)}</>;
                    }
                    return null;
                  })}
                </div>
                <div className="row">
                  {EsplitLines.map((line, index) => {
                    if (index !== 1 && index !== 0) {
                      return <>{parseLineSOption(line, 1)}</>;
                    }
                    return null;
                  })}
                </div>
              </div>
            )}

            <div
              className={`${
                props.type === "1" ? "col-9 col-md-5" : "col-9 col-md-2"
              }`}
            >
              {generateOptionsEnglish(optionType, 1, props, userid)}
            </div>

            <div className="col-md-4"></div>
          </div>
        )}

      {props.list.tm_question !== null &&
        props.list.tm_question !== "" &&
        (props.list.lang_type === 0 || props.list.lang_type === 2) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <span className="question_labels_tamil">
                {splitLines.length > 0 ? (
                  <LatexProcessorComponent originalString={splitLines[0]} />
                ) : (
                  ""
                )}
              </span>
            </div>

            {/* {console.log(splitLines, "splitLines", props.list.ques_no)} */}
            {splitLines.length > 1 && (
              <div className="col-md-12 mt-3">
                <div className="row">
                  {splitLines.map((line, index) => {
                    if (index === 1) {
                      return <>{parseLine(line, 0)}</>;
                    }
                    return null;
                  })}
                </div>
                <div className="row">
                  {splitLines.map((line, index) => {
                    if (index !== 1 && index !== 0) {
                      return <>{parseLineSOption(line, 0)}</>;
                    }
                    return null;
                  })}
                </div>
              </div>
            )}

            <div
              className={`${
                props.type === "1" ? "col-9 col-md-5" : "col-9 col-md-2"
              }`}
            >
              {generateOptionsTamil(optionType, 1, props, userid)}
            </div>

            <div className="col-md-4"></div>
          </div>
        )}
    </div>
  );
}

export default RtypeEight;
