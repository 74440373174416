import React, { useEffect, useState } from "react";
import "./Popup.css";

const Popup = ({ onContinue, message, isAlert }) => {
  // const [buttonDisabled, setButtonDisabled] = useState(true);
  // useEffect(() => {
  //   const popupAcknowledged = sessionStorage.getItem("popupAcknowledged")
  //   if (popupAcknowledged === "true"){
  //     setButtonDisabled(false)
  //   }
  // },[]);
  // --------------start----
  const [count, setCount] = useState(0);
  useEffect(() => {
    // Initialize count from sessionStorage
    const storedCount = sessionStorage.getItem("buttonClickCount");
    if (storedCount) {
      setCount(parseInt(storedCount, 10));
    }

    // Reset count when the tab is closed
    const handleTabClose = () => {
      sessionStorage.setItem("buttonClickCount", 0);
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  // ---------------------
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <div className="popup-overlay">
        <div className="popup-content">
          <div className="popup-header">
            <h2 className="full-title">
              Attention: Full Screen Mode Online Exam Instructions
            </h2>
          </div>
          <div className="popup-body">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {count >= 1 ? (
                    <>
                      <div className="row mb-3 ms-1">
                        <div className="col-lg-12 col-md-12 ps-0">
                          <div
                            className="Warning_Popup h-0"
                            style={{ textAlign: "center" }}
                          >
                            <strong>WARNING: </strong> <br /> You have pressed
                            the ESC or F11 button on your keyboard. As a result,
                            the exam will be terminated, <br /> and you will be
                            marked as absent.
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3 ms-1">
                        <div className="col-lg-12 col-md-12 ps-0">
                          <div className="Note_Popup h-0">
                            <strong>NOTE: </strong> Keep your browser in{" "}
                            <b>full-screen mode</b> throughout the entire exam.
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="row mb-3 ms-1">
                      <div className="col-lg-12 col-md-12 ps-0">
                        <div className="Note_Popup h-0">
                          <strong>NOTE: </strong> Keep your browser in{" "}
                          <b>full-screen mode</b> throughout the entire exam.
                        </div>
                      </div>
                    </div>
                  )}

                  <p className="fullscreen-paragraph">
                    <ul>
                      <li>Do not reload the page.</li>
                      <li>Do not close the browser.</li>
                      <li>Do not minimize the browser.</li>
                      <li>Do not switch tabs.</li>
                      <li>Do not switch to other applications.</li>
                      <li>Do not click the back button.</li>
                      <li>Do not press ESC, F1 (help), and F11.</li>
                    </ul>
                    <div className="row mb-3 ms-1">
                      <div className="col-lg-12 col-md-12 ps-0">
                        <div className="Note_Popup h-0">
                          <strong>NOTE: </strong> Reloading the page, closing
                          the browser, switching tabs, switching to other
                          applications, pressing ESC ,Shift or F1 button or
                          clicking the button will result in your exam being
                          automatically submitted and terminated.
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3 ms-1">
                      <div className="col-lg-12 col-md-12 ps-0">
                        <div className="Note_Popup h-0">
                          <strong>NOTE: </strong> Pressing ESC or F11 will also
                          result in your exam being terminated, and you will be
                          marked as absent.
                        </div>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="popup-footer">
            <button
              onClick={onContinue}
              disabled={count >= 1}
              className="DashboardButton"
            >
              {count >= 1 ? "Ok" : "I Understand and Accept"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
