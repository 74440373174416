import React, { useEffect } from "react";
import Select, { components, useStateManager } from "react-select";
import { multiCusutomStyle } from "../../../../pages/Multicustomstyles";
import DatePickerSample from "../../DatePicker";
import {
  CommonOption,
  CustomCOption,
  OptionBB,
} from "../../../../labels/Lableoption";
import { customStyles } from "../../../../pages/customStyles";
import { AxiosInstance } from "../../../../axios";
import { useState } from "react";

const Option = (props) => {
  return (
    <div style={{ textAlign: "left" }}>
      <components.Option {...props}>
        {!props.isDisabled && (
          <input
            type="checkbox"
            disabled={props.isDisabled}
            checked={props.isSelected}
            onChange={() => null}
            style={{
              ...(props.isSelected ? multiCusutomStyle.checkboxChecked : {}),
              width: "18px", // Change the width of the checkbox
              height: "18px", // Change the height of the checkbox
            }}
          />
        )}
        {props.isDisabled ? (
          <label style={multiCusutomStyle.optionLabelss}>{props.label}</label>
        ) : (
          <label style={multiCusutomStyle.optionLabel}>{props.label}</label>
        )}
      </components.Option>
    </div>
  );
};
function SubjectTopics(props) {
  const [testTopic, setTestTopic] = useState([]);
  useEffect(() => {
    AxiosInstance.post("/api/all/getTestTopics/bySubject", {
      category: props.cate,
      sub_category: props.subcate,
      course_name: props.course_name,
      subject: props.record.STSubject,
    })
      .then((res) => {
        if (res.data.status === true) {
          if (res.data.data.length > 0) {
            const test_topic = res.data.data.map((item, index) => ({
              value: item.id,
              label: item.test_topic,
              type: item.type,
              category: item.category,
              subcategory: item.subcategory,
              course_name: item.course_name,
              subject: item.subject,
              test_topic: item.test_topic,
            }));
            setTestTopic(test_topic);
          } else {
            setTestTopic([]);
          }
        }
      })
      .catch((error) => {
        return error;
      });
  }, [props.record.STSubject]);

  return (
    <div className="row box ">
      <div className="col-md-12">
        <p className="StepTitle">
          Subjects and Topics {props.record.id > 1 ? "-" : ""}{" "}
          {props.record.id > 1 ? props.record.id : ""}
        </p>

        <div className="row mb-4">
          <div className="col-md-6">
            <div className="forms ">
              <label for="STSubject">Subject</label>
              <Select
                class="Inputs"
                id="STSubject"
                value={
                  props.record.STSubject !== null &&
                  props.record.STSubject !== ""
                    ? {
                        value: props.record.STSubject_id,
                        label: props.record.STSubject,
                      }
                    : null
                }
                name="STSubject"
                options={props.subject_test}
                closeMenuOnSelect={true}
                hideSelectedOptions={true}
                isClearable={true}
                onChange={(es) =>
                  props.handleSubjectOptionChange(
                    es,
                    props.record.id,
                    "STSubject"
                  )
                }
                styles={customStyles} // Apply custom styles her
              />

              {props.record.STSubject_error && (
                <small className="error">{props.record.STSubject_error}</small>
              )}
            </div>
          </div>

          <div className="col-md-6">
            <div className="forms ">
              <label for="STtopic">Test Topic</label>

              <Select
                class="Inputs"
                id="STtopic"
                name="STtopic"
                closeMenuOnSelect={true}
                hideSelectedOptions={true}
                isClearable={true}
                isMulti
                onChange={(e) =>
                  props.handleSubjectOptionChange(e, props.record.id, "STtopic")
                }
                options={testTopic}
                value={props.record.STtopic.map((value) => ({
                  value: value.value,
                  label: value.label,
                  type: value.type,
                  category: value.category,
                  subcategory: value.subcategory,
                  course_name: value.course_name,
                  subject: value.subject,
                  test_topic: value.test_topic,
                }))}
                components={{
                  Option: CommonOption, // Correct the syntax here
                }}
                styles={multiCusutomStyle} // Apply custom styles her
              />
              {props.record.STtopic_error && (
                <small className="errors">{props.record.STtopic_error}</small>
              )}
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-12 dflexendcolu">
            {props.record.id === 1 ? (
              ""
            ) : (
              <a
                className="AddMore me-3"
                onClick={() => props.deleteSubTop(props.record.id)}
              >
                <span className="material-icons download_icon align-middle">
                  remove_circle
                </span>
                Delete This Subject
              </a>
            )}
            {props.length > 1 && props.record.id === 1 ? (
              <a
                className="AddMore"
                onClick={() => props.deleteSubTop(props.record.id)}
              >
                <span className="material-icons download_icon align-middle">
                  remove_circle
                </span>
                Delete This Subject
              </a>
            ) : (
              <a className="AddMore" onClick={props.AddSubTop}>
                <span className="material-icons download_icon align-middle">
                  add_circle
                </span>
                Add More Subjects
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default SubjectTopics;
