import React from "react";
import "../question/Question.css";
import parse from "html-react-parser";
import ImageDisplay from "./ImageDisplay";
//display type 8 means -  image  mcq design
// sample design

function RtypeNine(props) {
  const { index } = props;

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  const splitLines = props.list.tm_question.split("\n");
  const EsplitLines = props.list.en_question.split("\n");
  const optionType = props.list.options_type;

  const generateOptionsEnglish = (optionType) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];
    for (let i = 0; i < optionType; i++) {
      const valueKey = `options_en_${options[i]}`;
      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input">
            {props.type !== "1" && props.type !== "2" && (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_en`}
                value={props.list[`options_en_${options[i]}`]}
                checked={props.list.user_answer === options[i]}
              />
            )}
            {props.type === "2" && (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_en`}
                value={props.list[`options_en_${options[i]}`]}
                checked={props.list.answer_en === options[i]}
              />
            )}
            <span className="answer_label">({options[i]})</span>
            <ImageDisplay
              className="imgtnss_op"
              ImgUrl={`${props.list[valueKey]}`}
            />
            {/* <img className="imgtnss_op" src={`${props.list[valueKey]}`} /> */}
            {/* imgtnss_op */}
          </div>
        </div>
      );
    }

    return elements;
  };

  const generateOptionsTamil = (optionType) => {
    const options = ["A", "B", "C", "D", "E"];

    const elements = [];
    for (let i = 0; i < optionType; i++) {
      const valueKey = `options_tm_${options[i]}`;
      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input">
            {props.type !== "1" && props.type !== "2" && (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_tm`}
                value={props.list[`options_tm_${options[i]}`]}
                checked={props.list.user_answer === options[i]}
              />
            )}
            {props.type === "2" && (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_tm`}
                value={props.list[`options_tm_${options[i]}`]}
                checked={props.list.answer_tm === options[i]}
              />
            )}
            <span className="answer_label">({options[i]})</span>
            <ImageDisplay
              className="imgtnss_op"
              ImgUrl={`${props.list[valueKey]}`}
            />
            {/* <img className="imgtnss_op" src={`${props.list[valueKey]}`} /> */}
            {/* imgtnss_op */}
          </div>
        </div>
      );
    }

    return elements;
  };

  return (
    <div className="Question_temp_VQ">
      {props.list.en_question !== null &&
        props.list.en_question !== "" &&
        (props.list.lang_type === 1 || props.list.lang_type === 2) && (
          <div className="row tamilQues">
            <div className="col-md-12 ">
              <span className="question_labels_english">
                {EsplitLines.length > 0 ? parse(EsplitLines[0]) : ""}
              </span>
            </div>
            {EsplitLines.map((line) => {
              if (line.startsWith("image=")) {
                return (
                  <div className="col-md-12" key={line}>
                    <ImageDisplay
                      className="imgtnss"
                      ImgUrl={`${line.substring(8)}`}
                    />
                  </div>
                );
              }
            })}
            <div className="col-md-12  mt-4">
              <div className="row">
                {generateOptionsEnglish(optionType, props.index)}
              </div>
            </div>
          </div>
        )}
      {props.list.tm_question !== null &&
        props.list.tm_question !== "" &&
        (props.list.lang_type === 0 || props.list.lang_type === 2) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <span className="question_labels_tamil">
                {splitLines.length > 0 ? parse(splitLines[0]) : ""}
              </span>
            </div>
            {splitLines.map((line) => {
              if (line.startsWith("image=")) {
                return (
                  <div className="col-md-12" key={line}>
                    <ImageDisplay
                      className="imgtnss"
                      ImgUrl={`${line.substring(8)}`}
                    />
                  </div>
                );
              }
            })}
            <div className="col-md-12 mt-4">
              <div className="row">
                {generateOptionsTamil(optionType, props.index)}
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default RtypeNine;
