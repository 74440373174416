// CustomEvent.js

import React from "react";
import moment from "moment"; // Import the moment library if not already imported
import { ImageUrlPrefix } from "../../../../labels";
import "./ExamCenter.css";
import { Tooltip } from "react-tooltip";
const CustomEvent = (props) => {
  return (
    <>
      <div className="rbc-event-timing ">
        {props.event.syllabus_filepath !== null &&
          props.event.syllabus_filepath !== "" && (
            <a
              className="attachment-link"
              target="_blank"
              href={`${ImageUrlPrefix}/${props.event.syllabus_filepath}`}
            >
              <span className="material-icons mat_icon">attachment</span>
            </a>
          )}
        {props.event.syllabus_filepath !== null &&
        props.event.syllabus_filepath !== "" ? (
          <span
            className="timing_text"
            title={`${moment(props.event.start).format("h:mm A")} - ${moment(
              props.event.end
            ).format("h:mm A")} - ${
              props.event.subject !== null && props.event.subject !== ""
                ? props.event.subject
                : props.event.title
            } - ${ImageUrlPrefix}/${props.event.syllabus_filepath}`}
          >
            {moment(props.event.start).format("h:mm A")} -{" "}
            {moment(props.event.end).format("h:mm A")} -{" "}
            {props.event.subject !== null && props.event.subject !== ""
              ? props.event.subject
              : props.event.title}
          </span>
        ) : (
          <span
            className="timing_text"
            title={`${moment(props.event.start).format("h:mm A")} - ${moment(
              props.event.end
            ).format("h:mm A")} - ${
              props.event.subject !== null && props.event.subject !== ""
                ? props.event.subject
                : props.event.title
            } `}
          >
            {moment(props.event.start).format("h:mm A")} -{" "}
            {moment(props.event.end).format("h:mm A")} -{" "}
            {props.event.subject !== null && props.event.subject !== ""
              ? props.event.subject
              : props.event.title}
          </span>
        )}
      </div>
    </>
  );
};

export default CustomEvent;
