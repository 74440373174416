import React, { useState, useEffect } from "react";
// import MyCalendar from "../CourseBatch/CalendarView";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix } from "../../../../labels";
import ViewCalendar from "./Calendar";

export default function ViewSchedule() {
  const { batch_id, type } = useParams();

  const navigate = useNavigate();

  const [batch, setBatch] = useState([
    {
      batch_id: "",
      batch_name: "",
      category: "",
      img_url: "",
      course_name: "",
      course_id: "",
      batch_details: "",
      // start_time:"",
      // end_time:"",
      class_mode: "",
      start_on: "",
      duration: "",
      act_c_fee: "",
    },
  ]);

  const [eventFields, setEventFields] = useState([]);
  // load data
  useEffect(() => {
    AxiosInstance.post("api/purchase/schedule/list", {
      batch_id: parseInt(batch_id),
      start_date: moment().startOf("month").format("YYYY-MM-DD"),
      end_date: moment().endOf("month").format("YYYY-MM-DD"),
      event_type: "",
      // type === "1" ? 1 : 0  // future reference
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          if (res.data.data.batch_info.length > 0) {
            res.data.data.batch_info.map((item) => {
              const batch = {
                batch_id: item.id,
                category: item.category,
                batch_name: item.name,
                img_url: item.img_url,
                course_name: item.category_class,
                course_id: item.category_course_id,
                batch_details: item.batch_details,
                // start_time:item.start_time ,
                // end_time:item.end_time,
                class_mode: item.class_mode,
                start_on: item.started_dt,
                duration: item.duration,
                act_c_fee: item.fees,
              };
              setBatch([batch]);
            });
          }
          if (res.data.data.event_list.length > 0) {
            const parsedEvents = res.data.data.event_list.map((event) => {
              const startDateStr = `${event.start_date.split("T")[0]}T${
                event.start_time
              }`;
              const endDateStr = `${event.start_date.split("T")[0]}T${
                event.end_time
              }`;
              const startDate = moment(startDateStr).toDate();
              const endDate = moment(endDateStr).toDate();
              return {
                id: event.id,
                title: event.name,
                start: startDate,
                end: endDate,
                url: event.teams_url,
                subject: event.subject,
                staff_name: event.staff_name,
                startTime: event.start_time,
                endTime: event.end_time,
                description: event.description,
                event_type: event.event_type,
                type_batch: parseInt(type),
                syllabus_filepath: event.syllabus_filepath,
              };
            });
            setEventFields(parsedEvents);
          }
        }
      })
      .catch((error) => {
        return error;
      });
  }, []);

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  return (
    <div className="Purchase">
      <div className="row">
        <div className="col-md-12">
          <section className="sectionSix">
            <div className="heading dflexbetww">
              <div className="dbetw">
                <h4 className="Title mt-4 ">View Schedule</h4>
                <Link
                  to="javascript:void(0)"
                  onClick={navigateToPreviousScreen}
                  className="CancelButton MobileShow"
                >
                  Back to view Admission
                </Link>
              </div>
              <div className="d-flex smButton mt-3">
                <Link
                  to="javascript:void(0)"
                  onClick={navigateToPreviousScreen}
                  className="CancelButton MobileHide"
                >
                  Back to view Admission
                </Link>
              </div>
            </div>
            <div className="row  box me-0 ms-0">
              <div className="col-md-12">
                <p className="StepTitle">Batch Details</p>
                {batch.map((el, index) => (
                  <div className="row" key={index}>
                    <div className="col-md-12 mt-3 mb-3">
                      <div className="card  text-dark">
                        <div className="card-body BatchImage">
                          <div className="row">
                            <div className="col-lg-2 col-md-6 col-sm-12">
                              <img
                                src={`${ImageUrlPrefix}/${el.img_url}`}
                                id={el.batch_id}
                                alt="Imge"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column ImageText  justify-content-center">
                              <p className="title m-0 ">
                                {el.course_name}
                                {"-"}
                                {el.batch_name}
                              </p>
                              <p className="subtitle">{el.batch_details}</p>
                            </div>
                            <div className="col-lg-7 col-md-10 col-sm-12 col-xl-7">
                              <div className="row mt-4">
                                <div className="col-md-12 col-sm-12 ">
                                  <div className="row">
                                    {type != "4" && (
                                      <div className="col-md-3">
                                        <li className="IconsImage">
                                          <span className="material-icons">
                                            laptop_chromebook
                                          </span>
                                          <div className="d-flex flex-column">
                                            <p className="title ms-1">
                                              ClassMode
                                            </p>
                                            <p className="subtitle ms-1">
                                              {el.class_mode}
                                            </p>
                                          </div>
                                        </li>
                                      </div>
                                    )}

                                    <div className="col-md-3">
                                      <li className="IconsImage">
                                        <span className="material-icons">
                                          event
                                        </span>
                                        <div className="d-flex flex-column ">
                                          <p className="title ms-1">
                                            Starts On
                                          </p>
                                          <p className="subtitle ms-1">
                                            {moment
                                              .utc(el.start_on)
                                              .format("DD/MM/YYYY")}
                                          </p>
                                        </div>
                                      </li>
                                    </div>
                                    {type != "4" && (
                                      <div className="col-md-3">
                                        <li className="IconsImage">
                                          <span className="material-icons">
                                            currency_rupee
                                          </span>
                                          <div className="d-flex flex-column">
                                            <p className="title ms-1">
                                              Course Fee
                                            </p>
                                            <p className="subtitle ms-1">
                                              {el.act_c_fee}
                                            </p>
                                          </div>
                                        </li>
                                      </div>
                                    )}
                                    {type !== "4" && (
                                      <div className="col-md-3">
                                        <li className="IconsImage">
                                          <span className="material-icons">
                                            timer
                                          </span>
                                          <div className="d-flex flex-column">
                                            <p className="title ms-1">
                                              Duration
                                            </p>
                                            <p className="subtitle ms-1">
                                              {el.duration} Months
                                            </p>
                                          </div>
                                        </li>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section className=" StepOne">
            <div className="row  box me-0 ms-0">
              <div className="col-md-12">
                <p className="StepTitle">Schedule </p>
                <div className="row mt-4 mb-5">
                  <div className="col-md-12">
                    <ViewCalendar
                      batch_id={batch_id}
                      type={type}
                      category={batch.map((el) => {
                        return el.category;
                      })}
                      eventFields={eventFields}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
