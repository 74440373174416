import React from "react";
import "../question/Question.css";
import parse from "html-react-parser";
import LatexProcessorComponent from "../../../../utils/LatexProcessorComponent";

//display type 3 means - multiple option with statement -  mcq design
// பின்வரும் இணைகளில் எத்தனை சரி
// i. மீன்கள்   -    ஆம்பீபியா
// ii.  ஊர்வன     - ஏவ்ஸ்
// iii.பாலூட்டிகள்   - ரெப்டைல்ஸ்
// இவற்றுள்
//  (A) both statement 1 and statement 2 are correct and statement 11 is expatiation of statement 1
//  (B) In publishing and graphic design, Lorem ipsum is a placeholder text commonly used
//  (C) In publishing and graphic design, Lorem ipsum is a placeholder text commonly used
//  (D) In publishing and graphic design, Lorem ipsum is a placeholder text commonly used

function RtypeTen(props) {
  // console.log(props, "four");
  const { index } = props;

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  const splitLines = props.list.tm_question.split("\n");
  const EsplitLines = props.list.en_question.split("\n");

  const optionType = props.list.options_type;

  const generateOptionsEnglish = (optionType) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];
    for (let i = 0; i < optionType; i++) {
      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input d-flex">
            {props.type !== "1" && props.type !== "2" && (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_en`}
                value={props.list[`options_en_${options[i]}`]}
                checked={props.list.user_answer === options[i]}
              />
            )}
            {props.type === "2" && (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_en`}
                value={props.list[`options_en_${options[i]}`]}
                checked={props.list.answer_en === options[i]}
              />
            )}
            <span
              className={
                props.type != "1"
                  ? "answer_label_viewQ_english"
                  : "answer_labela"
              }
            >
              ({options[i]})
            </span>
          </div>
          <div>
            <span
              className={
                props.type != "1"
                  ? "answer_label_viewQ_english ms-1"
                  : "answer_labela"
              }
            >
              {/* {parse(props.list[`options_en_${options[i]}`])}{" "} */}
              <LatexProcessorComponent
                originalString={props.list[`options_en_${options[i]}`]}
              />
            </span>
          </div>
        </div>
      );
    }

    return elements;
  };

  const generateOptionsTamil = (optionType) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];

    for (let i = 0; i < optionType; i++) {
      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input d-flex">
            {props.type !== "1" && props.type !== "2" && (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_tm`}
                value={props.list[`options_tm_${options[i]}`]}
                checked={props.list.user_answer === options[i]}
              />
            )}
            {props.type === "2" && (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_tm`}
                value={props.list[`options_tm_${options[i]}`]}
                checked={props.list.answer_tm === options[i]}
              />
            )}
            <span
              className={
                props.type != "1" ? "answer_label_viewQ_tamil" : "answer_labela"
              }
            >
              ({options[i]})
            </span>
          </div>
          <div>
            <span
              className={
                props.type != "1"
                  ? "answer_label_viewQ_tamil ms-1"
                  : "answer_labela"
              }
            >
              {/* {parse(props.list[`options_tm_${options[i]}`])}{" "} */}
              <LatexProcessorComponent
                originalString={props.list[`options_tm_${options[i]}`]}
              />
            </span>
          </div>
        </div>
      );
    }

    return elements;
  };
  const parseLine = (line, lang) => {
    console.log(line, "linelinelineline");

    const parts = line.split(" - ").map((part) => part.trim());
    let colSize;
    let colSmallSize;

    switch (parts.length) {
      case 2:
        colSize = 2;
        colSmallSize = 3;
        break;
      case 3:
        colSize = 10;
        colSmallSize = 9;
        break;
      default:
        colSize = 0;
        colSmallSize = 12;
    }

    return parts.map((part, index) => (
      <div
        className={`${
          colSize ? `col-md-${index === 0 ? 2 : 10}` : ""
        } col-sm-${colSmallSize} 
        
        ${colSize ? `col-${index === 0 ? 3 : 9}` : ""}
       text-start mb-2`}
        key={index}
      >
        <span
          className={`${
            lang === 0 ? "question_labels_tamil" : "question_labels_english"
          }`}
        >
          <LatexProcessorComponent originalString={part} />
        </span>
      </div>
    ));
  };

  const parseLineSOption = (line, lang) => {
    const parts = line.split(" - ").map((part) => part.trim());

    let colSize;
    let colSmallSize;
    switch (parts.length) {
      case 2:
        colSize = 2;
        colSmallSize = 3;
        break;
      case 3:
        colSize = 10;
        colSmallSize = 9;
        break;
      default:
        colSize = 0;
        colSmallSize = 12;
    }
    return parts.map((part, index) => (
      <>
        <div
          className={`${
            colSize ? `col-md-${index === 0 ? 2 : 10}` : ""
          } col-sm-${colSmallSize}  ${
            colSize ? `col-${index === 0 ? 3 : 9}` : ""
          } text-start mb-2`}
          key={index}
        >
          <span
            className={`${
              lang === 0 ? "question_labels_tamil" : "question_labels_english"
            }`}
          >
            <LatexProcessorComponent originalString={part} />
          </span>
        </div>
        {/* <div
          className={`col-md-${colSize} col-sm-6 col-${colSmallSize} text-start mb-2`}
          key={index}
        >
          <span
            key={index}
            className={`${
              lang === 0 ? "question_labels_tamil" : "question_labels_english"
            }`}
          >
            <LatexProcessorComponent originalString={part} />
          </span>
        </div> */}
      </>
    ));
  };

  return (
    <div className=" Question_temp_VQ">
      {props.list.en_question !== null &&
        props.list.en_question !== "" &&
        (props.list.lang_type === 1 || props.list.lang_type === 2) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <span className="question_labels_english">
                {EsplitLines.length > 0 ? (
                  <LatexProcessorComponent originalString={EsplitLines[0]} />
                ) : (
                  ""
                )}
              </span>
            </div>
            {EsplitLines.length > 1 && (
              <div className="col-md-12 mt-3">
                <div className="row">
                  {EsplitLines.map((line, index) => {
                    if (index === 1) {
                      return <>{parseLine(line, 1)}</>;
                    }
                    return null;
                  })}
                </div>
                <div className="row">
                  {EsplitLines.map((line, index) => {
                    if (index !== 1 && index !== 0) {
                      return <>{parseLineSOption(line, 1)}</>;
                    }
                    return null;
                  })}
                </div>
              </div>
            )}

            <div className="col-md-12 mt-3">
              <div className="row">{generateOptionsEnglish(optionType)}</div>
            </div>
          </div>
        )}
      {props.list.tm_question !== null &&
        props.list.tm_question !== "" &&
        (props.list.lang_type === 0 || props.list.lang_type === 2) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <span className="question_labels_tamil">
                {splitLines.length > 0 ? (
                  <LatexProcessorComponent originalString={splitLines[0]} />
                ) : (
                  ""
                )}
              </span>
            </div>
            {splitLines.length > 1 && (
              <div className="col-md-12 mt-3">
                <div className="row">
                  {splitLines.map((line, index) => {
                    if (index === 1) {
                      return <>{parseLine(line, 0)}</>;
                    }
                    return null;
                  })}
                </div>
                <div className="row">
                  {splitLines.map((line, index) => {
                    if (index !== 1 && index !== 0) {
                      return <>{parseLineSOption(line, 0)}</>;
                    }
                    return null;
                  })}
                </div>
              </div>
            )}
            <div className="col-md-12 mt-1">
              <div className="row">{generateOptionsTamil(optionType)}</div>
            </div>
          </div>
        )}
    </div>
  );
}

export default RtypeTen;
