import React, { useState } from "react";
import logo from "../../../img/AIASA.png";
import { Link, useLocation, useParams } from "react-router-dom";
import "../../../pages/AdmissionForm.css";
import video from "../../../video/pexels-nataliya-vaitkevich-7235868.mp4";
import { Logostyle } from "../../../Themes/themes";
import { Error } from "../../../Themes/themes";
import { useEffect } from "react";
import { AxiosInstance } from "../../../../src/axios/index";
import { useNavigate } from "react-router-dom/dist";
import Swal from "sweetalert2";
import { Download, Visibility } from "@mui/icons-material";
import { LaptopChromebook } from "@mui/icons-material";
import { Today } from "@mui/icons-material";
import { Alarm } from "@mui/icons-material";
import { CurrencyRupee } from "@mui/icons-material";
import "../../../Themes/themes.css";
import { ImageUrlPrefix } from "../../../labels";
import "./StudentConfirm.css";
import moment from "moment";
import axios from "axios";
import html2pdf from "html2pdf.js";
import ModelComForP from "../../../pages/ModelComForP";
import StudentConfirmType4 from "./components/StudentConfirmType4";
import { TailSpin } from "react-loader-spinner";
import Loader from "../Loader";
function StudentConfirm() {
  const navigate = useNavigate();

  const location = useLocation();
  // get user id
  const { userId, admissId, appliedOn, type } = useParams();

  const { FSF, FCAF, FPYM, FSEC, FBTN, FLOC, FAPON, FTF, FSEAR } =
    location.state ? location.state : {};

  const user_id = userId;
  // set date format dd-mm-yyy
  const date_format = { day: "2-digit", month: "2-digit", year: "numeric" };
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const userJSON = sessionStorage.getItem("userDetails");
  let userids = null;
  let role_id = null;
  let user_type = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userids = user.user_id;
      role_id = user.role_id;
      user_type = user.user_type;
    }
  } else {
    console.log("User Id cannot available in session storage");
  }
  // declare initial values
  const [initialfields, setInitialfields] = useState({
    first_name: "",
    last_name: "",
    father_name: "",
    mother_name: "",
    dob: "",
    gender: "",
    age: "",
    marital_status: "",
    aadhar_no: "",
    aadhar_path: "",
    sign_path: "",
    original_sign_path: "",
    scp: [],
    community: "",
    community_path: "",
    phone_no: "",
    whatsapp_no: "",
    parent_mob_no: "",
    email: "",
    profile_path: "",
    status: "",
    feedback: "",
    Section: "",
    email_sent: 0,
    internal_comment: "",
    PaidAmount: 0,
    dis_amt_fees: 0,
    Balance_amt: 0,
    original_caste_path: null,
    original_profile_path: null,
    original_aadhar_path: null,
    PPayment: "",
    batch_fees: "",
    batch_spl: "",
    batch_coupon: "",
    batch_coupon_applied: "",
    old_stud_amount: "",
    old_stud_amount_applied: "",
    total_amount: "",
  });

  const [swalMsg, setSwalMsg] = useState({
    show: false,
    status: "",
    message: "",
    title: "",
    BottomOkBtn: "",
    BottomCancelBtn: "",
    BottomOkClose: "",
  });
  const [admm, setAdmm] = useState({
    status: "",
    feedback: "",
    Section: "",
    email_sent: 0,
    internal_comment: "",
    Admission_id: "",
  });
  const [section, setSection] = useState([]);
  const [typeOn, setTypeOn] = useState([]);

  const [examCenterMExam, setExamCenterMExam] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [examCenterName, setExamCenterName] = useState(null);

  const [commentSec, setCommentSec] = useState([
    // {
    //   user_type:"",
    //   updated_dt: "",
    //   admin_comment: "",
    //   admission_id: "",
    // }
  ]);

  // declare states for the education,special category,courses,technical,errors from the axios response
  const [specialcategory, SetSpecialcategory] = useState("");
  const [educationQualification, setEducationQualification] = useState([]);
  const [techQualification, setTechQualification] = useState("");
  const [address, setAddress] = useState([]);
  const [courses, setCourses] = useState("");
  const [subjectsss, setSubjectsss] = useState([]);
  const [batchdetails, setBatchdetails] = useState("");
  const [error, setError] = useState({});
  const [status, setStatus] = useState([
    { id: "0", name: "WaitingForApproval", type: "status" },
  ]);
  const [payment_type, setPayment_type] = useState([]);

  const [category_code, setCategory_code] = useState([]);
  const [appData, setAppData] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });

  //  to load the data for the corresponding fields when the page loads fetching from api
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        if (type !== "4") {
          const response = await AxiosInstance.post("api/course/admission/", {
            user_id: userId,
            admission_id: parseInt(admissId),
            type: parseInt(type),
          });

          if (response.data.status === true) {
            const { dob } = response.data.data;
            setEducationQualification(response.data.data.edu);
            SetSpecialcategory(response.data.data.scp);
            setCourses(response.data.data.Courses);
            setSubjectsss(response.data.data.Courses.subject);
            if (response.data.data.Courses.batch_details !== null) {
              setBatchdetails(response.data.data.Courses.batch_details);
            } else {
              setBatchdetails([
                {
                  category: "",
                  medium: "",
                  batch_id: "",
                  img_url: "",
                  course_name: "",
                  batch_details: "",
                  course_id: "",
                  class_mode: "",
                  start_on: "",
                  duration: "",
                  batch_type: "",
                  batch_loc: "",
                  batch_name: "",
                  act_c_fee: "",
                  offer_spl: "",
                  dis_amt: "",
                  batch_medium: "",
                  is_selected: false,
                  no_of_test: "",
                  exam_details: [],
                },
              ]);
            }

            // Filter Technical qualification and set into state
            const filterTechnical = response.data.data.edu.filter(
              (item) => item.qualification_type === "THQ"
            );
            setTechQualification(filterTechnical);

            const addresses = response.data.data.address;

            setAddress(addresses);
            // split the present address details from the response
            const presentAddressValues = addresses.filter(
              (address) => address.address_type === 1
            );

            setInitialfields((prevFields) => ({
              ...prevFields,
              BuildingNumberPresent: presentAddressValues[0].address1 || "",
              StreetNamePresent: presentAddressValues[0].address2 || "",
              AreaPresent: presentAddressValues[0].address3 || "",
              PostPresent: presentAddressValues[0].post_name || "",
              PincodePresent: presentAddressValues[0].postal_code || "",
              CityPresent: presentAddressValues[0].city || "",
              TalukPresent: presentAddressValues[0].taluk || "",
              districtPresent: presentAddressValues[0].district_name || "",
              statePresent: presentAddressValues[0].state_name || "",
              LandmarkPresent: presentAddressValues[0].landmark || "",
            }));

            // split the permanent address details from the response
            const PermanentAddressValues = addresses.filter(
              (address) => address.address_type === 2
            );

            setInitialfields((prevFields) => ({
              ...prevFields,
              BuildingNumberPermanent: PermanentAddressValues[0].address1 || "",
              StreetNamePermanent: PermanentAddressValues[0].address2 || "",
              AreaPermanent: PermanentAddressValues[0].address3 || "",
              PostPermanent: PermanentAddressValues[0].post_name || "",
              PincodePermanent: PermanentAddressValues[0].postal_code || "",
              CityPermanent: PermanentAddressValues[0].city || "",
              TalukPermanent: PermanentAddressValues[0].taluk || "",
              districtPermanent: PermanentAddressValues[0].district_name || "",
              statePermanent: PermanentAddressValues[0].state_name || "",
              LandmarkPermanent: PermanentAddressValues[0].landmark || "",
            }));
            setInitialfields((prevFields) => ({
              ...prevFields,
              ...response.data.data,
            }));
            if (response.data.data.Admission_details.length > 0) {
              const admissiondetail = response.data.data.Admission_details[0];
              setAdmm((prevFields) => ({
                ...prevFields,
                Admission_id: admissiondetail.admission_id,
                status: admissiondetail.status,
                Section: admissiondetail.section,
                feedback: admissiondetail.feedback,
                internal_comment: admissiondetail.internal_comment,
              }));
              setInitialfields((prevFields) => ({
                ...prevFields,
                status: admissiondetail.status,
                Section: admissiondetail.section,
                feedback: "",
                internal_comment: admissiondetail.internal_comment,
                Balance_amt: admissiondetail.balance_amt,
                PaidAmount: admissiondetail.paid_amount,
                dis_amt_fees: admissiondetail.discounted_fees,
                PPayment: admissiondetail.partial_payment,
                batch_fees: admissiondetail.batch_fees,
                batch_spl: admissiondetail.batch_special_offer,
                batch_coupon: admissiondetail.coupon_amount,
                batch_coupon_applied: admissiondetail.is_coupon,
                old_stud_amount: admissiondetail.old_stud_disc,
                old_stud_amount_applied: admissiondetail.old_stud_disc_applied,
              }));

              const total__dis_amount_fees =
                admissiondetail.is_coupon !== 0
                  ? admissiondetail.batch_fees -
                    (admissiondetail.batch_special_offer +
                      admissiondetail.coupon_amount)
                  : admissiondetail.batch_fees -
                    admissiondetail.batch_special_offer;

              const gstamount_dis =
                (total__dis_amount_fees * admissiondetail.gst_percent) / 100;

              const total_amount_to_pay =
                total__dis_amount_fees + gstamount_dis;

              setInitialfields((prevFields) => ({
                ...prevFields,
                total_amount: total_amount_to_pay,
              }));
            }
            setIsLoading(false);
          } else {
            Swal.fire({
              title: "Warning",
              text: response.data.message,
              icon: "Warning",
              confirmButtonText: "Ok",
            });
            setIsLoading(false);
          }
        } else {
          const response = await AxiosInstance.post("api/modelexam/view", {
            user_id: userId,
            admission_id: parseInt(admissId),
            type: parseInt(type),
          });

          if (response.data.status === true) {
            setIsLoading(false);
            const { dob } = response.data.data;

            setCourses(response.data.data.Courses);
            if (response.data.data.Courses.batch_details !== null) {
              setBatchdetails(response.data.data.Courses.batch_details);
            } else {
              setBatchdetails([
                {
                  category: "",
                  medium: "",
                  batch_id: "",
                  img_url: "",
                  course_name: "",
                  batch_details: "",
                  course_id: "",
                  class_mode: "",
                  start_on: "",
                  duration: "",
                  batch_type: "",
                  batch_loc: "",
                  batch_name: "",
                  act_c_fee: "",
                  offer_spl: "",
                  dis_amt: "",
                  batch_medium: "",
                  is_selected: false,
                  no_of_test: "",
                  exam_details: [],
                },
              ]);
            }

            const filterTechnical =
              response.data.data.tech_qualification.filter(
                (item) => item.qualification_type === "THQ"
              );
            setTechQualification(filterTechnical);

            // Filter Technical qualification and set into state
            // split the permanent address details from the response
            const addresses =
              response.data.data.address.length > 0
                ? response.data.data.address
                : [];

            setAddress(addresses);
            // split the present address details from the response
            const presentAddressValues = addresses.filter(
              (address) => address.address_type === 1
            );
            if (presentAddressValues.length > 0) {
              setInitialfields((prevFields) => ({
                ...prevFields,
                BuildingNumberPresent: presentAddressValues[0]
                  ? presentAddressValues[0].address1
                  : "",
                StreetNamePresent: presentAddressValues[0].address2 || "",
                AreaPresent: presentAddressValues[0].address3 || "",
                PostPresent: presentAddressValues[0].post_name || "",
                PincodePresent: presentAddressValues[0].postal_code || "",
                CityPresent: presentAddressValues[0].city || "",
                TalukPresent: presentAddressValues[0].taluk || "",
                districtPresent: presentAddressValues[0].district_name || "",
                statePresent: presentAddressValues[0].state_name || "",
                LandmarkPresent: presentAddressValues[0].landmark || "",
              }));
            }

            // split the permanent address details from the response
            const PermanentAddressValues = addresses.filter(
              (address) => address.address_type === 2
            );

            if (PermanentAddressValues.length > 0) {
              setInitialfields((prevFields) => ({
                ...prevFields,
                BuildingNumberPermanent:
                  PermanentAddressValues[0].address1 || "",
                StreetNamePermanent: PermanentAddressValues[0].address2 || "",
                AreaPermanent: PermanentAddressValues[0].address3 || "",
                PostPermanent: PermanentAddressValues[0].post_name || "",
                PincodePermanent: PermanentAddressValues[0].postal_code || "",
                CityPermanent: PermanentAddressValues[0].city || "",
                TalukPermanent: PermanentAddressValues[0].taluk || "",
                districtPermanent:
                  PermanentAddressValues[0].district_name || "",
                statePermanent: PermanentAddressValues[0].state_name || "",
                LandmarkPermanent: PermanentAddressValues[0].landmark || "",
              }));
            }

            setInitialfields((prevFields) => ({
              ...prevFields,
              ...response.data.data,
            }));
            if (response.data.data.Admission_details.length > 0) {
              const admissiondetail = response.data.data.Admission_details[0];
              // console.log("admissiondetail",admissiondetail);
              setAdmm((prevFields) => ({
                ...prevFields,
                Admission_id: admissiondetail.admission_id,
                status: admissiondetail.status,
                Section: admissiondetail.section,
                feedback: admissiondetail.feedback,
                internal_comment: admissiondetail.internal_comment,
              }));
              setInitialfields((prevFields) => ({
                ...prevFields,
                status: admissiondetail.status,
                Section: admissiondetail.section,
                feedback: "",
                internal_comment: admissiondetail.internal_comment,
                Balance_amt: admissiondetail.balance_amt,
                PaidAmount: admissiondetail.paid_amount,
                dis_amt_fees: admissiondetail.discounted_fees,
                PPayment: admissiondetail.partial_payment,
                batch_fees: admissiondetail.batch_fees,
                batch_spl: admissiondetail.batch_special_offer,
                batch_coupon: admissiondetail.coupon_amount,
                batch_coupon_applied: admissiondetail.is_coupon,
                old_stud_amount: admissiondetail.old_stud_disc,
                old_stud_amount_applied: admissiondetail.old_stud_disc_applied,
              }));

              const total__dis_amount_fees =
                admissiondetail.is_coupon !== 0
                  ? admissiondetail.batch_fees -
                    (admissiondetail.batch_special_offer +
                      admissiondetail.coupon_amount)
                  : admissiondetail.batch_fees -
                    admissiondetail.batch_special_offer;

              const gstamount_dis =
                (total__dis_amount_fees * admissiondetail.gst_percent) / 100;

              const total_amount_to_pay =
                total__dis_amount_fees + gstamount_dis;

              setInitialfields((prevFields) => ({
                ...prevFields,
                total_amount: total_amount_to_pay,
              }));
            }
          } else {
            Swal.fire({
              title: "Warning",
              text: response.data.message,
              icon: "Warning",
              confirmButtonText: "Ok",
            });
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AxiosInstance.post("api/course/admission/chat/comm/list", {
      user_id: userId,
      admission_id: parseInt(admissId),
      type: parseInt(type), // always
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          const comSec = res.data.data.map((el) => ({
            user_type: el.user_type,
            admission_id: el.admission_id,
            comment: el.comment,
            updated_dt: el.updated_dt,
          }));
          setCommentSec(comSec);
        }
      })
      .catch((error) => {
        return error;
      });
  }, [userId]);

  // AIASA_0000188 issues solved by indumathi

  useEffect(() => {
    if (batchdetails !== null && batchdetails.category !== null) {
      const bb = {
        category: batchdetails.category !== null ? batchdetails.category : "",
        course_name: batchdetails.course_name,
        category_course_id: batchdetails.course_id,
        class_mode: batchdetails.class_mode,
        batch_type: batchdetails.batch_type,
        location: parseInt(batchdetails.batch_loc),
        type: parseInt(type),
      };
      if (type !== "4" && type !== "5" && type !== "2") {
        bb.medium = batchdetails.batch_medium;
      }

      AxiosInstance.post("api/all/getSection", bb)
        .then((res) => {
          if (res.data.status === true && res.data.data.length > 0) {
            const uniqueSections = [];
            //Iterate through the data and add unique sections to the array
            res.data.data.forEach((item) => {
              if (!uniqueSections.includes(item.section)) {
                uniqueSections.push(item.section);
              }
            });
            uniqueSections.sort();
            // uniqueSections.sort((a, b) => parseInt(a) - parseInt(b));
            // Generate the options using the unique sections
            const sections = uniqueSections.map((section, index) => (
              <option key={index} value={section}>
                {section}
              </option>
            ));
            setSection(sections);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [batchdetails]);

  // useEffect(() => {
  //   AxiosInstance.post("api/course/admission/chat/comm/list",{
  //     "user_id": userId,
  //     "admission_id": parseInt(admissId),
  //     "type": parseInt(type) // always
  //   })
  //   .then((res) => {
  //     console.log(res);
  //     if(res.data.status === true){
  //       const comSec = res.data.data.map((el) => ({
  //         user_type :el.user_type,
  //         admission_id: el.admission_id,
  //         admin_comment: el.comment,
  //         updated_dt: el.updated_dt,
  //       }));
  //       setCommentSec(comSec);
  //     }
  //   })
  //   .catch((error) => {
  //     return error;
  //   });
  // }, [user_id,admissId])

  // console.log(batchdetails,"....batch_details")

  // validate form fields and setting error
  const validateForm = (data) => {
    const errors = {};
    // console.log(data);
    if (data.status === "" || data.status === 0 || data.status === "0") {
      errors.status = "Please Select Application Status.";
    }
    if (data.status === "1" && data.Section === "" && type !== "4") {
      errors.Section = "Please choose Section";
    }
    if (data.status === "1" && !data.email_sent) {
      errors.email_sent = "Please choose Any Option";
    }
    // if (!data.exam_center && type === "4") {
    //   errors.exam_center = "Please choose exam center";
    // }
    if (data.internal_comment === "" || data.internal_comment === null) {
      errors.internal_comment = "Please enter Admin remarks";
    }
    if (data.feedback === "" && data.status !== 1 && data.status !== "1") {
      errors.feedback = `Please enter feedback `;
    }
    return errors;
  };

  // exam center load dropdown api

  useEffect(() => {
    // console.log(batchdetails.batch_id,courses.district,"batchdetails.batch_id");
    if (batchdetails.batch_id != null && courses.district != null) {
      const vv = {
        batch_id: batchdetails.batch_id,
        district: courses.district,
      };
      AxiosInstance.post("api/all/getExamCenter/byDistrict", vv)
        .then((res) => {
          if (res.data.status === true) {
            const examce = res.data.data.map((el, index) => (
              <option
                key={index}
                id={el.exam_center_name}
                value={el.exam_center_id}
              >
                {el.exam_center_name}
              </option>
            ));
            setExamCenterMExam(examce);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [courses.district]);

  /**
   * Handle change event for fields.
   * Updates the corresponding field value in state based on the input field's name.
   * @param {object} event - The change event object.
   * @param {string} event.target.name - The name of the input field.
   * @param {string} event.target.value - The value of the input field.
   */
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    //  console.log(name,"ch",checked,value);
    if (name === "status") {
      if (value != 1) {
        setInitialfields((prevFields) => ({
          ...prevFields,
          email_sent: "",
        }));
      }

      setInitialfields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
    }
    if (name === "exam_center") {
      const selectedIndex = event.target.selectedIndex;
      const selectedOptionId = event.target.options[selectedIndex].id;
      setExamCenterName(selectedOptionId);
      setInitialfields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
    } else {
      setInitialfields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
    }

    // console.log(name,value);
  };

  // // Define the HTML template
  const pdf_data = {
    user_id: userId,
    first_name: initialfields.first_name,
    last_name: initialfields.last_name,
    father_name: initialfields.father_name,
    mother_name: initialfields.mother_name,
    profile_path: initialfields.profile_path,
    original_profile_path: initialfields.original_profile_path,
    dob: initialfields.dob,
    age: initialfields.age,
    gender: initialfields.gender,
    marital_status: initialfields.marital_status,
    aadhar_no: initialfields.aadhar_no,
    aadhar_path: initialfields.aadhar_path,
    original_aadhar_path: initialfields.original_aadhar_path,
    phone_no: initialfields.phone_no,
    whatsapp_no: initialfields.whatsapp_no,
    parent_mob_no: initialfields.parent_mob_no,
    contact_type: initialfields.contact_type,
    email: initialfields.email,
    specimen_signature_path: initialfields.specimen_signature_path,
    original_specimen_signature_path:
      initialfields.original_specimen_signature_path,
    scp: initialfields.scp,
    community: initialfields.community,
    community_path: initialfields.community_path,
    original_caste_path: initialfields.original_caste_path,
    scp: specialcategory,
    address: address,
    edu: educationQualification,
    Courses: {
      ID: courses.ID,
      is_paid: courses.is_paid,
      course_id: courses.course_id,
      category: courses.category,
      medium: courses.medium,
      course_name: courses.course_name,
      competitive_exam: courses.competitive_exam == 0 ? "No" : "Yes",
      competitive_exam_name:
        courses.competitive_exam_name != null
          ? courses.competitive_exam_name
          : null,
      institute_name:
        courses.institute_name != null ? courses.institute_name : null,
      cleared_any_exam: courses.cleared_any_exam == 0 ? "No" : "Yes",
      exam_title: courses.exam_title != null ? courses.exam_title : null,
      exam_details: courses.exam_details != null ? courses.exam_details : null,
      govt_emp: courses.govt_emp == 0 ? "No" : "Yes",
      designation: courses.designation != null ? courses.designation : null,
      department: courses.department != null ? courses.department : null,
      govt_join_dt: courses.govt_join_dt != null ? courses.govt_join_dt : null,
      aiasa_student: courses.aiasa_student == 0 ? "No" : "Yes",
      aiasa_year: courses.aiasa_year != null ? courses.aiasa_year : null,
      aiasa_category:
        courses.aiasa_category != null ? courses.aiasa_category : null,
      aiasa_course: courses.aiasa_course != null ? courses.aiasa_course : null,
      aiasa_id_number:
        courses.aiasa_id_number != null ? courses.aiasa_id_number : null,
      original_aiasa_id_path:
        courses.original_aiasa_id_path != null
          ? courses.original_aiasa_id_path
          : null,
      aiasa_id_path:
        courses.aiasa_id_path != null ? courses.aiasa_id_path : null,
      admission_type: courses.admission_type == 0 ? "No" : "Yes",
      district: courses.district,
      district_name: courses.district_name,
      location: courses.location != null ? courses.location : null,
      location_id: courses.location_id != null ? courses.location_id : null,
      exam_reg_no: courses.exam_reg_no != null ? courses.exam_reg_no : null,
      hall_ticket: courses.hall_ticket != null ? courses.hall_ticket : null,
      subject: subjectsss && subjectsss.length > 0 ? subjectsss : [],
      batch_details: {
        category: batchdetails !== null ? batchdetails.category : "",
        medium: batchdetails.medium,
        batch_id: batchdetails.batch_id,
        img_url: batchdetails.img_url,
        course_name: batchdetails.course_name,
        batch_details: batchdetails.batch_details,
        course_id: batchdetails.course_id,
        class_mode: batchdetails.class_mode,
        start_on: batchdetails.start_on,
        duration: batchdetails.duration,
        batch_type: batchdetails.batch_type,
        batch_loc: batchdetails.batch_loc,
        batch_name: batchdetails.batch_name,
        act_c_fee: batchdetails.act_c_fee,
        offer_spl: batchdetails.offer_spl,
        dis_amt: batchdetails.dis_amt,
        batch_medium: batchdetails.batch_medium,
        is_selected: batchdetails.is_selected,
        no_of_test: batchdetails.no_of_test,
        exam_details: {
          exam_details: batchdetails.exam_details
            ? batchdetails.exam_details.exam_details
            : [],
          exam_fees:
            type === "4"
              ? batchdetails.exam_details && batchdetails.exam_details.exam_fees
                ? batchdetails.exam_details.exam_fees
                : []
              : batchdetails.exam_fees
              ? batchdetails.exam_fees
              : [],
        },
      },
    },
    Admission_details: [
      {
        admission_id: `${admissId}`,
        section: initialfields.Section != null ? initialfields.Section : "",
        applied_on: appliedOn,
        paid_amount:
          initialfields.PaidAmount != null ? initialfields.PaidAmount : "",
        balance_amt:
          initialfields.Balance_amt != null ? initialfields.Balance_amt : "",
        status:
          initialfields.status === "1"
            ? "approved"
            : initialfields.status === "2"
            ? "Hold"
            : "Rejected",
        feedback: initialfields.feedback,
      },
    ],
  };

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  /**
   * Handles submission of the form when form submits.
   * Saves the updated form values to local storage.
   * @param {Object} e - The form submission event.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(initialfields);
    // console.log(errors,"errors");
    if (Object.keys(errors).length === 0) {
      try {
        // AIASA-000128 - v1 issues updated by lalitha chat enter api
        const approveStudent = {
          student_user_id: user_id,
          status: parseInt(initialfields.status),
          feedback: initialfields.feedback,
          batch_course_id: initialfields.Courses.batch_details.course_id,
          course_id: initialfields.Courses.course_id,
          batch_id: initialfields.Courses.batch_details.batch_id,
          updated_by: user_id,
          section: initialfields.Section,
          admission_id: parseInt(admissId),
          type: parseInt(type),
          category: initialfields.Courses.batch_details.category,
          send_email: initialfields.email_sent === "1" ? 1 : 0,
          pdf_data: pdf_data,
          internal_comment: initialfields.internal_comment,
          user_id: userId,
          comment: initialfields.feedback,
          user_type: user_type === 1 ? 0 : 1,
          read_status: 1, // send always 1
          created_by: userids,
          updated_by: userids,
        };
        if (parseInt(type) === 4) {
          approveStudent.exam_center_id = parseInt(initialfields.exam_center);
          approveStudent.exam_center =
            examCenterName != null ? examCenterName : null;
        }

        AxiosInstance.post("/api/course/admission/approval", approveStudent)
          .then((res) => {
            //  console.log(res,"res","res");
            if (res.data.status === true) {
              Swal.fire({
                title: "success",
                text: res.data.message,
                icon: "success",
                confirmButtonText: "Ok",
              });
              navigateToPreviousScreen();
            } else {
              Swal.fire({
                title: "Failure",
                text: res.data.message,
                icon: "Failure",
                confirmButtonText: "Ok",
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        Swal.fire({
          title: "Failure",
          text: error.response.message,
          icon: "Failure",
          confirmButtonText: "Ok",
        });
      }
    } else {
      setError(errors);
    }
    // setBody(myRef.current.innerHTML);
  };

  const handleCloseModels_Succ = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };

  const handleBackFilter = (jk) => {
    navigate(jk, {
      state: {
        filStatus: FSF,
        filCateCode: FCAF,
        fillPayType: FPYM,
        fillSec: FSEC,
        fillBatch: FBTN,
        fillLoc: FLOC,
        fillAon: FAPON,
        fillType: FTF,
        fillSearch: FSEAR,
      },
    });
  };

  const handleCloseModel = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });
    navigate("/ListViewStudentConfirm");
  };

  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <>
      <form onSubmit={handleSubmit}>
        <div className="AdmissionForm AdmissionForm_section ">
          {swalMsg.show === true && (
            <ModelComForP
              show={swalMsg.show}
              message={swalMsg.message}
              status={swalMsg.status}
              title={swalMsg.title}
              BottomOkBtn={swalMsg.BottomOkBtn}
              BottomCancelBtn={swalMsg.BottomCancelBtn}
              handleCloseModels={handleCloseModel}
              // handleClose_yes={handleSPayfee}
              BottomOkClose={swalMsg.BottomOkClose}
              handleCloseModels_Succ={handleCloseModels_Succ}
            />
          )}
          <div className="row">
            <div className="col-md-12" id="StudentReview">
              <section className="sectionSix">
                <div className="heading  d-flex justify-content-between ">
                  <div className="mt-1">
                    <h2 className="tit_listview ms-0">Review Admission Form</h2>
                  </div>
                  <div className="d-flex smButton  mt-1">
                    {type === "0" && (
                      <Link
                        onClick={() =>
                          handleBackFilter("/ListViewStudentConfirm/0")
                        }
                        to="javascript:void(0)"
                        className="CancelButton me-2"
                      >
                        Back to Admission
                      </Link>
                    )}
                    {type === "1" && (
                      <Link
                        onClick={() =>
                          handleBackFilter("/ListViewStudentConfirm/1")
                        }
                        to="javascript:void(0)"
                        className="CancelButton me-2"
                      >
                        Back to Admission
                      </Link>
                    )}
                    {type === "2" && (
                      <Link
                        onClick={() =>
                          handleBackFilter("/ListViewStudentConfirm/2")
                        }
                        to="javascript:void(0)"
                        className="CancelButton me-2"
                      >
                        Back to Admission
                      </Link>
                    )}
                    {type === "3" && (
                      <Link
                        onClick={() =>
                          handleBackFilter("/ListViewStudentConfirm/3")
                        }
                        to="javascript:void(0)"
                        className="CancelButton me-2"
                      >
                        Back to Admission
                      </Link>
                    )}
                    {type === "4" && (
                      <Link
                        onClick={() =>
                          handleBackFilter("/ListViewStudentConfirm/4")
                        }
                        to="javascript:void(0)"
                        className="CancelButton me-2"
                      >
                        Back to Admission
                      </Link>
                    )}
                    {type === "5" && (
                      <Link
                        onClick={() =>
                          handleBackFilter("/ListViewStudentConfirm/5")
                        }
                        to="javascript:void(0)"
                        className="CancelButton me-2"
                      >
                        Back to Admission
                      </Link>
                    )}
                  </div>
                </div>

                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Basic Details</p>

                    <div className="row">
                      <div className="col-lg-9 col-md-7">
                        <div className="row mb-3">
                          <div className="col-md-4">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                First Name
                              </label>
                              <div className="register_app_val mb-3">
                                {initialfields.first_name || "-"}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Last Name
                              </label>
                              <div className="register_app_val mb-3">
                                {initialfields.last_name || "-"}
                              </div>
                            </div>
                          </div>
                          {/* {type !== "4" && ( */}
                          <div className="col-lg-4 col-md-12">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Father's Name/Guardian's Name
                              </label>
                              <div className="register_app_val mb-3">
                                {" "}
                                {initialfields.father_name || "-"}
                              </div>
                            </div>
                          </div>
                          {/* )} */}

                          {type !== "4" && (
                            <div className="col-lg-4 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Mother's Name
                                </label>
                                <div className="register_app_val mb-3">
                                  {" "}
                                  {initialfields.mother_name || "-"}
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="col-lg-4 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Date of Birth
                              </label>
                              <div className="register_app_val mb-3">
                                {new Date(initialfields.dob)
                                  .toLocaleDateString("en-GB", date_format)
                                  .split("/")
                                  .join("/") || "-"}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Gender
                              </label>
                              <div className="register_app_val mb-3">
                                {initialfields.gender || "-"}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Age
                              </label>
                              <div className="register_app_val mb-3">
                                {initialfields.age || "-"}
                              </div>
                            </div>
                          </div>
                          {/* {type === "4" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Phone Number
                                </label>
                                <span className="ReviewLabel">
                                  {initialfields.phone_no || ""}
                                </span>
                              </div>
                            </div>
                          )}
                          {type === "4" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  WhatsApp Number
                                </label>
                                <span className="ReviewLabel">
                                  {initialfields.whatsapp_no || ""}
                                </span>
                              </div>
                            </div>
                          )} */}

                          {/* {type !== "4" && ( */}
                          <div className="col-lg-4 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Marital Status
                              </label>
                              <div className="register_app_val mb-3">
                                {" "}
                                {initialfields.marital_status || "-"}
                              </div>
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-5">
                        <div className="forms mb-2  ">
                          <div id="profilephotos">
                            {initialfields.profile_path && (
                              <img
                                className="s_batch_image"
                                src={`${ImageUrlPrefix}/${initialfields.profile_path}`}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <div className="row">
                <div className="col-md-6">
                  <section className="sectionSix">
                    <div className="row box mt-2">
                      <div className="col-md-12">
                        <p className="StepTitle">ID Proofs</p>

                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                AADHAR Number
                              </label>
                              <div className="register_app_val mb-3">
                                {initialfields.aadhar_no || "-"}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Upload AADHAR Card
                                {initialfields.aadhar_path && (
                                  <Link
                                    to={`${ImageUrlPrefix}/${initialfields.aadhar_path}`}
                                    target="_blank"
                                  >
                                    {" "}
                                    <Download className="download_icon" />
                                  </Link>
                                )}
                              </label>
                              <div className="register_app_val mb-3">
                                {/* {initialfields.aadhar_path || ""} */}
                                {initialfields.aadhar_path !== null
                                  ? initialfields.original_aadhar_path
                                  : "-"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                {type === "4" && (
                  <div className="col-md-6">
                    <section className="sectionSix ">
                      <div className="row box mt-2">
                        <div className="col-md-12">
                          <p className="StepTitle">Specimen Signature</p>
                          <div className="row mb-3">
                            <div className="col-lg-12 col-md-12">
                              <div className="forms ">
                                <img
                                  className="SignatureFile"
                                  src={`${ImageUrlPrefix}/${initialfields.specimen_signature_path}`}
                                  alt="Imagefor signature"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                )}

                <div className="col-md-6">
                  <section className="sectionSix ">
                    <div className="row box mt-2">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          Communal Reservation Category
                        </p>

                        <div className="row">
                          <div className="col-lg-4 col-md-12">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Category
                              </label>
                              <div className="register_app_val mb-3">
                                {initialfields.community || "-"}
                              </div>
                            </div>
                          </div>
                          {type !== "4" && (
                            <div className="col-lg-8 col-md-12">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Uploaded Certificate
                                  {initialfields.community_path && (
                                    <Link
                                      to={`${ImageUrlPrefix}/${
                                        initialfields.community_path || "-"
                                      }`}
                                      target="_blank"
                                    >
                                      <Download className="download_icon" />
                                    </Link>
                                  )}
                                </label>
                                <div className="register_app_val mb-3">
                                  {/* {initialfields.community_path || ""} */}
                                  {initialfields.community_path !== null
                                    ? initialfields.original_caste_path
                                    : "-"}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                {type === "4" && (
                  <div className="col-md-6">
                    <section className="sectionSix ">
                      <div className="row box mt-2">
                        <div className="col-md-12">
                          <p className="StepTitle">
                            Applicable Special Category Preferences
                          </p>

                          <div className="row">
                            <div className="col-lg-8 col-md-12">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Category
                                </label>
                                <div className="register_app_val mb-3">
                                  {/* {initialfields.community_path || ""} */}
                                  {initialfields.scp.length > 0
                                    ? initialfields.scp
                                        .map((el) => el)
                                        .join(",")
                                    : "-"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                )}
              </div>

              {type === "4" &&
                techQualification.length > 0 &&
                !(
                  techQualification[0].name === "" &&
                  techQualification[0].level === "" &&
                  techQualification[0].lang === ""
                ) &&
                techQualification.map((data, index) => (
                  <>
                    {data.qualification_type === "THQ" ? (
                      <div className="row" key={index}>
                        {/* {techQualification.map((el, index) => ( */}
                        <div
                          className={
                            techQualification.length > 0
                              ? "col-md-12"
                              : "col-md-6 "
                          }
                          // key={index}
                        >
                          <section className=" sectionSix">
                            <div className="row box  mt-2">
                              <div className="col-md-12 ">
                                <p className="StepTitle">
                                  Technical Qualification{" "}
                                  {techQualification.length === 1
                                    ? " "
                                    : `- ${index + 1}`}
                                </p>
                                <div className="row">
                                  <div className="col-lg-4 col-md-6">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Do you possess any technical
                                        qualification?
                                      </label>
                                      <div className="register_app_val mb-3">
                                        {data.name !== "" ? "Yes" : "No"}
                                      </div>
                                    </div>
                                  </div>
                                  {data.name !== "" && (
                                    <div className="col-lg-3 col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Technical Course
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.name === "Others"
                                            ? data.level
                                            : data.name || "_"}
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {data.name !== "" &&
                                    data.name !== "Others" && (
                                      <div className="col-lg-2 col-md-6">
                                        <div className="forms ">
                                          <label
                                            htmlFor="inputName"
                                            className="form-label text-muted "
                                          >
                                            Typewriting Level
                                          </label>
                                          <div className="register_app_val mb-3">
                                            {data.level || "_"}
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                  {data.name !== "" &&
                                    data.name !== "Others" && (
                                      <div className="col-lg-3 col-md-6">
                                        <div className="forms ">
                                          <label
                                            htmlFor="inputName"
                                            className="form-label text-muted "
                                          >
                                            Language
                                          </label>
                                          <div className="register_app_val mb-3">
                                            {data.edu_medium || "_"}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                        {/* ))} */}
                      </div>
                    ) : null}
                  </>
                ))}

              {type !== "4" && (
                <div className="row">
                  {specialcategory.length > 0 &&
                    specialcategory.map((el, index) => (
                      <div
                        className={
                          specialcategory.length % 2 !== 0
                            ? specialcategory.length - 1 === index
                              ? "col-md-12"
                              : "col-md-6 "
                            : "col-md-6 "
                        }
                        key={index}
                      >
                        <section className="sectionSix">
                          <div className="row box">
                            <div className="col-md-12">
                              <p className="StepTitle">
                                Special Category{" "}
                                {specialcategory.length === 1
                                  ? " "
                                  : `- ${el.id}`}
                              </p>
                              <div className="row mb-3">
                                <div className="col-lg-4 col-md-12">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      Category
                                    </label>
                                    <span className="ReviewLabel">
                                      {el.name || "-"}
                                    </span>
                                  </div>
                                </div>
                                {el.file !== "" && el.file !== null && (
                                  <div className="col-lg-8 col-md-12 d-flex justify-content-center">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Uploaded Certificate
                                        {el.file !== "" && el.file !== null && (
                                          <a
                                            target="_blank"
                                            href={`${ImageUrlPrefix}/${el.file}`}
                                          >
                                            {" "}
                                            <Download className="download_icon" />
                                          </a>
                                        )}
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.file !== null
                                          ? el.original_pref_path
                                          : "-"}
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {el.path_sslc !== "" &&
                                  el.path_sslc !== null && (
                                    <div className="col-lg-8 col-md-12 d-flex justify-content-center">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Uploaded Certificate for sslc
                                          {el.path_sslc !== "" &&
                                            el.path_sslc !== null && (
                                              <a
                                                target="_blank"
                                                href={`${ImageUrlPrefix}/${el.path_sslc}`}
                                              >
                                                {" "}
                                                <Download className="download_icon" />
                                              </a>
                                            )}
                                        </label>
                                        <span className="ReviewLabel">
                                          {el.path_sslc !== null
                                            ? el.original_path_sslc
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                {el.path_hsc !== "" && el.path_hsc !== null && (
                                  <div className="col-lg-8 col-md-12 d-flex justify-content-center">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Uploaded Certificate for HSC
                                        {el.path_hsc !== "" &&
                                          el.path_hsc !== null && (
                                            <a
                                              target="_blank"
                                              href={`${ImageUrlPrefix}/${el.path_hsc}`}
                                            >
                                              {" "}
                                              <Download className="download_icon" />
                                            </a>
                                          )}
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.path_sslc !== null
                                          ? el.original_path_hsc
                                          : "-"}
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {el.path_ug !== "" && el.path_ug !== null && (
                                  <div className="col-lg-8 col-md-12 d-flex justify-content-center">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Uploaded Certificate for UG
                                        {el.path_ug !== "" &&
                                          el.path_ug !== null && (
                                            <a
                                              target="_blank"
                                              href={`${ImageUrlPrefix}/${el.path_ug}`}
                                            >
                                              {" "}
                                              <Download className="download_icon" />
                                            </a>
                                          )}
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.path_sslc !== null
                                          ? el.original_path_ug
                                          : "-"}
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {el.path_pg !== "" && el.path_pg !== null && (
                                  <div className="col-lg-8 col-md-12 d-flex justify-content-center">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Uploaded Certificate for PG
                                        {el.path_pg !== "" &&
                                          el.path_pg !== null && (
                                            <a
                                              target="_blank"
                                              href={`${ImageUrlPrefix}/${el.path_pg}`}
                                            >
                                              {" "}
                                              <Download className="download_icon" />
                                            </a>
                                          )}
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.path_sslc !== null
                                          ? el.original_path_pg
                                          : "-"}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    ))}
                </div>
              )}

              <section className="sectionSix">
                <div className="row box mt-2">
                  <div className="col-md-12">
                    <p className="StepTitle">Contact details</p>

                    <div className="row">
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Phone Number
                          </label>
                          <div className="register_app_val mb-3">
                            {initialfields.phone_no || "-"}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            WhatsApp Number
                          </label>
                          <div className="register_app_val mb-3">
                            {initialfields.whatsapp_no || "-"}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Father/Guardian Phone Number
                          </label>
                          <div className="register_app_val mb-3">
                            {initialfields.parent_mob_no || "-"}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-12">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Email ID
                          </label>
                          <div className="register_app_val mb-3">
                            {initialfields.email || "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* {type !== "4" && ( */}
              <div className="row">
                <div className="col-md-6">
                  <section className="sectionSix">
                    <div className="row box mt-2">
                      <div className="col-md-12">
                        <p className="StepTitle">Present Address</p>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Present Address
                              </label>
                            </div>
                          </div>

                          <div className="register_app_val mb-3">
                            {initialfields.BuildingNumberPresent
                              ? initialfields.BuildingNumberPresent +
                                  (initialfields.BuildingNumberPresent
                                    ? ","
                                    : "") || ""
                              : "_"}
                            {initialfields.StreetNamePresent
                              ? initialfields.StreetNamePresent +
                                  (initialfields.StreetNamePresent
                                    ? ","
                                    : "") || ""
                              : "_"}
                          </div>
                          <div className="register_app_val mb-3">
                            {initialfields.AreaPresent
                              ? initialfields.AreaPresent ||
                                "" + (initialfields.AreaPresent ? "," : "") ||
                                ""
                              : "_"}
                            {initialfields.LandmarkPresent
                              ? initialfields.LandmarkPresent +
                                  (initialfields.LandmarkPresent ? "," : "") ||
                                ""
                              : "_"}
                          </div>
                          <div className="register_app_val mb-3">
                            {initialfields.TalukPresent
                              ? initialfields.TalukPresent +
                                  (initialfields.TalukPresent ? "," : "") || ""
                              : "_"}
                            {initialfields.PostPresent
                              ? initialfields.PostPresent +
                                  (initialfields.PostPresent ? "," : "") || ""
                              : "_"}
                          </div>

                          <div className="register_app_val mb-3">
                            {initialfields.districtPresent
                              ? initialfields.districtPresent +
                                  (initialfields.districtPresent ? "," : "") ||
                                ""
                              : "_"}
                            {initialfields.statePresent
                              ? initialfields.statePresent +
                                  (initialfields.statePresent ? "," : "") || ""
                              : "_"}
                          </div>
                          <div className="register_app_val mb-3">
                            {initialfields.CityPresent
                              ? initialfields.CityPresent +
                                  (initialfields.CityPresent ? "," : "") || ""
                              : "_"}
                            {initialfields.PincodePresent
                              ? initialfields.PincodePresent +
                                  (initialfields.PincodePresent ? "" : "") || ""
                              : "_"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="col-md-6">
                  <section className="sectionSix ">
                    <div className="row box mt-2">
                      <div className="col-md-12">
                        <p className="StepTitle">Permanent Address</p>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                {/* {initialfields.SamePresentAddress === true
                                  ? "Present Address"
                                  : "Permanent Address"} */}
                                {initialfields.SamePresentAddress === true
                                  ? "Permanent Address"
                                  : "Permanent Address"}
                              </label>
                            </div>
                          </div>

                          <div className="register_app_val mb-3">
                            {initialfields.BuildingNumberPermanent
                              ? initialfields.SamePresentAddress === true
                                ? initialfields.BuildingNumberPresent +
                                  (initialfields.BuildingNumberPresent
                                    ? ","
                                    : "")
                                : initialfields.BuildingNumberPermanent +
                                    (initialfields.BuildingNumberPermanent
                                      ? ","
                                      : "") || "_"
                              : "_"}
                            {initialfields.StreetNamePermanent
                              ? initialfields.SamePresentAddress === true
                                ? initialfields.StreetNamePresent +
                                  (initialfields.StreetNamePresent ? "," : "")
                                : initialfields.StreetNamePermanent +
                                    (initialfields.StreetNamePermanent
                                      ? ","
                                      : "") || ""
                              : "_"}
                          </div>
                          <div className="register_app_val mb-3">
                            {initialfields.AreaPermanent
                              ? initialfields.SamePresentAddress === true
                                ? initialfields.AreaPresent +
                                  (initialfields.AreaPresent ? "," : "")
                                : initialfields.AreaPermanent +
                                    (initialfields.AreaPermanent ? "," : "") ||
                                  ""
                              : "_"}
                            {initialfields.LandmarkPermanent
                              ? initialfields.SamePresentAddress === true
                                ? initialfields.LandmarkPresent +
                                  (initialfields.LandmarkPresent ? "," : "")
                                : initialfields.LandmarkPermanent +
                                    (initialfields.LandmarkPermanent
                                      ? ","
                                      : "") || ""
                              : "_"}
                          </div>
                          <div className="register_app_val mb-3">
                            {initialfields.TalukPermanent
                              ? initialfields.SamePresentAddress === true
                                ? initialfields.TalukPresent +
                                  (initialfields.TalukPresent ? "," : "")
                                : initialfields.TalukPermanent +
                                    (initialfields.TalukPermanent ? "," : "") ||
                                  ""
                              : "_"}
                            {initialfields.PostPermanent
                              ? initialfields.SamePresentAddress === true
                                ? initialfields.PostPresent +
                                  (initialfields.PostPresent ? "," : "")
                                : initialfields.PostPermanent +
                                    (initialfields.PostPermanent ? "," : "") ||
                                  ""
                              : "_"}
                          </div>

                          <div className="register_app_val mb-3">
                            {initialfields.districtPermanent
                              ? initialfields.SamePresentAddress === true
                                ? initialfields.districtPresent +
                                  (initialfields.districtPresent ? "," : "")
                                : initialfields.districtPermanent +
                                    (initialfields.districtPermanent
                                      ? ","
                                      : "") || ""
                              : "_"}
                            {initialfields.statePermanent
                              ? initialfields.SamePresentAddress === true
                                ? initialfields.statePresent +
                                  (initialfields.statePresent ? "," : "")
                                : initialfields.statePermanent +
                                    (initialfields.statePermanent ? "," : "") ||
                                  ""
                              : "_"}
                          </div>
                          <div className="register_app_val mb-3">
                            {initialfields.CityPermanent
                              ? initialfields.SamePresentAddress === true
                                ? initialfields.CityPresent +
                                  (initialfields.CityPresent ? "," : "")
                                : initialfields.CityPermanent +
                                    (initialfields.CityPermanent ? "," : "") ||
                                  ""
                              : "_"}
                            {initialfields.PincodePermanent
                              ? initialfields.SamePresentAddress === true
                                ? initialfields.PincodePresent +
                                  (initialfields.PincodePresent ? "" : "")
                                : initialfields.PincodePermanent +
                                    (initialfields.PincodePermanent
                                      ? ""
                                      : "") || ""
                              : "_"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              {/* )} */}

              {type !== "4" &&
                // <div className="">
                educationQualification.length > 0 && (
                  <div
                    className={
                      educationQualification.some((data) => data.name === "hsc")
                        ? "row "
                        : "row justify-content-center "
                    }
                  >
                    {educationQualification.map((data, index) => (
                      <React.Fragment key={index}>
                        {data.name === "sslc" &&
                        data.qualification_type === "EDQ" ? (
                          <div
                            title={data.name}
                            className={
                              educationQualification.some(
                                (data) => data.name === "hsc"
                              )
                                ? "col-md-6"
                                : "col-md-12"
                            }
                          >
                            <section className=" sectionSix ">
                              <div className="row box">
                                <div className="col-md-12">
                                  <p className="StepTitle">SSLC</p>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Certificate Number
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.certificate_no || "-"}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          {" "}
                                          Year of Passing
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {new Date(data.year_of_passing)
                                            .toLocaleDateString(
                                              "en-GB",
                                              date_format
                                            )
                                            .split("/")
                                            .join("-") || "-"}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          {" "}
                                          Marks Percentage
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.marks_percentage || "-"}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          {" "}
                                          Education Board
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.edu_board || "-"}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          {" "}
                                          Medium of Instruction
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.edu_medium || "-"}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          {" "}
                                          School Name
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.institute || "-"}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Uploaded Certificate
                                          {data.certificate_path && (
                                            <Link
                                              to={`${ImageUrlPrefix}/${data.certificate_path}`}
                                              target="_blank"
                                            >
                                              {" "}
                                              <Download className="download_icon" />
                                            </Link>
                                          )}
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.certificate_path != null
                                            ? data.original_file_path
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        ) : null}
                        {data.name === "hsc" &&
                        data.qualification_type === "EDQ" ? (
                          <div
                            title={data.name}
                            className={
                              educationQualification.some(
                                (data) => data.name === "sslc"
                              )
                                ? "col-md-6"
                                : "col-md-12"
                            }
                          >
                            <section className=" sectionSix">
                              <div className="row box">
                                <div className="col-md-12">
                                  <p className="StepTitle">HSC</p>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Certificate Number
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.certificate_no || "-"}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          {" "}
                                          Year of Passing
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {new Date(data.year_of_passing)
                                            .toLocaleDateString(
                                              "en-GB",
                                              date_format
                                            )
                                            .split("/")
                                            .join("-") || "-"}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          {" "}
                                          Marks Percentage
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.marks_percentage || "-"}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          {" "}
                                          Education Board
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.edu_board || "-"}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          {" "}
                                          Medium of Instruction
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.edu_medium || "-"}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          {" "}
                                          School Name
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.institute || "-"}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Uploaded Certificate
                                          {data.certificate_path && (
                                            <Link
                                              to={`${ImageUrlPrefix}/${data.certificate_path}`}
                                              target="_blank"
                                            >
                                              {" "}
                                              <Download className="download_icon" />
                                            </Link>
                                          )}
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.certificate_path !== null
                                            ? data.certificate_path
                                                .split("/")
                                                .pop()
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        ) : null}
                      </React.Fragment>
                    ))}
                  </div>
                )}

              {type !== "4" &&
                educationQualification.length > 0 &&
                educationQualification.map((data, index) => (
                  <>
                    {data.name === "UG" && data.qualification_type === "EDQ" ? (
                      <section className="sectionSix">
                        <div className="row box">
                          <div className="col-md-12">
                            <p className="StepTitle">UG</p>

                            <div className="row">
                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Certificate Number
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.certificate_no || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Year of Passing
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {new Date(data.year_of_passing)
                                      .toLocaleDateString("en-GB", date_format)
                                      .split("/")
                                      .join("-") || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Degree
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.degree_name || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Major
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.major_subject || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Marks Percentage
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.marks_percentage || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Class
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.grade || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Name of Institute
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.institute || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Uploaded UG Certificate
                                    {data.certificate_path && (
                                      <Link
                                        to={`${ImageUrlPrefix}/${data.certificate_path}`}
                                        target="_blank"
                                      >
                                        {" "}
                                        <Download className="download_icon" />
                                      </Link>
                                    )}{" "}
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.certificate_path != null
                                      ? data.original_file_path
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    ) : null}
                  </>
                ))}
              {type !== "4" &&
                educationQualification.length > 0 &&
                educationQualification.map((data, index) => (
                  <>
                    {data.name === "PG" && data.qualification_type === "EDQ" ? (
                      <section className="sectionSix">
                        <div className="row box">
                          <div className="col-md-12">
                            <p className="StepTitle">PG</p>

                            <div className="row">
                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Certificate Number
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.certificate_no || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Year of Passing
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {new Date(data.year_of_passing)
                                      .toLocaleDateString("en-GB", date_format)
                                      .split("/")
                                      .join("-") || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Degree
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.degree_name || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Major
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.major_subject || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Marks Percentage
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.marks_percentage || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Class
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.grade || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Name of Institute
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.institute || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Uploaded Certificate
                                    {data.certificate_path && (
                                      <Link
                                        to={`${ImageUrlPrefix}/${data.certificate_path}`}
                                        target="_blank"
                                      >
                                        {" "}
                                        <Download className="download_icon" />
                                      </Link>
                                    )}{" "}
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.certificate_path != null
                                      ? data.original_file_path
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    ) : null}
                  </>
                ))}

              {type !== "4" &&
                educationQualification.length > 0 &&
                educationQualification.map((data, index) => (
                  <>
                    {data.name === "Integrated PG" &&
                    data.qualification_type === "EDQ" ? (
                      <section className="sectionSix">
                        <div className="row box">
                          <div className="col-md-12">
                            <p className="StepTitle">Integrated PG</p>

                            <div className="row">
                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Certificate Number
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.certificate_no || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Year of Passing
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {new Date(data.year_of_passing)
                                      .toLocaleDateString("en-GB", date_format)
                                      .split("/")
                                      .join("-") || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Degree
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.degree_name || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Major
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.major_subject || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Marks Percentage
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.marks_percentage || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Class
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.grade || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Name of Institute
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.institute || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Uploaded Certificate
                                    {data.certificate_path && (
                                      <Link
                                        to={`${ImageUrlPrefix}/${data.certificate_path}`}
                                        target="_blank"
                                      >
                                        {" "}
                                        <Download className="download_icon" />
                                      </Link>
                                    )}{" "}
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.certificate_path != null
                                      ? data.original_file_path
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    ) : null}
                  </>
                ))}

              {type !== "4" &&
                educationQualification.length > 0 &&
                educationQualification.map((data, index) => (
                  <>
                    {data.name === "Diploma" &&
                    data.qualification_type === "EDQ" ? (
                      <section className="sectionSix">
                        <div className="row box">
                          <div className="col-md-12">
                            <p className="StepTitle">Diploma</p>

                            <div className="row">
                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Certificate Number
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.certificate_no || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Year of Passing
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {new Date(data.year_of_passing)
                                      .toLocaleDateString("en-GB", date_format)
                                      .split("/")
                                      .join("-") || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Degree
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.degree_name || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Major
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.major_subject || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Marks Percentage
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.marks_percentage || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Class
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.grade || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Name of Institute
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.institute || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Uploaded Certificate
                                    {data.certificate_path && (
                                      <Link
                                        to={`${ImageUrlPrefix}/${data.certificate_path}`}
                                        target="_blank"
                                      >
                                        {" "}
                                        <Download className="download_icon" />
                                      </Link>
                                    )}
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.certificate_path != null
                                      ? data.original_file_path
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    ) : null}
                  </>
                ))}

              {type !== "4" &&
                educationQualification.length > 0 &&
                educationQualification.map((data, index) => (
                  <>
                    {data.name === "Professional Courses" &&
                    data.qualification_type === "EDQ" ? (
                      <section className="sectionSix">
                        <div className="row box">
                          <div className="col-md-12">
                            <p className="StepTitle">Professional Courses</p>

                            <div className="row">
                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Certificate Number
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.certificate_no || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Year of Passing
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {new Date(data.year_of_passing)
                                      .toLocaleDateString("en-GB", date_format)
                                      .split("/")
                                      .join("-") || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Degree
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.degree_name || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Major
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.major_subject || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Marks Percentage
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.marks_percentage || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Class
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.grade || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Name of Institute
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.institute || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Uploaded Certificate
                                    {data.certificate_path && (
                                      <Link
                                        to={`${ImageUrlPrefix}/${data.certificate_path}`}
                                        target="_blank"
                                      >
                                        {" "}
                                        <Download className="download_icon" />
                                      </Link>
                                    )}{" "}
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.certificate_path != null
                                      ? data.original_file_path
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    ) : null}
                  </>
                ))}

              {type !== "4" &&
                educationQualification.length > 0 &&
                educationQualification.map((data, index) => (
                  <>
                    {data.name === "Others" &&
                    data.qualification_type === "EDQ" ? (
                      <section className="sectionSix">
                        <div className="row box">
                          <div className="col-md-12">
                            <p className="StepTitle">Others</p>

                            <div className="row">
                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Certificate Number
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.certificate_no || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Year of Passing
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {new Date(data.year_of_passing)
                                      .toLocaleDateString("en-GB", date_format)
                                      .split("/")
                                      .join("-") || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Degree
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.degree_name || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Major
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.major_subject || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Marks Percentage
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.marks_percentage || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Class
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.grade || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Name of Institute
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.institute || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Uploaded Certificate
                                    {data.certificate_path && (
                                      <Link
                                        to={`${ImageUrlPrefix}/${data.certificate_path}`}
                                        target="_blank"
                                      >
                                        {" "}
                                        <Download className="download_icon" />
                                      </Link>
                                    )}{" "}
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {data.certificate_path != null
                                      ? data.original_file_path
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    ) : null}
                  </>
                ))}

              {type !== "4" &&
                techQualification.length > 0 &&
                !(
                  techQualification[0].name === "" &&
                  techQualification[0].level === "" &&
                  techQualification[0].lang === "" &&
                  techQualification[0].Cnum === "" &&
                  techQualification[0].grade === "" &&
                  techQualification[0].year === "" &&
                  techQualification[0].file === ""
                ) &&
                techQualification.map((data, index) => (
                  <>
                    {data.qualification_type === "THQ" ? (
                      <div className="row" key={index}>
                        {/* {techQualification.map((el, index) => ( */}
                        <div
                          className={
                            techQualification.length > 0
                              ? "col-md-12"
                              : "col-md-6 "
                          }
                          // key={index}
                        >
                          <section className=" sectionSix">
                            <div className="row box ">
                              <div className="col-md-12 ">
                                <p className="StepTitle">
                                  Technical Qualification{" "}
                                  {techQualification.length === 1
                                    ? " "
                                    : `- ${data.id}`}
                                </p>
                                <div className="row">
                                  <div className="col-lg-3 col-md-6">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Technical Course
                                      </label>
                                      <div className="register_app_val mb-3">
                                        {data.name || "-"}
                                      </div>
                                    </div>
                                  </div>

                                  {data.name !== "Nil" && (
                                    <div className="col-lg-3 col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Typewriting Level
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.level || "-"}
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {data.name !== "Nil" && (
                                    <div className="col-lg-3 col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Language
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.edu_medium || "-"}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {data.name !== "Nil" && (
                                    <div className="col-lg-3 col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Certificate Number
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.certificate_no || "-"}
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {data.name !== "Nil" && (
                                    <div className="col-lg-3 col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Grade
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.grade || "-"}
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {data.name !== "Nil" && (
                                    <div className="col-lg-3 col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Year of Passing
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.year_of_passing !== "" &&
                                          data.year_of_passing !== null
                                            ? new Date(data.year_of_passing)
                                                .toLocaleDateString(
                                                  "en-GB",
                                                  date_format
                                                )
                                                .split("/")
                                                .join("-")
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {data.name !== "Nil" && (
                                    <div className="col-lg-3 col-md-6">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Upload Certificate
                                          {data.certificate_path && (
                                            <Link
                                              to={`${ImageUrlPrefix}/${data.certificate_path}`}
                                              target="_blank"
                                            >
                                              {" "}
                                              <Download className="download_icon" />
                                            </Link>
                                          )}{" "}
                                        </label>
                                        <div className="register_app_val mb-3">
                                          {data.certificate_path != null
                                            ? data.original_file_path
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                        {/* ))} */}
                      </div>
                    ) : null}
                  </>
                ))}

              {type !== "2" && (
                <section className="sectionSix">
                  <div className="row box mt-2">
                    <div className="col-md-12">
                      <p className="StepTitle">
                        {type === "4" && "Model Exam Enrollment"}
                        {type === "5" && "Mock Interview Enrollment"}{" "}
                        {type === "0" && "Program Enrollment"}
                        {type === "1" && "Test Batch Enrollment"}
                        {type === "3" && "Special class Enrollment"}
                      </p>

                      <div className="row mb-2">
                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Category
                            </label>
                            <div className="register_app_val mb-3">
                              {courses.category || "-"}
                            </div>
                          </div>
                        </div>
                        {/* {batchdetails.length > 0 && batchdetails.map((data,index)=>( */}
                        <>
                          {type !== "5" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Course
                                </label>
                                <div className="register_app_val mb-3">
                                  {courses.course_name || "-"}
                                </div>
                              </div>
                            </div>
                          )}

                          {type === "4" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Mode Of Exam
                                </label>
                                <span className="ReviewLabel">
                                  {batchdetails !== null &&
                                  batchdetails.class_mode !== null
                                    ? batchdetails.class_mode === "ClassRoom"
                                      ? "Offline"
                                      : "Online"
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          )}

                          {type === "4" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Medium of Exam
                                </label>
                                <span className="ReviewLabel">
                                  {batchdetails !== null &&
                                  batchdetails.batch_medium !== null
                                    ? batchdetails.batch_medium
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          )}
                          {type === "4" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Exam District
                                </label>
                                <span className="ReviewLabel">
                                  {batchdetails !== null &&
                                  batchdetails.district_name !== null
                                    ? batchdetails.district_name
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          )}
                          {type === "5" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Name of the Exam You Passed
                                </label>
                                <span className="ReviewLabel">
                                  {courses.passed_exam_name || "-"}
                                </span>
                              </div>
                            </div>
                          )}

                          {type === "5" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Main Exam Regsiter Number
                                </label>
                                <span className="ReviewLabel">
                                  {courses.exam_reg_no || "-"}
                                </span>
                              </div>
                            </div>
                          )}

                          {type === "5" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Government InterView Date
                                </label>
                                <span className="ReviewLabel">
                                  {moment(courses.govt_interview_dt).format(
                                    "DD-MM-YYYY"
                                  ) || "-"}
                                </span>
                              </div>
                            </div>
                          )}

                          {type !== "4" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  location
                                </label>
                                <span className="ReviewLabel">
                                  {courses.location || "-"}
                                </span>
                              </div>
                            </div>
                          )}
                          {type !== "5" &&
                            type !== "4" &&
                            type !== "2" &&
                            subjectsss.length > 0 && (
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label
                                    Courses
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Subject
                                  </label>
                                  <div className="register_app_val mb-3">
                                    {subjectsss && subjectsss.length > 0
                                      ? subjectsss
                                          .map((el) => el.name)
                                          .join(", ")
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            )}
                          {type !== "4" && type !== "5" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted"
                                >
                                  {type === "3"
                                    ? "Medium of Examination"
                                    : "Medium of Examination"}
                                </label>
                                <div className="register_app_val mb-3">
                                  {courses.medium || "-"}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                        {/* ))} */}
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {type === "2" && (
                <section className="sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">
                        Counselling Awareness Program Enrollment
                      </p>

                      <div className="row mb-3">
                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Category
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {batchdetails.category || "-"}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Name of the Exam You Passed
                            </label>
                            <span className="ReviewLabel">
                              {courses.passed_exam_name !== ""
                                ? courses.passed_exam_name
                                : courses.passed_exam_name || "-"}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Exam Registration Number
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {courses.exam_reg_no || "-"}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Location
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {courses.location || "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {type === "2" && (
                <section className="sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Exam Result Info</p>

                      <div className="row mb-3">
                        {courses.written_exam_mark !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Written Exam Mark
                              </label>
                              <span className="ReviewLabel">
                                {courses.written_exam_mark || "-"}
                              </span>
                            </div>
                          </div>
                        )}
                        {courses.interview_mark !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Interview marks
                              </label>
                              <span className="ReviewLabel">
                                {courses.interview_mark || "-"}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row mb-3">
                        {courses.overall_rank !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Overall rank
                              </label>
                              <span className="ReviewLabel">
                                {courses.overall_rank || "-"}
                              </span>
                            </div>
                          </div>
                        )}
                        {courses.communal_rank !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Communal Rank
                              </label>
                              <span className="ReviewLabel">
                                {courses.communal_rank || "-"}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.marks_in_paper1 !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Mark uptained in Paper I
                              </label>
                              <span className="ReviewLabel">
                                {courses.marks_in_paper1 || "-"}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.marks_in_paper2 !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Mark uptained in Paper II
                              </label>
                              <span className="ReviewLabel">
                                {courses.marks_in_paper2 || "-"}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.marks_in_paper3 !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Mark uptained in Paper III
                              </label>
                              <span className="ReviewLabel">
                                {courses.marks_in_paper3 || "-"}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.marks_in_paper4 !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Mark uptained in Paper IV
                              </label>
                              <span className="ReviewLabel">
                                {courses.marks_in_paper4 || "-"}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.marks_in_paper5 !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Mark uptained in Paper V
                              </label>
                              <span className="ReviewLabel">
                                {courses.marks_in_paper5 || "-"}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.marks_in_paper6 !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Mark uptained in Paper VI
                              </label>
                              <span className="ReviewLabel">
                                {courses.marks_in_paper6 || "-"}
                              </span>
                            </div>
                          </div>
                        )}
                        {courses.marks_in_paper7 !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Mark uptained in Paper VII
                              </label>
                              <span className="ReviewLabel">
                                {courses.marks_in_paper7 || "-"}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.marks_in_paper8 !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Mark uptained in Paper VIII
                              </label>
                              <span className="ReviewLabel">
                                {courses.marks_in_paper8 || "-"}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.marks_in_paper9 !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Mark uptained in Paper VIII
                              </label>
                              <span className="ReviewLabel">
                                {courses.marks_in_paper9 || "-"}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.tot_marks_in_mains !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Total marks uptained in mains
                              </label>
                              <span className="ReviewLabel">
                                {courses.tot_marks_in_mains || "-"}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.tot_marks_in_oral_test !== null && (
                          <div className="col-md-4">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Total marks uptained marked uptained in oral
                                test
                              </label>
                              <span className="ReviewLabel">
                                {courses.tot_marks_in_oral_test || "-"}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.steno_rank !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Steno Rank
                              </label>
                              <span className="ReviewLabel">
                                {courses.steno_rank || "-"}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.widow_rank !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Widow Rank
                              </label>
                              <span className="ReviewLabel">
                                {courses.widow_rank || "-"}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.marks_in_tamil !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Marks in Tamil
                              </label>
                              <span className="ReviewLabel">
                                {courses.marks_in_tamil || ""}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.rank_in_typist !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Typist Rank
                              </label>
                              <span className="ReviewLabel">
                                {courses.rank_in_typist || ""}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.pstm_rank !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                PSTM Rank
                              </label>
                              <span className="ReviewLabel">
                                {courses.pstm_rank || ""}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.disabled_rank !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Disabled Rank
                              </label>
                              <span className="ReviewLabel">
                                {courses.disabled_rank || ""}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.mark_in_tire1 !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Marks in Tier I
                              </label>
                              <span className="ReviewLabel">
                                {courses.mark_in_tire1 || ""}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.mark_in_tire2 !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Marls in Tier II
                              </label>
                              <span className="ReviewLabel">
                                {courses.mark_in_tire2 || ""}
                              </span>
                            </div>
                          </div>
                        )}

                        {courses.marks_in_tamil !== null && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Rank in Tamil Medium Reservation
                              </label>
                              <span className="ReviewLabel">
                                {courses.marks_in_tamil || ""}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Preferred Post
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {courses.preferred_post || ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {/* {batchdetails ? ( */}
              <>
                <section className="sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Batch Details</p>
                      {type != "4" && type != "2" && type != "5" && (
                        <div className="row">
                          <div className="col-md-12 mt-3 mb-3">
                            <div className="card  text-dark">
                              <div className="card-body BatchImage">
                                <div className="row">
                                  <div className="col-lg-2 col-md-6 col-sm-12">
                                    <img
                                      src={`${ImageUrlPrefix}/${batchdetails.img_url}`}
                                      id="ImageTag"
                                      alt="Imge"
                                    />
                                  </div>
                                  <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column ImageText  justify-content-center">
                                    <span className="ReviewLabels">
                                      {batchdetails.course_name || ""}
                                      {"-"}
                                      {batchdetails.batch_name}
                                    </span>
                                    <span className="ReviewLabel">
                                      {batchdetails.batch_details || ""}
                                    </span>
                                  </div>
                                  <div className="col-lg-6 col-md-10 col-sm-12 col-xl-6 mt-2 ms-md-5 ms-sm-0 d-flex flex-column justify-content-center">
                                    <div className="row">
                                      <div className="col-md-12 col-sm-12 ">
                                        <div className="row">
                                          <div className="col-md-3">
                                            <li className="IconsImage">
                                              <span className="material-icons">
                                                laptop_chromebook
                                              </span>
                                              <div className="d-flex flex-column">
                                                <p className="title ms-1">
                                                  Class Mode
                                                </p>
                                                <span className="ReviewLabelsss">
                                                  {batchdetails.class_mode ||
                                                    ""}
                                                </span>
                                              </div>
                                            </li>
                                          </div>
                                          <div className="col-md-3">
                                            <li className="IconsImage">
                                              <span className="material-icons">
                                                event
                                              </span>
                                              <div className="d-flex flex-column ">
                                                <p className="title ms-1">
                                                  Starts On
                                                </p>
                                                <span className="ReviewLabelsss">
                                                  {batchdetails.start_on !== ""
                                                    ? moment
                                                        .utc(
                                                          batchdetails.start_on
                                                        )
                                                        .format("YYYY-MM-DD")
                                                    : ""}
                                                </span>
                                              </div>
                                            </li>
                                          </div>
                                          <div className="col-md-3">
                                            <li className="IconsImage">
                                              <span className="material-icons">
                                                timer
                                              </span>
                                              <div className="d-flex flex-column">
                                                <p className="title ms-1">
                                                  Duration
                                                </p>
                                                <span className="ReviewLabelsss">
                                                  {batchdetails.duration || ""}
                                                </span>
                                              </div>
                                            </li>
                                          </div>
                                          <div className="col-md-3">
                                            <li className="IconsImage">
                                              <span className="material-icons">
                                                currency_rupee
                                              </span>
                                              <div className="d-flex flex-column">
                                                <p className="title ms-1">
                                                  Course Fee
                                                </p>
                                                <span className="ReviewLabelsss">
                                                  {batchdetails.act_c_fee || ""}
                                                </span>
                                              </div>
                                            </li>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {batchdetails.panel_details !== undefined &&
                        batchdetails.panel_details !== null &&
                        Object.keys(batchdetails.panel_details).length > 0 &&
                        type === "2" && (
                          <div className="row">
                            <div className="col-md-12 mt-3 mb-3">
                              <div className="card  text-dark">
                                <div className="card-body BatchImage">
                                  <div className="row">
                                    <div className="col-md-4 d-flex align-item-center justify-content-center">
                                      <div className="row">
                                        <div className="col-lg-12 col-md-12 text-center col-sm-12">
                                          <img
                                            src={`${ImageUrlPrefix}/${batchdetails.img_url}`}
                                            // src="https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg"
                                            id={batchdetails.batch_id}
                                            alt="Imge"
                                          />
                                          <p className="ReviewLabels text-center ms-0">
                                            {batchdetails.course_name || ""}
                                            {"-"}
                                            {batchdetails.batch_name}
                                          </p>
                                        </div>
                                        {/* <div className="col-lg-8 col-md-8 col-sm-12 ImageText ">
                                  <span className="ReviewLabels ms-0">
                                    {batchdetails.course_name || ""}
                                  </span>
                                  
                                  <p className="subtitle mb-1 mt-4  ms-5">
                                         Interview Fee
                                      </p>
                                      <div className="row mt-1 d-flex ms-5 flex-column-reverse">
                                        <div className="col-md-4">
                                          <span
                                            class="material-icons"
                                            id="mockinmcurr"
                                          >
                                            currency_rupee
                                          </span>
                                        </div>
                                        <div className="col-md-11 p-0">
                                        <p className="subtitle mt-1 mb-0" >
                                     {batchdetails.panel_details.exam_fees.map((mm)=> mm.fees)}
                                       </p>
       
                                        </div>
                                      </div>
                                </div> */}
                                      </div>
                                    </div>

                                    <div className="col-lg-8 mt-3 mb-2 col-md-10 col-sm-12 col-xl-8">
                                      {batchdetails.panel_details.panel_list.map(
                                        (items, index) => (
                                          <>
                                            <div className="row">
                                              <div className="col-md-12 col-sm-12 ">
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <li className="IconsImage">
                                                      <span className="material-icons me-3">
                                                        format_shapes
                                                      </span>
                                                      <div className="d-flex flex-column">
                                                        <p className="title ms-1">
                                                          Panel Name
                                                        </p>
                                                        <p className="subtitle ms-1">
                                                          {items.panel_name}
                                                        </p>
                                                      </div>
                                                    </li>
                                                  </div>
                                                  <div className="col-md-4">
                                                    <li className="IconsImage">
                                                      <span className="material-icons me-3">
                                                        event
                                                      </span>
                                                      <div className="d-flex flex-column ">
                                                        <p className="title ms-1">
                                                          Date
                                                        </p>
                                                        <p className="subtitle ms-1">
                                                          {moment(
                                                            items.date
                                                          ).format(
                                                            "Do MMM YYYY"
                                                          )}
                                                        </p>
                                                      </div>
                                                    </li>
                                                  </div>
                                                  <div className="col-md-4">
                                                    <li className="IconsImage">
                                                      <span className="material-icons me-3">
                                                        timer
                                                      </span>
                                                      <div className="d-flex flex-column ">
                                                        <p className="title ms-1">
                                                          Time
                                                        </p>
                                                        <p className="subtitle ms-1">
                                                          {items.event_time}
                                                        </p>
                                                      </div>
                                                    </li>
                                                  </div>
                                                </div>
                                              </div>
                                              {index !== 0 && (
                                                <span className="border mt-3 mb-3"></span>
                                              )}
                                            </div>
                                          </>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      {batchdetails.panel_details !== undefined &&
                        batchdetails.panel_details !== null &&
                        Object.keys(batchdetails.panel_details).length > 0 &&
                        type === "5" && (
                          <div className="row">
                            <div className="col-md-12 mt-3 mb-3">
                              <div className="card  text-dark">
                                <div className="card-body BatchImage">
                                  <div className="row">
                                    <div className="col-md-4 d-flex align-item-center">
                                      <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                          <img
                                            src={`${ImageUrlPrefix}/${batchdetails.img_url}`}
                                            // src="https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg"
                                            id={batchdetails.batch_id}
                                            alt="Imge"
                                          />
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-12 ImageText ">
                                          <span className="ReviewLabels ms-4 ms-sm-0">
                                            {batchdetails.course_name || ""}
                                            {"-"}
                                            {batchdetails.batch_name}
                                          </span>

                                          <p className="subtitle mb-1 mt-4  ms-5 ms-sm-0">
                                            Interview Fee
                                          </p>
                                          <div className="row mt-1 d-flex ms-5 ms-sm-0 flex-column-reverse">
                                            {/* <div className="col-md-4">
                                          <span
                                            class="material-icons"
                                            id="mockinmcurr"
                                          >
                                            currency_rupee
                                          </span>
                                        </div> */}
                                            <div className="col-md-11 p-0">
                                              <p className="subtitle mt-1 mb-0">
                                                <span className="ruppe_color">
                                                  ₹
                                                </span>{" "}
                                                {batchdetails.panel_details.exam_fees.map(
                                                  (mm) => mm.fees
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-7 mt-3 mb-2 col-md-10 col-sm-12 col-xl-6">
                                      {batchdetails.panel_details.panel_list.map(
                                        (items, index) => (
                                          <>
                                            <div className="row">
                                              <div className="col-md-12 col-sm-12 ">
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <li className="IconsImage">
                                                      <span className="material-icons me-3">
                                                        format_shapes
                                                      </span>
                                                      <div className="d-flex flex-column">
                                                        <p className="title ms-1">
                                                          Panel Name
                                                        </p>
                                                        <p className="subtitle ms-1">
                                                          {items.panel_name}
                                                        </p>
                                                      </div>
                                                    </li>
                                                  </div>
                                                  <div className="col-md-4">
                                                    <li className="IconsImage">
                                                      <span className="material-icons me-3">
                                                        event
                                                      </span>
                                                      <div className="d-flex flex-column ">
                                                        <p className="title ms-1">
                                                          Date
                                                        </p>
                                                        <p
                                                          className="subtitle ms-1"
                                                          style={{
                                                            width: "auto",
                                                          }}
                                                        >
                                                          {/* {items.available_dates !=null && items.available_dates.length > 0 ? (
  items.available_dates.join(', ') // Join the formatted dates back into a comma-separated string
) : ""} */}
                                                          {items.available_dates !=
                                                          null
                                                            ? items.available_dates
                                                            : "_"}
                                                        </p>
                                                      </div>
                                                    </li>
                                                  </div>
                                                </div>
                                              </div>
                                              {index !== 0 && (
                                                <span className="border mt-3 mb-3"></span>
                                              )}
                                            </div>
                                          </>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      {/* {type === "4" && (
                          <div className="row mt-3 mb-3">
                            <div className="col-md-12">
                              <div className="card  text-dark">
                                <div className="card-body BatchImage">
                                  <div className="row">
                                    <div className="col-md-5 d-flex flex-column align-items-center">
                                      <img
                                        className="mt-3"
                                        src={`${ImageUrlPrefix}/${batchdetails.img_url}`}
                                        id={batchdetails.batch_id}
                                        alt="Imge"
                                      />
                                      <p className="title m-0  mt-3">
                                        {batchdetails.course_name || ""}
                                        {"-"}
                                        {batchdetails.batch_name}
                                      </p>
                                    </div>
                                    <div className="col-lg-7 mt-3 mb-2 col-md-10 col-sm-12 col-xl-6">
                                      {Object.keys(batchdetails.exam_details)
                                        .length > 0 &&
                                        batchdetails.exam_details.exam_details
                                          .length > 0 &&
                                        batchdetails.exam_details.exam_details.map(
                                          (items, index) => (
                                            <>
                                              <div
                                                className="row mt-2 mb-2"
                                                id={
                                                  batchdetails.exam_details
                                                    .exam_details.length !==
                                                  index + 1
                                                    ? "BorderBathc"
                                                    : ""
                                                }
                                              >
                                                <div className="col-lg-11">
                                                  <div className="row">
                                                    <div className="col-md-12 col-sm-12 ">
                                                      <div className="row">
                                                        <div className="col-md-4">
                                                          <li className="IconsImage">
                                                            <span className="material-icons">
                                                              format_shapes
                                                            </span>
                                                            <div className="d-flex flex-column">
                                                              <p className="title ms-1">
                                                                Exam Title
                                                              </p>
                                                              <p className="subtitle ms-1">
                                                                {
                                                                  items.exam_title
                                                                }
                                                              </p>
                                                            </div>
                                                          </li>
                                                        </div>
                                                        <div className="col-md-5">
                                                          <li className="IconsImage">
                                                            <span className="material-icons">
                                                              assignment
                                                            </span>
                                                            <div className="d-flex flex-column ">
                                                              <p className="title ms-1">
                                                                Syllabus
                                                              </p>
                                                              <a
                                                                href={`${ImageUrlPrefix}/${items.syllabus_url}`}
                                                                className="subTet  ms-1"
                                                              >
                                                                Click here to
                                                                view syllabus
                                                              </a>
                                                            </div>
                                                          </li>
                                                        </div>
                                                        <div className="col-md-3">
                                                          <li className="IconsImage">
                                                            <span className="material-icons">
                                                              event
                                                            </span>
                                                            <div className="d-flex flex-column ">
                                                              <p className="title ms-1">
                                                                Date
                                                              </p>
                                                              <p className="subtitle ms-1">
                                                                {moment
                                                                  .utc(
                                                                    items.start_on
                                                                  )
                                                                  .format(
                                                                    "YYYY-MM-DD"
                                                                  )}
                                                              </p>
                                                            </div>
                                                          </li>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-12 col-sm-12">
                                                      <div className="row mt-3">
                                                        <div className="col-md-6">
                                                          <li className="IconsImage">
                                                            <span class="material-icons-outlined">
                                                              timer
                                                            </span>
                                                            <div className="d-flex flex-column">
                                                              <p className="title ms-1">
                                                                Time
                                                              </p>
                                                              <p className="subtitle ms-1">
                                                                {
                                                                  items.batch_time
                                                                }
                                                              </p>
                                                            </div>
                                                          </li>
                                                        </div>
                                                        <div className="col-md-6">
                                                          <li className="IconsImage">
                                                            <span className="material-icons-outlined">
                                                              timer
                                                            </span>
                                                            <div className="d-flex flex-column">
                                                              <p className="title ms-1">
                                                                Duration
                                                              </p>
                                                              <p className="subtitle ms-1">
                                                                {
                                                                  items.duration_hrs
                                                                }{" "}
                                                                Hours
                                                              </p>
                                                            </div>
                                                          </li>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )} */}

                      {type === "4" && (
                        <StudentConfirmType4
                          paper_type={
                            courses.paper_type ? courses.paper_type : 0
                          }
                          batchdetails={batchdetails !== null && batchdetails}
                          type={2} // student approve page --- lalitha changed
                        />
                      )}
                    </div>
                  </div>
                </section>
                <section className="sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">
                        Other Course Relevant Informations
                      </p>
                      <div className="row mt-2">
                        {type === "4" && (
                          <div
                            className={`${
                              courses.hall_ticket === 1
                                ? "col-md-4"
                                : "col-md-12"
                            } `}
                          >
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted  oneline"
                              >
                                Did you receive your hall ticket from the
                                recruitment board?
                              </label>
                              <span className="ReviewLabel mt-2">
                                {courses.hall_ticket === 1 ? "Yes" : "No"}
                              </span>
                            </div>
                          </div>
                        )}
                        {type === "4" && courses.hall_ticket === 1 && (
                          <div className="col-md-5">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Upload Hall Ticket (Issued by the recruitment
                                board)
                                {courses.mainexam_hallticket_filepath && (
                                  <>
                                    <a
                                      target="_blank"
                                      download={`${ImageUrlPrefix}/${courses.mainexam_hallticket_filepath}`}
                                      href={`${ImageUrlPrefix}/${courses.mainexam_hallticket_filepath}`}
                                    >
                                      {" "}
                                      <Download className="download_icon" />
                                    </a>
                                    <a
                                      target="_blank"
                                      href={`${ImageUrlPrefix}/${courses.mainexam_hallticket_filepath}`}
                                    >
                                      {" "}
                                      <Visibility className="download_icon" />
                                    </a>
                                  </>
                                )}
                              </label>
                              <span className="ReviewLabel mt-2">
                                {courses.hall_ticket === 1 &&
                                courses.original_mainexam_hallticket
                                  ? courses.original_mainexam_hallticket
                                  : "_"}
                              </span>
                            </div>
                          </div>
                        )}
                        {type === "4" && courses.hall_ticket === 1 && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Exam Registration Number
                              </label>
                              <span className="ReviewLabel mt-2">
                                {courses.hall_ticket === 1
                                  ? courses.exam_reg_no
                                  : "_"}
                              </span>
                            </div>
                          </div>
                        )}
                        {type !== "4" && type !== "5" && (
                          <div
                            className={
                              courses.competitive_exam === 1
                                ? `col-lg-3 col-md-6`
                                : `col-lg-12 col-md-6`
                            }
                          >
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted  oneline"
                              >
                                Have You Already Studied for Competitive
                                Examination?
                              </label>

                              <div className="register_app_val mb-3">
                                {courses.competitive_exam === 1 ? "Yes" : "No"}
                              </div>
                            </div>
                          </div>
                        )}
                        {type !== "4" &&
                          type !== "5" &&
                          courses.competitive_exam === 1 && (
                            <div className="col-lg-3 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Name of the Examination
                                </label>
                                <div className="register_app_val mb-3">
                                  {courses.competitive_exam_name || ""}
                                </div>
                              </div>
                            </div>
                          )}

                        {type !== "4" &&
                          type !== "5" &&
                          courses.competitive_exam === 1 && (
                            <div className="col-lg-3 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Name of the Institute you studied
                                  before(separated by commas)
                                </label>
                                <div className="register_app_val mb-3">
                                  {courses.institute_name || ""}
                                </div>
                              </div>
                            </div>
                          )}

                        {type !== "4" && (
                          <div
                            className={
                              courses.cleared_any_exam === 1
                                ? `col-lg-3 col-md-6`
                                : `col-lg-12 col-md-6`
                            }
                          >
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted  oneline"
                              >
                                Have You Cleared Any Exam Previously?
                              </label>
                              <div className="register_app_val mb-3">
                                {courses.cleared_any_exam === 1 ? "Yes" : "No"}
                              </div>
                            </div>
                          </div>
                        )}
                        {type !== "4" && courses.cleared_any_exam === 1 && (
                          <div className="col-lg-3 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted  oneline"
                              >
                                How many exams have you cleared in the past?
                              </label>
                              <div className="register_app_val mb-3">
                                {courses.exam_details || ""}
                              </div>
                            </div>
                          </div>
                        )}

                        {type !== "4" && courses.cleared_any_exam === 1 && (
                          <div className="col-lg-3 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted  oneline"
                              >
                                Exam details
                              </label>
                              <div className="register_app_val mb-3">
                                {courses.exam_title || ""}
                              </div>
                            </div>
                          </div>
                        )}

                        {type !== "4" && (
                          <div
                            className={
                              courses.govt_emp === 1
                                ? `col-lg-3 col-md-6`
                                : `col-lg-12 col-md-6`
                            }
                          >
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted  oneline"
                              >
                                Are you a government employee?
                              </label>
                              <div className="register_app_val mb-3">
                                {courses.govt_emp === 1 ? "Yes" : "No"}
                              </div>
                            </div>
                          </div>
                        )}

                        {type !== "4" && courses.govt_emp === 1 && (
                          <div className="col-lg-3 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Designation
                              </label>
                              <div className="register_app_val mb-3">
                                {courses.designation || ""}
                              </div>
                            </div>
                          </div>
                        )}

                        {type !== "4" && courses.govt_emp === 1 && (
                          <div className="col-lg-3 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Department
                              </label>
                              <div className="register_app_val mb-3">
                                {courses.department || ""}
                              </div>
                            </div>
                          </div>
                        )}

                        {type !== "4" && courses.govt_emp === 1 && (
                          <div className="col-lg-3 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Joining Date of Government Job
                              </label>
                              <div className="register_app_val mb-3">
                                {courses.govt_emp === 1
                                  ? new Date(courses.govt_join_dt)
                                      .toLocaleDateString("en-GB", date_format)
                                      .split("/")
                                      .join("-")
                                  : ""}
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="col-lg-6 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted  oneline"
                            >
                              Are You an AIASA Student?
                            </label>
                            <div className="register_app_val mb-3">
                              {courses.aiasa_student === 1 ? "Yes" : "No"}
                            </div>
                          </div>
                        </div>
                        {courses.aiasa_student === 1 && (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Year
                                </label>
                                <div className="register_app_val mb-3">
                                  {courses.aiasa_year || ""}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  category
                                </label>
                                <div className="register_app_val mb-3">
                                  {courses.aiasa_category || ""}
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Course
                                </label>
                                <div className="register_app_val mb-3">
                                  {courses.aiasa_student === 1
                                    ? courses.aiasa_course
                                    : ""}
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  ID Number
                                </label>
                                <div className="register_app_val mb-3">
                                  {courses.aiasa_id_number || ""}
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted  oneline"
                                >
                                  Upload ID Card
                                  {courses.aiasa_id_path && (
                                    <Link
                                      to={`${ImageUrlPrefix}/${courses.aiasa_id_path}`}
                                      target="_blank"
                                    >
                                      {" "}
                                      <Download className="download_icon" />
                                    </Link>
                                  )}
                                </label>
                                <div className="register_app_val mb-3">
                                  {courses.aiasa_id_path !== null
                                    ? courses.original_aiasa_id_path
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Admission Details</p>
                      <div class="form-outline">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Admission ID
                              </label>
                              <div className="register_app_val mb-3">
                                {admm.Admission_id || ""}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Applied On
                              </label>
                              <div className="register_app_val mb-3">
                                {appliedOn || ""}
                              </div>
                            </div>
                          </div>
                          {type !== "4" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Paid Amount
                                </label>
                                <div className="register_app_val mb-3">
                                  <span class="current_rip align-middle">
                                    ₹
                                  </span>{" "}
                                  {initialfields.PaidAmount || ""}
                                </div>
                              </div>
                            </div>
                          )}

                          {/* <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Balance Amount
                                </label>
                                <div className="register_app_val mb-3">
                                  {initialfields.Balance_amt || "-"}
                                </div>
                              </div>
                            </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {type !== "4" && (
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Payment Option</p>
                        <div class="form-outline">
                          <div className="row">
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Payment Option
                                </label>
                                <div className="register_app_val mb-3">
                                  {initialfields.PPayment === 1
                                    ? "Partial Payment"
                                    : "Full Payment"}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Fees Paid
                                </label>
                                <div className="register_app_val mb-3">
                                  <span class="current_rip align-middle">
                                    ₹
                                  </span>{" "}
                                  {initialfields.PaidAmount}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Balance Fee
                                </label>
                                <div className="register_app_val mb-3">
                                  <span class="current_rip align-middle">
                                    ₹
                                  </span>{" "}
                                  {initialfields.Balance_amt
                                    ? initialfields.Balance_amt
                                    : 0}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {initialfields.PPayment === 1 && type !== "4" && (
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          Partial Payment Schedule Details
                        </p>
                        <div class="form-outline">
                          <div className="row">
                            <div className="col-md-6 col-lg-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  2nd Payment Date (On or Before)
                                </label>
                                <div className="register_app_val ">
                                  {batchdetails.pay_2_duedate
                                    ? moment(
                                        batchdetails.pay_2_duedate,
                                        "DD/MM/YYYY",
                                        true
                                      ).isValid()
                                      ? moment(
                                          batchdetails.pay_2_duedate,
                                          "DD/MM/YYYY"
                                        ).format("YYYY-MM-DD")
                                      : moment(
                                          batchdetails.pay_2_duedate
                                        ).format("YYYY-MM-DD")
                                    : ""}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  2nd Payment Amount{" "}
                                  {batchdetails.pay_2_perc
                                    ? `( ${batchdetails.pay_2_perc}% of Final Course Fee)`
                                    : ""}
                                </label>
                                <div className="register_app_val  mt-sm-4 mt-md-0 ">
                                  <span class="current_rip align-middle">
                                    ₹
                                  </span>
                                  {(
                                    initialfields.total_amount *
                                    (batchdetails.pay_2_perc / 100)
                                  ).toFixed(2)}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  3rd Payment Date (On or Before)
                                </label>
                                <div className="register_app_val mb-3">
                                  {batchdetails.pay_3_duedate
                                    ? moment(
                                        batchdetails.pay_3_duedate,
                                        "DD/MM/YYYY",
                                        true
                                      ).isValid()
                                      ? moment(
                                          batchdetails.pay_3_duedate,
                                          "DD/MM/YYYY"
                                        ).format("YYYY-MM-DD")
                                      : moment(
                                          batchdetails.pay_3_duedate
                                        ).format("YYYY-MM-DD")
                                    : ""}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 col-lg-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  3rd Payment Amount{" "}
                                  {batchdetails.pay_3_perc
                                    ? initialfields.old_stud_amount_applied !==
                                      0
                                      ? `( ${batchdetails.pay_3_perc} % of Final Course Fee - Old Student Discount)`
                                      : `(${batchdetails.pay_3_perc}% of Final Course Fee)`
                                    : ""}
                                </label>
                                <div className="register_app_val  mt-sm-4 mt-md-0  mb-3">
                                  <span class="current_rip align-middle">
                                    ₹
                                  </span>
                                  {initialfields.old_stud_amount_applied !== 0
                                    ? (
                                        initialfields.total_amount *
                                          (batchdetails.pay_3_perc / 100) -
                                        initialfields.old_stud_amount
                                      ).toFixed(2)
                                    : (
                                        initialfields.total_amount *
                                        (batchdetails.pay_3_perc / 100)
                                      ).toFixed(2)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* </div> */}
                  {admm.status != 1 && (
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Review by Admin</p>
                        <div class="form-outline">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="forms ">
                                <label for="status">Application Status:</label>
                                <div className="app_status">
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="checkbox_radio"
                                      type="radio"
                                      name="status"
                                      id="Approve"
                                      value="1"
                                      onChange={handleChange}
                                      checked={
                                        initialfields.status === "1" ||
                                        initialfields.status === 1
                                      }
                                    />
                                    <label
                                      class="form-check-label app_status align-baseline"
                                      for="Approve"
                                    >
                                      Approve
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="checkbox_radio"
                                      type="radio"
                                      name="status"
                                      id="Hold"
                                      value="2"
                                      checked={
                                        initialfields.status === "2" ||
                                        initialfields.status === 2
                                      }
                                      onChange={handleChange}
                                    />
                                    <label
                                      class="form-check-label app_status align-baseline"
                                      for="Hold"
                                    >
                                      Hold
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="checkbox_radio"
                                      type="radio"
                                      name="status"
                                      id="Reject"
                                      value="3"
                                      checked={
                                        initialfields.status === "3" ||
                                        initialfields.status === 3
                                      }
                                      onChange={handleChange}
                                    />
                                    <label
                                      class="form-check-label app_status align-baseline"
                                      for="Reject"
                                    >
                                      Reject
                                    </label>
                                  </div>
                                  <Error>
                                    {error.status && (
                                      <small className="error ms-4">
                                        {error.status}
                                      </small>
                                    )}
                                  </Error>
                                </div>
                              </div>
                            </div>
                            {((initialfields.status === "1" && type !== "4") ||
                              initialfields.status === 1) && (
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label htmlFor="Section">
                                    {type === "5" || type === "2"
                                      ? "Panel"
                                      : "Section"}
                                  </label>
                                  <select
                                    id="Section"
                                    name="Section"
                                    className="Inputs"
                                    onChange={handleChange}
                                    defaultValue={initialfields.Section}
                                  >
                                    <option value="" selected>
                                      Please select{" "}
                                      {type === "5" || type === "2"
                                        ? "Panel"
                                        : "Section"}
                                    </option>
                                    {section}
                                  </select>
                                  <Error>
                                    {error.Section && (
                                      <small className="error ms-4">
                                        {error.Section}
                                      </small>
                                    )}
                                  </Error>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="row ">
                            {/* {type === "4" && (
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label htmlFor="Section">Exam Center</label>
                                  <select
                                    id="exam_center"
                                    name="exam_center"
                                    className="Inputs "
                                    onChange={handleChange}
                                    value={initialfields.exam_center}
                                  >
                                    <option value="" disabled selected>
                                      Please select exam center
                                    </option>
                                    {examCenterMExam}
                                  </select>
                                  <Error>
                                    {error.exam_center && (
                                      <small className="error ms-4">
                                        {error.exam_center}
                                      </small>
                                    )}
                                  </Error>
                                </div>
                              </div>
                            )} */}
                            <div className="col-md-6">
                              <div className="forms ">
                                <label for="email_sent">
                                  Mail Application Form to Student :
                                </label>
                                <div className="app_status">
                                  <div
                                    className="form-check mt-3  form-check-inline"
                                    id="email_sent"
                                  >
                                    <input
                                      className="checkbox_radio"
                                      type="radio"
                                      id="email_sent1"
                                      disabled={
                                        initialfields.status === "1"
                                          ? false
                                          : true
                                      }
                                      name="email_sent"
                                      onChange={handleChange}
                                      checked={
                                        initialfields.email_sent === "1"
                                          ? true
                                          : false
                                      }
                                      value="1"
                                    />
                                    <label
                                      className="form-check-label align-baseline mt-1"
                                      htmlFor="email_sent1"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div
                                    className="form-check mt-3 ms-2 form-check-inline"
                                    id="email_sent"
                                  >
                                    <input
                                      className="checkbox_radio"
                                      type="radio"
                                      id="email_sent2"
                                      disabled={
                                        initialfields.status === "1"
                                          ? false
                                          : true
                                      }
                                      name="email_sent"
                                      onChange={handleChange}
                                      checked={
                                        initialfields.email_sent === "0"
                                          ? true
                                          : false
                                      }
                                      value="0"
                                    />
                                    <label
                                      className="form-check-label align-baseline mt-1"
                                      htmlFor="email_sent2"
                                    >
                                      No
                                    </label>
                                  </div>
                                  <Error>
                                    {error.email_sent && (
                                      <small className="error ms-4">
                                        {error.email_sent}
                                      </small>
                                    )}
                                  </Error>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="forms ">
                                <label for="internal_comment">
                                  Admin Remarks:
                                </label>
                                <textarea
                                  class="Inputs"
                                  id="internal_comment"
                                  rows="4"
                                  defaultValue={initialfields.internal_comment}
                                  name="internal_comment"
                                  placeholder="Admin Remarks"
                                  onChange={handleChange}
                                ></textarea>
                                <Error>
                                  {error.internal_comment && (
                                    <small className="error ms-4">
                                      {error.internal_comment}
                                    </small>
                                  )}
                                </Error>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {admm.status === 1 && (
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Review by Admin</p>
                        <div class="form-outline">
                          <div className="row ">
                            <div className="col-md-6">
                              <div className="forms ">
                                <label for="status">Application Status:</label>
                                <div className="register_app_val mb-2 mt-2">
                                  {admm.status == 1 && "Approved"}
                                  {admm.status == 2 && "Hold"}
                                  {admm.status == 3 && "Rejected"}
                                </div>
                              </div>
                            </div>
                            {(admm.status === "1" || admm.status === 1) &&
                              type !== "4" && (
                                <div className="col-md-6">
                                  <div className="forms ">
                                    <label htmlFor="Section">
                                      {type === "5" || type === "2"
                                        ? "Panel"
                                        : "Section"}
                                    </label>
                                    <div className="register_app_val  mt-3">
                                      {admm.Section}
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-6">
                              <div className="forms ">
                                <label for="email_sent">
                                  Mail Application Form to Student :
                                </label>
                                <div className="register_app_val mb-2 mt-2">
                                  {admm.email_sent == 0 ? "Not sent" : "Sent"}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="forms ">
                                <label for="internal_comment">
                                  Admin Remarks:
                                </label>
                                <div className="register_app_val mb-2 mt-2">
                                  {admm.internal_comment}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {admm.status != 1 && (
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Student Communication</p>

                        <div class="form-outline">
                          <div className="row mb-2 mt-3">
                            {commentSec.length > 0 ? (
                              commentSec.map((el) => (
                                <div className="col-md-12">
                                  <div className="forms ">
                                    <label
                                      for="internal_comment"
                                      className="mt-1"
                                    >
                                      {el.user_type == 0 && "Comment by Admin"}{" "}
                                      {el.user_type == 1 && "Reply By Student"}{" "}
                                      {el.updated_dt != null &&
                                        `- ${moment
                                          .utc(el.updated_dt)
                                          .format("DD/MM/YYYY hh:mm:ss A")}`}
                                    </label>
                                    <div className="register_app_val mb-2 mt-2">
                                      {el.comment != null || el.comment != ""
                                        ? el.comment
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="col-md-12">
                                <div className="forms ">
                                  <label
                                    for="internal_comment"
                                    className="mt-1"
                                  >
                                    Comment
                                  </label>
                                  <div className="register_app_val mb-2 mt-2">
                                    None
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="col-md-6">
                              <div className="forms ">
                                <label for="feedback">Comment by Admin</label>
                                <textarea
                                  class="Inputs"
                                  id="feedback"
                                  rows="4"
                                  name="feedback"
                                  defaultValue={initialfields.feedback}
                                  placeholder="Comment by Admin"
                                  onChange={handleChange}
                                ></textarea>
                                <Error>
                                  {error.feedback && (
                                    <small className="error ms-4">
                                      {error.feedback}
                                    </small>
                                  )}
                                </Error>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {admm.status === 1 && (
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Student Communication</p>
                        <div class="form-outline">
                          <div className="row ">
                            {commentSec.length > 0 ? (
                              commentSec.map((el) => (
                                <div className="col-md-12">
                                  <div className="forms ">
                                    <label for="internal_comment">
                                      {el.user_type == 0 && "Comment by Admin"}{" "}
                                      {el.user_type == 1 && "Reply By Student"}{" "}
                                      {el.updated_dt != null &&
                                        `- ${moment(el.updated_dt).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}`}
                                    </label>
                                    <div className="register_app_val mb-2 mt-2">
                                      {el.comment != null || el.comment != ""
                                        ? el.comment
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="col-md-12">
                                <div className="forms ">
                                  <label for="internal_comment">Comment</label>
                                  <div className="register_app_val mb-2 mt-2">
                                    None
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {admm.status != 1 && (
                    <div className="heading ms-lg-4 me-md-4 mb-4 mt_mobile d-flex justify-content-between ">
                      <div className="ms-lg-4 mt-4"></div>
                      <div className="d-flex smButton  mt-4">
                        {type === "0" && (
                          <Link
                            onClick={() =>
                              handleBackFilter("/ListViewStudentConfirm/0")
                            }
                            to="javascript:void(0)"
                            className="CancelButton me-2"
                          >
                            Back to Admission
                          </Link>
                        )}
                        {type === "1" && (
                          <Link
                            onClick={() =>
                              handleBackFilter("/ListViewStudentConfirm/1")
                            }
                            to="javascript:void(0)"
                            className="CancelButton me-2"
                          >
                            Back to Admission
                          </Link>
                        )}
                        {type === "2" && (
                          <Link
                            onClick={() =>
                              handleBackFilter("/ListViewStudentConfirm/2")
                            }
                            to="javascript:void(0)"
                            className="CancelButton me-2"
                          >
                            Back to Admission
                          </Link>
                        )}
                        {type === "3" && (
                          <Link
                            onClick={() =>
                              handleBackFilter("/ListViewStudentConfirm/3")
                            }
                            to="javascript:void(0)"
                            className="CancelButton me-2"
                          >
                            Back to Admission
                          </Link>
                        )}
                        {type === "4" && (
                          <Link
                            onClick={() =>
                              handleBackFilter("/ListViewStudentConfirm/4")
                            }
                            to="javascript:void(0)"
                            className="CancelButton me-2"
                          >
                            Back to Admission
                          </Link>
                        )}
                        {type === "5" && (
                          <Link
                            onClick={() =>
                              handleBackFilter("/ListViewStudentConfirm/5")
                            }
                            to="javascript:void(0)"
                            className="CancelButton me-2"
                          >
                            Back to Admission
                          </Link>
                        )}

                        <button
                          type="submit"
                          className="listviewSaveButton "
                          onClick={handleSubmit}
                        >
                          <a href="javascript:void(0)">Update and submit</a>
                        </button>
                      </div>
                    </div>
                  )}{" "}
                </section>
              </>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default StudentConfirm;
