import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix } from "../../../../labels";
import Swal from "sweetalert2";
import IconDownload from "@mui/icons-material/FileDownload";
export default function DSchedule() {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location);
  const { id, types, coures_name, category_course_id, subcate } =
    location.state;
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [id]);

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  useEffect(() => {
    // console.log(id,"id");
    AxiosInstance.post("api/event/findById", {
      id: id,
      staff_id: "",
      // "event_type": (types === "1" || types === 1) ? 1 : 0
      event_type: "",
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          let el = res.data.data;
          setFdata((prevFdata) => ({
            ...prevFdata,
            title: el.name,
            sDate:
              el.start_date !== null
                ? moment(el.start_date).format("YYYY-MM-DD")
                : "",
            startTime: el.start_time,
            endTime: el.end_time,
            subject: el.subject,
            staff_name: el.staff_name,
            description: el.description,
            batchName: el.batch_name,
            category: el.category,
            course: el.category_class,
            classMode: el.class_mode,
            medium: el.medium,
            location: el.location,
            teams_url: el.teams_url,
            cut_off: el.cut_off,
            batch_id: el.batch_id,
            event_id: el.id,
            test_num: el.test_no,
            test_topic: el.test_topic,
            tol_mark: el.tot_marks,
            no_ques: el.no_of_questions,
            syllabus_path: el.syllabus_filepath,
            staff_dec: el.staff_description,
            method_of_test: el.method_of_test,
          }));
          const subTopics =
            el.subject_test_topics && el.subject_test_topics.length > 0
              ? el.subject_test_topics
              : [];

          setSubTopic(subTopics);

          const OtherApplicableBatches =
            el.oth_app_batch && el.oth_app_batch.length > 0
              ? el.oth_app_batch
              : [];

          setOth_app_batch(OtherApplicableBatches);
        }
      })
      .catch((error) => {
        return error;
      });
  }, []);

  const [fdata, setFdata] = useState({
    title: "",
    sDate: "",
    startTime: "",
    endTime: "",
    subject: "",
    staff_name: "",
    description: "",
    batchName: "",
    category: "",
    course: "",
    classMode: "",
    medium: "",
    location: "",
    teams_url: "",
    batch_id: "",
    event_id: "",
    test_num: "",
    test_topic: "",
    tol_mark: "",
    no_ques: "",
    syllabus_path: null,
    staff_dec: "",
    method_of_test: "",
    cut_off: null,
  });

  const [subTopic, setSubTopic] = useState([]);

  const [oth_app_batch, setOth_app_batch] = useState([]);

  const handleMeeting = () => {
    window.open(fdata.teams_url, "_blank");
  };

  const handleQuiz = () => {
    navigate("/TestPermeance");
  };

  const handleEdit = () => {
    const sDate = fdata.sDate;
    const batch_id = fdata.batch_id;
    const category = fdata.category;
    const startTime = fdata.startTime;
    const endTime = fdata.endTime;
    const type = types;
    // console.log(types);
    navigate(`/CreateEvent`, {
      state: {
        id,
        sDate,
        batch_id,
        category,
        startTime,
        endTime,
        type,
        coures_name,
        category_course_id,
        subcate,
      },
    });
    // navigate(`/CreateEvent/${id}/${fdata.sDate}/${fdata.batch_id}/${fdata.category}/${fdata.startTime}/${fdata.endTime}`)
  };
  const navigateToPreviousScreen = () => {
    navigate(-2); // Navigate back by one step in the history
  };
  const handleDownloadSyllabus = (path) => {
    window.open(`${ImageUrlPrefix}/${path}`);
  };
  return (
    <div className=" BathDet">
      <div className="row">
        <div className="col-md-12">
          <section className=" StepOne">
            <div className="heading  mt-3 d-flex justify-content-between ">
              <h6 className={types != "1" ? "Tits" : "Titss"}>
                {" "}
                {types != "1" ? "View Event Details" : "View Test "}{" "}
              </h6>
              <Link
                to="javascript:void(0)"
                onClick={() => navigateToPreviousScreen()}
                className="CancelButton mt-2"
              >
                Back to Batches
              </Link>
              <button
                type="button"
                className="EditEventButton"
                onClick={handleEdit}
              >
                {" "}
                <span class="material-icons ">edit</span>
                <a href="javascript:void(0)">Edit Event</a>
              </button>
              {types != "1" && (
                <button
                  type="button"
                  className="EditEventButtons"
                  onClick={fdata.teams_url !== null ? handleMeeting : ""}
                  disabled={fdata.teams_url !== null ? false : true}
                >
                  {" "}
                  <span class="material-icons me-2">event_available</span>{" "}
                  <a href="javascript:void(0)">
                    {types === "1" ? "View Test" : "Join Event"}
                  </a>
                </button>
              )}
            </div>
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Event Details</p>
                <div className="row mb-3">
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Event Name
                      </label>
                      <span className="ReviewLabel">
                        {fdata.title !== null ? fdata.title : "⎯"}
                      </span>
                    </div>
                  </div>
                  {/* {types === "1" && (
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Test Number
                        </label>
                        <span className="ReviewLabel">
                          {fdata.test_num !== null ? fdata.test_num : "⎯"}
                        </span>
                      </div>
                    </div>
                  )} */}
                  {types === "1" && (
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Test Topic
                        </label>
                        <span className="ReviewLabel">
                          {fdata.test_topic !== null ? fdata.test_topic : "⎯"}
                        </span>
                      </div>
                    </div>
                  )}
                  {types === "1" && (
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Total Number of Questions
                        </label>
                        <span className="ReviewLabel">
                          {fdata.no_ques !== null ? fdata.no_ques : "⎯"}
                        </span>
                      </div>
                    </div>
                  )}
                  {types === "1" && (
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Total Marks
                        </label>
                        <span className="ReviewLabel">
                          {fdata.tol_mark !== null ? fdata.tol_mark : "⎯"}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Batch Name
                      </label>
                      <span className="ReviewLabel">
                        {fdata.batchName !== null ? fdata.batchName : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Category
                      </label>
                      <span className="ReviewLabel">
                        {fdata.category !== null ? fdata.category : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Course
                      </label>
                      <span className="ReviewLabel">
                        {fdata.course !== null ? fdata.course : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Class Mode
                      </label>
                      <span className="ReviewLabel">
                        {fdata.classMode !== null ? fdata.classMode : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Medium
                      </label>
                      <span className="ReviewLabel">
                        {fdata.medium !== null ? fdata.medium : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted"
                      >
                        Location
                      </label>
                      <span className="ReviewLabel">
                        {fdata.location !== null && fdata.location !== ""
                          ? fdata.location
                          : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Date
                      </label>
                      <span className="ReviewLabel">
                        {fdata.sDate !== null ? fdata.sDate : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Start Timing
                      </label>
                      <span className="ReviewLabel">
                        {fdata.startTime !== null
                          ? moment(fdata.startTime, "HH:mm:ss").format("h:mm A")
                          : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        End Timing
                      </label>
                      <span className="ReviewLabel">
                        {fdata.endTime !== null
                          ? moment(fdata.endTime, "HH:mm:ss").format("h:mm A")
                          : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Method of Test
                      </label>
                      <span className="ReviewLabel">
                        {fdata.method_of_test !== null
                          ? fdata.method_of_test === 1
                            ? "Descriptive"
                            : "Objective"
                          : "⎯"}
                      </span>
                    </div>
                  </div>
                  {types != "1" && (
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Faculty Name
                        </label>
                        <span className="ReviewLabel">
                          {fdata.staff_name !== null ? fdata.staff_name : "⎯"}
                        </span>
                      </div>
                    </div>
                  )}{" "}
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Student Description
                      </label>
                      <span className="ReviewLabel">
                        {fdata.description !== null ? fdata.description : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Faculty Description
                      </label>
                      <span className="ReviewLabel">
                        {fdata.staff_dec !== null && fdata.staff_dec !== ""
                          ? fdata.staff_dec
                          : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Syllabus Path
                      </label>
                      <span className="ReviewLabel">
                        <a
                          className="text-danger"
                          target="_blank"
                          href={`${ImageUrlPrefix}/${fdata.syllabus_path}`}
                        >
                          {fdata.syllabus_path !== null
                            ? fdata.syllabus_path.split("/").pop()
                            : "_"}
                        </a>
                      </span>
                    </div>
                  </div>
                  {types == "1" && (
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Cut Off
                        </label>
                        <span className="ReviewLabel">
                          {fdata.cut_off !== null ? fdata.cut_off : "⎯"}
                        </span>
                      </div>
                    </div>
                  )}{" "}
                </div>
              </div>
            </div>
          </section>
          {types === 1 && (
            <section className=" StepOne">
              <div className="row box">
                <div className="col-md-12 mb-3">
                  <p className="StepTitle">Subjects and Topics</p>
                  <div className="row ">
                    <div className="col-md-3">
                      <div className="forms ">
                        <button
                          style={{
                            border: "none",
                            background: "none",
                            padding: "0px",
                            margin: "0px",
                            textAlign: "start",
                          }}
                          onClick={() =>
                            handleDownloadSyllabus(fdata.syllabus_path)
                          }
                        >
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                            style={{ cursor: "pointer" }}
                          >
                            Uploaded Syllabus{" "}
                            <IconDownload
                              style={{
                                color: "#C0272D",
                                marginLeft: "5px",
                              }}
                            />
                          </label>
                        </button>
                        <span className="ReviewLabel">
                          {" "}
                          {fdata.syllabus_path
                            ? fdata.syllabus_path.split("/").pop()
                            : "_"}
                        </span>
                      </div>
                    </div>
                  </div>
                  {subTopic.length > 0 ? (
                    subTopic.map((onk) => {
                      return (
                        <div className="row ">
                          <div className="col-md-4">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Subject
                              </label>
                              <span className="ReviewLabel">
                                {onk.subject_name}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Test Topics
                              </label>
                              <span className="ReviewLabel">
                                {onk.test_topics
                                  .map((el) => el.test_topic)
                                  .join(", ")}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="row ">
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            {" "}
                            _
                          </label>
                          <span className="ReviewLabel">No Records</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          )}

          {types === 1 && (
            <section className=" StepOne">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Other Applicable Batches</p>
                  <div className="row mb-3 ">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted"
                        >
                          Other Applicable Batches
                        </label>
                        {oth_app_batch.length > 0 ? (
                          <span className="ReviewLabel">
                            {oth_app_batch.map((lk, index) => (
                              <React.Fragment key={index}>
                                {index > 0 && ", "}{" "}
                                {/* Add comma and space for elements after the first one */}
                                {lk.name}({lk.section})
                              </React.Fragment>
                            ))}
                          </span>
                        ) : (
                          <span className="ReviewLabel">_</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
}
