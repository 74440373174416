import React, { useState, useEffect, useRef } from "react";
// import MyCalendar from "../CourseBatch/CalendarView";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import ICON_WARNING from "@mui/icons-material/Warning";
import styled from "styled-components";
import Swal from "sweetalert2";
import IconDownload from "@mui/icons-material/FileDownload";
import Select, { components } from "react-select";
import ViewCalendar from "../Purchase/Calendar";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix } from "../../../../labels";
import { ALLOWED_MINUTES, MEDIA_SIZE_LG } from "../../../../utils/helper";
import ModelComForP from "../../../../pages/ModelComForP";
import StartTest from "./components/StartTest";
import ViewTestAlert from "./components/ViewTestAlert";
import toast from "react-hot-toast";
import RtypeOne from "./RtypeOne";
import RtypeTwo from "./RtypeTwo";
import RtypeThree from "./RtypeThree";
import RtypeFour from "./RtypeFour";
import RtypeFive from "./RtypeFive";
import RtypeTen from "./RtypeTen";
import RtypeNine from "./RtypeNine";
import RtypeEight from "./RtypeEight";
import RtypeSeven from "./RtypeSeven";
import RtypeSix from "./RtypeSix";
import Rtypepara from "./Rtypepara";
import RtypeParaOption from "./RtypeParaOption";
import ExplanationQuery from "../Faculty/StudentsQuery/ExplanationQuery";
import QuerySection from "../Faculty/StudentsQuery/QuerySection";
import { customStyles } from "../../../../pages/customStyles";

import { Dialog, DialogContentText, DialogTitle } from "@mui/material";
import axios from "axios";
import { Modal } from "react-bootstrap";
import ModalDialogContent from "../../../../ui/ModalDialogContent";
import ModalDialogActions from "../../../../ui/ModalDialogActions";
import NavigationButton from "../../../../ui/NavigationButton";
import { TailSpin } from "react-loader-spinner";
import Loader from "../../Loader";

// warning-section
const WarningSectionContainer = styled.section`
  background-color: var(--color-brand-200);
  color: var(--color-brand-800);
  padding: 15px;
  margin-top: 10px;
  border: 1px solid var(--color-brand-800);
  border-radius: var(--border-radius-md);
  margin-bottom: 20px;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    padding: 30px;
    margin-bottom: 30px;
  }
`;

const WarningSectionPara = styled.p`
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
`;

export default function ViewTestDetail() {
  const { event_id, batch_id, status, event_type, type_batch } = useParams();
  const currentDate = moment();
  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [event_id]);

  const [updateStatus, setUpdateStatus] = useState(status);
  const [subTopic, setSubTopic] = useState([]);
  // console.log(
  //   subTopic.map((el) => {
  //     return el;
  //   }),
  //   "issues"
  // );
  const [swalMsg, setSwalMsg] = useState({
    show: false,
    status: "",
    message: "",
    title: "",
    BottomOkBtn: "",
    BottomCancelBtn: "",
    BottomOkClose: "",
  });

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot available in session storage");
  }

  useEffect(() => {
    setIsLoading(true);
    AxiosInstance.post("api/test/view/byUser", {
      event_id: parseInt(event_id),
      batch_id: parseInt(batch_id),
      user_id: userid,
    })
      .then((res) => {
        if (res.data.status === true) {
          setIsLoading(false);
          let elss = res.data.data;
          setFdata((prevFdata) => ({
            ...prevFdata,
            title: elss.event_name,
            sDate:
              elss.start_date !== null
                ? // ? moment(elss.start_date).format("DD/MM/YYYY")
                  moment(elss.start_date).format("YYYY/MM/DD")
                : "",
            startTime: elss.start_time,
            endTime: elss.end_time,
            methodOfTest: elss.method_of_test,
            descriptionForStudent: elss.description,
            syllabusFile: elss.syllabus_filepath,
            testType: elss.test_type_name,
            subject: elss.subject,
            ans_sheet_download_req: elss.ans_sheet_download_req,
            obtained_marks: elss.marks_obtained,
            rank: elss.rank ? elss.rank : "",
            total_answer: elss.no_of_answered,
            teams_url: elss.teams_url ? elss.teams_url : null,
            marks_obtained: elss.marks_obtained,
            batch_id: elss.batch_id,
            event_id: elss.event_pk,
            test_num: elss.test_no,
            test_topic: elss.test_topic,
            tol_mark: elss.tot_marks,
            no_ques: elss.no_of_questions,
            resultStatus:
              elss.marks_obtained !== null && elss.publish_results === 1
                ? true
                : false,
            subjectList: elss.sub_tot_marks,
            classRank: elss.classrank,
            overallRank: elss.overallrank,
            attemptScore2: elss.attempt_2_score,
            attempScore3: elss.attempt_3_score,
            attendenceStatus: elss.attendance_status,
            attempt_no_status: elss.attempt_no_status,
          }));
          const subTopics =
            elss.subjects && elss.subjects.length > 0 ? elss.subjects : [];
          setSubTopic(subTopics);
          const query_io = elss.query.length > 0 ? elss.query : [];
          setQueries(query_io);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        return error;
      });
  }, []);

  const [fdata, setFdata] = useState({
    title: "",
    sDate: "",
    methodOfTest: "",
    testType: "",
    descriptionForStudent: "",
    syllabusFile: "",
    startTime: "",
    endTime: "",
    subject: "",
    obtained_marks: "",
    rank: "",
    total_answer: "",
    teams_url: "",
    batch_id: "",
    event_id: "",
    test_num: "",
    test_topic: "",
    marks_obtained: "",
    tol_mark: "",
    no_ques: "",
    resultStatus: false,
    subjectList: [],
    classRank: "",
    overallRank: "",
    attemptScore2: "",
    attempScore3: "",
    attendenceStatus: "",
    ans_sheet_download_req: "",
  });

  const [queries, setQueries] = useState([]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const currentTime = moment();
  const startTime = moment(fdata.startTime, "HH:mm:ss");
  const STARTTIME = new Date(fdata.startTime);
  const calculatedEndTime = new Date(
    STARTTIME.getFullYear(),
    STARTTIME.getMonth(),
    STARTTIME.getDate(),
    STARTTIME.getMinutes() + ALLOWED_MINUTES,
    0
  );
  const endTime = moment(fdata.endTime, "HH:mm:ss");

  const [canJoinTest, setCanJoinTest] = useState(false);
  const userTime = useRef(new Date());

  useEffect(() => {
    const startTime = moment(fdata.startTime, "HH:mm:ss");
    const endTime = moment(fdata.endTime, "HH:mm:ss");
    if (
      moment(fdata.sDate).isAfter(currentDate, "day") ||
      (moment(currentDate).isSame(fdata.sDate, "day") &&
        moment(startTime, "HH:mm:ss").isAfter(currentDate, "minute") &&
        moment(endTime, "HH:mm:ss").isAfter(currentDate, "minute"))
    ) {
      // Event is in the future
      setUpdateStatus("2");
      // navigate(`/ViewTestDetail/${props.batch_id}/${id}/2/${event_type}`);
    } else if (
      (moment(currentDate).isSame(fdata.sDate, "day") &&
        moment(startTime, "HH:mm:ss").isSame(currentDate, "minute")) ||
      (moment(currentDate).isSame(fdata.sDate, "day") &&
        moment(startTime, "HH:mm:ss").isBefore(currentDate, "minute") &&
        moment(endTime, "HH:mm:ss").isAfter(currentDate, "minute"))
    ) {
      // console.log(endTime,"sds")
      // Event is ongoing
      setUpdateStatus("1");
      // navigate(`/ViewTestDetail/${props.batch_id}/${id}/1/${event_type}`);
    } else if (
      (moment(currentDate).isSame(fdata.sDate, "day") &&
        moment(endTime, "HH:mm:ss").isSame(currentDate, "minute")) ||
      (moment(currentDate).isSame(fdata.sDate, "day") &&
        moment(startTime, "HH:mm:ss").isBefore(currentDate, "minute") &&
        moment(endTime, "HH:mm:ss").isAfter(currentDate, "minute"))
    ) {
      // console.log(endTime,"sdsdf")
      // Event is in the past
      setUpdateStatus("0");
    } else if (
      moment(fdata.sDate).isBefore(currentDate, "day") ||
      (moment(currentDate).isSame(fdata.sDate, "day") &&
        moment(endTime, "HH:mm:ss").isBefore(currentDate, "minute"))
    ) {
      // console.log(endTime,"nmnm")
      // Event is in the past
      setUpdateStatus("0");
    } else {
      setUpdateStatus("0");
    }
  }, [startTime, endTime]);

  const handleReviewSubmission = () => {
    navigate(`/ReviewSubmission/${batch_id}/${event_id}`);
  };

  const handleDownloadAnswerSheet = () => {
    const formData = {
      user_id: userid,

      batch_id,

      event_id,
    };
    AxiosInstance.post("api/test/answer/download", formData)
      .then((res) => {
        if (res.data.status) {
          const { path } = res.data.data;
          if (path) window.location(`${path}`);
        } else {
          throw new Error("download answer sheet pdf error");
        }
      })
      .catch((err) => console.error(err));
  };

  const handleQuiz = () => {
    let dt = {
      batch_id: parseInt(batch_id),
      event_id: parseInt(event_id),
      user_id: userid,
    };
    // console.log(dt,"dt");
    AxiosInstance.post("api/test/start", dt)
      .then((res) => {
        if (res.data.status === true) {
          let startTime =
            res.data.data[0].start_time != null
              ? res.data.data[0].start_time
              : "";
          let endTime =
            res.data.data[0].end_time != null ? res.data.data[0].end_time : "";
          let batch_id = res.data.data[0].batch_id;
          let event_id = res.data.data[0].event_id;
          let no_ques = res.data.data[0].no_of_questions;
          let tol_mark = res.data.data[0].tot_marks;
          // let attempt_no =  (fdata.obtained_marks !==null && fdata.obtained_marks !=="") ? res.data.data[0].attempt : 2 ;
          let attempt_no = res.data.data[0].attempt;
          let method_of_test = res.data.data[0].method_of_test;
          let event_name = res.data.data[0].event_name;
          let type_type_name = res.data.data[0].name;
          let start_date_test = res.data.data[0].start_date;
          let description = res.data.data[0].description;
          const navState = res.data.data[0];
          navigate(res.data.data[0].teams_url, {
            state: {
              eventName: event_name,
              methodOfTest: method_of_test,
              testType: type_type_name,
              date: start_date_test,
              startTime: startTime,
              endTime: endTime,
              totalNoQues: no_ques,
              totalMark: tol_mark,
              descForStudent: description,
              batchID: batch_id,
              eventID: event_id,
              attempt_no: attempt_no,
            },
          });
        } else {
          navigate("/");
          toast.error("You already reach 3 attempt");
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };
  const txtQueryStatus = useRef(null);

  const handleCloseModels_Succ = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };

  const handleCloseModel = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };
  const handleDownloadSyllabus = (path) => {
    window.open(`${ImageUrlPrefix}/${path}`);
  };
  const handleViewTestPerfomance = () => {
    navigate(`/viewTestPerformance`, {
      state: {
        userID: userid,
        batchID: batch_id,
        eventID: event_id,
        type_batch: type_batch,
      },
    });
  };

  const [staffMem, setStaffMem] = useState([]);
  const [viewQueries, setViewQueries] = useState([]);

  const handleAssign = (option, dname, questionPk) => {
    // console.log(option, "option");
    if (option) {
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === questionPk) {
            return {
              ...q,
              IsFaculty_id: option.value,
              isFaculty_name: option.label,
              isFaculty_error: "",
            };
          }
          return q;
        });
      });
    } else {
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === questionPk) {
            return {
              ...q,
              IsFaculty_id: "",
              isFaculty_name: "",
              isFaculty_error: "",
            };
          }
          return q;
        });
      });
    }
  };
  const [error, setError] = useState({});

  const handleChange = (event, elements) => {
    const { name, value, files, checked } = event.target;
    if (name === "query_path" && files && files.length > 0) {
      // console.log(value);
      const query_file = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("query_file", query_file);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setViewQueries((prevState) => {
                return prevState.map((q) => {
                  if (q.question_pk === elements.question_pk) {
                    return {
                      ...q,
                      query_path: res.data.path,
                      original_query_path: res.data.originalname,
                      query_path_error: "",
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setViewQueries((prevState) => {
            return prevState.map((q) => {
              if (q.question_pk === elements.question_pk) {
                return { ...q, query_path_error: err };
              }
              return q;
            });
          });
        }
      } else {
        let err = "pdf files only allowed";
        setViewQueries((prevState) => {
          return prevState.map((q) => {
            if (q.question_pk === elements.question_pk) {
              return { ...q, query_path_error: err };
            }
            return q;
          });
        });
      }
    } else if (name === "query_status") {
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === elements.question_pk) {
            return { ...q, query_status: value, query_Status_error: "" };
          }
          return q;
        });
      });
      setError({
        ...error,
        [name]: "",
      });
    } else if (name === "reply_query") {
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === elements.question_pk) {
            return { ...q, reply_query: value, reply_status_error: "" };
          }
          return q;
        });
      });
      setError({
        ...error,
        [name]: "",
      });
    }
  };
  const [flagReload, setFlagReload] = useState(false);

  const RaiseAdminQuery = (dff) => {
    AxiosInstance.post("api/test/raise/query/byAdmin", dff)
      .then((response) => {
        if (response.data.status) {
          setFlagReload(true);
          Swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "Ok",
          });
        } else {
          setFlagReload(false);
          Swal.fire({
            title: "Warning",
            text: response.data.message,
            icon: "Warning",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };
  const validateQueryReply = (queries) => {
    const UpdateReplyss = queries.map((OB) => {
      let updatedOB = { ...OB };
      if (!OB.query_status) {
        updatedOB.query_Status_error = "Please Choose Query Status";
      }
      if (OB.query_path === null) {
        updatedOB.query_path_error = "Please Choose Query Path";
      }
      if (!OB.reply_query) {
        updatedOB.reply_status_error = "Please enter reply ";
      }

      if (!OB.isFaculty_error) {
        updatedOB.isFaculty_error = "Please choose faculty ";
      }

      return updatedOB;
    });

    setViewQueries(UpdateReplyss);
  };
  const handleUpdateApprove = (elements) => {
    validateQueryReply(viewQueries);
    if (
      viewQueries.length > 0 &&
      viewQueries.every(
        (el) =>
          el.query_Status_error === "" &&
          (el.query_path_error === "" || el.query_path_error === null) &&
          el.reply_status_error === ""
      )
    ) {
      const df = {
        user_type: 0, // admin assign to deafult user_type  0→admin, 1→faculty
        event_type: fdata.event_type ? fdata.event_type : 1, // default as test so event type 1
        event_pk: fdata.event_id ? fdata.event_id : "",
        batch_pk: fdata.batch_id ? fdata.batch_id : "",
        question_pk: elements.question_pk,
        remaind_again: 0,
        assigned_to: elements.IsFaculty_id ? elements.IsFaculty_id : "",
        assigned_by: userid, // admin_user id
        // rquery_pk:
        //   elements.stud_query && elements.stud_query.length > 0
        //     ? elements.stud_query.map((el) => el.pk)
        //     : null,
        query_status: elements.query_status, // 0 valid 1 invalid
        query_proof_filepath: elements.query_path,
        original_query_filepath: elements.original_query_path,
        reply_by_staff: elements.reply_query,
        query_stage: 1, // 0 others 1 approved
        query_approved_dt: moment().format("YYYY/MM/DD HH:mm:ss"),
        created_by: userid,
        updated_by: userid,
      };
      if (elements.reply_query !== "" && elements.query_status !== "") {
        RaiseAdminQuery(df);
      }
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === elements.question_pk) {
            return {
              ...q,
              isUpdate: false,
              isEdit: false,
              IsFacultyEdit: false,
              IsfacultyUpdate: true,
            };
          }
          return q;
        });
      });
    }
  };
  const StyledPara = styled.p`
    color: var(--color-text-primary);
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
  `;
  const StyledAlert = styled.div`
    background-color: var(--color-brand-800);
  `;
  const StyledAlertPara = styled.p`
    margin: 0;
    padding: 5px 0;
    text-align: center;
    color: var(--color-white);
    font-size: 14px;
    font-weight: var(--font-weight-bold);
  `;
  const HighLight = styled.span`
    color: var(--color-brand-900);
  `;

  const [startOpen, setStartOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  // const [joinTestDetails, setJoinTestdetails] = useState({});
  // const [data, setData] = useState([]);
  // const [open, setOpen] = useState(true);
  // let date = new Date(data);
  // let serveTime = date.toTimeString().split(" ")[0];
  // function convertTimeFormat(time) {
  //   let [hours, minutes] = time.split(":");
  //   return `${hours}h ${minutes}m`;
  // }
  // let formattedServerTime = convertTimeFormat(serveTime);

  // function convertShowTimeFormat(time) {
  //   let [hours, minutes, seconds] = time.split(":");
  //   return `${hours}h ${minutes}m ${seconds}s`;
  // }
  // let formattedShowServerTime = convertShowTimeFormat(serveTime);

  // function getCurrentFormattedTime() {
  //   let now = new Date();
  //   let hours = String(now.getHours()).padStart(2, "0");
  //   let minutes = String(now.getMinutes()).padStart(2, "0");

  //   return `${hours}h ${minutes}m`;
  // }
  // let formattedCurrentTime = getCurrentFormattedTime();

  // function getCurrentShowFormattedTime() {
  //   let now = new Date();
  //   let hours = String(now.getHours()).padStart(2, "0");
  //   let minutes = String(now.getMinutes()).padStart(2, "0");
  //   let seconds = String(now.getSeconds()).padStart(2, "0");

  //   return `${hours}h ${minutes}m ${seconds}s`;
  // }
  // let formattedShowCurrentTime = getCurrentShowFormattedTime();

  // // const current
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetchData();
  //   }, 1000); // Update every second

  //   return () => clearInterval(interval); // Clean up on unmount
  // }, []);
  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       "https://aiasadev.annaiasacademy.com/api/api/server_time"
  //     );
  //     setData(response.data.time);
  //   } catch (err) {
  //     setError(err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (formattedServerTime !== formattedCurrentTime) {
  //     console.log("Server time and current time are not equal!");
  //     console.log(formattedServerTime !== formattedCurrentTime, "minutes");

  //     setLoading(true);
  //     const timer = setTimeout(() => {
  //       setOpen(true);
  //       setStartOpen(true);
  //       setLoading(false); // Set loading to false after timeout
  //     }, 1500);

  //     return () => clearTimeout(timer);
  //   } else {
  //     setOpen(false);
  //   }
  // }, [formattedServerTime, formattedCurrentTime]);

  // const differenceTime = formattedShowCurrentTime - formattedCurrentTime;

  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <>
      <div className="BathDet">
        <div className="row">
          <div className="col-md-12">
            {swalMsg.show === true && (
              <ModelComForP
                show={swalMsg.show}
                message={swalMsg.message}
                status={swalMsg.status}
                title={swalMsg.title}
                BottomOkBtn={swalMsg.BottomOkBtn}
                BottomCancelBtn={swalMsg.BottomCancelBtn}
                handleCloseModels={handleCloseModel}
                // handleClose_yes={handleSPayfee}
                BottomOkClose={swalMsg.BottomOkClose}
                handleCloseModels_Succ={handleCloseModels_Succ}
              />
            )}
            <div></div>
            <section className="StepOne">
              <ViewTestAlert
                type_batch={type_batch}
                startTime={fdata.startTime}
                sDate={fdata.sDate}
                attempt_no_status={fdata.attempt_no_status}
                attendenceStatus={fdata.attendenceStatus}
                endTime={fdata.endTime}
                onClickJoinTest={handleQuiz}
                onClickViewtestPerfomance={handleViewTestPerfomance}
                onClickDownloadAnswerSheet={handleDownloadAnswerSheet}
                ans_sheet_download_req={fdata.ans_sheet_download_req}
                onClickReviewSubmission={handleReviewSubmission}
                isResultsPublished={fdata.resultStatus}
              />
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Test Details</p>
                  <div className="row mb-3">
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Event Name
                        </label>
                        <span className="ReviewLabel">
                          {fdata.title !== null && fdata.title !== ""
                            ? fdata.title
                            : "-"}
                        </span>
                      </div>
                    </div>
                    {type_batch !== "4" && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Method of Test
                          </label>
                          <span className="ReviewLabel">
                            {fdata.methodOfTest === 0
                              ? "Objective"
                              : "Descriptive"}
                          </span>
                        </div>
                      </div>
                    )}

                    {type_batch !== "4" && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Test Type
                          </label>
                          <span className="ReviewLabel">
                            {fdata.testType || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Date
                        </label>
                        <span className="ReviewLabel">
                          {fdata.sDate !== null && fdata.sDate !== ""
                            ? fdata.sDate
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Start Time
                        </label>
                        <span className="ReviewLabel">
                          {fdata.startTime !== null && fdata.startTime !== ""
                            ? moment(fdata.startTime, "HH:mm:ss").format(
                                "h:mm A"
                              )
                            : "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          End Time
                        </label>
                        <span className="ReviewLabel">
                          {fdata.endTime !== null && fdata.endTime !== ""
                            ? moment(fdata.endTime, "HH:mm:ss").format("h:mm A")
                            : "-"}
                        </span>
                      </div>
                    </div>
                    {type_batch !== "4" && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Total Number of Questions
                          </label>
                          <span className="ReviewLabel">
                            {fdata.no_ques !== null && fdata.no_ques !== ""
                              ? fdata.no_ques
                              : "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Total Marks
                        </label>
                        <span className="ReviewLabel">
                          {fdata.tol_mark !== null && fdata.tol_mark !== ""
                            ? fdata.tol_mark
                            : "_"}
                        </span>
                      </div>
                    </div>

                    {type_batch !== "4" &&
                      fdata.subjectList.length > 0 &&
                      fdata.resultStatus && (
                        <>
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Number of Questions Answered
                              </label>
                              <span className="ReviewLabel">
                                {fdata?.total_answer || "-"}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Mark Obtained
                              </label>
                              <span className="ReviewLabel">
                                {fdata?.marks_obtained !== null
                                  ? fdata.marks_obtained === 0
                                    ? "A"
                                    : fdata.marks_obtained
                                  : "_"}
                              </span>
                            </div>
                          </div>

                          {fdata.subjectList.map((subject, i) => (
                            <div className="col-md-3" key={i}>
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  {`Marks in ${subject.subject_name}`}
                                </label>
                                <span className="ReviewLabel">
                                  {subject.marks_obtained
                                    ? subject.marks_obtained === 0
                                      ? "A"
                                      : subject.marks_obtained
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          ))}
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Class Rank
                              </label>
                              <span className="ReviewLabel">
                                {fdata?.classRank || "-"}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Overall Rank
                              </label>
                              <span className="ReviewLabel">
                                {fdata?.overallRank || "-"}
                              </span>
                            </div>
                          </div>
                          {fdata?.attemptScore2 !== null && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  2nd Attempt Score
                                </label>
                                <span className="ReviewLabel">
                                  {fdata?.attemptScore2 || "-"}
                                </span>
                              </div>
                            </div>
                          )}
                          {fdata.attempScore3 !== null && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  3rd Attempt Score
                                </label>
                                <span className="ReviewLabel">
                                  {fdata?.attempScore3 || "-"}
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      )}

                    {type_batch === "4" && fdata.resultStatus && (
                      <>
                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Mark Obtained
                            </label>
                            <span className="ReviewLabel">
                              {fdata?.marks_obtained !== null
                                ? fdata.marks_obtained === 0
                                  ? "A"
                                  : fdata.marks_obtained
                                : "_"}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Class Rank
                            </label>
                            <span className="ReviewLabel">
                              {fdata?.classRank || "-"}
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    {type_batch !== "4" && (
                      <div className="col-md-12">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Description for Student
                          </label>
                          <span className="ReviewLabel">
                            {fdata.descriptionForStudent || "-"}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>

            {type_batch !== "4" && (
              <section className=" StepOne">
                <div className="row box">
                  <div className="col-md-12 mb-3">
                    <p className="StepTitle">Subjects and Topics</p>
                    <div className="row ">
                      <div className="col-md-3">
                        <div className="forms ">
                          <button
                            style={{
                              border: "none",
                              background: "none",
                              padding: "0px",
                              margin: "0px",
                              textAlign: "start",
                            }}
                            onClick={() =>
                              handleDownloadSyllabus(fdata.syllabusFile)
                            }
                          >
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                              style={{ cursor: "pointer" }}
                            >
                              Uploaded Syllabus{" "}
                              <IconDownload
                                style={{
                                  color: "#C0272D",
                                  marginLeft: "5px",
                                }}
                              />
                            </label>
                          </button>
                          <span className="ReviewLabel">
                            {fdata.syllabusFile
                              ? fdata.syllabusFile.split("/").pop()
                              : "_"}
                          </span>
                        </div>
                      </div>
                    </div>
                    {subTopic.length > 0 ? (
                      subTopic.map((onk) => {
                        return (
                          <>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Subject
                                  </label>
                                  <span className="ReviewLabel">
                                    {onk.subject_name}
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-8">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Test Topics
                                  </label>
                                  <span className="ReviewLabel">
                                    {onk.test_topic_name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <div className="row ">
                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Subject
                            </label>
                            <span className="ReviewLabel">No Records</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            )}

            {queries.map((el, index) => (
              <section className="StepOne">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">
                      Query - Question No: {el.ques_no}
                    </p>
                    <div className="row mt-3">
                      <div className="col-md-12 p-0">
                        {el.display_type === 0 && (
                          <RtypeOne type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 1 && (
                          <RtypeTwo type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 2 && (
                          <RtypeThree type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 3 && (
                          <RtypeFour type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 4 && (
                          <RtypeFive type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 5 && (
                          <RtypeSix type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 6 && (
                          <RtypeSeven type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 7 && (
                          <RtypeEight type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 8 && (
                          <RtypeNine type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 9 && (
                          <RtypeTen type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 10 && (
                          <>
                            {el.para_text_tm !== "" &&
                              (el.lang_type === 0 || el.lang_type === 2) && (
                                <Rtypepara
                                  type={"2"}
                                  classET={"question_labels_tamil"}
                                  index={index}
                                  list={el.para_text_tm}
                                />
                              )}

                            {el.para_text_en !== "" &&
                              (el.lang_type === 1 || el.lang_type === 2) && (
                                <Rtypepara
                                  type={"2"}
                                  classET={"question_labels_english"}
                                  index={index}
                                  list={el.para_text_en}
                                />
                              )}
                            <RtypeParaOption
                              type={"2"}
                              index={index}
                              list={el}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <ExplanationQuery
                      margin_yes={0}
                      lang_type={el.lang_type}
                      en_explan={el.en_explanation}
                      tm_expla={el.tm_explanation}
                    />
                    <div className="BorderQuery"></div>
                    {el.stud_query &&
                      el.stud_query.map((ojk, index) => {
                        return <QuerySection query_rise={ojk} index={index} />;
                      })}
                    <div className="BorderQuery"></div>
                    <div className="row mt-3 mb-4">
                      <div className="col-md-6 ">
                        {el.assignee_info !== null && (
                          <div className="forms ">
                            <p
                              for="assign_to mt-0"
                              className="titleQuery mb-0"
                              id="assign_to_id"
                            >
                              Assign To
                            </p>
                            <span className="ReviewLabel mt-2">
                              {el.assignee_info !== null &&
                                el.assignee_info.assigned_to_name}
                            </span>
                          </div>
                        )}
                      </div>
                      {el.assignee_info !== null &&
                        (el.assignee_info.reply_by_staff !== null ||
                          el.assignee_info.reply_by_staff !== "") && (
                          <>
                            <div className="col-md-6 ">
                              <div className="forms ">
                                <p
                                  for="assign_to mt-0"
                                  className="titleQuery mb-0"
                                  id="assign_to_id"
                                >
                                  Query Status
                                </p>
                                <span className="ReviewLabel mt-2">
                                  {el.assignee_info.query_status === 1
                                    ? "Valid"
                                    : "Invalid"}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6 ">
                              <div className="forms ">
                                <p
                                  for="assign_to mt-0"
                                  className="titleQuery mt-2 mb-0"
                                  id="assign_to_id"
                                >
                                  Reply by {el.assignee_info.assigned_to_name} (
                                  {moment(el.assignee_info.reply_dt).format(
                                    "DD/MM/YYYY hh:mm A"
                                  )}
                                  )
                                </p>
                                <span className="ReviewLabel mt-2">
                                  {el.assignee_info.reply_by_staff
                                    ? el.assignee_info.reply_by_staff
                                    : "_"}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="forms ">
                                <div class="d-flex">
                                  {" "}
                                  <p className="titleQuery mb-1">Attachments</p>
                                  <a
                                    download
                                    target="_blank"
                                    className="attachmentQuery"
                                    href={`${ImageUrlPrefix}/${
                                      el.assignee_info &&
                                      el.assignee_info.query_proof_filepath
                                        ? el.assignee_info.query_proof_filepath
                                        : null
                                    }`}
                                  >
                                    <span class="material-icons iconQuery">
                                      download
                                    </span>
                                  </a>
                                  <a
                                    className="attachmentQuery"
                                    target="_blank"
                                    href={`${ImageUrlPrefix}/${
                                      el.assignee_info &&
                                      el.assignee_info.query_proof_filepath
                                        ? el.assignee_info.query_proof_filepath
                                        : null
                                    }`}
                                  >
                                    <span class="material-icons iconQuery">
                                      visibility
                                    </span>
                                  </a>
                                </div>

                                <p className="explain_ques mt-0">
                                  {" "}
                                  {el.assignee_info !== null &&
                                    el.assignee_info.original_query_filepath}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </section>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
