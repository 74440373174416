import React from "react";
import "./Question.css";
import parse from "html-react-parser";
import LatexProcessorComponent from "../../../../utils/LatexProcessorComponent";

//display type 5 means -   mcq design
// sample design
// Which pair of district and waterfall is not correctly paired?
// (A) Dharmapuri - Hogenakkal
// (B) Namakkal - Agayagangai
// (C) Theni - Kumbakkarai
// (D) Coimbatore - Kumbakkarai

function Qtypesix(props) {
  const { index } = props;

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  const splitLines = props.list.tm_question.split("\n");
  const EsplitLines = props.list.en_question.split("\n");

  const optionType = props.list.options_type;

  const generateOptionsEnglish = (optionType, index, props, userid) => {
    const options = ["A", "B", "C", "D", "E"];

    const elements = [];

    for (let i = 0; i < optionType; i++) {
      const option = options[i];
      const valueKey = `options_en_${option}`;
      // console.log("ValueKey:", valueKey);

      if (!props.list || !props.list[valueKey]) {
        // console.error(`props.list or props.list[${valueKey}] is undefined`);
        continue;
      }
      const checked = props.userInput.some(
        (item) => item.pk === props.list.pk && item.UserOptionSec === option
      );
      const optionValue = props.list[valueKey];
      if (typeof optionValue !== "string" || !optionValue.includes(" - ")) {
        // console.error(
        // `Invalid value at ${valueKey}, expected " - " to separate words.`
        // );
        continue;
      }

      const optionWords = optionValue.split(" - ");

      const optionWord0 = optionWords[0];
      const optionWord1 = optionWords[1];

      elements.push(
        <div className="row" key={i}>
          <div
            className={`${
              props.list.option_visibility == 1
                ? "col-md-6 col-6 d-flex"
                : "col-md-6 col-6 d-flex"
            } mt-1 d-flex`}
          >
            <div className="forms_input d-flex">
              <input
                type="radio"
                className="radioInput"
                name={`q_answer_${props.index}_en`}
                checked={checked}
                value={props.list[valueKey]}
                onChange={(e) =>
                  props.handleOptionChange(
                    e,
                    props.list.pk,
                    props.list.batch_id,
                    props.list.event_pk,
                    userid,
                    1,
                    option,
                    props.list.subject_type
                  )
                }
              />
              <span
                className="answer_label opone ms-1"
                onClick={(e) => {
                  console.log("Span clicked for option:", options[i]);
                  props.handleOptionChange(
                    e,
                    props.list.pk,
                    props.list.batch_id,
                    props.list.event_pk,
                    userid,
                    1,
                    option,
                    props.list.subject_type
                  );
                }}
              >
                ({option})
              </span>
            </div>
            <div>
              <span
                className="answer_label_english opone ms-1"
                onClick={(e) => {
                  console.log("Span clicked for option:", options[i]);
                  props.handleOptionChange(
                    e,
                    props.list.pk,
                    props.list.batch_id,
                    props.list.event_pk,
                    userid,
                    1,
                    option,
                    props.list.subject_type
                  );
                }}
              >
                {/* {parse(optionWord0)} */}
                <LatexProcessorComponent originalString={optionWord0} />
              </span>
            </div>
          </div>
          <div className="col-md-6 col-6">
            <div className="forms_input">
              <span className="answer_label_english ms-2">
                {/* {parse(optionWord1)} */}
                <LatexProcessorComponent originalString={optionWord1} />
              </span>
            </div>
          </div>
        </div>
      );
    }

    return elements;
  };

  const generateOptionsTamil = (optionType, index, props, userid) => {
    const options = ["A", "B", "C", "D", "E"];

    const elements = [];

    for (let i = 0; i < optionType; i++) {
      const option = options[i];
      const valueKey = `options_tm_${option}`;
      // console.log("ValueKey:", valueKey);

      if (!props.list || !props.list[valueKey]) {
        // console.error(`props.list or props.list[${valueKey}] is undefined`);
        continue;
      }
      const checked = props.userInput.some(
        (item) => item.pk === props.list.pk && item.UserOptionSec === option
      );
      const optionValue = props.list[valueKey];
      if (typeof optionValue !== "string" || !optionValue.includes(" - ")) {
        continue;
      }

      const optionWords = optionValue.split(" - ");

      const optionWord0 = optionWords[0];
      const optionWord1 = optionWords[1];

      elements.push(
        <div className="row" key={i}>
          <div
            className={`${
              props.list.option_visibility == 1
                ? "col-md-6 col-6 d-flex"
                : "col-md-6 col-6 d-flex"
            } mt-1 d-flex`}
          >
            <div className="forms_input d-flex">
              <input
                type="radio"
                className="radioInput"
                name={`q_answer_${props.index}_tm`}
                checked={checked}
                value={props.list[valueKey]}
                onChange={(e) =>
                  props.handleOptionChange(
                    e,
                    props.list.pk,
                    props.list.batch_id,
                    props.list.event_pk,
                    userid,
                    0,
                    option,
                    props.list.subject_type
                  )
                }
              />
              <span
                className="answer_label opone ms-1"
                onClick={(e) => {
                  console.log("Span clicked for option:", options[i]);
                  props.handleOptionChange(
                    e,
                    props.list.pk,
                    props.list.batch_id,
                    props.list.event_pk,
                    userid,
                    0,
                    option,
                    props.list.subject_type
                  );
                }}
              >
                ({option})
              </span>
            </div>
            <div>
              <span
                className="answer_label_tamil opone ms-1"
                onClick={(e) => {
                  console.log("Span clicked for option:", options[i]);
                  props.handleOptionChange(
                    e,
                    props.list.pk,
                    props.list.batch_id,
                    props.list.event_pk,
                    userid,
                    0,
                    option,
                    props.list.subject_type
                  );
                }}
              >
                {/* {parse(optionWord0)} */}
                <LatexProcessorComponent originalString={optionWord0} />
              </span>
            </div>
          </div>
          <div className="col-md-6 col-6">
            <div className="forms_input">
              <span className="answer_label_tamil ms-2">
                {/* {parse(optionWord1)} */}
                <LatexProcessorComponent originalString={optionWord1} />
              </span>
            </div>
          </div>
        </div>
      );
    }

    return elements;
  };

  const parseLine = (line, lang) => {
    const parts = line.split(" - ").map((part) => part.trim());
    let colSize;
    switch (parts.length) {
      case 2:
        colSize = 6;
        break;
      case 3:
        colSize = 4;
        break;
      default:
        colSize = 12 / parts.length;
    }
    return parts.map((part, index) => (
      <div
        className={`container col-md-${index === 0 ? 6 : 6} col-${
          index === 0 ? 6 : 6
        } text-start mb-3`}
        key={index}
      >
        <span
          key={index}
          className={`${
            lang === 0 ? "question_labels_tamil" : "question_labels_english"
          }`}
        >
          {/* {parse(part)} */}
          <LatexProcessorComponent originalString={part} />
        </span>
      </div>
    ));
  };

  const parseLineSOption = (line, lang) => {
    const parts = line.split(" - ").map((part) => part.trim());
    let colSize;
    switch (parts.length) {
      case 2:
        colSize = 6;
        break;
      case 3:
        colSize = 4;
        break;
      default:
        colSize = 12 / parts.length;
    }
    return parts.map((part, index) => (
      <div
        className={`col-md-${index === 0 ? 6 : 6} col-${
          index === 0 ? 6 : 6
        } text-start mb-3`}
        key={index}
      >
        <span
          key={index}
          className={`${
            lang === 0 ? "question_labels_tamil" : "question_labels_english"
          }`}
        >
          {/* {parse(part)} */}
          <LatexProcessorComponent originalString={part} />
        </span>
      </div>
    ));
  };

  return (
    <div className="Question_temp_VQ">
      {props.list.en_question !== null &&
        props.list.en_question !== "" &&
        (props.list.lang_type === 1 ||
          (props.list.lang_type === 2 &&
            (props.Lang === 1 || props.Lang === 2))) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <span className="question_labels_english">
                {EsplitLines.length > 0 ? (
                  <LatexProcessorComponent originalString={EsplitLines[0]} />
                ) : (
                  ""
                )}
              </span>
            </div>
            {EsplitLines.length > 1 && (
              <div className="col-md-12 mt-1">
                <div className="row">
                  {EsplitLines.map((line, index) => {
                    if (index === 1) {
                      return <>{parseLine(line, 1)}</>;
                    }
                    return null;
                  })}
                </div>
                <div className="row">
                  {EsplitLines.map((line, index) => {
                    if (index !== 1 && index !== 0) {
                      return <>{parseLineSOption(line, 1)}</>;
                    }
                    return null;
                  })}
                </div>
              </div>
            )}
            <div className="col-md-12 mt-4">
              {generateOptionsEnglish(optionType, 1, props, userid)}
            </div>
          </div>
        )}
      {props.list.tm_question !== null &&
        props.list.tm_question !== "" &&
        (props.list.lang_type === 0 ||
          (props.list.lang_type === 2 &&
            (props.Lang === 0 || props.Lang === 2))) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <span className="question_labels_tamil">
                {splitLines.length > 0 ? (
                  <LatexProcessorComponent originalString={splitLines[0]} />
                ) : (
                  ""
                )}
              </span>
            </div>
            {splitLines.length > 1 && (
              <div className="col-md-12 mt-1">
                <div className="row">
                  {splitLines.map((line, index) => {
                    if (index === 1) {
                      return <>{parseLine(line, 0)}</>;
                    }
                    return null;
                  })}
                </div>
                <div className="row">
                  {splitLines.map((line, index) => {
                    if (index !== 1 && index !== 0) {
                      return <>{parseLineSOption(line, 0)}</>;
                    }
                    return null;
                  })}
                </div>
              </div>
            )}
            <div className="col-md-12 mt-4">
              {generateOptionsTamil(optionType, 1, props, userid)}
            </div>
          </div>
        )}
    </div>
  );
}

export default Qtypesix;
