import React, { useState } from "react";
import "../../../../pages/dashboard/Modelcss.css";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

export default function ViewExplaination(props) {
  // const [showModal, setShowModal] = useState(true);
  const { showModal, onSetShowModal } = props;


  const handleClose = () => {
    onSetShowModal(!showModal);
  };
  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 class="view-title" id="exampleModalLabel">
            View Explaination
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-12  mb-3">
              <p className="view-paragraph">
               {props.element.en_explanation ? props.element.en_explanation : "_"}
              </p>
              <p className="view-paragraph">
               {props.element.tm_explanation ? props.element.tm_explanation : "_"}
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="DashboardButton" onClick={handleClose}>
          <a href="#">Okay</a>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
