import React, { useEffect, useState } from "react";
import "./Class.css";
import { AxiosInstance } from "../../../../axios/index";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

function ClassEdit() {
  //navigate another component

  const navigate = useNavigate();

  //get id from url
  const { id } = useParams();

  //get user_id from localhost and set global

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  // use states
  const [editcourse, setEditcourse] = useState([]);
  const [ccategory, setCcategory] = useState([]);
  const [subcategorys, setSubcategorys] = useState([]);

  // setting error value for all input fields
  const [error, setError] = useState({});

  const [initialfields, setInitialfields] = useState({
    courseCategory: "",
    subCategory: "",
    categoryclasses: "",
    branch: "",
  });

  //get subcategory for category
  useEffect(() => {
    if (initialfields.courseCategory === "TNPSC") {
      // ${initialfields.courseCategory} === 2 || tnpsc
      AxiosInstance.get(`/api/all/getSubcategory/2`)
        .then((res) => {
          // console.log(res);
          let subcategorys = res.data.data.map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ));
          setSubcategorys(subcategorys);
        })
        .catch((error) => {
          return error;
        });
    }
  }, [initialfields.courseCategory]);

  /**fetch the data for the id from the url */
  useEffect(() => {
    // const coursestoredItem = sessionStorage.getItem("categoryclasses");
    // if (coursestoredItem) {
    //   const courses = JSON.parse(coursestoredItem);
    //   const selectedCourse = courses.filter((course, i) => i == id);
    //   setEditcourse(selectedCourse);
    // }

    // get category list

    AxiosInstance.get("api/codevalues?codetype=CURS")
      .then((res) => {
        const ccategory = res.data.map((item, index) => (
          <option key={index} value={item.name}>
            {item.name}
          </option>
        ));
        setCcategory(ccategory);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  //edit view

  useEffect(() => {
    AxiosInstance.post(`api/course/findById`, { id: id })
      .then((res) => {
        setInitialfields({
          courseCategory: res.data.data[0].category_code,
          subCategory:
            res.data.data[0].subcategory_code != ""
              ? res.data.data[0].subcategory_code
              : "",
          categoryclasses: res.data.data[0].course_name,
          branch: res.data.data[0].branch_id,
        });
      })
      .catch((error) => {
        return error;
      });
  }, [id]);

  // validate form fields and setting error
  const errors = {};

  const validate = (data) => {
    const currentDate = new Date();
    const selectedDate = new Date(data.startDate);

    if (data.courseCategory == "") {
      errors.courseCategory = "Please select course Category.";
    } else if (data.categoryclasses == "") {
      errors.categoryclasses = "Please Enter Class Name.";
    } else if (data.branch == "") {
      errors.branch = "Please Select Branch.";
    }

    return errors;
  };

  /**
   * Handle change event for password fields.
   * Updates the corresponding field value in state based on the input field's name.
   * @param {object} event - The change event object.
   * @param {string} event.target.name - The name of the input field.
   * @param {string} event.target.value - The value of the input field.
   */
  const handleChange = (event) => {
    // console.log(event.target.name,event.target.value,"...handlechange...");
    const { name, value, id, checked } = event.target;
    // console.log(event.target.id, event.target.value, "...handlechange...");

    // set input values into state
    setInitialfields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));

    // set error values into state
    setError({
      ...error,
      [name]: "",
    });
  };

  /* handle reset function  */
  const handleReset = (e) => {
    e.preventDefault();
    setInitialfields({
      courseCategory: "",
      subCategory: "",
      categoryclasses: "",
      branch: "",
    });
  };

  /* handle reset function  end */

  /**
   * Handles submission of the form when form submits.
   * Saves the updated form values to local storage.
   * @param {Object} e - The form submission event.
   */

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(initialfields);

    if (Object.keys(errors).length === 0) {
      try {
        if (id) {
          // If an id is provided, update the data
          const updateCoures = {
            category_code: initialfields.courseCategory,
            subcategory_code: initialfields.subCategory || "",
            course_name: initialfields.categoryclasses,
            branch_id: initialfields.branch,
            created_by: userid,
            id: id,
            type: 0,
          };
          AxiosInstance.post(`api/course/edit/`, updateCoures)
            .then((res) => {
              if (res.status === 200) {
                Swal.fire({
                  title: "Success",
                  text: res.data.message,
                  icon: "success",
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/class");
                  }
                });
              } else {
                Swal.fire({
                  title: "warning",
                  text: res.data.message,
                  icon: "warning",
                  confirmButtonText: "Ok",
                });
              }
            })
            .catch((error) => {
              return error;
            });
        } else {
          // If no id is provided, add the new data
          const createCoures = {
            category_code: initialfields.courseCategory,
            subcategory_code: initialfields.subCategory || "",
            course_name: initialfields.categoryclasses,
            branch_id: initialfields.branch,
            created_by: userid,
            type: 0,
          };
          // console.log(createCoures, "createCoures");
          AxiosInstance.post(`api/course/add`, createCoures)
            .then((res) => {
              // console.log(res);
              if (res.status === 200) {
                Swal.fire({
                  title: "Success",
                  text: res.data.message,
                  icon: "success",
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/class");
                  }
                });
              } else {
                Swal.fire({
                  title: "warning",
                  text: res.data.message,
                  icon: "warning",
                  confirmButtonText: "Ok",
                });
              }
            })
            .catch((error) => {
              return error;
            });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };

  return (
    <div className="Classes">
      <div className="row ">
        <div className="col-md-12">
          <section className="StepOne ">
            <div className="row m-3 mb-2">
              <div className="col-md-3">
                <Link to="/classedit" className="SaveButton_disabled" disabled>
                  Create Category classes
                </Link>
              </div>
              <div className="col-md-3"></div>
              <div className="col-md-3"></div>
              <div className="col-md-3">
                <Link to="/class" className="SaveButton">
                  List Category classes
                </Link>
              </div>
            </div>
            <div className="row box">
              <div className="col-md-12">
                {id ? (
                  <p className="StepTitle">Edit Category classes</p>
                ) : (
                  <p className="StepTitle">Create Category classes</p>
                )}
                <div className="row">
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="branch">Branch location</label>
                      <select
                        class="Inputs"
                        id="branch"
                        name="branch"
                        onChange={handleChange}
                        value={initialfields.branch}
                      >
                        <option value="" disabled>
                          --- Select Branch ---
                        </option>
                        <option value="Kanchipuram">Kanchipuram</option>
                        <option value="Tambaram">Tambaram</option>
                      </select>
                      {error.branch && (
                        <small className="error">{error.branch}</small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="courseCategory">Course Category:</label>
                      <select
                        class="Inputs"
                        id="courseCategory"
                        name="courseCategory"
                        onChange={handleChange}
                        value={initialfields.courseCategory}
                      >
                        <option value="" disabled>
                          --- Select Category ---
                        </option>
                        {ccategory}
                      </select>
                      {error.courseCategory && (
                        <small className="error">{error.courseCategory}</small>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row mb-5">
                  {initialfields.courseCategory === "TNPSC" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="courseCategory">Sub Category:</label>
                        <select
                          class="Inputs"
                          id="subCategory"
                          name="subCategory"
                          onChange={handleChange}
                          defaultValue={initialfields.subCategory}
                        >
                          <option value="" disabled>
                            --- Select Sub Category ---
                          </option>
                          {subcategorys}
                        </select>
                        {error.subCategory && (
                          <small className="error">{error.subCategory}</small>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="categoryclasses">Category Classes:</label>
                      <input
                        type="text"
                        class="Inputs"
                        id="categoryclasses"
                        name="categoryclasses"
                        placeholder="Enter Category Class Name"
                        value={initialfields.categoryclasses}
                        onChange={handleChange}
                      />
                      {error.categoryclasses && (
                        <small className="error">{error.categoryclasses}</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-3 mb-5">
              <div className="col-md-3"></div>
              <div className="col-md-3"></div>
              <div className="col-md-3">
                <button
                  type="button"
                  className="SaveButton"
                  onClick={handleReset}
                >
                  reset
                </button>
              </div>
              <div className="col-md-3">
                <button
                  type="button"
                  className="SaveButton"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ClassEdit;
