import React, { useState, useRef, useEffect } from "react";

import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Chip from "@mui/material/Chip";
import { CloseOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import { styled } from "@mui/material/styles";
import DatePickerSample from "../../DatePicker";
function TestListview() {
  const moment = require("moment-timezone");
  const [listData, setListData] = useState([]);
  const txtBSearch = useRef(null); //Search icon
  // List data load table

  let page = R_PER_PAGE;

  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [dCourse, setDcourse] = useState([]);
  const [dCourses, setDcourses] = useState([]);
  const [sTopicData, setSTopicData] = useState([]);
  const [testTopicData, setTestTopicData] = useState([]);
  const [ssubject, setSsubject] = useState([]);
  const [ssubjects, setSsubjects] = useState(ssubject);
  const [testTopic, setTestTopic] = useState([]);
  const [testTopics, setTestTopics] = useState(testTopic);
  const [test_topics, setTest_topics] = useState([]);
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState([]);
  const [updateListData, setUpdateListData] = useState(listData);
  const [isOpen, setIsOpen] = useState({
    status: false,
    time: false,
    startDate: false,
    schedule: false,
    category: false,
    medium: false,
    classMode: false,
    location: false,
    method_of_test: false,
    testTopic: false,
    uploadDate: false,
  });
  const [appData, setAppData] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });
  const [appDatas, setAppDatas] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });
  const [timedata, setTimedate] = useState({
    STime: "",
    TTime: "",
    name: "",
  });
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [updateListData]);
  const [updateChipData, setUpdateChipData] = useState([]);

  const [status, setStatus] = useState([]);
  const [methodTest, setMethodTest] = useState([]);

  const [location, setLocation] = useState([]);
  const [appOn, setAppOn] = useState([]);
  const [appOns, setAppOns] = useState([]);
  const [typeOn, setTypeOn] = useState([]);
  const [typeOns, setTypeOns] = useState([]);
  const [time, setTime] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [classModes, setClassModes] = useState([]);
  const [mediums, setMedium] = useState([]);
  let filterStatus = [];
  let filterType = [];
  let filterTime = [];
  let filterSchedule = [];
  let filterClassMode = [];
  let filterLocation = [];
  let filterMedium = [];
  let filterApplyed = [];
  let filterApplyeds = [];
  let filterSearch = [];

  let filterTestTopic = [];

  let filterMethodTest = [];

  useEffect(() => {
    filterStatus = status;
  }, [status]);
  useEffect(() => {
    filterMethodTest = methodTest;
  }, [methodTest]);
  useEffect(() => {
    filterTestTopic = test_topics;
  }, [test_topics]);
  useEffect(() => {
    filterLocation = location;
  }, [location]);
  useEffect(() => {
    filterClassMode = classModes;
  }, [classModes]);
  useEffect(() => {
    filterSchedule = schedule;
  }, [schedule]);

  useEffect(() => {
    filterMedium = mediums;
  }, [mediums]);

  useEffect(() => {
    filterType = typeOn;
  }, [typeOn]);

  useEffect(() => {
    filterApplyed = appOn;
  }, [appData]);

  useEffect(() => {
    filterApplyed = appOns;
  }, [appDatas]);

  useEffect(() => {
    filterTime = time;
  }, [time]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }, [searchTerm]);

  useEffect(() => {
    loadChipdata();
  }, []);

  useEffect(() => {
    loadListdata();
  }, [updateChipData]);

  // useEffect(() => {
  //   console.log(" updateListData : ", updateListData);
  //   currentData = updateListData.slice(start, end);
  //   // total table data  with pages
  //   totalPages = Math.ceil(updateListData.length / rowsPerPage);
  // }, [updateListData]);

  function updateStateData() {
    filterStatus = status;
    filterType = typeOn;
    filterApplyed = appOn;
    filterApplyeds = appOns;
    filterTime = time;
    filterSchedule = schedule;
    filterLocation = location;
    filterClassMode = classModes;
    filterMethodTest = methodTest;
    filterMedium = mediums;
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }

  //sorting function

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  // filter checkbox update

  const handleCheckboxChange = (event, names, typename) => {
    // console.log(names, " : ", names);
    const { value, checked, name } = event.target;
    if (name === "NotYetUploaded" || name === "Uploaded") {
      if (checked) {
        // setStatus([...status, value]);
        setStatus([...status, { id: value, name: name, type: "status" }]);
      } else {
        setStatus(status.filter((item) => item.id !== value));
      }
    } else if (name === "Objective" || name === "Descriptive") {
      if (checked) {
        // setStatus([...status, value]);
        setMethodTest([
          ...methodTest,
          { id: value, name: name, type: "methodTest" },
        ]);
      } else {
        setMethodTest(methodTest.filter((item) => item.id !== value));
      }
    } else if (names === "location") {
      if (checked) {
        // setStatus([...status, value]);
        setLocation([...location, { id: value, name: name, type: "location" }]);
      } else {
        setLocation(location.filter((item) => item.id !== value));
      }
    } else if (typename === "type") {
      if (checked) {
        setTypeOn([...typeOn, { name: value, type: "type" }]);
      } else {
        setTypeOn(typeOn.filter((item) => item.name !== value));
      }
    } else if (name === "Weekend" || name === "Regular") {
      if (checked) {
        setSchedule([...schedule, { id: value, name: name, type: "schedule" }]);
      } else {
        setSchedule(schedule.filter((item) => item.id !== value));
      }
    } else if (name === "Classroom" || name === "Online") {
      if (checked) {
        setClassModes([
          ...classModes,
          { id: value, name: name, type: "classMode" },
        ]);
      } else {
        setClassModes(classModes.filter((item) => item.id !== value));
      }
    } else if (names === "medium") {
      if (checked) {
        setMedium([...mediums, { id: value, name: name, type: "medium" }]);
      } else {
        setMedium(mediums.filter((item) => item.id !== value));
      }
    } else if (names === "test_topic") {
      if (checked) {
        console.log(value, name);
        setTest_topics([
          ...test_topics,
          { id: value, name: name, type: "test_topic" },
        ]);
      } else {
        setTest_topics(test_topics.filter((item) => item.id !== value));
      }
    }
  };

  // handle search function
  const handleSearch = (event) => {
    // console.log(event);
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      updateStateData();
      setSearchTerm(val);
      if (val.length > 0) {
        filterSearch = [{ name: "" + val, type: "search" }];
      } else {
        filterSearch = [];
      }
      loadChipdata();
    }
  };

  //-------handle search icon function
  const handleSearchFocus = () => {
    if (txtBSearch.current) {
      txtBSearch.current.focus();

      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        code: "Enter",
        keyCode: 13,
        which: 13,
        bubbles: true,
      });
      txtBSearch.current.dispatchEvent(event);
    }
  };
  //-------end

  const handleFilterCourseSearch = (event) => {
    const searchInput = event.target.value;

    if (searchInput != "") {
      const matchingObjects = dCourse.filter((item) =>
        item.toLowerCase().includes(searchInput.toLowerCase())
      );
      // console.log(matchingObjects, searchInput);

      // Do something with the matchingObjects, such as updating state or displaying them in the UI

      setDcourses(matchingObjects);
    } else {
      setDcourses(dCourse);
    }
  };

  const handleFilterSearch = (event, namesev) => {
    const searchInput = event.target.value;
    if (namesev === "medium") {
      if (searchInput != "") {
        const matchingObjects = ssubject.filter((item) =>
          item.toLowerCase().includes(searchInput.toLowerCase())
        );

        setSsubjects(matchingObjects);
      } else {
        setSsubjects(ssubject);
      }
    } else if (namesev === "test_topic") {
      const searchInput = event.target.value.toLowerCase(); // Convert search input to lowercase
      console.log(searchInput);

      if (searchInput !== "") {
        const matchingObjects = testTopic.filter((ed) => {
          if (typeof ed === "string") {
            // Check if ed is a string
            return ed.toLowerCase().includes(searchInput);
          }
          return false; // Return false if ed is not a string
        });
        setTestTopics(matchingObjects);
      } else {
        setTestTopics(testTopic);
      }
    } else if (namesev === "subject") {
      const searchInput = event.target.value.toLowerCase();
      if (searchInput !== "") {
        const matchingObjects = ssubject.filter((item) => {
          if (typeof item.subjectName === "string") {
            // Check if subjectName is a string
            return item.subjectName.toLowerCase().includes(searchInput);
          }
          return false; // Return false if subjectName is not a string
        });
        setSsubjects(matchingObjects);
      } else {
        setSsubjects(ssubject);
      }
    } else {
      setTestTopics(testTopic);
      setSsubjects(ssubject);
    }
  };

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  //chips overall clear button
  const handleAllClear = () => {
    setSearchTerm("");
    setStatus([]);
    setSchedule([]);
    setTime([]);
    setMethodTest([]);
    setLocation([]);
    setClassModes([]);
    setUpdateChipData([]);
    setAppOn([]);
    setAppOns([]);
    setMedium([]);
    setTypeOn([]);
    setTestTopics([]);
    setTest_topics([]);
    setAppData({
      FDate: "",
      TDate: "",
      Days: "",
    });
    setUpdateListData(listData);
  };

  // filter apply button

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "status") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        status: false,
      });
    } else if (name === "method_of_test") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        method_of_test: false,
      });
    } else if (name === "location") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        location: false,
      });
    } else if (name === "appliedOn") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "appliedOns") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        uploadDate: false,
      });
    } else if (name === "type") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        category: false,
      });
    } else if (name === "time") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        time: false,
      });
    } else if (name === "schedule") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        schedule: false,
      });
    } else if (name === "classMode") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        classMode: false,
      });
    } else if (name === "medium") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        medium: false,
      });
    } else if (name === "test_topic") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        testTopic: false,
      });
    }
  };

  function loadListdata() {
    let tempstatus = [];
    let temptypeon = [];
    let tempsche = [];
    let tempclassMode = [];
    let tempLocanum = [];
    let templocation = [];
    let tempMedium = [];
    let tempMethodTest = [];
    let tempLocMedium = [];
    let tempTestTopic = [];
    let tempLocTestTopic = [];
    status.map((obj) => {
      tempstatus.push(obj.id);
    });

    methodTest.map((obj) => {
      tempMethodTest.push(obj.id);
    });

    location.map((obj) => {
      templocation.push(obj.id);
    });

    classModes.map((obj) => {
      tempclassMode.push(obj.name);
    });

    mediums.map((obj) => {
      tempMedium.push(obj.id);
    });

    test_topics.map((obj) => {
      tempTestTopic.push(obj.id);
    });

    schedule.map((obj) => {
      tempsche.push(obj.name);
    });

    typeOn.map((obj) => {
      temptypeon.push(obj.name);
    });

    tempLocanum = templocation.map(Number);

    tempLocMedium = tempMedium.map(Number);
    tempLocTestTopic = tempTestTopic.map(Number);

    let statusFilter = {
      category: typeOn.length > 0 ? temptypeon : "",
      course: location.length > 0 ? templocation : "",
      status: status.length > 0 ? tempstatus : "",
      start_date: appOn.length > 0 ? appOn[0].fdate + " 00:00:00" : "",
      end_date: appOn.length > 0 ? appOn[0].edate + " 23:59:00" : "",
      search: searchTerm.length > 0 ? searchTerm : "",
      subject: mediums.length > 0 ? tempLocMedium : "",
      test_topic: test_topics.length > 0 ? tempLocTestTopic : "",
      qu_start_date: appOns.length > 0 ? appOns[0].fdate + " 00:00:00" : "",
      qu_end_date: appOns.length > 0 ? appOns[0].fdate + " 00:00:00" : "",
      method_of_test: methodTest.length < 2 ? parseInt(tempMethodTest[0]) : "", // 0:objective 1:descriptive
    };

    AxiosInstance.post("api/test/report/list/byAdmin", statusFilter)
      .then((res) => {
        if (res.data.status === true) {
          const fiata = res.data.data;
          // const fiatacvv = fiata.sort((a, b) => {
          //   const dateA = moment(a.start_date).valueOf();
          //   const dateB = moment(b.start_date).valueOf();
          //   return dateB - dateA; // For descending order
          // });
          const serialNumberAddedData = fiata.map((data, index) => ({
            ...data,
            serialNumber: index + 1,
          }));

          setUpdateListData(serialNumberAddedData);
          const uniqueClassesSet = new Set(
            fiata.map((item) => item.category_class)
          );
          const uniqueClasses = [...uniqueClassesSet];
          setDcourse(uniqueClasses);
          setDcourses(uniqueClasses);
          const subjectSet = fiata.flatMap((event) => event.subject);
          // Create a map to filter unique subjects by subject name
          const uniqueSubjectMap = new Map();
          subjectSet.forEach((subj) => {
            uniqueSubjectMap.set(subj.subject.toLowerCase(), subj); // Use lowercase to ignore case sensitivity
          });
          // Convert the map back to an array
          const uniqueSubjects = Array.from(uniqueSubjectMap.values());
          setSsubject(uniqueSubjects);
          setSsubjects(uniqueSubjects);
          const testtopicSet = fiata.flatMap((event) => event.test_topic);

          // Create a map to filter unique test topics by test_topic name
          const uniqueTestTopicMap = new Map();
          testtopicSet.forEach((topic) => {
            uniqueTestTopicMap.set(topic.test_topic.toLowerCase(), topic); // Use lowercase to ignore case sensitivity
          });

          // Convert the map back to an array
          const uniqueTestTopics = Array.from(uniqueTestTopicMap.values());

          setTestTopic(uniqueTestTopics);
          setTestTopics(uniqueTestTopics);
        } else if (res.data.status === false) {
          setUpdateListData([]);
        }
      })
      .catch((error) => {
        // console.log("Error res: ", error.response.data);
        setUpdateListData([]);
        return error;
      });
  }

  function loadChipdata() {
    //setUpdateChipData([]);
    // console.log(location , filterLocation);
    let tempArr = [];
    // setUpdateChipData(status);
    filterSearch.map((obj) => {
      tempArr.push(obj);
    });
    filterStatus.map((obj) => {
      tempArr.push(obj);
    });

    filterMethodTest.map((obj) => {
      tempArr.push(obj);
    });

    filterLocation.map((obj) => {
      tempArr.push(obj);
    });

    filterType.map((obj) => {
      tempArr.push(obj);
    });
    filterTime.map((obj) => {
      tempArr.push(obj);
    });

    filterApplyed.map((obj) => {
      tempArr.push(obj);
    });

    filterSchedule.map((obj) => {
      tempArr.push(obj);
    });

    filterClassMode.map((obj) => {
      tempArr.push(obj);
    });

    filterMedium.map((obj) => {
      tempArr.push(obj);
    });

    filterTestTopic.map((obj) => {
      tempArr.push(obj);
    });

    // console.log(tempArr, "tempArr");
    setUpdateChipData(tempArr);
  }

  const handleTime = (e) => {
    const { name, value, checked } = e.target;
    if (name === "STime" || name === "TTime") {
      setTimedate((prevState) => ({ ...prevState, [name]: value }));
      // console.log(timedata.STime);
      // console.log(timedata.TTime);
      const from = name === "STime" ? value : timedata.STime;
      const to = name === "TTime" ? value : timedata.TTime;
      const dateRange = `${from} - ${to}`;

      setTime([
        {
          name: dateRange,
          STime: from,
          TTime: to,
          type: "time",
        },
      ]);
    }
  };

  const handleAppliedOn = (e) => {
    const { name, value, checked } = e.target;
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: value }));
      const from = name === "FDate" ? value : appData.FDate;
      const to = name === "TDate" ? value : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    } else if (name === "Days") {
      if (checked) {
        setAppData((prevState) => ({ ...prevState, [name]: value }));
      }
      // else {
      //   setAppOn(appOn.filter((item) => item !== value));
      // }
      if (value === "Today") {
        const today = new Date();
        setAppOn([
          {
            name: value,
            fdate: moment(today.toDateString()).format("YYYY-MM-DD"),
            edate: moment(today.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Yesterday") {
        const today = new Date(); // Get today's date
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setAppOn([
          {
            name: value,
            fdate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            edate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tweek") {
        const today = new Date(); // Get today's date
        const thisWeekStartDate = moment(today)
          .subtract(0, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const thisWeekEndDate = moment(today)
          .subtract(0, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "This week",
            fdate: moment(thisWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lweek") {
        const today = new Date(); // Get today's date
        const lastWeekStartDate = moment(today)
          .subtract(1, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const lastWeekEndDate = moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "Last week",
            fdate: moment(lastWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tmonth") {
        const today = new Date(); // Get today's date
        const thisMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ); // Get start date of this month
        const thisMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setAppOn([
          {
            name: "This month",
            fdate: moment(thisMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lmonth") {
        const today = new Date();
        const lastMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        setAppOn([
          {
            name: "Last month",
            fdate: moment(lastMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      }
    }
  };

  const handleAppliedOnUpload = (e) => {
    const { name, value, checked } = e.target;
    if (name === "FDate" || name === "TDate") {
      setAppDatas((prevState) => ({ ...prevState, [name]: value }));
      const from = name === "FDate" ? value : appData.FDate;
      const to = name === "TDate" ? value : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOns([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    } else if (name === "Days") {
      if (checked) {
        setAppDatas((prevState) => ({ ...prevState, [name]: value }));
      }
      // else {
      //   setAppOn(appOn.filter((item) => item !== value));
      // }
      if (value === "Today") {
        const today = new Date();
        setAppOns([
          {
            name: value,
            fdate: moment(today.toDateString()).format("YYYY-MM-DD"),
            edate: moment(today.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      } else if (value === "Yesterday") {
        const today = new Date(); // Get today's date
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setAppOns([
          {
            name: value,
            fdate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            edate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      } else if (value === "Tweek") {
        const today = new Date(); // Get today's date
        const thisWeekStartDate = moment(today)
          .subtract(0, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const thisWeekEndDate = moment(today)
          .subtract(0, "week")
          .endOf("week")
          .toDate();
        setAppOns([
          {
            name: "This week",
            fdate: moment(thisWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      } else if (value === "Lweek") {
        const today = new Date(); // Get today's date
        const lastWeekStartDate = moment(today)
          .subtract(1, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const lastWeekEndDate = moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate();
        setAppOns([
          {
            name: "Last week",
            fdate: moment(lastWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      } else if (value === "Tmonth") {
        const today = new Date(); // Get today's date
        const thisMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ); // Get start date of this month
        const thisMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setAppOns([
          {
            name: "This month",
            fdate: moment(thisMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      } else if (value === "Lmonth") {
        const today = new Date();
        const lastMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        setAppOns([
          {
            name: "Last month",
            fdate: moment(lastMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      }
    }
  };

  // filter clear button
  const handleClear = (name) => {
    updateStateData();
    if (name === "appliedOn") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "appliedOns") {
      setAppDatas({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOns([]);
      filterApplyeds = [];
      setIsOpen({
        ...isOpen,
        uploadDate: false,
      });
    } else if (name === "status") {
      // Clear the status array
      setStatus([]);
      filterStatus = [];
      setIsOpen({
        ...isOpen,
        status: false,
      });
      // setUpdateListData(listData);
    } else if (name === "method_of_test") {
      // Clear the status array
      setMethodTest([]);
      filterMethodTest = [];
      setIsOpen({
        ...isOpen,
        method_of_test: false,
      });
      // setUpdateListData(listData);
    } else if (name === "type") {
      setTypeOn([]);
      filterType = [];

      setIsOpen({
        ...isOpen,
        type: false,
      });
    } else if (name === "time") {
      setTime([]);
      filterTime = [];

      setIsOpen({
        ...isOpen,
        time: false,
      });
    } else if (name === "schedule") {
      setSchedule([]);
      filterSchedule = [];

      setIsOpen({
        ...isOpen,
        schedule: false,
      });
    } else if (name === "classMode") {
      setClassModes([]);
      filterClassMode = [];

      setIsOpen({
        ...isOpen,
        classMode: false,
      });
    } else if (name === "medium") {
      setMedium([]);
      filterMedium = [];

      setIsOpen({
        ...isOpen,
        medium: false,
      });
    } else if (name === "test_topic") {
      setTestTopics([]);
      filterTestTopic = [];
      setIsOpen({
        ...isOpen,
        testTopic: false,
      });
    } else if (name === "location") {
      // Clear the status array
      setLocation([]);
      filterLocation = [];
      setIsOpen({
        ...isOpen,
        location: false,
      });
      // setUpdateListData(listData);
    }
    loadChipdata();
  };

  // //   checking once again  -  handle delete function
  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
    } else if (chipToDelete.type === "status") {
      setStatus(status.filter((item) => item.id !== chipToDelete.id));
      filterStatus = status.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "methodTest") {
      setMethodTest(methodTest.filter((item) => item.id !== chipToDelete.id));
      filterMethodTest = methodTest.filter(
        (item) => item.id !== chipToDelete.id
      );
    } else if (chipToDelete.type === "location") {
      setLocation(location.filter((item) => item.id !== chipToDelete.id));
      filterLocation = location.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "type") {
      setTypeOn(typeOn.filter((item) => item.name !== chipToDelete.name));
      filterType = typeOn.filter((item) => item.name !== chipToDelete.name);
    } else if (chipToDelete.type === "dayRange") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
    } else if (chipToDelete.type === "dayRanges") {
      setAppDatas({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOns([]);
      filterApplyeds = [];
    } else if (chipToDelete.type === "time") {
      setTimedate({
        STime: "",
        TTime: "",
        name: "",
      });
      setTime([]);
      filterTime = [];
    } else if (chipToDelete.type === "schedule") {
      setSchedule(schedule.filter((item) => item.id !== chipToDelete.id));
      filterSchedule = schedule.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "classMode") {
      setClassModes(classModes.filter((item) => item.id !== chipToDelete.id));
      filterClassMode = classModes.filter(
        (item) => item.id !== chipToDelete.id
      );
    } else if (chipToDelete.type === "medium") {
      setMedium(mediums.filter((item) => item.id !== chipToDelete.id));
      filterMedium = mediums.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "test_topic") {
      setTest_topics(test_topics.filter((item) => item.id !== chipToDelete.id));
      filterTestTopic = test_topics.filter(
        (item) => item.id !== chipToDelete.id
      );
    } else {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
    }
    loadChipdata();
  };
  const handleChangeDate = (date, name) => {
    const dateFilter = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateFilter);
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: dateFilter }));
      const from = name === "FDate" ? dateFilter : appData.FDate;
      const to = name === "TDate" ? dateFilter : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    }
  };
  const handleChangeDateUpload = (date, name) => {
    const dateFilter = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateFilter);
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: dateFilter }));
      const from = name === "FDate" ? dateFilter : appData.FDate;
      const to = name === "TDate" ? dateFilter : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    }
  };
  const handleFilter = (e, boxName) => {
    if (boxName === "status") {
      setIsOpen((prevState) => ({
        time: false,
        startDate: false,
        schedule: false,
        category: false,
        medium: false,
        classMode: false,
        location: false,
        status: !prevState.status,
        method_of_test: false,
        testTopic: false,
        uploadDate: false,
      }));
    }
    if (boxName === "method_of_test") {
      setIsOpen((prevState) => ({
        time: false,
        startDate: false,
        schedule: false,
        category: false,
        method_of_test: !prevState.method_of_test,
        medium: false,
        classMode: false,
        location: false,
        status: false,
        testTopic: false,
        uploadDate: false,
      }));
    } else if (boxName === "location") {
      setIsOpen((prevState) => ({
        time: false,
        startDate: false,
        schedule: false,
        category: false,
        method_of_test: false,
        medium: false,
        classMode: false,
        status: false,
        location: !prevState.location,
        testTopic: false,
        uploadDate: false,
      }));
    } else if (boxName === "time") {
      setIsOpen((prevState) => ({
        startDate: false,
        schedule: false,
        category: false,
        method_of_test: false,
        medium: false,
        classMode: false,
        status: false,
        location: false,
        time: !prevState.time,
        testTopic: false,
        uploadDate: false,
      }));
    } else if (boxName === "startDate") {
      setIsOpen((prevState) => ({
        schedule: false,
        category: false,
        // course:false,
        method_of_test: false,
        medium: false,
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: !prevState.startDate,
        testTopic: false,
        uploadDate: false,
      }));
    } else if (boxName === "uploadDate") {
      setIsOpen((prevState) => ({
        schedule: false,
        category: false,
        // course:false,
        method_of_test: false,
        medium: false,
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        testTopic: false,
        uploadDate: !prevState.uploadDate,
      }));
    } else if (boxName === "schedule") {
      setIsOpen((prevState) => ({
        category: false,
        // course:false,
        medium: false,
        classMode: false,
        method_of_test: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        schedule: !prevState.schedule,
        testTopic: false,
        uploadDate: false,
      }));
    } else if (boxName === "category") {
      setIsOpen((prevState) => ({
        medium: false,
        classMode: false,
        status: false,
        method_of_test: false,
        location: false,
        time: false,
        startDate: false,
        schedule: false,
        category: !prevState.category,
        testTopic: false,
        uploadDate: false,
      }));
    }
    // else if (boxName === "course") {
    //   setIsOpen((prevState) => ({
    //     ...prevState,
    //     course: !prevState.course,
    //   }));
    // }
    else if (boxName === "medium") {
      setIsOpen((prevState) => ({
        classMode: false,
        status: false,
        location: false,
        time: false,
        method_of_test: false,
        startDate: false,
        schedule: false,
        category: false,
        medium: !prevState.medium,
        testTopic: false,
        uploadDate: false,
      }));
    } else if (boxName === "classMode") {
      setIsOpen((prevState) => ({
        status: false,
        location: false,
        time: false,
        method_of_test: false,
        startDate: false,
        schedule: false,
        category: false,
        medium: false,
        classMode: !prevState.classMode,
        testTopic: false,
        uploadDate: false,
      }));
    } else if (boxName === "testTopic") {
      setIsOpen((prevState) => ({
        status: false,
        location: false,
        time: false,
        method_of_test: false,
        startDate: false,
        schedule: false,
        category: false,
        medium: false,
        classMode: false,
        testTopic: !prevState.testTopic,
        uploadDate: false,
      }));
    }
  };

  // generate pdf when user click download button
  const generatePDF = () => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Add a title to the PDF document
    doc.text("Table Data", 10, 10);

    // Convert the table to a JavaScript array
    const tableData = [];

    // Add header row to tableData array
    const headers = [];
    const tableHeader = document.querySelectorAll("#example th");
    for (const header of tableHeader) {
      headers.push(header.innerText);
    }
    tableData.push(headers);

    // Add data rows to tableData array
    const tableRows = document.querySelectorAll("#example tbody tr");
    for (const row of tableRows) {
      const rowData = [];
      const rowCells = row.querySelectorAll("td");
      for (const cell of rowCells) {
        rowData.push(cell.innerText);
      }
      tableData.push(rowData);
    }

    // Add the table to the PDF document
    doc.autoTable({
      head: [tableData[0]], // Use the first row as the table header
      body: tableData.slice(1), // Use the remaining rows as the table body
    });
    // Save the PDF document
    doc.save("AdmissionData.pdf");
  };

  // total table data  with pages
  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;

    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      setUpdateListData([]);
    }
    loadListdata();
  };
  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = updateListData.slice(start, end);

  const handleReadView = (id, status, type) => {
    navigate(`/DetailTestView/${id}/${status}/${type}`);
  };

  return (
    <div className="BathDet ">
      <h6 className="Title">Test </h6>
      <div className="row ">
        <div className="col-lg-6 col-md-12">
          <div class="input-group">
            <input
              style={{ width: "50%" }}
              ref={txtBSearch}
              type="text"
              class="Inputs"
              id="SearchInput"
              name="SearchInput"
              placeholder="Search by Event Name,Batch Name"
              onKeyDown={handleSearch}
            />
            <span
              class="input-group-text"
              id="SearchInput"
              onClick={handleSearchFocus}
            >
              <span className="material-icons" onClick={handleSearchFocus}>
                search
              </span>
            </span>
          </div>
        </div>
      </div>
      {updateChipData.length > 0 ? (
        <div className="row mt-3">
          <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
            <Paper
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {updateChipData.map((data, index) => {
                // console.log(data, " - data - ", index);
                let icon;
                let key = "" + data.name;
                return (
                  <ListItem key={data}>
                    <Chip
                      icon={icon}
                      label={key}
                      onDelete={handleDelete(data, index)}
                    />
                  </ListItem>
                );
              })}
              <ListItem>
                {/* <Chip
                      label=" Clear All Filters"
                      onClick={handleAllClear}
                    /> */}
                <a className="allclear" href="#" onClick={handleAllClear}>
                  Clear All Filters
                </a>
              </ListItem>
            </Paper>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="row mt-4 ms-1 me-1">
        <div className="col-md-12 table-responsive p-0">
          <table className="table" id="example">
            <thead>
              <tr>
                <th id="th" style={{ width: "5%" }}>
                  {" "}
                  <span className="text_th">S.No</span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "serialNumber")}
                  >
                    {" "}
                    import_export{" "}
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "batch_name")}
                  >
                    {" "}
                    Batch Name
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "batch_name")}
                  >
                    {" "}
                    import_export{" "}
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "category")}
                  >
                    {" "}
                    Category
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "category")}
                  >
                    {" "}
                    import_export{" "}
                  </span>{" "}
                  <span
                    class={` align-middle material-icons  ${
                      typeOn.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "category")}
                  >
                    filter_list
                  </span>
                  {isOpen.category === true && (
                    <div className="filterBoxs p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="UPSC"
                              value="UPSC"
                              id="UPSC"
                              checked={typeOn.some((element) => {
                                if (element.name === "UPSC") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "UPSC", "type")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              UPSC
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="TNPSC"
                              value="TNPSC"
                              id="TNPSC"
                              checked={typeOn.some((element) => {
                                if (element.name === "TNPSC") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "TNPSC", "type")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              TNPSC
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="RRB"
                              value="RRB"
                              id="RRB"
                              checked={typeOn.some((element) => {
                                if (element.name === "RRB") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "RRB", "type")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              RRB
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="TET"
                              value="TET"
                              id="TET"
                              checked={typeOn.some((element) => {
                                if (element.name === "TET") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "TET", "type")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              TET
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="TRB"
                              value="TRB"
                              id="TRB"
                              checked={typeOn.some((element) => {
                                if (element.name === "TRB") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "TRB", "type")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              TRB
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="TNUSRB"
                              value="TNUSRB"
                              id="TNUSRB"
                              checked={typeOn.some((element) => {
                                if (element.name === "TNUSRB") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "TNUSRB", "type")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              TNUSRB
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="SSC"
                              value="SSC"
                              id="SSC"
                              checked={typeOn.some((element) => {
                                if (element.name === "SSC") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "SSC", "type")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              SSC
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Banking"
                              value="Banking"
                              checked={typeOn.some((element) => {
                                if (element.name === "Banking") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Banking", "type")
                              }
                              id="Banking"
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              Banking
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("type")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("type")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>

                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "category_class")}
                  >
                    {" "}
                    Course
                  </span>

                  <span class="material-icons align-middle">import_export</span>
                  <span
                    class={` align-middle material-icons  ${
                      location.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "location")}
                  >
                    filter_list
                  </span>
                  {isOpen.location === true && (
                    <div className="filterBoxs p-1">
                      <div className="filterBoxText">
                        <div className="row ms-1">
                          <div className="col-lg-12 col-md-12">
                            <div class="input-group searchInputsss">
                              <input
                                type="text"
                                class="Inputs"
                                id="SearchFilterInputs"
                                name="SearchFilterInputs"
                                placeholder="Search Here"
                                // onChange={handleSearch}
                                onKeyDown={handleFilterCourseSearch}
                              />
                              <span class="input-group-text" id="SearchInput">
                                <span className="material-icons">search</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        {dCourses.map((el) => (
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name={el}
                                  value={el}
                                  id={el}
                                  checked={location.some((element) => {
                                    if (element.name === el) {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "location")
                                  }
                                />
                                <label
                                  class="form-check-label PinkText"
                                  for="defaultCheck1"
                                >
                                  {el}
                                </label>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("location")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("location")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "event_name")}
                  >
                    {" "}
                    Event Name
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "event_name")}
                  >
                    import_export
                  </span>{" "}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "method_of_test")}
                  >
                    Method of Test
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "method_of_test")}
                  >
                    import_export
                  </span>
                  <span
                    class={` align-middle material-icons  ${
                      methodTest.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "method_of_test")}
                  >
                    filter_list
                  </span>
                  {isOpen.method_of_test === true && (
                    <div className="filterBoxs p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Objective"
                              value="0"
                              id="Objective"
                              checked={methodTest.some((element) => {
                                if (element.id === "0") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Objective")
                              }
                            />
                            <label
                              class="form-check-label PinkText"
                              for="defaultCheck1"
                            >
                              Objective
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Descriptive"
                              value="1"
                              id="Descriptive"
                              checked={methodTest.some((element) => {
                                if (element.id === "1") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Descriptive")
                              }
                            />
                            <label
                              class="form-check-label GreenText"
                              for="defaultCheck1"
                            >
                              Descriptive
                            </label>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("method_of_test")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("method_of_test")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                {/* <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "test_no")}
                  >
                    {" "}
                    Test Number
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "test_no")}
                  >
                    import_export
                  </span>
                </th> */}
                <th id="th">
                  <span
                    className="text_th"
                    // onClick={(e) => handleSort(e, "subject")}
                  >
                    {" "}
                    Subject
                  </span>{" "}
                  {/* <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "subject")}
                  >
                    import_export
                  </span> */}
                  <span
                    class={` align-middle material-icons  ${
                      mediums.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "medium")}
                  >
                    filter_list
                  </span>
                  {isOpen.medium === true && (
                    <div className="filterBoxs p-1">
                      <div className="filterBoxText ">
                        <div className="row ms-1">
                          <div className="col-lg-12 col-md-12">
                            <div class="input-group searchInputsss">
                              <input
                                type="text"
                                class="Inputs width_75"
                                id="SearchFilterInput"
                                name="SearchFilterInput"
                                placeholder="Search Here"
                                // onChange={handleSearch}
                                onKeyDown={(e) =>
                                  handleFilterSearch(e, "subject")
                                }
                              />
                              <span class="input-group-text" id="SearchInput">
                                <span className="material-icons">search</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        {ssubjects.map((el) => (
                          <div className="row mt-2 ">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name={el.subject}
                                  value={el.subject_pk}
                                  id={el.subject}
                                  checked={mediums.some((element) => {
                                    if (element.id == el.subject_pk) {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "medium")
                                  }
                                />
                                <label
                                  class="form-check-label PinkText"
                                  for="defaultCheck1"
                                >
                                  {el.subject}
                                </label>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("medium")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("medium")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>

                <th id="th">
                  <span
                    className="text_th"
                    // onClick={(e) => handleSort(e, "test_topic_name")}
                  >
                    {" "}
                    Test Topic
                  </span>{" "}
                  {/* <span
                    class="material-icons align-middle"
                    // onClick={(e) => handleSort(e, "test_topic_name")}
                  >
                    import_export
                  </span> */}
                  <span
                    class={` align-middle material-icons  ${
                      test_topics.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "testTopic")}
                  >
                    filter_list
                  </span>
                  {isOpen.testTopic === true && (
                    <div className="filterBoxs p-1">
                      <div className="filterBoxText">
                        <div className="row ms-1">
                          <div className="col-lg-12 col-md-12">
                            <div class="input-group searchInputsss">
                              <input
                                type="text"
                                class="Inputs width_75"
                                id="SearchFilterInput"
                                name="SearchFilterInput"
                                placeholder="Search Here"
                                // onChange={handleSearch}
                                onKeyDown={(e) =>
                                  handleFilterSearch(e, "test_topic")
                                }
                              />
                              <span class="input-group-text" id="SearchInput">
                                <span className="material-icons">search</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        {testTopics.map((el) => (
                          <div className="row mt-2 ">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name={el.test_topic}
                                  value={el.test_topic_pk}
                                  id={el.test_topic}
                                  checked={test_topics.some((element) => {
                                    if (element.id == el.test_topic_pk) {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "test_topic")
                                  }
                                />
                                <label
                                  class="form-check-label PinkText"
                                  for="defaultCheck1"
                                >
                                  {el.test_topic}
                                </label>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("test_topic")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("test_topic")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>

                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "start_date")}
                  >
                    Test Date & Time
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "start_date")}
                  >
                    import_export
                  </span>{" "}
                  <span
                    onClick={(e) => handleFilter(e, "startDate")}
                    class={` align-middle material-icons  ${
                      appOn.length > 0 ? "active_icons" : ""
                    }`}
                  >
                    filter_list
                  </span>
                  {isOpen.startDate === true && (
                    <div className="filterBoxOne container p-1">
                      <div className="row mt-1">
                        <div className="col-md-12">
                          <div class="form-check p-1">
                            <label
                              htmlFor="FDate"
                              className="d-flex form-label"
                            >
                              From Date
                              <span
                                data-required="true"
                                aria-hidden="true"
                              ></span>
                            </label>
                            {/* <input
                              id="FDate"
                              type="date"
                              name="FDate"
                              value={appData.FDate}
                              className="FilterInputs"
                              onChange={handleAppliedOn}
                              placeholder="FDate"
                              autoComplete="FDate"
                              required
                            /> */}
                            <DatePickerSample
                              IconInput={"filterIocnBoxInput"}
                              class={"FilterInputs"}
                              name={"FDate"}
                              handleChange={handleChangeDate}
                              fieldInput={appData.FDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-md-12">
                          <div class="form-check p-1">
                            <label
                              htmlFor="TDate "
                              className="d-flex form-label"
                            >
                              To Date
                              <span
                                data-required="true"
                                aria-hidden="true"
                              ></span>
                            </label>
                            {/* <input
                              id="TDate"
                              type="date"
                              name="TDate"
                              value={appData.TDate}
                              className="FilterInputs"
                              placeholder="TDate"
                              onChange={handleAppliedOn}
                              autoComplete="TDate"
                              required
                            /> */}
                            <DatePickerSample
                              class={"FilterInputs"}
                              IconInput={"filterIocnBoxInput"}
                              name={"TDate"}
                              handleChange={handleChangeDate}
                              fieldInput={appData.TDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              id="Today"
                              value="Today"
                              checked={appData.Days === "Today"}
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Today">
                              Today
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Yesterday"
                              checked={appData.Days === "Yesterday"}
                              id="Yesterday"
                              onChange={handleAppliedOn}
                            />
                            <span
                              class="form-check-label d-flex"
                              for="Yesterday"
                            >
                              Yesterday
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Tweek"
                              checked={appData.Days === "Tweek"}
                              id="Tweek"
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Tweek">
                              This week
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Lweek"
                              checked={appData.Days === "Lweek"}
                              id="Lweek"
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Lweek">
                              Last week
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Tmonth"
                              checked={appData.Days === "Tmonth"}
                              id="Tmonth"
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Tmonth">
                              This month
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              checked={appData.Days === "Lmonth"}
                              value="Lmonth"
                              id="Lmonth"
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Lmonth">
                              Last month
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("appliedOn")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("appliedOn")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "que_upload_dt")}
                  >
                    Uploaded Date & Time
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "que_upload_dt")}
                  >
                    import_export
                  </span>{" "}
                  <span
                    onClick={(e) => handleFilter(e, "uploadDate")}
                    class={` align-middle material-icons  ${
                      appOns.length > 0 ? "active_icons" : ""
                    }`}
                  >
                    filter_list
                  </span>
                  {isOpen.uploadDate === true && (
                    <div className="filterBoxOne container p-1">
                      <div className="row mt-1">
                        <div className="col-md-12">
                          <div class="form-check p-1">
                            <label
                              htmlFor="FDate"
                              className="d-flex form-label"
                            >
                              From Date
                              <span
                                data-required="true"
                                aria-hidden="true"
                              ></span>
                            </label>
                            {/* <input
                              id="FDate"
                              type="date"
                              name="FDate"
                              value={appData.FDate}
                              className="FilterInputs"
                              onChange={handleAppliedOn}
                              placeholder="FDate"
                              autoComplete="FDate"
                              required
                            /> */}
                            <DatePickerSample
                              IconInput={"filterIocnBoxInput"}
                              class={"FilterInputs"}
                              name={"FDate"}
                              handleChange={handleChangeDateUpload}
                              fieldInput={appDatas.FDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-md-12">
                          <div class="form-check p-1">
                            <label
                              htmlFor="TDate "
                              className="d-flex form-label"
                            >
                              To Date
                              <span
                                data-required="true"
                                aria-hidden="true"
                              ></span>
                            </label>
                            {/* <input
                              id="TDate"
                              type="date"
                              name="TDate"
                              value={appData.TDate}
                              className="FilterInputs"
                              placeholder="TDate"
                              onChange={handleAppliedOn}
                              autoComplete="TDate"
                              required
                            /> */}
                            <DatePickerSample
                              class={"FilterInputs"}
                              IconInput={"filterIocnBoxInput"}
                              name={"TDate"}
                              handleChange={handleChangeDateUpload}
                              fieldInput={appDatas.TDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              id="Today"
                              value="Today"
                              checked={appDatas.Days === "Today"}
                              onChange={handleAppliedOnUpload}
                            />
                            <span class="form-check-label d-flex" for="Today">
                              Today
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Yesterday"
                              checked={appDatas.Days === "Yesterday"}
                              id="Yesterday"
                              onChange={handleAppliedOnUpload}
                            />
                            <span
                              class="form-check-label d-flex"
                              for="Yesterday"
                            >
                              Yesterday
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Tweek"
                              checked={appDatas.Days === "Tweek"}
                              id="Tweek"
                              onChange={handleAppliedOnUpload}
                            />
                            <span class="form-check-label d-flex" for="Tweek">
                              This week
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Lweek"
                              checked={appDatas.Days === "Lweek"}
                              id="Lweek"
                              onChange={handleAppliedOnUpload}
                            />
                            <span class="form-check-label d-flex" for="Lweek">
                              Last week
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Tmonth"
                              checked={appDatas.Days === "Tmonth"}
                              id="Tmonth"
                              onChange={handleAppliedOnUpload}
                            />
                            <span class="form-check-label d-flex" for="Tmonth">
                              This month
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              checked={appDatas.Days === "Lmonth"}
                              value="Lmonth"
                              id="Lmonth"
                              onChange={handleAppliedOnUpload}
                            />
                            <span class="form-check-label d-flex" for="Lmonth">
                              Last month
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("appliedOns")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("appliedOns")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "que_status")}
                  >
                    Status
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "que_status")}
                  >
                    import_export
                  </span>{" "}
                  <span
                    class={` align-middle material-icons  ${
                      status.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "status")}
                  >
                    filter_list
                  </span>
                  {isOpen.status === true && (
                    <div className="filterBoxs_testlist p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="NotYetUploaded"
                              value="0"
                              id="Not Yet Uploaded"
                              checked={status.some((element) => {
                                if (element.id === "0") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "NotYetUploaded")
                              }
                            />
                            <label
                              class="form-check-label PinkText"
                              for="defaultCheck1"
                            >
                              Not Yet Uploaded
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Uploaded"
                              value="1"
                              id="Uploaded"
                              checked={status.some((element) => {
                                if (element.id === "1") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Uploaded")
                              }
                            />
                            <label
                              class="form-check-label GreenText"
                              for="defaultCheck1"
                            >
                              Uploaded
                            </label>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("status")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("status")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  {" "}
                  <span className="text_th">Action</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData.length === 0 ? (
                <tr>
                  <td colspan="12" style={{ textAlign: "center" }}>
                    No record to display
                  </td>
                </tr>
              ) : (
                currentData.map(
                  (course, index) => (
                    console.log(course, "coursecoursecourse"),
                    (
                      <tr key={index} title={course.pk}>
                        <td id="td">{course.serialNumber}</td>
                        <td id="td">
                          {course.batch_name}
                          {course.section && `(${course.section})`}
                        </td>

                        <td id="td">{course.category}</td>
                        <td id="td"> {course.category_class}</td>
                        <td id="td">{course.event_name}</td>
                        <td id="td">
                          {course.method_of_test === 0
                            ? "Objective"
                            : "Descriptive"}
                        </td>
                        {/* <td id="td">{course.test_no}</td> */}
                        <td id="td">
                          {course.subject && course.subject.length > 0
                            ? course.subject
                                .map((subject) => subject.subject)
                                .join(", ")
                            : "_"}
                        </td>
                        <td id="td">
                          {course.test_topic && course.test_topic.length > 0
                            ? course.test_topic
                                .map((test_topic) => test_topic.test_topic)
                                .join(", ")
                            : "_"}
                        </td>
                        <td id="td">
                          {moment(course.started_dt).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </td>
                        <td id="td">
                          {course.que_upload_dt !== null
                            ? moment(course.que_upload_dt).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : "_"}
                        </td>
                        {course.que_status === 0 && (
                          <td id="td">
                            <span className="PinkText">Not Yet Uploaded</span>
                          </td>
                        )}
                        {course.que_status === 1 && (
                          <td id="td">
                            <span className="GreenTexts">Uploaded</span>
                          </td>
                        )}
                        <td id="td">
                          <button
                            className="button"
                            onClick={() =>
                              handleReadView(
                                course.event_pk,
                                course.que_status,
                                course.type
                              )
                            }
                          >
                            <span class="material-icons">visibility</span>
                          </button>
                          {/* <button
                          className="button me-2"
                          onClick={() => handleEditView(course.type,course.id)}
                        >
                          <span class="material-icons">edit</span>
                        </button>{" "} */}
                        </td>
                      </tr>
                    )
                  )
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row mt-3 ms-1 me-1">
        <div className="col-md-12">
          <div className="pag w-100 dflexcenter">
            <span className="ipage">Items Per Page</span>
            <select
              name="pageCount"
              id="pageCount"
              defaultValue={rowsPerPage}
              onChange={handlePageCount}
              className="ms-2"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span className="pagenumber ms-2">
              {" "}
              {updateListData.length > 0
                ? currentPage * rowsPerPage + 1 - rowsPerPage
                : 0}{" "}
              -
              {updateListData.length < currentPage * rowsPerPage
                ? updateListData.length
                : currentPage * rowsPerPage}{" "}
              of {updateListData.length}
            </span>
            <button
              className="prev_button ms-2 me-2"
              onClick={(e) => handlePrev(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <span class="material-icons">chevron_left</span>
            </button>
            <button
              className="prev_button"
              onClick={() => handleNext(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <span class="material-icons">chevron_right</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestListview;
