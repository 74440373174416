import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import RtypeOne from "../StudentTestWorkflow/RtypeOne";
import RtypeTwo from "../StudentTestWorkflow/RtypeTwo";
import RtypeThree from "../StudentTestWorkflow/RtypeThree";
import RtypeFour from "../StudentTestWorkflow/RtypeFour";
import RtypeFive from "../StudentTestWorkflow/RtypeFive";
import RtypeSix from "../StudentTestWorkflow/RtypeSix";
import RtypeSeven from "../StudentTestWorkflow/RtypeSeven";
import RtypeEight from "../StudentTestWorkflow/RtypeEight";
import RtypeNine from "../StudentTestWorkflow/RtypeNine";
import RtypeTen from "../StudentTestWorkflow/RtypeTen";
import Rtypepara from "../StudentTestWorkflow/Rtypepara";
import RtypeParaOption from "../StudentTestWorkflow/RtypeParaOption";
import ExplanationQuery from "../Faculty/StudentsQuery/ExplanationQuery";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
export default function ViewQuestionPaper() {
  const navigate = useNavigate();
  const location = useLocation();

  const txtAssignTo = useRef(null);
  // console.log(location);
  const { id, status, eventName, bachId, publish_results } = location.state;
  const [fdata, setFdata] = useState([]);
  const [listData, setListData] = useState([]);
  const [publishResult, setPublishResult] = useState(0);
  const [displayType, setDisplayType] = useState([]);
  // difficulty_type:
  // 0→easy, 1→very_easy, 2→hard, 3→very_hard

  useEffect(() => {
    AxiosInstance.post("api/test/view/question", {
      batch_id: parseInt(bachId),
      event_id: parseInt(id),
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          let ut = res.data.data.question_papers;
          setListData(ut);
        }
      })
      .catch((error) => {
        return error;
      });
  }, [bachId]);

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  const Upload_Question = () => {
    navigate(`/UploadQuestionFile`, {
      state: {
        id,
        status,
      },
    });
  };

  // console.log(fdata,"aafaf");
  return (
    <div className="BathDet">
      <div className="row">
        <div className="col-md-12">
          <section className="StepOne">
            <div className="heading mt-3 dflexbetww">
              <div class="mt-2 dbetw">
                <h6 className="Tit_ques w-100"> View Question Paper</h6>
                <Link
                  to="javascript:void(0)"
                  onClick={navigateToPreviousScreen}
                  className="CancelButton MobileShow"
                >
                  Back to View Test
                </Link>
              </div>
              <div className="d-flex smButton mobiledown  ">
                <Link
                  to="javascript:void(0)"
                  onClick={navigateToPreviousScreen}
                  className="CancelButtons  MobileHide"
                >
                  Back to View Test
                </Link>

                <button
                  type="button"
                  className={`detailEventButton ${
                    publish_results === 1 ? "DisabledListviS" : ""
                  }`}
                  onClick={Upload_Question}
                  disabled={publish_results === 1 ? true : false}
                >
                  {" "}
                  <span class="material-icons me-1">upload</span>
                  <a>Upload New Question Paper</a>
                </button>
              </div>
            </div>
            {/* <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Question Paper Details</p>
                {fdata.length > 0 &&
                  fdata.map((el, index) => (
                    <div className="row mb-3">
                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">Subject</h5>
                          <span className="ReviewLabel">
                            {el.subject !==null && el.subject !==""
                              ? el.subject
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Total Number of Questions
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.no_ques !==null && el.no_ques !==""
                              ? el.no_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Very Easy Questions{" "}
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.very_easy_ques !==null &&
                            el.very_easy_ques !==""
                              ? el.very_easy_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Easy Questions{" "}
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.easy_ques !==null && el.easy_ques !==""
                              ? el.easy_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Medium Questions{" "}
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.medium_ques !==null && el.medium_ques !==""
                              ? el.medium_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Hard Questions{" "}
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.hard_ques !==null && el.hard_ques !==""
                              ? el.hard_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Very Hard Questions{" "}
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.very_hard_ques !==null &&
                            el.very_hard_ques !==""
                              ? el.very_hard_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div> */}
          </section>

          <section className="StepOne ">
            {listData.length > 0 &&
              listData.map((el, index) => {
                // Calculate the effective step based on the loop count
                if (el.display_type === 0) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>
                        <div className="row">
                          <RtypeOne type={"2"} index={index} list={el} />
                        </div>

                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 1) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeTwo type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 2) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>
                        <div className="row">
                          <RtypeThree type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 3) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeFour type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 4) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeFive type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 5) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeSix type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 6) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeSeven type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 7) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeEight type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 8) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeNine type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 9) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>
                        <div className="row">
                          <RtypeTen type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 10) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>
                        {el.para_text_tm !== "" &&
                          (el.lang_type === 0 || el.lang_type === 2) && (
                            <Rtypepara
                              type={"2"}
                              classET={"question_labels_tamil"}
                              index={index}
                              list={el.para_text_tm}
                            />
                          )}

                        {el.para_text_en !== "" &&
                          (el.lang_type === 1 || el.lang_type === 2) && (
                            <Rtypepara
                              type={"2"}
                              classET={"question_labels_english"}
                              index={index}
                              list={el.para_text_en}
                            />
                          )}

                        <div className="row">
                          <RtypeParaOption type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                }

                //                 else if (el.display_type === 10) {
                //                   if(el.easy!=null){
                //                     return (
                //                       <>
                //                       <div className="row box_review">
                //                         <div className="col-md-12">
                //                           <div className="row">
                //                           <Rtypepara type={"2"} index={index} list={el.easy} />
                //                           </div>
                //                         </div>
                //                       </div>
                //                         <div className="row box_review">
                //                         <div className="col-md-12">
                //                           <div className="row">
                //                           <p className="StepTitle">
                //                             {(index + 1).toString().padStart(2, "0")}
                //                           </p>
                //                           <RtypeParaOption type={"2"} index={index} list={el} />
                //                           </div>
                //                         </div>
                //                       </div>
                // </>
                //                     );
                //                   }else{
                //                     return (
                //                       <div className="row box_review">
                //                         <div className="col-md-12">
                //                           <div className="row">
                //                           <p className="StepTitle">
                //                             {(index + 1).toString().padStart(2, "0")}
                //                           </p>
                //                           <RtypeParaOption type={2} index={index} list={el} />
                //                           </div>
                //                         </div>
                //                       </div>
                //                     );
                //                   }

                //                 }
                return null; // Return null for non-display_type 10 items
              })}
          </section>
        </div>
      </div>
    </div>
  );
}
