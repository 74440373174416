import React, { useState, useEffect } from "react";

export const UserContext = React.createContext();

const UserDetailsContextProvider = (props) => {
  const [user, setUser] = useState([]);

  useEffect(() => {
    const userDetails = sessionStorage.getItem("userDetails");

    if (userDetails) {
      setUser(JSON.parse(userDetails));
    } else {
      setUser("");
    }
  }, []);

  //login user details

  const loginUser = (arg, callback) => {
    sessionStorage.setItem("userDetails", JSON.stringify(arg));
    setUser(arg);
    callback();
  };
  //logout user details
  const logout = () => {
    setUser("");
    sessionStorage.removeItem("userDetails");
    sessionStorage.removeItem("stateRefreshs", true);
    sessionStorage.removeItem("stateRefreshs_test", true);
    sessionStorage.removeItem("stateRefreshs_spl", true);
    sessionStorage.removeItem("stateRefreshs_model", true);
    sessionStorage.removeItem("stateRefreshs_mock", true);
    sessionStorage.removeItem("stateRefreshs_coun", true);
    sessionStorage.removeItem("user_id", true);
    sessionStorage.removeItem("role_id", true);
    sessionStorage.removeItem("Is_remember", true);
    sessionStorage.setItem(
      "active_user_count",
      JSON.stringify({
        logout_flag: 1,
        user_id_active_user: "",
      })
    );
  };

  return (
    <UserContext.Provider
      value={{ user: user, login: loginUser, logout: logout }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserDetailsContextProvider;
