import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import { validateTeachingProgress } from "./validateTeachingProgress";
import Swal from "sweetalert2";

export default function StaffDetailCalendar() {
  const modalCloseRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState({});
  // console.log(location);
  const { id, staff_id } = location.state;
  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;
  let role_id = null;
  let user_type = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
      role_id = user.role_id;
      user_type = user.user_type;
    }
  } else {
    console.log("User Id cannot available in localstorge");
  }
  useEffect(() => {
    // console.log(id, "id");
    AxiosInstance.post("api/event/findById", {
      id: id,
      staff_id: staff_id,
      event_type: 0, // it not necessary because its faculty related but api side want to give that key word for event find by id api
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          let el = res.data.data;
          setFdata((prevStates) => ({
            ...prevStates,
            title: el.name,
            sDate:
              el.start_date !== null
                ? moment(el.start_date).format("YYYY-MM-DD")
                : "",
            startTime: el.start_time,
            endTime: el.end_time,
            subject: el.subject,
            staff_name: el.staff_name,
            description: el.staff_description,
            batchName: el.batch_name,
            category: el.category,
            course: el.category_class,
            classMode: el.class_mode,
            medium: el.medium,
            location: el.location,
            teams_url: el.teams_url,
            batch_id: el.batch_id,
            staff_id: el.staff_id,
            event_id: el.id,
          }));
          let tp =
            res.data.teaching_progress !== ""
              ? res.data.teaching_progress
              : null;
          if (tp !== null) {
            setFormData((prevState) => ({
              ...prevState,
              id: tp.pk,
              topic_covered: tp.topics_covered,
              topics_pending: tp.topics_pending,
              percentage_completion: tp.completed_perc,
              comments: tp.comment,
            }));
            let Com =
              res.data.teaching_progress_comment.length > 0
                ? res.data.teaching_progress_comment
                : null;
            if (Com !== null) {
              setCommentSec(Com);
            }
          }
        }
      })
      .catch((error) => {
        return error;
      });
  }, []);

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };
  const today = new Date();

  const [fdata, setFdata] = useState({
    title: "",
    sDate: "",
    startTime: "",
    endTime: "",
    subject: "",
    staff_name: "",
    staff_id: "",
    event_id: "",
    description: "",
    batchName: "",
    category: "",
    course: "",
    classMode: "",
    medium: "",
    location: "",
    teams_url: "",
    batch_id: "",
  });

  const [formData, setFormData] = useState({
    id: "",
    topic_covered: "",
    topics_pending: "",
    percentage_completion: "",
    comments: "",
  });

  const [fs, setFs] = useState([
    {
      teaching_comment: "",
      user_type: user_type === 1 ? "admin" : "staff",
    },
  ]);
  const [commentSec, setCommentSec] = useState([
    {
      pk: "",
      staff_id: "",
      staff_type: "",
      teachprog_id: "",
      user_type: user_type === 1 ? 0 : 1,
      comment: "",
    },
  ]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setError({
      ...error,
      [name]: "",
    });
  };
  const handleTeachingProgress = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // console.log(formData); // Log the form data to the console for testing purposes
    const errors = validateTeachingProgress(formData);
    // console.log(errors, "errors");
    if (Object.keys(errors).length === 0) {
      try {
        if (formData.id !== null && formData.id !== "") {
          // alert("lo");

          const Updat = {
            pk: formData.id,
            staff_id: parseInt(fdata.staff_id),
            staff_type: 0,
            event_id: fdata.event_id,
            batch_id: fdata.batch_id,
            topics_covered: formData.topic_covered,
            topics_pending: formData.topics_pending,
            completed_perc: formData.percentage_completion,
            comment: formData.comments,
            created_by: userid,
            updated_by: userid,
          };
          // console.log(Updat, "Updat");
          AxiosInstance.post("api/staff/teachProg/edit", Updat)
            .then((res) => {
              if (res.data.status === true) {
                Swal.fire({
                  title: "Success",
                  text: res.data.message,
                  icon: "success",
                  confirmButtonText: "Ok",
                  willClose: () => {
                    const modal = document.querySelector("#TeachingModel"); // Adjust the selector as needed
                    // const modal_close = document.querySelector('#closeModel');
                    // if (modal) {
                    //   modal_close.click();
                    // }
                    const modal_close = modalCloseRef.current;
                    if (modal && modal_close) {
                      modal_close.click();
                    }
                  },
                });
              } else {
                Swal.fire({
                  title: "warning",
                  text: res.data.message,
                  icon: "warning",
                  confirmButtonText: "Ok",
                });
              }
            })
            .catch((error) => {
              return error;
            });
        } else {
          const dat = {
            staff_id: parseInt(fdata.staff_id),
            staff_type: 0,
            event_id: fdata.event_id,
            batch_id: fdata.batch_id,
            topics_covered: formData.topic_covered,
            topics_pending: formData.topics_pending,
            completed_perc: formData.percentage_completion,
            comment: formData.comments,
            created_by: userid,
            updated_by: userid,
          };
          // console.log(dat, "dat");
          AxiosInstance.post("api/staff/teachProg/add", dat)
            .then((res) => {
              if (res.data.status === true) {
                Swal.fire({
                  title: "Success",
                  text: res.data.message,
                  icon: "success",
                  confirmButtonText: "Ok",
                  willClose: () => {
                    // Code to dismiss the modal here
                  },
                });
              } else {
                Swal.fire({
                  title: "warning",
                  text: res.data.message,
                  icon: "warning",
                  confirmButtonText: "Ok",
                });
              }
            })
            .catch((error) => {
              return error;
            });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };
  const handleMeeting = () => {
    window.open(fdata.teams_url, "_blank");
  };
  const handleDateToday = (date) => {
    const givenDate = new Date(date);
    if (givenDate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
      return true;
    } else {
      return false;
    }
  };
  // console.log(fdata);
  return (
    <div className="BathDet">
      <div className="row">
        <div className="col-md-12">
          <section className="StepOne">
            <div className="heading mt-3 d-flex justify-content-between ">
              <h6
                className={
                  handleDateToday(fdata.sDate)
                    ? user_type === 3
                      ? "twidth"
                      : "Title_width"
                    : user_type === 1
                    ? "Title_width"
                    : "Title_widthsss"
                }
              >
                View Event Details
              </h6>
              <Link
                to="javascript:void(0)"
                onClick={navigateToPreviousScreen}
                className="CancelButton"
              >
                {user_type === 3 ? "Back " : "Back to Batches"}
              </Link>
              {user_type === 3 && handleDateToday(fdata.sDate) && (
                <button
                  type="button"
                  className="EditEventButtonss"
                  data-bs-toggle="modal"
                  data-bs-target="#TeachingModel"
                >
                  {" "}
                  <span class="material-icons me-2">border_color</span>
                  <a href="javascript:void(0)"> Edit Teaching Progress </a>
                </button>
              )}
              <button
                type="button"
                className="EditEventButtons"
                onClick={fdata.teams_url !== null ? handleMeeting : ""}
                disabled={fdata.teams_url !== null ? false : true}
              >
                {" "}
                <span class="material-icons me-2">event_available</span> Join
                Event
              </button>
            </div>
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Event Details</p>
                <div className="row mb-3">
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Event Name
                      </label>
                      <span className="ReviewLabel">
                        {fdata.title !== null ? fdata.title : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Batch Name
                      </label>
                      <span className="ReviewLabel">
                        {fdata.batchName !== null ? fdata.batchName : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Category
                      </label>
                      <span className="ReviewLabel">
                        {fdata.category !== null ? fdata.category : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Course
                      </label>
                      <span className="ReviewLabel">
                        {fdata.course !== null ? fdata.course : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Class Mode
                      </label>
                      <span className="ReviewLabel">
                        {fdata.classMode !== null ? fdata.classMode : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Medium
                      </label>
                      <span className="ReviewLabel">
                        {fdata.medium !== null ? fdata.medium : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted"
                      >
                        Location
                      </label>
                      <span className="ReviewLabel">
                        {fdata.location !== null && fdata.location !== ""
                          ? fdata.location
                          : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Date
                      </label>
                      <span className="ReviewLabel">
                        {fdata.sDate !== null ? fdata.sDate : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Start Timing
                      </label>
                      <span className="ReviewLabel">
                        {fdata.startTime !== null
                          ? moment(fdata.startTime, "HH:mm:ss").format("h:mm A")
                          : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        End Timing
                      </label>
                      <span className="ReviewLabel">
                        {fdata.endTime !== null
                          ? moment(fdata.endTime, "HH:mm:ss").format("h:mm A")
                          : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Subject
                      </label>
                      <span className="ReviewLabel">
                        {fdata.subject !== null ? fdata.subject : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Faculty Name
                      </label>
                      <span className="ReviewLabel">
                        {fdata.staff_name !== null ? fdata.staff_name : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Description
                      </label>
                      <span className="ReviewLabel">
                        {fdata.description !== null ? fdata.description : "⎯"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {handleDateToday(fdata.sDate) && (
            <section className="StepOne">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Teaching Progress</p>
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Topics Covered
                        </label>
                        <span className="ReviewLabel">
                          {formData.topic_covered !== null &&
                          formData.topic_covered !== ""
                            ? formData.topic_covered
                            : "⎯"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Topics Pending
                        </label>
                        <span className="ReviewLabel">
                          {formData.topics_pending !== null &&
                          formData.topics_pending !== ""
                            ? formData.topics_pending
                            : "⎯"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Percentage of Completion
                        </label>
                        <span className="ReviewLabel">
                          {formData.percentage_completion !== null &&
                          formData.percentage_completion !== ""
                            ? formData.percentage_completion
                            : "⎯"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Comments
                        </label>
                        <span className="ReviewLabel">
                          {formData.comments !== null &&
                          formData.comments !== ""
                            ? formData.comments
                            : "⎯"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {handleDateToday(fdata.sDate) && (
            <section className="StepOne">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Comments</p>
                  <div className="row mb-3">
                    {commentSec.length > 0 &&
                      commentSec.map((el, index) => (
                        <div className="col-md-12" key={index}>
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              {el.user_type === 0
                                ? "Comment by Admin"
                                : "Reply by Faculty"}
                            </label>
                            <span className="ReviewLabel">
                              {el.comment !== null && el.comment !== ""
                                ? el.comment
                                : "⎯"}
                            </span>
                          </div>
                        </div>
                      ))}{" "}
                  </div>
                </div>
              </div>
            </section>
          )}

          <div
            class="modal fade"
            id="TeachingModel"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Edit Teaching Progress
                  </h5>
                  <button
                    type="button"
                    ref={modalCloseRef}
                    class="btn-close"
                    id="closeModel"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="inputName" className="form-labels">
                            Topics Covered
                          </label>
                          <input
                            type="text"
                            class="Inputss"
                            value={formData.topic_covered}
                            onChange={handleChange}
                            id="topic_covered"
                            name="topic_covered"
                            placeholder="Enter Topic covered"
                          />
                          {error.topic_covered && (
                            <p className="error text-danger text-center">
                              {error.topic_covered}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="inputName" className="form-labels">
                            Topics Pending
                          </label>
                          <input
                            type="text"
                            class="Inputss"
                            value={formData.topics_pending}
                            onChange={handleChange}
                            id="topics_pending"
                            name="topics_pending"
                            placeholder="Enter Pending Topic"
                          />
                          {error.topics_pending && (
                            <p className="error text-danger text-center">
                              {error.topics_pending}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label htmlFor="inputName" className="form-labels">
                            Percentage of Completion
                          </label>
                          <input
                            type="number"
                            class="Inputss"
                            value={formData.percentage_completion}
                            onChange={handleChange}
                            id="percentage_completion"
                            name="percentage_completion"
                            placeholder="Enter percentage"
                          />
                          {error.percentage_completion && (
                            <p className="error text-danger text-center">
                              {error.percentage_completion}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-12">
                        <div className="forms ">
                          <label htmlFor="inputName" className="form-labels">
                            Comments
                          </label>
                          <textarea
                            name="comments"
                            id="comments"
                            onChange={handleChange}
                            defaultValue={formData.comments}
                            className="InputsTextarea"
                            placeholder="Comments"
                          ></textarea>
                          {error.comments && (
                            <p className="error text-danger text-center">
                              {error.comments}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <Link data-bs-dismiss="modal" className="CancelButton">
                    Back to View Event
                  </Link>
                  <button
                    type="button"
                    className="SaveButton"
                    onClick={handleTeachingProgress}
                  >
                    Save Teaching Progress
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
