import React, { useState } from "react";
import "../../../../pages/dashboard/Modelcss.css";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import { ImageUrlPrefix } from "../../../../labels";
import moment from "moment/moment";
export default function ViewQuery(props) {
  console.log(props, "props");
  // const [showModal, setShowModal] = useState(true);
  const { showModal, onSetShowModal } = props;
  console.log(props, "props");
  const handleClose = () => {
    onSetShowModal(!showModal);
  };
  const replyDate = props.element?.view_queries?.adm_query?.reply_dt;
  const formattedDate = replyDate
    ? moment(replyDate).format("DD/MM/YYYY hh:mm A")
    : "_";
  // console.log("date", props.element.view_queries.stud_query.created_dt);

  const getFormattedDate = (date) => {
    // Debugging: Log the received date
    console.log("Received date:", date);

    // Check if the date is valid using moment
    if (date && moment(date, moment.ISO_8601, true).isValid()) {
      return moment(date).format("DD/MM/YYYY hh:mm A");
    }

    console.warn("Invalid date:", date);
    return "_"; // Return a default value if the date is invalid or missing
  };

  console.log("Props:", props);
  console.log("Date:", props.element?.view_queries?.stud_query?.created_dt);

  const dateToFormat = props.element?.view_queries?.stud_query?.created_dt;

  function convertDateTime(dateTimeStr) {
    let [datePart, timePart, period] = dateTimeStr.split(" ");

    let [day, month, year] = datePart.split("-");

    return `${day}/${month}/${year} ${timePart} ${period}`;
  }

  let convertedDateTime = convertDateTime(dateToFormat);
  console.log(convertedDateTime, "convertedDateTime"); // Output: 28/06/2024 09:59:34 AM
  // console.log(
  //   props.element.view_queries.adm_query.reply_dt,
  //   "convertedDateTime"
  // );
  // const reply_time = props.element.view_queries.adm_query.reply_dt;
  // console.log(reply_time, "ssssssssss");

  // function convertDateTime(dateTimeStr) {
  //   let [datePart, timePart, period] = dateTimeStr.split(" ");

  //   let [day, month, year] = datePart.split("-");

  //   return `${day}/${month}/${year} ${timePart} ${period}`;
  // }

  // let formatteReplydDateTime = convertDateTime(reply_time);
  // let datePart = dateToFormat.split(" ")[0];
  // let formattedDates = datePart.replace(/-/g, "/");

  // console.log(formattedDates, "formattedDates");

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 class="view-title" id="exampleModalLabel">
            View Query
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-12  mb-3">
              <div>
                <p className="raise-title">
                  Query by You{" "}
                  <span className="time-query">
                    {" "}
                   ( {convertedDateTime} )
                  </span>
                </p>
                <p className="view-bold">
                  {props.element && props.element.view_queries.stud_query.query
                    ? props.element.view_queries.stud_query.query
                    : // moment(props.element.view_queries.stud_query.query).format("DD/MM/YYY hh:mm A")
                      "None"}
                </p>
              </div>
              {/* ------------------- */}
              <div>
                <div className="inline-container">
                  <span className="raise-title-second">Attachments </span>
                  <a
                    download
                    target="_blank"
                    className="attachmentQuery"
                    href={`${ImageUrlPrefix}/${
                      props.element &&
                      props.element.view_queries.stud_query.query_proof_filepath
                        ? props.element.view_queries.stud_query
                            .query_proof_filepath
                        : null
                    }`}
                  >
                    <DownloadIcon />
                  </a>
                  <a
                    className="attachmentQuery"
                    target="_blank"
                    href={`${ImageUrlPrefix}/${
                      props.element &&
                      props.element.view_queries.stud_query.query_proof_filepath
                        ? props.element.view_queries.stud_query
                            .query_proof_filepath
                        : null
                    }`}
                  >
                    <VisibilityIcon />
                  </a>
                </div>
                <p className="view-bold-1">
                  {" "}
                  {props.element &&
                  props.element.view_queries.stud_query.original_query_filepath
                    ? props.element.view_queries.stud_query
                        .original_query_filepath
                    : "None"}
                </p>
              </div>
              {/* ---------------------------------- */}
              <div>
                <hr className="line-view" />
              </div>
              {/* ----------------------------------- */}
              <div>
                <p className="raise-title">Assigned To</p>
                <p className="view-bold">
                  {props.element && props.element.view_queries.adm_query
                    ? `${props.element.view_queries.adm_query.first_name} ${props.element.view_queries.adm_query.last_name}`
                    : "_"}
                </p>
              </div>
              {/* added */}

              {
                props.element &&
                  props.element.view_queries.adm_query !== null && (
                    <div>
                      <p className="raise-title">
                        Reply by{" "}
                        {props.element.view_queries.adm_query.first_name &&
                        props.element.view_queries.adm_query.last_name
                          ? `${props.element.view_queries.adm_query.first_name} ${props.element.view_queries.adm_query.last_name}`
                          : "_"}{" "}
                        <span className="time-query">
                          {props.element.view_queries.adm_query.reply_dt &&
                          props.element.view_queries.adm_query.reply_dt !== null
                            ? `(${props.element.view_queries.adm_query.reply_dt})`
                            : ""}
                        </span>
                      </p>
                      <p className="view-bold mb-2">
                        {props.element.view_queries.adm_query.reply_by_staff
                          ? `${props.element.view_queries.adm_query.reply_by_staff}`
                          : "_"}
                      </p>
                    </div>
                  )
                // : (
                //   <div>
                //     <p className="raise-title">
                //       Reply by{" "}
                //       {props.element && props.element.view_queries.adm_query
                //         ? `${props.element.view_queries.adm_query.first_name} ${props.element.view_queries.adm_query.last_name}`
                //         : "_"}{" "}
                //       {/* <span className="time-query">
                //         {props.element.view_queries.adm_query.reply_dt &&
                //         props.element.view_queries.adm_query.reply_dt !== null
                //           ? `(${props.element.view_queries.adm_query.reply_dt})`
                //           : ""}
                //       </span> */}
                //     </p>
                //     {/* <p className="view-bold mb-2">
                //       {props.element && props.element.view_queries.adm_query
                //         ? `${props.element.view_queries.adm_query.reply_by_staff}`
                //         : "_"}
                //     </p> */}
                //   </div>
                // )
              }

              {
                props.element &&
                  props.element.view_queries.adm_query !== null && (
                    <div>
                      <div className="inline-container">
                        <span className="raise-title-second">Attachments </span>
                        {props.element &&
                          props.element.view_queries.adm_query &&
                          props.element.view_queries.adm_query
                            .query_proof_filepath !== null &&
                          props.element.view_queries.adm_query
                            .query_proof_filepath !== "" && (
                            <a
                              download
                              target="_blank"
                              className="attachmentQuery"
                              href={`${ImageUrlPrefix}/${
                                props.element &&
                                props.element.view_queries.adm_query
                                  ? props.element.view_queries.adm_query
                                      .query_proof_filepath
                                  : null
                              }`}
                            >
                              <span class="material-icons iconQuery">
                                download
                              </span>
                            </a>
                          )}
                        {props.element &&
                          props.element.view_queries.adm_query &&
                          props.element.view_queries.adm_query
                            .query_proof_filepath !== null &&
                          props.element.view_queries.adm_query
                            .query_proof_filepath !== "" && (
                            <a
                              download
                              target="_blank"
                              className="attachmentQuery"
                              href={`${ImageUrlPrefix}/${
                                props.element &&
                                props.element.view_queries.adm_query
                                  ? props.element.view_queries.adm_query
                                      .query_proof_filepath
                                  : null
                              }`}
                            >
                              <span class="material-icons iconQuery">
                                visibility
                              </span>
                            </a>
                          )}
                      </div>
                      <p className="view-bold-2">
                        {props.element &&
                        props.element.view_queries.adm_query &&
                        props.element &&
                        props.element.view_queries.adm_query
                          .original_query_filepath !== ""
                          ? `${props.element.view_queries.adm_query.original_query_filepath}`
                          : "_"}
                      </p>
                    </div>
                  )
                // : (
                // <div>
                //   <div className="inline-container">
                //     <span className="raise-title-second">Attachments</span>
                //     <a
                //       download
                //       target="_blank"
                //       className="attachmentQuery"
                //       href={`${ImageUrlPrefix}/${
                //         props.element && props.element.view_queries.adm_query
                //           ? props.element.view_queries.adm_query
                //               .query_proof_filepath
                //           : null
                //       }`}
                //     >
                //       <span class="material-icons iconQuery">download</span>
                //     </a>
                //     <a
                //       download
                //       target="_blank"
                //       className="attachmentQuery"
                //       href={`${ImageUrlPrefix}/${
                //         props.element && props.element.view_queries.adm_query
                //           ? props.element.view_queries.adm_query
                //               .query_proof_filepath
                //           : null
                //       }`}
                //     >
                //       <span class="material-icons iconQuery">visibility</span>
                //     </a>
                //   </div>
                //   <p className="view-bold-2">
                //     {props.element && props.element.view_queries.adm_query
                //       ? `${props.element.view_queries.adm_query.original_query_filepath}`
                //       : "_"}
                //   </p>
                // </div>
                // )
              }
              {/* ------------------- */}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <p className="cancel-button" onClick={handleClose}>
          Cancel
        </p>
        <button type="button" className="DashboardButton" onClick={handleClose}>
          <a href="#">Okay</a>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
