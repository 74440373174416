import React, { useState, useRef } from "react";
import logo from "../../../img/AIASA.png";
import IconLogo from "../../../img/IconLogo.png";
import { Link } from "react-router-dom";
import "./Login.css";
import video from "../../../video/pexels-nataliya-vaitkevich-7235868.mp4";
import { Logostyle } from "../../../Themes/themes";
import { Error } from "../../../Themes/themes";
import { useEffect } from "react";
import { AxiosInstance } from "../../../../src/axios/index";
import { useNavigate } from "react-router-dom/dist";
import Swal from "sweetalert2";
import Video from "../../../img/india.mp4";

import intlTelInput from "intl-tel-input";
function RegisterPage() {
  const navigate = useNavigate();
  const savedFields = JSON.parse(sessionStorage.getItem("registerFields"));

  const [initialfields, setInitialfields] = useState(
    savedFields || {
      fname: "",
      lname: "",
      email: "",
      mob: "",
      MobCode: "+91",
      cpassword: "",
      password: "",
      privacypolicy: false,
    }
  );

  const txtLoginFname = useRef(null);
  const txtLoginLname = useRef(null);
  const txtLoginEmail = useRef(null);
  const txtLoginMobile = useRef(null);
  const txtLoginPass = useRef(null);
  const txtLoginCPass = useRef(null);

  const [error, setError] = useState({});
  const [eyes, setEyes] = useState(false);

  const [eyess, setEyess] = useState(false);

  function isValidEmail(email) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  }

  useEffect(() => {
    // Initialize intl-tel-input on component mount
    const iti = intlTelInput(txtLoginMobile.current, {
      // Add options as needed, refer to the intl-tel-input documentation
      initialCountry: "in",
      separateDialCode: true,
    });

    // You can listen for changes if needed
    txtLoginMobile.current.addEventListener("countrychange", () => {
      // console.log('Country changed:', iti.getSelectedCountryData());
      const selectedCountryData = iti.getSelectedCountryData();
      setInitialfields((prevFields) => ({
        ...prevFields,
        MobCode: `+${selectedCountryData.dialCode}`, // Update phone number with the new country code
      }));
    });

    // Clean up on component unmount
    return () => {
      iti.destroy();
    };
  }, []);

  useEffect(() => {
    const videoElement = document.getElementById(
      "Register-background-image-poster"
    );

    const isIPhone = () => {
      return /iPhone/.test(navigator.userAgent) && !window.MSStream;
    };

    if (isIPhone()) {
      videoElement.setAttribute("disablePictureInPicture", true);
      videoElement.setAttribute("disableRemotePlayback", true);
      videoElement.setAttribute("playsInline", true);
    }

    // In case the mini player still appears, we pause the video
    videoElement.addEventListener("enterpictureinpicture", (event) => {
      if (isIPhone()) {
        event.preventDefault();
        videoElement.pause();
      }
    });
  }, []);

  const handleEyes = () => {
    setEyes(!eyes);
  };

  const handleEyess = () => {
    setEyess(!eyess);
  };

  function isValidMobileNumber(mobileNumber) {
    if (mobileNumber.length >= 10) {
      return true; // the mobile number is  valid
    } else {
      return false; // the mobile number is not valid
    }
  }

  const validateForm = (data) => {
    const errors = {};
    if (!data.fname) {
      errors.fname = "Please Enter First name.";
    }
    if (!data.lname) {
      errors.lname = "Please Enter Last name.";
    }
    if (data.email === "") {
      errors.email = "Please Enter Email address.";
    }
    if (!isValidEmail(data.email)) {
      errors.email = "Please enter a valid email";
    }
    if (data.mob === "") {
      errors.mob = "Please Enter Mobile no.";
    }
    if (data.mob && data.mob.length < 10) {
      errors.mob = "Please enter valid Mobile no";
    }
    if (data.mob && data.mob.length > 10) {
      errors.mob = "Please enter valid Mobile no";
    }
    if (data.password === "") {
      errors.password = "Please Enter Password.";
    }
    if (data.cpassword === "") {
      errors.cpassword = "Please Enter confirm password.";
    }
    if (data.password.length < 8) {
      errors.password = "Password must be 8 character.";
    }
    if (data.password !== data.cpassword) {
      errors.password = "Password does not match.";
    }
    validateFfForm(data);
    return errors;
  };

  const validateFfForm = (data) => {
    const errors = {};
    if (!data.fname) {
      txtLoginFname.current.focus();
    } else if (!data.lname) {
      txtLoginLname.current.focus();
    } else if (data.email === "") {
      txtLoginEmail.current.focus();
    } else if (!isValidEmail(data.email)) {
      txtLoginEmail.current.focus();
    } else if (data.mob === "") {
      txtLoginMobile.current.focus();
    } else if (data.mob && data.mob.length < 10) {
      txtLoginMobile.current.focus();
    } else if (data.mob && data.mob.length > 10) {
      txtLoginMobile.current.focus();
    } else if (data.password === "") {
      txtLoginPass.current.focus();
    } else if (data.cpassword === "") {
      txtLoginCPass.current.focus();
    } else if (data.password.length < 8) {
      txtLoginCPass.current.focus();
    } else if (data.password !== data.cpassword) {
      txtLoginCPass.current.focus();
    }
    return errors;
  };
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    // console.log(name,value);
    const letterPattern = /^[a-zA-Z]*$/;
    const numericValue = value.replace(/[^0-9]/g, "");
    if (name === "privacypolicy") {
      setInitialfields((prevFields) => ({
        ...prevFields,
        [name]: checked,
      }));
      setError({
        ...error,
        [name]: "",
      });
    } else if (name === "mob") {
      if (value.length === 10 || value.length === 0) {
        setInitialfields((prevList) => ({
          ...prevList,
          [name]: value,
          [name]: numericValue,
        }));
        setError({
          ...error,
          ["mob"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setInitialfields((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setError({
          ...error,
          ["mob"]: "Please Enter valid 10 digit number",
        });
        return;
      }
    } else if (name === "cpassword") {
      setInitialfields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
        ["password"]: "",
      });
    } else if (name === "fname" || name === "lname") {
      if (!letterPattern.test(value)) {
        setError({
          ...error,
          [name]: "Only letters are allowed",
        });
      } else {
        setError({
          ...error,
          [name]: "",
        });
      }
      setInitialfields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
    } else {
      setInitialfields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };
  const handleWheel = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const inputElement = txtLoginMobile.current;
    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(initialfields);

    // console.log(initialfields,"...initialfields...");

    if (
      initialfields.privacypolicy === false &&
      Object.keys(errors).length === 0
    ) {
      errors.privacypolicy = "Please look and verify the Terms and Conditions.";
    }

    if (Object.keys(errors).length === 0) {
      try {
        // console.log(initialfields.MobCode,"initialfields.MobCode");
        const response = await AxiosInstance.post("/api/user/register", {
          first_name: initialfields.fname,
          last_name: initialfields.lname,
          email: initialfields.email,
          phone_no: initialfields.mob,
          password: initialfields.password,
          country_code: initialfields.MobCode,
          method: 0,
        });
        // console.log(response.data);
        if (response.data.status === true) {
          // Remove the registerFields item from local storage
          sessionStorage.removeItem("registerFields");
          Swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              const dataToPass = {
                email: initialfields.email,
                mobileNumber: initialfields.phone_no,
              };
              navigate("/RegisterUser/EmailVerify", { state: dataToPass });
            }
          });
        } else {
          Swal.fire({
            title: "Status",
            text: response.data.message,
            icon: "Warning",
            confirmButtonText: "Ok",
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };

  useEffect(() => {
    const fieldsToSaveCopy = {
      ...initialfields,
      password: "",
      cpassword: "",
    };
    sessionStorage.setItem("registerFields", JSON.stringify(fieldsToSaveCopy));
  }, [initialfields]);

  return (
    <>
      <section className="LoginForm videoWrapper" id="wrapper_Login">
        <video
          poster={Image}
          id="Register-background-image-poster"
          autoPlay
          muted
          loop
          data-wf-ignore="true"
          data-object-fit="cover"
          disableRemotePlayback
          disablePictureInPicture
          playsInline
          controls={false}
        >
          <source src={Video} type="video/mp4" />
        </video>
        <div className="row ">
          <div className="col-md-12 box" id="boxs">
            <form className="" onSubmit={handleSubmit}>
              <img src={IconLogo} id="img" alt="Logo" />
              <h1 className="">Register to Anna IAS Academy</h1>
              <p>
                Ready to make a difference? Register now and <br /> let's create
                positive change together.
              </p>

              <div className="row">
                <div className="form-outline mb-2 col-lg-6 col-6">
                  <input
                    ref={txtLoginFname}
                    type="text"
                    name="fname"
                    id="fname_register"
                    value={initialfields.fname}
                    onChange={handleChange}
                    className="form-control registerfield"
                    placeholder="First Name"
                  />
                  {error.fname && (
                    <small className="error">{error.fname}</small>
                  )}
                </div>

                <div className="form-outline mb-2 col-lg-6 col-6">
                  <input
                    ref={txtLoginLname}
                    type="text"
                    name="lname"
                    id="lname_register"
                    value={initialfields.lname}
                    onChange={handleChange}
                    className="form-control registerfield"
                    placeholder="Last Name"
                  />
                  {error.lname && (
                    <small className="error">{error.lname}</small>
                  )}
                </div>
              </div>

              <div className="form-outline mb-1">
                <input
                  ref={txtLoginEmail}
                  type="email"
                  name="email"
                  id="email"
                  value={initialfields.email}
                  onChange={handleChange}
                  className="form-control registerfield "
                  placeholder="Email"
                />
                {error.email && <small className="error">{error.email}</small>}
              </div>

              <div className="form-outline mb-2">
                {/* <input
                  ref={txtLoginMobile}
                  type="number"
                  name="mob"
                  id="mob"
                  value={initialfields.mob}
                  onChange={handleChange}
                  className="form-control registerfield "
                  placeholder="Phone Number"
                /> */}

                <input
                  type="number"
                  id="mob"
                  name="mob"
                  value={initialfields.mob}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Phone Number"
                  className="form-control Phone_number_fields "
                  ref={txtLoginMobile}
                />

                {error.mob && <small className="error">{error.mob}</small>}
              </div>

              <div className="row">
                <div className="form-outline mb-1 col-lg-6 col-6">
                  <input
                    ref={txtLoginPass}
                    type={eyes === true ? "text" : "password"}
                    name="password"
                    id="password"
                    value={initialfields.password}
                    onChange={handleChange}
                    className="form-control registerfield"
                    placeholder="Password"
                  />

                  {initialfields.password.length > 0 && (
                    <span
                      className="material-icons eyes_logins_s"
                      onClick={handleEyes}
                    >
                      {eyes === true ? "visibility" : "visibility_off"}
                    </span>
                  )}
                  {error.password && (
                    <div className="error">{error.password}</div>
                  )}
                </div>
                <div className="form-outline mb-1 col-lg-6 col-6">
                  <input
                    ref={txtLoginCPass}
                    type={eyess === true ? "text" : "password"}
                    name="cpassword"
                    id="cpassword"
                    value={initialfields.cpassword}
                    onChange={handleChange}
                    className="form-control registerfield"
                    placeholder="Confirm Password"
                  />
                  {initialfields.cpassword.length > 0 && (
                    <span
                      className="material-icons eyes_logins_s"
                      onClick={handleEyess}
                    >
                      {eyess === true ? "visibility" : "visibility_off"}
                    </span>
                  )}

                  {error.cpassword && (
                    <div className="error">{error.cpassword}</div>
                  )}
                </div>
              </div>

              <div class="form-check ">
                <input
                  className="registerfield me-2 align-text-bottom"
                  type="checkbox"
                  checked={initialfields.privacypolicy}
                  onChange={handleChange}
                  id="privacypolicy"
                  name="privacypolicy"
                />
                <label
                  class="form-check-label align-bottom"
                  id="poliytext"
                  for="inlineCheckbox1"
                >
                  I agree to the{" "}
                  <a
                    href={require("../../../PrivacyPolicyTermsCondition.pdf")}
                    target="_blank"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href={require("../../../PrivacyPolicyTermsCondition.pdf")}
                    target="_blank"
                  >
                    Terms of Use.
                  </a>
                </label>
              </div>
              {error.privacypolicy && (
                <small className="error">{error.privacypolicy}</small>
              )}
              <div className="text-center ">
                <button
                  type="submit"
                  className="LoginBtn"
                  name="RegisterNow"
                  value=""
                >
                  Register Now
                </button>
                <h5 className="registeralready mt-4">
                  Already Registered?
                  <Link to="/login" className="login loginpage">
                    {" "}
                    Log in Now
                  </Link>
                </h5>{" "}
              </div>
              <div className="copyRights">
                © {new Date().getFullYear()} All Rights Reserved
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default RegisterPage;
