import React, { useState, useEffect } from "react";

import MathJax from "react-mathjax";
import parse from "html-react-parser";
// import MathJax from 'react-mathjax';

// const LatexProcessorComponent = ({originalString}) => {
//     console.log("originalString ",originalString)
//     // Example string containing LaTeX tags
//     // const originalString = 'This is a <latex>\\frac{1}{2}</latex> equation and <latex>e^{i\\pi} + 1 = 0</latex>.';

//     // Function to process LaTeX content between <latex> tags
//     const processLatexContent = (str) => {
//         // Regular expression to match <latex>...</latex> tags
//         const latexRegex = /<latex>(.*?)<\/latex>/gs;

//         // const latexRegex = '\\((.*?) \\)';

//         // Replace LaTeX content with processed HTML
//         const processedString = str.replace(latexRegex, (match, latexContent) => {
//             // Example: Render LaTeX equations to HTML here
//             // For simplicity, just wrapping LaTeX content in a <span> for display
//             // return `<span style="color: blue;">${latexContent}</span>`;
//             // return <LatexParserComponent latex = {latexContent}/>;
//             console.log("latexContent ",latexContent)
//             const mathjaxdata = <MathJax.Provider>
//             <div>
//               <MathJax.Node formula={'\( '+latexContent+' \)'} />
//             </div>
//           </MathJax.Provider>;
//           console.log("mathjaxda", mathjaxdata);
//             return (mathjaxdata);
//         });
//         console.log("processedString ", processedString)
//         return processedString;
//     };

//     // State to hold processed string
//     const [processedString, setProcessedString] = useState(processLatexContent(originalString));

//     return (
//         <div>
//             {/* <p>Original String: {originalString}</p> */}
//             {/* <p>Processed String: {processedString}</p> */}
//             {processedString}
//         </div>
//     );
// };

// export default LatexProcessorComponent;

// const LatexProcessorComponent = ({originalString}) => {
//         useEffect(() => {
//             console.log("originalString ",originalString);
//             // Load MathJax script dynamically
//             const script = document.createElement('script');
//             script.async = true;
//             script.type = 'text/javascript';
//             script.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-MML-AM_CHTML';
//             document.head.appendChild(script);

//             return () => {
//                 // Clean up MathJax on component unmount
//                 document.head.removeChild(script);
//             };
//         }, []);

//         return ({originalString}
//         );
//     };

// export default LatexProcessorComponent;

// const LatexProcessorComponent = ({originalString}) => {
//     // Example string containing LaTeX tags
//     // const originalString = 'This is a <latex>\\frac{1}{2}</latex> equation and <latex>e^{i\\pi} + 1 = 0</latex>.';

//     // Function to split and process LaTeX content
//     const processLatexContent = (str) => {
//         // Regular expression to match <latex>...</latex> tags
//         const latexRegex = /<latex>(.*?)<\/latex>/gs;

//         // Initialize variables to store processed parts and positions
//         let parts = [];
//         let positions = [];

//         // Use index to track position
//         let index = 0;

//         // Process string using regex
//         let match;
//         while ((match = latexRegex.exec(str)) !== null) {
//             // Push the text before the match
//             parts.push(str.substring(index, match.index));
//             positions.push(parts.length); // Store position in parts array

//             // Push the matched LaTeX content (you can process it here if needed)
//             parts.push(match[1]);

//             // Update index to continue search
//             index = latexRegex.lastIndex;
//         }

//         // Push remaining part of the string
//         parts.push(str.substring(index));

//         // Return processed parts and positions
//         return { parts, positions };
//     };

//     // Process the original string
//     const { parts, positions } = processLatexContent(originalString);

//     // Render the processed content
//     return (
//         <div>
//             <p>Original String: {originalString}</p>
//             <p>Processed String:{parts}</p>
//             <p>
//                 {parts.map((part, index) => (
//                     <span key={index}>
//                         {part}
//                         {positions.includes(index + 1) && (
//                             <LatexParserComponent  latexCode = {parts} />
//                         )}
//                     </span>
//                 ))}
//             </p>
//         </div>
//     );
// };

// export default LatexProcessorComponent;

const LatexProcessorComponent = ({ originalString }) => {
  // console.log(originalString, "originalString");
  // Example string containing LaTeX tags
  // const originalString = 'This is a <latex>\\frac{1}{2}</latex> equation and <latex>e^{i\\pi} + 1 = 0</latex>.';

  // Function to split and process LaTeX content
  const processLatexContent = (str) => {
    // Regular expression to match <latex>...</latex> tags
    // const latexRegex = /<latex>(.*?)<\/latex>/gs;
    const latexRegex = /\\\((.*?)\\\)/g;

    // Initialize arrays to store matched and unmatched parts
    let matchedParts = [];
    let unmatchedParts = [];
    let originalStringParts = [];
    // Use index to track position
    let index = 0;

    // Process string using regex
    let match;
    while ((match = latexRegex.exec(str)) !== null) {
      // Push the text before the match as unmatched
      if (match.index > index) {
        unmatchedParts.push({
          type: "unmatched",
          content: str.substring(index, match.index),
        });
        originalStringParts.push({
          type: "unmatched",
          content: str.substring(index, match.index),
        });
      }

      // Push the matched LaTeX content (you can process it here if needed)
      matchedParts.push({
        type: "matched",
        content: match[1],
        position: match.index,
      });

      originalStringParts.push({
        type: "matched",
        content: match[1],
        position: match.index,
      });

      // Update index to continue search
      index = latexRegex.lastIndex;
    }

    // Push remaining part of the string as unmatched
    if (index < str.length) {
      unmatchedParts.push({
        type: "unmatched",
        content: str.substring(index),
      });
      originalStringParts.push({
        type: "unmatched",
        content: str.substring(index),
      });
    }

    // Return processed parts
    return { matchedParts, unmatchedParts, originalStringParts };
  };

  // Process the original string
  const { matchedParts, unmatchedParts, originalStringParts } =
    processLatexContent(originalString);

  // Render the processed content
  return (
    <>
      {originalStringParts.map((part, index) =>
        part.type == "matched" ? (
          <MathJax.Provider>
            <MathJax.Node formula={part.content} />
          </MathJax.Provider>
        ) : (
          parse(part.content)
        )
      )}
    </>
  );
};

export default LatexProcessorComponent;
