import moment from 'moment';
import React from 'react'

export default function TimeTakenSection(props) {

  const secondsToformat = (seconds) => {
    console.log(seconds,"seconds")
    const duration = moment.duration(seconds, 'seconds');
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;
    const formattedTime = `${hours > 0 ? hours + 'h ' : ''}${minutes}m:${duration.seconds()}s`;
    return formattedTime;
  } 
  return (
    <div class="col-md-12 mb-2">
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="forms ">
          <label
            for="inputName"
            class="form-label text-muted mt-2 "
          >
            Time Taken by you
          </label>
          <span class="ReviewLabel_sub">{secondsToformat(props.Timeel.time_taken)}</span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="forms ">
          <label
            for="inputName"
            class="form-label text-muted mt-2 "
          >
            Optimum Time
          </label>
          <span class="ReviewLabel_sub">{secondsToformat(props.Timeel.optimum_time)}</span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="forms ">
          <label
            for="inputName"
            class="form-label text-muted mt-2 "
          >
            Time Taken by Topper
          </label>
          <span class="ReviewLabel_sub"> {secondsToformat(props.Timeel.top_time_taken) }</span>
        </div>
      </div>
    </div>
  </div>
  )
}
