import React from "react";
import "./Question.css";
import parse from "html-react-parser";

//display type 10 and para_que 2   means - para question  mcq design

function Qquesparatype(props) {
  // console.log(props);
  const { index } = props;
  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  const optionType = props.list.options_type;

  const generateOptions = (optionType) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];

    for (let i = 0; i < optionType; i++) {
      elements.push(
        <div key={i} className="col-md-12 d-flex mt-1">
          <div className="forms_input">
            <input
              type="radio"
              className="radioInput"
              name={`q_answer_${props.index}_en`}
              value={props.list[`options_en_${options[i]}`]}
              checked={props.userInput.some(
                (item) =>
                  item.pk === props.list.pk && item.UserOptionSec === options[i]
              )}
              onChange={(e) =>
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  1,
                  options[i],
                  props.list.subject_type
                )
              }
            />{" "}
            <span
              className="answer_label"
              onClick={(e) => {
                console.log("Span clicked for option:", options[i]);
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  1,
                  options[i],
                  props.list.subject_type
                );
              }}
            >
              ({options[i]})
            </span>
          </div>
          <div>
            <span
              className="answer_label"
              onClick={(e) => {
                console.log("Span clicked for option:", options[i]);
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  1,
                  options[i],
                  props.list.subject_type
                );
              }}
            >
              {parse(props.list[`options_en_${options[i]}`])}{" "}
            </span>
          </div>
        </div>
      );
    }

    return elements;
  };

  const generateOptionsTamil = (optionType) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];

    for (let i = 0; i < optionType; i++) {
      elements.push(
        <div key={i} className="col-md-12 d-flex mt-1">
          <div className="forms_input">
            <input
              type="radio"
              className="radioInput"
              name={`q_answer_${props.index}_tm`}
              value={props.list[`options_tm_${options[i]}`]}
              checked={props.userInput.some(
                (item) =>
                  item.pk === props.list.pk && item.UserOptionSec === options[i]
              )}
              onChange={(e) =>
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  options[i],
                  props.list.subject_type
                )
              }
            />{" "}
            <span
              className="answer_label_tamil"
              onClick={(e) => {
                console.log("Span clicked for option:", options[i]);
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  1,
                  options[i],
                  props.list.subject_type
                );
              }}
            >
              ({options[i]})
            </span>
          </div>
          <div>
            <span
              className="answer_label_tamil"
              onClick={(e) => {
                console.log("Span clicked for option:", options[i]);
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  1,
                  options[i],
                  props.list.subject_type
                );
              }}
            >
              {parse(props.list[`options_tm_${options[i]}`])}{" "}
            </span>
          </div>
        </div>
      );
    }

    return elements;
  };

  return (
    <div className="Question_temp mb-3  mt-1">
      {props.list.en_question !== null &&
        props.list.en_question !== "" &&
        (props.list.lang_type === 1 ||
          (props.list.lang_type === 2 &&
            (props.Lang === 1 || props.Lang === 2))) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <span className="question_labels_english">
                {props.list.en_question}
              </span>
            </div>
            <div className="col-md-12 ">
              <div className="row">{generateOptions(optionType)}</div>
            </div>
          </div>
        )}

      {props.list.tm_question !== null &&
        props.list.tm_question !== "" &&
        (props.list.lang_type === 0 ||
          (props.list.lang_type === 2 &&
            (props.Lang === 0 || props.Lang === 2))) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <span className="question_labels_tamil">
                {props.list.tm_question}
              </span>
            </div>
            <div className="col-md-12">
              <div className="row">{generateOptionsTamil(optionType)}</div>
            </div>
          </div>
        )}
    </div>
  );
}

export default Qquesparatype;
