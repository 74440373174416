import React, { useEffect, useRef, useState } from "react";
import ICON_ARROW_RIGHT from "@mui/icons-material/KeyboardArrowRight";
import ICON_ARROW_LEFT from "@mui/icons-material/KeyboardArrowLeft";
import IconButton from "@mui/material/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, DialogContent, Tooltip } from "@mui/material";
import styled from "styled-components";
import ICON_EYE from "@mui/icons-material/Visibility";
import ICON_SORT from "@mui/icons-material/SwapVert";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import FloatedTitleContainer from "../../../../ui/FloatedTitleContainer";
import {
  MEDIA_SIZE_LG,
  MEDIA_SIZE_MD,
  // capitalizeFirstLetter,
} from "../../../../utils/helper";
import CustomContainer from "../../../../ui/CustomContainer";
import StyledTitle from "../../../../ui/StyledTitle";
import SecondaryButton from "../../../../ui/SecondaryButton";
import PrimaryButton from "../../../../ui/PrimaryButton";
import ButtonContainer from "../../../../ui/ButtonContainer";
import SkeletonLoaderPage from "../../../../ui/SkeletonLoaderPage";
import {
  apiStudentDetailsByCentre,
  apiViewModelExamAnalytics,
  apiViewModelExamAnalyticsDistrictAndCentreList,
} from "../../../../services/services";
import { ImageUrlPrefix } from "../../../../labels";
import toast from "react-hot-toast";

// squad styles
const SquadContainer = styled.div`
  padding: 5px;
`;
const SquadContentBox = styled.div`
  border: 1px solid var(--color-text-primary);
  border-radius: var(--border-radius-sm);
`;
const SquadRank = styled.p`
  font-size: 32px;
  color: var(--color-brand-800);
  font-style: italic;
  margin: 0;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    font-size: 64px;
  }
`;
const SquadImg = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  /* box-shadow: var(--shadow-lg); */
`;

const SquadName = styled.p`
  font-size: 18px;
  font-weight: var(--font-weight-medium);
  color: var(--color-brand-800);
  text-transform: capitalize;
  margin: 8px 0;
`;
const SquadHeader = styled.p`
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  color: var(--color-text-secondary);
  text-transform: capitalize;
  margin: 0;
  margin-bottom: 0px;
`;
const SquadText = styled.p`
  font-size: 16px;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
  margin: 0;
  margin-bottom: 12px;
  word-wrap: break-word;
`;
//title

const StyledSelectContainer = styled.div`
  display: flex;
  justify-content: end;
  /* margin-right: 20px; */
  margin-top: 35px;
  @media (min-width: ${MEDIA_SIZE_MD}) {
    margin-top: 20px;
  }
`;
const StyledSelect = styled.select`
  && {
    border: 1px solid var(--color-secondary-border);
    color: var(--color-text-primary);
    padding: 5px 10px 5px 5px;
    font-size: 14px;
    background: none !important;
    appearance: auto;
    border-radius: 6px;
    &:focus,
    :focus-visible {
      outline: none;
      border-color: var(--color-secondary-border);
    }
    @media (min-width: ${MEDIA_SIZE_MD}) {
      padding-right: 100px;
    }
  }
`;
// scroll-sections
const StyledScrollSection = styled.section`
  margin: 20px 0;
  position: relative;
`;

const ScrollButton = styled.button`
  border: none;
  outline: none;
  background: none;
  padding-bottom: 10px;
  font-size: 12px;
  /* color: var(--color-text-primary); */
  padding: 0px 30px;
  /* margin: 0px 15px; */
  padding-bottom: 10px;
  /* border-bottom: 1px solid var(--color-secondary-border); */
  border-bottom: 2px solid transparent;
  color: ${(props) => (props.isSelected ? "#c1393d" : "#041134")};
  font-weight: ${(props) => (props.isSelected ? 700 : 500)};
  &:hover {
    border-bottom: 2px solid var(--color-brand-800);
    color: var(--color-brand-800);
    font-weight: var(--font-weight-medium);
  }
`;
const CustomScrollbar = styled.div`
  margin: 0px 15px;
  /* width: 95%; */
  border-bottom: 1px solid var(--color-secondary-border);
  white-space: nowrap;
  display: block;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const SELECT_STATE = 1;
const SELECT_DISTRICT = 2;
const SELECT_CENTRE = 3;
const ICON_SORT_STYLES = {
  fontSize: "16px",
  color: true ? "rgba(4,17,52,0.5)" : "inherit",
};

function ViewModelExamAnalytics() {
  // -----> HOOKS <-----
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [analyticsData, setanalyticsData] = useState({
    isLoading: true,
    isSelectLoading: false,
    examName: "",
    examTitle: "",
    analyticsSelectType: SELECT_STATE,
    districtList: [],
    centreList: [],
    squadDetails: [],
    selectedDistrict: "",
    selectedCentre: "",
    //Attendance Overview
    totalStudentsEntrolled: 0,
    totalStudentsPresent: 0,
    totalStudentsAbsent: 0,
    totalMaleStudentsPresent: 0,
    totalFemaleStudentsPrsent: 0,
    totalTransgenderStudentsPresent: 0,
  });

  const ListItem = styled("li")(({ theme }) => ({
    margin: 0.5,
  }));

  const [studentsList, setStudentsList] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const districtListRef = useRef();
  const navigate = useNavigate();
  const htmlAnalyticsDistrict = useRef();
  const htmlAnalyticsCentre = useRef();
  const updateListData = [];
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // -----> DERIVED-STATE <-----

  const BATCH_ID = location.state.batch_id;
  const EXAM_ID = location.state.exam_id;
  const EVENT_PK = location.state.event_pk || 0;
  const PAPER_TYPE = location.state.paper_type;

  // console.log(EXAM_ID, "EXAM_IDEXAM_IDEXAM_IDEXAM_ID");

  const [searchTerm, setSearchTerm] = useState("");
  const [updateChipData, setUpdateChipData] = useState([]);
  const [gender, setGender] = useState([]);
  //chip data list view
  const totalPages = Math.ceil(studentsList.length / rowsPerPage);
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = studentsList.slice(start, end);
  const [isOpen, setIsOpen] = useState({
    gender: false,
  });
  let filterGender = [];
  let filterSearch = [];

  useEffect(() => {
    filterGender = gender;
  }, [gender]);
  useEffect(() => {
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }, [searchTerm]);
  useEffect(() => {
    loadChipdata();
  }, []);

  useEffect(() => {
    loadListdata();
  }, [updateChipData]);

  function handleGenerateReport() {
    // return navigate("/deleteme");
    return;
  }
  // -->custome scroll-events-start
  function handleDistrictLeft() {
    const currentScroll = htmlAnalyticsDistrict.current.scrollLeft;
    const maxScroll = 1;
    if (currentScroll < maxScroll) {
      htmlAnalyticsDistrict.current.scrollLeft =
        htmlAnalyticsDistrict.current.scrollWidth;
    } else {
      htmlAnalyticsDistrict.current.scrollLeft -= 120;
    }
  }

  // handle search function
  const handleSearch = (event) => {
    // console.log(event);
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      updateStateData();
      setSearchTerm(val);
      if (val.length > 0) {
        filterSearch = [{ name: "" + val, type: "search" }];
      } else {
        filterSearch = [];
      }
      loadChipdata();
    }
  };

  //load chip data
  function loadChipdata() {
    //setUpdateChipData([]);
    let tempArr = [];
    // setUpdateChipData(status);
    filterSearch.map((obj) => {
      tempArr.push(obj);
    });
    filterGender.map((obj) => {
      tempArr.push(obj);
    });
    // console.log(tempArr, "tempArr");
    setUpdateChipData(tempArr);
  }
  function handleDistrictRight() {
    // add minus 10 avoid logical issues in responsiveness
    const maxScrollLeft =
      htmlAnalyticsDistrict.current.scrollWidth -
      htmlAnalyticsDistrict.current.clientWidth -
      10;
    const currentScroll = htmlAnalyticsDistrict.current.scrollLeft;
    if (currentScroll > maxScrollLeft) {
      htmlAnalyticsDistrict.current.scrollLeft = 0;
    } else {
      htmlAnalyticsDistrict.current.scrollLeft += 120;
    }
  }
  function handleCentreLeft() {
    const currentScroll = htmlAnalyticsCentre.current.scrollLeft;
    const maxScroll = 1;
    if (currentScroll < maxScroll) {
      htmlAnalyticsCentre.current.scrollLeft =
        htmlAnalyticsCentre.current.scrollWidth;
    } else {
      htmlAnalyticsCentre.current.scrollLeft -= 340;
    }
  }
  function handleCentreRight() {
    const maxScrollLeft =
      htmlAnalyticsCentre.current.scrollWidth -
      htmlAnalyticsCentre.current.clientWidth -
      10;
    const currentScroll = htmlAnalyticsCentre.current.scrollLeft;
    if (currentScroll > maxScrollLeft) {
      htmlAnalyticsCentre.current.scrollLeft = 0;
    } else {
      htmlAnalyticsCentre.current.scrollLeft += 340;
    }
  }
  // -->custome scroll-events-end
  // -->list-events-start
  function loadListdata() {
    let tempGender = [];
    gender.map((obj) => {
      tempGender.push(obj.name);
    });
    const formDataStList = {
      batch_id: BATCH_ID,
      exam_id: EXAM_ID,
      center_id: analyticsData.selectedCentre,
      gender: gender.length > 0 ? tempGender : "",
      no_of_exams: "",
      search: searchTerm.length > 0 ? searchTerm : "",
    };
    if (PAPER_TYPE === 1) {
      formDataStList.event_pk = EVENT_PK;
    }
    apiStudentDetailsByCentre(formDataStList)
      .then((resStList) => {
        if (resStList.status) {
          const apiDataStList = resStList.data;
          const convertedApiList = apiDataStList.map((ele, index) => ({
            ...ele,
            serialNumber: index + 1,
          }));
          setStudentsList(convertedApiList);
          return true;
        } else {
          setStudentsList([]);
        }
      })
      .catch((errStList) => {
        console.error(errStList);
        handleSelectLoading(false);
      });
  }
  function handleChange(e) {
    const { value, name } = e.target;
    if (name === "selectType") {
      setanalyticsData((prevState) => ({
        ...prevState,
        isSelectLoading: true,
      }));
      if (+value === SELECT_DISTRICT) {
        const formData = {
          id: EXAM_ID,
          batch_id: BATCH_ID,
          district: analyticsData.districtList[0].value,
          center: "",
        };
        if (PAPER_TYPE === 1) {
          formData.event_pk = EVENT_PK;
        }
        apiViewModelExamAnalytics(formData)
          .then((res) => {
            if (res.status) {
              const apiData = res.data;
              const tempSqudData = apiData.data.map((squad) => ({
                first_name: squad.fullname,
                marks_obtained: squad.marks_obtained,
                admissionID: squad.admission_id,
                examDistrict: squad.district_name,
                phoneNumber: squad.phone_no,
                examCentre: squad.exam_center,
                emailID: squad.email,
                centerSupervisorName: squad.staff_name,
                centerSupervisorNumber: squad.mobile_no,
                profilePath: squad.profile_path,
                totalMarks: squad.tot_marks,
                rank: squad.ranks,
              }));

              setanalyticsData((prevState) => ({
                ...prevState,
                isSelectLoading: false,
                analyticsSelectType: +value,
                selectedCentre: "", // i added
                selectedDistrict: prevState.districtList[0].value,
                totalStudentsEntrolled: apiData.attendance.total_count,
                totalStudentsPresent:
                  apiData.attendance.attendance_details.present,
                totalStudentsAbsent:
                  apiData.attendance.attendance_details.absent,
                totalMaleStudentsPresent: apiData.attendance.male_count,
                totalFemaleStudentsPrsent: apiData.attendance.female_count,
                totalTransgenderStudentsPresent:
                  apiData.attendance.transgender_count,
                squadDetails: tempSqudData,
              }));
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.error("apiViewModelExamAnalytics");
            setanalyticsData((prevState) => ({
              ...prevState,
              isSelectLoading: false,
            }));
          });
      } else if (+value === SELECT_CENTRE) {
        let tempSelectedDistrict = analyticsData.districtList[0].value;
        if (analyticsData.selectedDistrict > 0) {
          tempSelectedDistrict = analyticsData.selectedDistrict;
        }
        const tempCentreList = districtListRef.current.filter(
          (ele) => ele.district_id === tempSelectedDistrict
        );

        const centreList = tempCentreList.map((ele) => ({
          name: ele.center_name,
          value: ele.center_id,
        }));
        const formData = {
          id: EXAM_ID,
          batch_id: BATCH_ID,
          district: tempSelectedDistrict,
          center: centreList[0].value,
        };
        if (PAPER_TYPE === 1) {
          formData.event_pk = EVENT_PK;
        }
        apiViewModelExamAnalytics(formData)
          .then((res) => {
            if (res.status) {
              const apiData = res.data;
              if (res.status === true) {
                const tempSqudData = apiData.data.map((squad) => ({
                  first_name: squad.fullname,
                  marks_obtained: squad.marks_obtained,
                  admissionID: squad.admission_id,
                  examDistrict: squad.district_name,
                  phoneNumber: squad.phone_no,
                  examCentre: squad.exam_center,
                  emailID: squad.email,
                  centerSupervisorName: squad.staff_name,
                  centerSupervisorNumber: squad.mobile_no,
                  profilePath: squad.profile_path,
                  totalMarks: squad.tot_marks,
                  rank: squad.ranks,
                }));
                const formDataStList = {
                  batch_id: BATCH_ID,
                  exam_id: EXAM_ID,
                  center_id: centreList[0].value,
                  gender: [],
                  no_of_exams: "",
                  search: "",
                };
                if (PAPER_TYPE === 1) {
                  formDataStList.event_pk = EVENT_PK;
                }
                apiStudentDetailsByCentre(formDataStList)
                  .then((resStList) => {
                    if (resStList.status) {
                      setUpdateChipData([]);
                      setGender([]);
                      const apiDataStList = resStList.data;

                      setanalyticsData((prevState) => ({
                        ...prevState,
                        isSelectLoading: false,
                        analyticsSelectType: +value,
                        centreList: centreList,
                        selectedDistrict: tempSelectedDistrict,
                        selectedCentre: centreList[0].value,
                        totalStudentsEntrolled: apiData.attendance.total_count,
                        totalStudentsPresent:
                          apiData.attendance.attendance_details.present,
                        totalStudentsAbsent:
                          apiData.attendance.attendance_details.absent,
                        totalMaleStudentsPresent: apiData.attendance.male_count,
                        totalFemaleStudentsPrsent:
                          apiData.attendance.female_count,
                        totalTransgenderStudentsPresent:
                          apiData.attendance.transgender_count,
                        squadDetails: tempSqudData,
                      }));
                      const convertedApiList = apiDataStList.map(
                        (ele, index) => ({ ...ele, serialNumber: index + 1 })
                      );
                      setStudentsList(convertedApiList);
                    }
                  })
                  .catch((errStList) => {
                    handleSelectLoading(false);
                  });
              } else {
                setanalyticsData((prevState) => ({
                  ...prevState,
                  isSelectLoading: false,
                  analyticsSelectType: +value,
                }));
                handleSelectLoading(false);
              }
            }
          })
          .catch((err) => {
            console.error("apiViewModelExamAnalytics");
            handleSelectLoading(false);
          });
      } else {
        const formData = {
          id: EXAM_ID,
          batch_id: BATCH_ID,
          district: "",
          center: "",
        };
        if (PAPER_TYPE === 1) {
          formData.event_pk = EVENT_PK;
        }
        apiViewModelExamAnalytics(formData)
          .then((res) => {
            if (res.status) {
              const apiData = res.data;
              const tempSqudData = apiData.data.map((squad) => ({
                first_name: squad.fullname,
                marks_obtained: squad.marks_obtained,
                admissionID: squad.admission_id,
                examDistrict: squad.district_name,
                phoneNumber: squad.phone_no,
                examCentre: squad.exam_center,
                emailID: squad.email,
                centerSupervisorName: squad.staff_name,
                centerSupervisorNumber: squad.mobile_no,
                profilePath: squad.profile_path,
                totalMarks: squad.tot_marks,
                rank: squad.ranks,
              }));
              setanalyticsData((prevState) => ({
                ...prevState,
                isSelectLoading: false,
                analyticsSelectType: +value,
                totalStudentsEntrolled: apiData.attendance.total_count,
                totalStudentsPresent:
                  apiData.attendance.attendance_details.present,
                totalStudentsAbsent:
                  apiData.attendance.attendance_details.absent,
                totalMaleStudentsPresent: apiData.attendance.male_count,
                totalFemaleStudentsPrsent: apiData.attendance.female_count,
                totalTransgenderStudentsPresent:
                  apiData.attendance.transgender_count,
                squadDetails: tempSqudData,
              }));
            } else {
              setanalyticsData((prevState) => ({
                ...prevState,
                isSelectLoading: false,
              }));
            }
          })
          .catch((err) => {
            // console.log("laksh-err,apiViewModelExamAnalytics-state-wise");
            setanalyticsData((prevState) => ({
              ...prevState,
              isSelectLoading: false,
            }));
          });
      }
    }
  }
  function handleSelectLoading(flag) {
    return setanalyticsData((prevState) => ({
      ...prevState,
      isSelectLoading: flag,
    }));
  }
  function handleScrollButton(value, type = 1) {
    // type-1=>district 2->centre
    if (type === 1) {
      // distric button
      // console.log("laksh-distbtn", analyticsData.analyticsSelectType);
      if (+value === analyticsData.selectedDistrict) {
        return;
      }
      handleSelectLoading(true);
      if (analyticsData.analyticsSelectType === SELECT_CENTRE) {
        // it will run when select type is centre stores both district and centre
        const tempCentreList = districtListRef.current.filter(
          (ele) => ele.district_id === +value
        );
        const centreList = tempCentreList.map((ele) => ({
          name: ele.center_name,
          value: ele.center_id,
        }));
        const formData = {
          id: EXAM_ID,
          batch_id: BATCH_ID,
          district: +value,
          center: centreList[0].value,
        };
        if (PAPER_TYPE === 1) {
          formData.event_pk = EVENT_PK;
        }

        apiViewModelExamAnalytics(formData)
          .then((res) => {
            if (res.status) {
              const formDataStList = {
                batch_id: BATCH_ID,
                exam_id: EXAM_ID,
                center_id: centreList[0].value,
                gender: "",
                no_of_exams: "",
                search: "",
              };
              if (PAPER_TYPE === 1) {
                formDataStList.event_pk = EVENT_PK;
              }
              apiStudentDetailsByCentre(formDataStList)
                .then((resSt) => {
                  if (resSt.status) {
                    const apiDataSt = resSt.data;
                    const convertedApiList = apiDataSt.map((ele, index) => ({
                      ...ele,
                      serialNumber: index + 1,
                    }));
                    const apiData = res.data;
                    const tempSqudData = apiData.data.map((squad) => ({
                      first_name: squad.fullname,
                      marks_obtained: squad.marks_obtained,
                      admissionID: squad.admission_id,
                      examDistrict: squad.district_name,
                      phoneNumber: squad.phone_no,
                      examCentre: squad.exam_center,
                      emailID: squad.email,
                      centerSupervisorName: squad.staff_name,
                      centerSupervisorNumber: squad.mobile_no,
                      profilePath: squad.profile_path,
                      totalMarks: squad.tot_marks,
                      rank: squad.ranks,
                    }));

                    setanalyticsData((prevState) => ({
                      ...prevState,
                      isSelectLoading: false,
                      centreList: centreList,
                      selectedDistrict: +value,
                      selectedCentre: centreList[0].value,
                      totalStudentsEntrolled: apiData.attendance.total_count,
                      totalStudentsPresent:
                        apiData.attendance.attendance_details.present,
                      totalStudentsAbsent:
                        apiData.attendance.attendance_details.absent,
                      totalMaleStudentsPresent: apiData.attendance.male_count,
                      totalFemaleStudentsPrsent:
                        apiData.attendance.female_count,
                      totalTransgenderStudentsPresent:
                        apiData.attendance.transgender_count,
                      squadDetails: tempSqudData,
                    }));
                    setStudentsList(convertedApiList);
                  } else {
                    handleSelectLoading(false);
                  }
                  // console.log("laksh-st)", resSt);
                })
                .catch((errSt) => {});
              // const apiData = res.data.data;
            }
          })
          .catch((err) => {
            console.error("apiViewModelExamAnalytics");
            setanalyticsData((prevState) => ({
              ...prevState,
              isSelectLoading: false,
            }));
          });
      } else {
        // it will run select-type is district,stores district details.
        const formData = {
          id: EXAM_ID,
          batch_id: BATCH_ID,
          district: value,
          center: "",
        };
        if (PAPER_TYPE === 1) {
          formData.event_pk = EVENT_PK;
        }
        apiViewModelExamAnalytics(formData)
          .then((res) => {
            if (res.status) {
              const apiData = res.data;
              const tempSqudData = apiData.data.map((squad) => ({
                first_name: squad.fullname,
                marks_obtained: squad.marks_obtained,
                admissionID: squad.admission_id,
                examDistrict: squad.district_name,
                phoneNumber: squad.phone_no,
                examCentre: squad.exam_center,
                emailID: squad.email,
                centerSupervisorName: squad.staff_name,
                centerSupervisorNumber: squad.mobile_no,
                profilePath: squad.profile_path,
                totalMarks: squad.tot_marks,
                rank: squad.ranks,
              }));
              setanalyticsData((prevState) => ({
                ...prevState,
                isSelectLoading: false,
                selectedDistrict: value,
                totalStudentsEntrolled: apiData.attendance.total_count,
                totalStudentsPresent:
                  apiData.attendance.attendance_details.present,
                totalStudentsAbsent:
                  apiData.attendance.attendance_details.absent,
                totalMaleStudentsPresent: apiData.attendance.male_count,
                totalFemaleStudentsPrsent: apiData.attendance.female_count,
                totalTransgenderStudentsPresent:
                  apiData.attendance.transgender_count,
                squadDetails: tempSqudData,
              }));
            }
          })
          .catch((err) => {
            console.error("apiViewModelExamAnalytics");
            setanalyticsData((prevState) => ({
              ...prevState,
              isSelectLoading: false,
            }));
          });
      }

      // return setanalyticsData((prevData) => ({
      //   ...prevData,
      //   selectedDistrict: value,
      // }));
    } else if (type === 2) {
      if (+value === analyticsData.selectedCentre) {
        return;
      }
      handleSelectLoading(true);
      // return console.log(
      //   "laksh-sc",
      //   +value,
      //   analyticsData.selectedCentre,
      //   analyticsData.isSelectLoading
      // );
      const formData = {
        id: EXAM_ID,
        batch_id: BATCH_ID,
        district: analyticsData.selectedDistrict,
        center: +value,
      };
      if (PAPER_TYPE === 1) {
        formData.event_pk = EVENT_PK;
      }
      apiViewModelExamAnalytics(formData)
        .then((res) => {
          if (res.status) {
            const formDataStList = {
              batch_id: BATCH_ID,
              exam_id: EXAM_ID,
              center_id: +value,
              gender: "",
              no_of_exams: "",
              search: "",
            };
            if (PAPER_TYPE === 1) {
              formDataStList.event_pk = EVENT_PK;
            }
            apiStudentDetailsByCentre(formDataStList)
              .then((resSt) => {
                if (resSt.status) {
                  const apiDataSt = resSt.data;
                  const convertedApiList = apiDataSt.map((ele, index) => ({
                    ...ele,
                    serialNumber: index + 1,
                  }));
                  const apiData = res.data;
                  const tempSqudData = apiData.data.map((squad) => ({
                    first_name: squad.fullname,
                    marks_obtained: squad.marks_obtained,
                    admissionID: squad.admission_id,
                    examDistrict: squad.district_name,
                    phoneNumber: squad.phone_no,
                    examCentre: squad.exam_center,
                    emailID: squad.email,
                    centerSupervisorName: squad.staff_name,
                    centerSupervisorNumber: squad.mobile_no,
                    profilePath: squad.profile_path,
                    totalMarks: squad.tot_marks,
                    rank: squad.ranks,
                  }));
                  // console.log("value-res", +value);
                  setanalyticsData((prevState) => ({
                    ...prevState,
                    isSelectLoading: false,
                    selectedCentre: +value,
                    totalStudentsEntrolled: apiData.attendance.total_count,
                    totalStudentsPresent:
                      apiData.attendance.attendance_details.present,
                    totalStudentsAbsent:
                      apiData.attendance.attendance_details.absent,
                    totalMaleStudentsPresent: apiData.attendance.male_count,
                    totalFemaleStudentsPrsent: apiData.attendance.female_count,
                    totalTransgenderStudentsPresent:
                      apiData.attendance.transgender_count,
                    squadDetails: tempSqudData,
                  }));
                  setStudentsList(convertedApiList);
                } else {
                  handleSelectLoading(false);
                }
              })
              .catch((err) => {
                handleSelectLoading(false);
              });

            // const apiData = res.data.data;
          }
        })
        .catch((err) => {
          console.error("apiViewModelExamAnalytics");
          setanalyticsData((prevState) => ({
            ...prevState,
            isSelectLoading: false,
          }));
        });
      // return setanalyticsData((prevData) => ({
      //   ...prevData,
      //   selectedCentre: value,
      //   isSelectLoading: false,
      // }));
    }
  }
  // st-list statrt
  const handleSort = (event, sortKey) => {
    event.preventDefault();
    // console.log("sort", event, sortKey);

    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    let sortedList = [...studentsList].sort((a, b) => {
      let aValue = a;
      let bValue = b;

      // Split the sortKey to handle nested properties
      sortKey.split(".").forEach((key) => {
        aValue = aValue ? aValue[key] : null;
        bValue = bValue ? bValue[key] : null;
      });
      // console.log("a", aValue);
      if (aValue < bValue) {
        return order === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });

    setStudentsList(sortedList);
  };

  function updateStateData() {
    filterGender = gender;
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }

  // //   checking once again  -  handle delete function
  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
    } else if (chipToDelete.type === "gender") {
      setGender(gender.filter((item) => item.id !== chipToDelete.id));
      filterGender = gender.filter((item) => item.id !== chipToDelete.id);
    }
    loadChipdata();
  };

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "gender") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        gender: false,
      });
    }
  };

  // filter clear button
  const handleClear = (name) => {
    updateStateData();
    if (name === "gender") {
      setGender([]);
      filterGender = [];
      setIsOpen({
        ...isOpen,
        gender: false,
      });
    }
    loadChipdata();
  };

  const handleCheckboxChange = (event, names, typename) => {
    const { value, checked, name } = event.target;
    if (names === "male" || names === "female" || names === "transgender") {
      if (checked) {
        // setStatus([...status, value]);
        setGender([...gender, { id: value, name: name, type: "gender" }]);
      } else {
        setGender(gender.filter((item) => item.id !== value));
      }
    }
  };

  const handleFilter = (e, dame) => {
    if (dame === "gender") {
      setIsOpen((prevState) => ({
        ...prevState,
        gender: !prevState.gender,
      }));
    }
  };
  //chips overall clear button
  const handleAllClear = () => {
    setSearchTerm("");
    setGender([]);
    setUpdateChipData([]);
    // setStudentsList([]);
  };

  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;
    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      setStudentsList([]);
    }
    loadListdata();
  };
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  const handleNext = (val) => {
    setCurrentPage(val);
  };
  // -->list-events-end

  // -----> EFFECTS <-----
  useEffect(() => {
    htmlAnalyticsDistrict.current =
      document.getElementById("analytics-district");
    htmlAnalyticsCentre.current = document.getElementById("analytics-centre");
  }, [analyticsData.analyticsSelectType]);
  useEffect(() => {
    // 1->PAYLOAD
    const formData = {
      id: EXAM_ID,
      // event_pk: "54",
      batch_id: BATCH_ID,
      district: "",
      center: "",
    };
    if (PAPER_TYPE === 1) {
      formData.event_pk = EVENT_PK;
    }
    const formDataDistrictList = {
      batch_id: BATCH_ID,
      exam_detail_id: EXAM_ID,
    };

    // 2->PROMISES
    const promises = [
      apiViewModelExamAnalytics(formData),
      apiViewModelExamAnalyticsDistrictAndCentreList(formDataDistrictList),
    ];
    Promise.allSettled(promises)
      .then((results) => {
        // 3->VARIABLES
        let apiData;
        let tempDistrictData;
        let tempDistrictList = [];
        let tempCentreList = [];
        // 4->LOOP
        results.forEach((result, index) => {
          const { status, value } = result;
          if (index === 0) {
            if (value.status) {
              apiData = value.data;
            } else {
              console.error("apiViewModelExamAnalytics api error");
            }
          } else if (index === 1) {
            if (value.status) {
              // apiData = value.data;
              tempDistrictData = value.data;
              const districtSet = new Set();
              value.data.forEach((ele) => {
                districtSet.add(ele.district_id);
                tempCentreList.push({
                  name: ele.center_name,
                  value: ele.center_id,
                });
                districtSet.add(ele.district_id);
              });
              const districtSetArray = Array.from(districtSet);
              const districtSetArrayWithCentre = districtSetArray.map((ele) => {
                return value.data.find(
                  (district) => district.district_id === ele
                );
              });
              const finalDistrictList = districtSetArrayWithCentre.map(
                (ele) => ({
                  name: ele.district_name,
                  value: ele.district_id,
                })
              );
              tempDistrictList = finalDistrictList;
            } else {
              console.error(
                "apiViewModelExamAnalyticsDistrictAndCentreList api error"
              );
            }
          } else {
            throw new Error("un-handled api response from apilist");
          }
        });
        // 5->STATE-UPDATE
        const tempSqudData = apiData.data.map((squad) => ({
          first_name: squad.fullname,
          marks_obtained: squad.marks_obtained,
          admissionID: squad.admission_id,
          examDistrict: squad.district_name,
          phoneNumber: squad.phone_no,
          examCentre: squad.exam_center,
          emailID: squad.email,
          centerSupervisorName: squad.staff_name,
          centerSupervisorNumber: squad.mobile_no,
          profilePath: squad.profile_path,
          totalMarks: squad.tot_marks,
          rank: squad.ranks,
        }));
        const districtList = tempDistrictList.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        const centreList = tempCentreList.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        setanalyticsData((prevData) => ({
          ...prevData,
          totalStudentsEntrolled: apiData.attendance.total_count,
          totalStudentsPresent: apiData.attendance.attendance_details.present,
          totalStudentsAbsent: apiData.attendance.attendance_details.absent,
          totalMaleStudentsPresent: apiData.attendance.male_count,
          totalFemaleStudentsPrsent: apiData.attendance.female_count,
          totalTransgenderStudentsPresent: apiData.attendance.transgender_count,
          examName: apiData.exam.name,
          examTitle: apiData.exam.exam_title,
          squadDetails: tempSqudData,
          districtList: districtList,
          centreList: centreList,
        }));
        districtListRef.current = tempDistrictData;
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, []);
  if (isLoading) {
    return <SkeletonLoaderPage />;
  }
  return (
    <CustomContainer>
      <section
        id="title"
        className="row justify-content-center align-items-center p-0"
        // style={{ margin: "10px 0" }}
      >
        <div className="col-12 col-lg-6">
          <StyledTitle>
            <span
              style={{ textTransform: "capitalize" }}
            >{`View Model Exam Analytics - ${analyticsData.examName} - ${analyticsData.examTitle}`}</span>
          </StyledTitle>
        </div>
        <div className="col-12 col-lg-6">
          <ButtonContainer>
            <SecondaryButton onClick={() => navigate(-1)}>
              Back to Model Exam Analytics
            </SecondaryButton>
            <PrimaryButton
              onClick={() => handleGenerateReport()}
              style={{ display: "none" }}
            >
              Generate Report
            </PrimaryButton>
          </ButtonContainer>
        </div>
      </section>
      <section id="search">
        {/* SEARCH */}
        <div className="">
          <StyledSelectContainer>
            <StyledSelect
              onChange={handleChange}
              name="selectType"
              value={analyticsData.analyticsSelectType}
            >
              <option value={SELECT_STATE}>State-wise Analytics</option>
              <option value={SELECT_DISTRICT}>District-wise Analytics</option>
              <option value={SELECT_CENTRE}>Centre-wise Analytics</option>
            </StyledSelect>
          </StyledSelectContainer>
        </div>
      </section>
      {analyticsData.analyticsSelectType !== SELECT_STATE && (
        <StyledScrollSection>
          <Tooltip title="scroll-left" placement="top">
            <IconButton
              id="slideRight"
              onClick={handleDistrictLeft}
              style={{
                position: "absolute",
                top: "3px",
                left: "0px",
                padding: "0px",
                backgroundColor: "#fff",
              }}
            >
              <ICON_ARROW_LEFT color="error" />
            </IconButton>
          </Tooltip>

          {/* <div> */}
          <CustomScrollbar id="analytics-district">
            {analyticsData.districtList.map((district, index, arr) => (
              <>
                <ScrollButton
                  key={district.value}
                  onClick={() => handleScrollButton(district.value)}
                  isSelected={district.value === analyticsData.selectedDistrict}
                >
                  {district.name}
                </ScrollButton>
              </>
            ))}
          </CustomScrollbar>
          {/* </div> */}
          <Tooltip title="scroll-right" placement="top">
            <IconButton
              id="slideLeft"
              onClick={handleDistrictRight}
              style={{
                position: "absolute",
                top: "3px",
                right: "0px",
                padding: "0px",
                backgroundColor: "#fff",
              }}
            >
              <ICON_ARROW_RIGHT color="error" />
            </IconButton>
          </Tooltip>
        </StyledScrollSection>
      )}
      {analyticsData.analyticsSelectType === SELECT_CENTRE && (
        <StyledScrollSection>
          <Tooltip title="scroll-left" placement="top">
            <IconButton
              id="slideRight"
              onClick={handleCentreLeft}
              style={{
                position: "absolute",
                top: "3px",
                left: "0px",
                padding: "0px",
                backgroundColor: "#fff",
              }}
            >
              <ICON_ARROW_LEFT color="error" />
            </IconButton>
          </Tooltip>

          <CustomScrollbar id="analytics-centre">
            {analyticsData.centreList.map((centre, index, arr) => (
              <>
                <ScrollButton
                  key={centre.value}
                  onClick={() => handleScrollButton(centre.value, 2)}
                  isSelected={centre.value === analyticsData.selectedCentre}
                >
                  {centre.name}
                </ScrollButton>
              </>
            ))}
          </CustomScrollbar>
          <Tooltip title="scroll-right" placement="top">
            <IconButton
              id="slideLeft"
              onClick={handleCentreRight}
              style={{
                position: "absolute",
                top: "3px",
                right: "0px",
                padding: "0px",
                backgroundColor: "#fff",
              }}
            >
              <ICON_ARROW_RIGHT color="error" />
            </IconButton>
          </Tooltip>
        </StyledScrollSection>
      )}
      {/* SQUAD */}
      <FloatedTitleContainer
        title={`Top ${
          analyticsData.analyticsSelectType === SELECT_CENTRE ? 3 : 10
        } Squad`}
        className="mt-5"
      >
        <div
          className="row align-items-center  p-0 "
          style={{ margin: "10px" }}
        >
          {analyticsData.squadDetails.map((squad, index) => {
            // const splittedTime = squad.duration_completed.split(":");
            return (
              <SquadContainer className="col-12  m-0 ">
                <SquadContentBox>
                  <div className="row p-1 m-0 justify-content-center align-items-center py-3">
                    <div className="col-6 col-lg-1 m-0 p-0">
                      <div className="d-flex justify-content-center align-items-center">
                        <SquadRank>{squad.rank}</SquadRank>
                      </div>
                    </div>
                    <div className="col-6 col-lg-1">
                      <SquadImg
                        src={`${ImageUrlPrefix}/${squad.profilePath}`}
                        className="img-fluid"
                        alt="student profile"
                      />
                    </div>
                    <div className="col-12 col-lg-3">
                      <SquadName>{`${squad.first_name}`}</SquadName>

                      <div className="row p-0 m-0">
                        <div className="col-12 p-0 m-0">
                          <SquadHeader>marks obtained</SquadHeader>
                          <SquadText>{`${squad.marks_obtained} / ${squad.totalMarks}`}</SquadText>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-2">
                      <div className="row p-0 m-0">
                        <div className="col-12 col-md-6 col-lg-12 p-0 m-0">
                          <SquadHeader>Admission ID</SquadHeader>
                          <SquadText>{`${squad.admissionID}`}</SquadText>
                        </div>
                        <div className="col-12 col-md-6 col-lg-12 p-0 m-0">
                          <SquadHeader>Exam District</SquadHeader>
                          <SquadText>{`${squad.examDistrict}`}</SquadText>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-3">
                      <div className="row p-0 m-0">
                        <div className="col-12 col-md-6 col-lg-12 p-0 m-0">
                          <SquadHeader>Phone Number</SquadHeader>
                          <SquadText>{`${squad.phoneNumber}`}</SquadText>
                        </div>
                        <div className="col-12 col-md-6 col-lg-12 p-0 m-0">
                          <SquadHeader>Exam Centre</SquadHeader>
                          <SquadText>{`${squad.examCentre}`}</SquadText>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-2">
                      <div className="row p-0 m-0">
                        <div className="col-12 col-md-6 col-lg-12 p-0 m-0">
                          <SquadHeader>Email ID</SquadHeader>
                          <SquadText>{`${squad.emailID}`}</SquadText>
                        </div>
                        <div className="col-12 col-md-6 col-lg-12 p-0 m-0">
                          <SquadHeader>Centre Supervisor</SquadHeader>
                          <SquadText
                            style={{ marginBottom: "0px" }}
                          >{`${squad.centerSupervisorName}`}</SquadText>
                          <SquadText>{`${squad.centerSupervisorNumber}`}</SquadText>
                        </div>
                      </div>
                    </div>
                  </div>
                </SquadContentBox>
              </SquadContainer>
            );
          })}
        </div>
      </FloatedTitleContainer>
      {analyticsData.analyticsSelectType === SELECT_CENTRE && (
        <FloatedTitleContainer title="Student Details" className="mt-4">
          <div className="BathDet mb-3">
            <div className="row mb-3">
              <div className="col-lg-5 col-md-6">
                <div class="input-group">
                  <input
                    type="text"
                    class="Inputs_search"
                    id="SearchInput"
                    name="SearchInput"
                    placeholder="Search by ID, Name, Email, Phone No."
                    // onChange={handleSearch}
                    onKeyDown={handleSearch}
                  />
                  <span class="input-group-text" id="SearchInput">
                    <span className="material-icons">search</span>
                  </span>
                </div>
              </div>
              <div className="col-lg-3 col-md-4"></div>
              <div className="col-lg-6  d-flex justify-content-evenly col-md-6"></div>
            </div>
            {updateChipData.length > 0 ? (
              <div className="row mt-3">
                <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
                  <Paper
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      listStyle: "none",
                      p: 0.5,
                      m: 0,
                    }}
                    component="ul"
                  >
                    {updateChipData.map((data, index) => {
                      // console.log(data, " - data - ", index);
                      let icon;
                      let key = "" + data.name;
                      return (
                        <ListItem key={data}>
                          <Chip
                            icon={icon}
                            label={key}
                            onDelete={handleDelete(data, index)}
                          />
                        </ListItem>
                      );
                    })}

                    <ListItem>
                      <a
                        className="allclear"
                        href="javascript:void(0)"
                        onClick={handleAllClear}
                      >
                        Clear All Filters
                      </a>
                    </ListItem>
                  </Paper>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <div className="table-responsive">
              <table className="table" id="example">
                <thead>
                  <tr>
                    <th id="th">
                      <span className="text_th">S.No</span>
                      <IconButton
                        onClick={(e) => handleSort(e, "serialNumber")}
                        style={{ padding: "initial" }}
                      >
                        <ICON_SORT style={ICON_SORT_STYLES} />
                      </IconButton>
                    </th>
                    <th id="th">
                      <span className="text_th">Name</span>
                      <IconButton
                        onClick={(e) => handleSort(e, "full_name")}
                        style={{ padding: "initial" }}
                      >
                        <ICON_SORT style={ICON_SORT_STYLES} />
                      </IconButton>
                    </th>
                    <th id="th">
                      <span className="text_th">Exam Reg. No.</span>
                      <IconButton
                        onClick={(e) => handleSort(e, "aiasa_reg_no")}
                        style={{ padding: "initial" }}
                      >
                        <ICON_SORT style={ICON_SORT_STYLES} />
                      </IconButton>
                    </th>
                    <th id="th">
                      <span className="text_th">Admission ID</span>
                      <IconButton
                        onClick={(e) => handleSort(e, "admission_id")}
                        style={{ padding: "initial" }}
                      >
                        <ICON_SORT style={ICON_SORT_STYLES} />
                      </IconButton>
                    </th>
                    <th id="th">
                      <span className="text_th">Phone Number</span>
                      <IconButton
                        onClick={(e) => handleSort(e, "phone_no")}
                        style={{ padding: "initial" }}
                      >
                        <ICON_SORT style={ICON_SORT_STYLES} />
                      </IconButton>
                    </th>
                    <th id="th">
                      <span className="text_th">Email ID</span>
                      <IconButton
                        onClick={(e) => handleSort(e, "email")}
                        style={{ padding: "initial" }}
                      >
                        <ICON_SORT style={ICON_SORT_STYLES} />
                      </IconButton>
                    </th>
                    <th id="th">
                      <span className="text_th">Gender</span>
                      <IconButton
                        onClick={(e) => handleSort(e, "gender")}
                        style={{ padding: "initial" }}
                      >
                        <ICON_SORT style={ICON_SORT_STYLES} />
                      </IconButton>

                      <span
                        class={` align-middle material-icons  ${
                          gender.length > 0 ? "active_icons" : ""
                        }`}
                        onClick={(e) => handleFilter(e, "gender")}
                      >
                        filter_list
                      </span>
                      {isOpen.gender === true && (
                        <div className="filterBoxs p-1">
                          <div className="row mt-4">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name="Male"
                                  value="1"
                                  id="Male"
                                  checked={gender.some((element) => {
                                    if (element.name === "Male") {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "male")
                                  }
                                />
                                <label
                                  class="form-check-label "
                                  for="defaultCheck1"
                                >
                                  Male
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name="Female"
                                  value="2"
                                  id="female"
                                  checked={gender.some((element) => {
                                    if (element.name === "Female") {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "female")
                                  }
                                />
                                <label
                                  class="form-check-label "
                                  for="defaultCheck1"
                                >
                                  Female
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name="Transgender"
                                  value="3"
                                  id="Transgender"
                                  checked={gender.some((element) => {
                                    if (element.name === "Transgender") {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "transgender")
                                  }
                                />
                                <label
                                  class="form-check-label "
                                  for="defaultCheck1"
                                >
                                  Transgender
                                </label>
                              </div>
                            </div>
                          </div>

                          <hr />
                          <div className="row mt-3">
                            <div className="col-md-4  col-4 col-sm-4">
                              <span
                                className="clearFilter ms-2 "
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClear("gender")}
                              >
                                Clear
                              </span>
                            </div>
                            <div className="col-md-8  col-8 col-sm-8">
                              <button
                                className="FilterButton"
                                onClick={() => handleApplyFilter("gender")}
                              >
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </th>
                    <th id="th">
                      <span className="text_th">No. of Exams Enrolled</span>
                      <IconButton
                        onClick={(e) => handleSort(e, "no_of_exams")}
                        style={{ padding: "initial" }}
                      >
                        <ICON_SORT style={ICON_SORT_STYLES} />
                      </IconButton>
                    </th>
                    <th id="th">
                      <span className="text_th">Paid Amount</span>
                      <IconButton
                        onClick={(e) => handleSort(e, "trans_amount")}
                        style={{ padding: "initial" }}
                      >
                        <ICON_SORT style={ICON_SORT_STYLES} />
                      </IconButton>
                    </th>
                    <th id="th">
                      <span className="text_th">Marks Obtained</span>
                      <IconButton
                        onClick={(e) => handleSort(e, "marks_obtained")}
                        style={{ padding: "initial" }}
                      >
                        <ICON_SORT style={ICON_SORT_STYLES} />
                      </IconButton>
                    </th>
                    <th id="th">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.length === 0 ? (
                    <tr>
                      <td
                        colspan="15"
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid #dee2e6",
                        }}
                      >
                        No record to display
                      </td>
                    </tr>
                  ) : (
                    currentData.map((student, index) => (
                      <tr key={index} title={student.id}>
                        <td id="td">{student.serialNumber}</td>
                        <td id="td">{student.full_name}</td>
                        <td id="td">{student.aiasa_reg_no}</td>
                        <td id="td">{student.admission_id}</td>
                        <td id="td">{student.phone_no}</td>
                        <td id="td">{student.email}</td>
                        <td id="td">{student.gender}</td>
                        <td id="td">{student.no_of_exams}</td>
                        <td id="td">{student.trans_amount}</td>
                        <td id="td">
                          {student.marks_obtained !== null
                            ? student.marks_obtained === 0
                              ? "A"
                              : student.marks_obtained
                            : "-"}
                        </td>
                        <td id="td">
                          <IconButton>
                            <ICON_EYE color="error" fontSize="18px" />
                          </IconButton>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="row mt-3 ms-1 me-1">
              <div className="col-md-12">
                <div className="pag w-100 dflexcenter">
                  <span className="ipage">Items Per Page</span>
                  <select
                    name="pageCount"
                    id="pageCount"
                    defaultValue={rowsPerPage}
                    onChange={handlePageCount}
                    className="ms-2"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  <span className="pagenumber ms-2">
                    {" "}
                    {studentsList.length > 0
                      ? currentPage * rowsPerPage + 1 - rowsPerPage
                      : 0}{" "}
                    -
                    {studentsList.length < currentPage * rowsPerPage
                      ? studentsList.length
                      : currentPage * rowsPerPage}{" "}
                    of {studentsList.length}
                  </span>
                  <button
                    className="prev_button ms-2 me-2"
                    onClick={(e) => handlePrev(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <span class="material-icons">chevron_left</span>
                  </button>
                  <button
                    className="prev_button"
                    onClick={() => handleNext(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <span class="material-icons">chevron_right</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </FloatedTitleContainer>
      )}
      {/* Attendance */}
      <FloatedTitleContainer title="Attendance Overview" className="mt-4">
        <div
          className="row  align-items-center p-0"
          style={{ margin: "20px 15px" }}
        >
          <div className="col-12 col-md-6 col-lg-3 p-0 m-0">
            <SquadHeader>Total Number of Students Enrolled</SquadHeader>
            <SquadText>{`${analyticsData.totalStudentsEntrolled}`}</SquadText>
          </div>
          <div className="col-12 col-md-6 col-lg-3 p-0 m-0">
            <SquadHeader>Total Number of Male Students</SquadHeader>
            <SquadText>{`${analyticsData.totalMaleStudentsPresent}`}</SquadText>
          </div>
          <div className="col-12 col-md-6 col-lg-3 p-0 m-0">
            <SquadHeader>Total Number of Female Students</SquadHeader>
            <SquadText>{`${analyticsData.totalFemaleStudentsPrsent}`}</SquadText>
          </div>
          <div className="col-12 col-md-6 col-lg-3 p-0 m-0">
            <SquadHeader>Total Number of Transgender Students</SquadHeader>
            <SquadText>{`${analyticsData.totalTransgenderStudentsPresent}`}</SquadText>
          </div>
          <div className="col-12 col-md-6 col-lg-3 p-0 m-0">
            <SquadHeader>Total Number of Students Present</SquadHeader>
            <SquadText>{`${analyticsData.totalStudentsPresent}`}</SquadText>
          </div>
          <div className="col-12 col-md-6 col-lg-3 p-0 m-0">
            <SquadHeader>Total Number of Students Absent</SquadHeader>
            <SquadText>{`${analyticsData.totalStudentsAbsent}`}</SquadText>
          </div>
        </div>
      </FloatedTitleContainer>

      {/* loader */}
      {analyticsData.isSelectLoading && (
        <Dialog open={analyticsData.isSelectLoading}>
          <DialogContent>
            <div style={{ margin: "15px" }}>
              <div className="spinner-border text-danger"></div>{" "}
              {/* <p>Loading ...</p> */}
            </div>
          </DialogContent>
        </Dialog>
      )}
    </CustomContainer>
  );
}

export default ViewModelExamAnalytics;
