import React from "react";
import "../question/Question.css";
import parse from "html-react-parser";
import LatexProcessorComponent from "../../../../utils/LatexProcessorComponent";
//display type 0 means - normal mcq design
// sample design
// 1.Pure silicon is ?
//  (A) p-type semiconductor
//  (B) an n-type semiconductor
//  (C) an intrinsic semiconductor
//  (D) an extrinsic semiconductor
// கூதிர்ப்பருவம் என அழைக்கப்படுவத
//  (A) மார்கழி, ழை  //  (B) மாசி, பங்குன
//  (C) ஐப்பசி, கார்த்திழக  //  (D) சித்திழை, ழவகாச

function RtypeOne(props) {
  const { index } = props;

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }
  const optionType = props.list.options_type;

  const generateOptions = (optionType) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];

    for (let i = 0; i < optionType; i++) {
      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1
              ? "col-md-6 juio"
              : "col-md-12 ghg"
          } mt-1 d-flex`}
        >
          <div className="forms_input d-flex">
            {props.type !== "1" && props.type !== "2" && props.type === "0" ? (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_en`}
                value={props.list[`options_en_${options[i]}`]}
                checked={props.list.user_answer === options[i] ? true : false}
              />
            ) : (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_en`}
                value={props.list[`options_en_${options[i]}`]}
                checked={props.list.answer_en === options[i] ? true : false}
              />
            )}
            <span
              className={
                props.type !== "1"
                  ? "answer_label_viewQ_english"
                  : "answer_labela"
              }
            >
              ({options[i]})
            </span>
          </div>
          <div>
            <span
              className={
                props.type !== "1"
                  ? "answer_label_viewQ_english ms-1"
                  : "answer_labela"
              }
            >
              {/* {parse(props.list[`options_en_${options[i]}`])}{" "} */}
              <LatexProcessorComponent
                originalString={props.list[`options_en_${options[i]}`]}
              />
            </span>
          </div>
        </div>
      );
    }

    return elements;
  };

  const generateOptionsTamil = (optionType) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];

    for (let i = 0; i < optionType; i++) {
      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input  d-flex">
            {props.type !== "1" && props.type !== "2" ? (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_tm`}
                value={props.list[`options_tm_${options[i]}`]}
                checked={props.list.user_answer === options[i] ? true : false}
              />
            ) : (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_tm`}
                value={props.list[`options_tm_${options[i]}`]}
                checked={props.list.answer_tm === options[i] ? true : false}
              />
            )}
            <span
              className={
                props.type !== "1"
                  ? "answer_label_viewQ_tamil"
                  : "answer_label_viewQa"
              }
            >
              ({options[i]})
            </span>
          </div>
          <div>
            <span
              className={
                props.type !== "1"
                  ? "answer_label_viewQ_tamil ms-1"
                  : "answer_label_viewQa"
              }
            >
              {/* {parse(props.list[`options_tm_${options[i]}`])}{" "} */}
              <LatexProcessorComponent
                originalString={props.list[`options_tm_${options[i]}`]}
              />
            </span>
          </div>
        </div>
      );
    }
    return elements;
  };

  return (
    <div className=" Question_temp_VQ">
      {props.list.en_question !== null &&
        props.list.en_question !== "" &&
        (props.list.lang_type === 1 || props.list.lang_type === 2) && (
          <div className="row tamilQues ">
            <div className="col-md-12">
              <span className="question_labels_english">
                {/* {parse(props.list.en_question)} */}
                <LatexProcessorComponent
                  originalString={props.list.en_question}
                />
              </span>
            </div>

            <div className="col-md-12 mt-1">
              <div className="row">{generateOptions(optionType)}</div>
            </div>
          </div>
        )}

      {props.list.tm_question !== null &&
        props.list.tm_question !== "" &&
        (props.list.lang_type === 0 || props.list.lang_type === 2) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <span className="question_labels_tamil">
                {/* {parse(props.list.tm_question)} */}
                <LatexProcessorComponent
                  originalString={props.list.tm_question}
                />
              </span>
            </div>
            <div className="col-md-12 mt-1">
              <div className="row">{generateOptionsTamil(optionType)}</div>
            </div>
          </div>
        )}
    </div>
  );
}

export default RtypeOne;
