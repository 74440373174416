// ModalComponent.js
import React from "react";
import "../../../../pages/dashboard/Modelcss.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { CommonOption } from "../../../../labels/Lableoption";
import { multiCusutomStyle } from "../../../../pages/Multicustomstyles";
import Select, { components, useStateManager } from "react-select";
import { customStyles } from "../../../../pages/customStyles";

function ExamAdvancedFilterModel({
  show,
  modelExamOption,
  handleFilterClose,
  handleExamChange,
  ModelExamValue,
  centerDistrict,
  handleExamRecordsFilter,
  district,
  ClearAllFilterBut,
  genderOption,
  clearFilterDisabled,
  handleExamDistrict,
  selectedDistrict,
  examCenterOption,
  selectedExamCenter,
  handleClearSection,
  handleApplyFilterAll,
  handleAllClearFilter,
  error,
}) {
  // console.log(ModelExamValue, "ModelExamValue");
  // const idsToFilter = new Set(ModelExamValue.id);
  return (
    <Modal show={show} onHide={handleFilterClose} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 class="modal-title modelHallTicketTitle" id="exampleModalLabel">
            Advanced Filters
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className="StepOne_modelFilter">
          <div className="row box ">
            <div className="col-md-12">
              <p className="StepTitle">Filter by Exam</p>
              <div className="col-md-6">
                <div className="forms ">
                  <label htmlFor="model_exam_name">Model Exam Name</label>
                  <Select
                    id="model_exam_name"
                    name="model_exam_name"
                    options={modelExamOption}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    onChange={(e) => handleExamChange(e, "model_exam_name")}
                    value={ModelExamValue}
                    styles={customStyles} // Apply custom styles her
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="StepOne_modelFilter">
          <div className="row box">
            <div className="col-md-12">
              <p className="StepTitle">Filter by Location</p>
              <div className="col-md-12">
                <div className="forms ">
                  <label htmlFor="model_exam_name">Location</label>
                  <div className="d-flex  col-md-12" id="radiobuttons">
                    <div className="form-check form-check-inline Mobileform-check-inline p-0 ">
                      <input
                        className="checkbox_radio"
                        type="radio"
                        name="centerD"
                        onChange={(e) => handleExamRecordsFilter(e, "centerD")}
                        checked={centerDistrict.centerD === "0" ? true : false}
                        id="district_name"
                        value="0"
                      />
                      <label
                        className="checkbox-label mt-1"
                        htmlFor="district_name"
                      >
                        District
                      </label>
                    </div>
                    <div className="form-check form-check-inline Mobileform-check-inline p-0 ">
                      <input
                        className="checkbox_radio"
                        type="radio"
                        name="centerD"
                        id="exam_centers"
                        onChange={(e) => handleExamRecordsFilter(e, "centerD")}
                        checked={centerDistrict.centerD === "1" ? true : false}
                        value="1"
                      />
                      <label
                        className="checkbox-label mt-1"
                        htmlFor="exam_centers"
                      >
                        {Object.keys(selectedExamCenter).length > 0
                          ? "Exam Center"
                          : "Exam Center"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {centerDistrict.centerD !== "" &&
                  centerDistrict.centerD !== null &&
                  centerDistrict.centerD === "0" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label className="mt-1" htmlFor="district_name">
                          District Name
                        </label>
                        <Select
                          id="district_name"
                          name="district_name"
                          options={district}
                          isSearchable={true} // Enables search functionality
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          isClearable={true}
                          placeholder="Please select district "
                          onChange={(option) =>
                            handleExamDistrict(option, "district_name")
                          }
                          value={
                            Object.keys(selectedDistrict).length === 0
                              ? null
                              : selectedDistrict
                          }
                          styles={customStyles} // Apply custom styles her
                        />
                      </div>
                    </div>
                  )}
                {centerDistrict.centerD !== "" &&
                  centerDistrict.centerD !== null &&
                  centerDistrict.centerD === "1" && (
                    <>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label className="mt-1" htmlFor="district_names">
                            District Name
                          </label>
                          <Select
                            id="district_names"
                            name="district_name"
                            options={district}
                            closeMenuOnSelect={true}
                            isSearchable={true} // Enables search functionality
                            hideSelectedOptions={false}
                            isClearable={true}
                            placeholder="Please select district "
                            onChange={(option) =>
                              handleExamDistrict(option, "district_name")
                            }
                            value={
                              Object.keys(selectedDistrict).length === 0
                                ? null
                                : selectedDistrict
                            }
                            styles={customStyles} // Apply custom styles her
                          />
                          {error.district_name && (
                            <small className="error_filter">
                              {error.district_name}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label className="mt-1" htmlFor="exam_center_name">
                            Exam Centre Name
                          </label>
                          <Select
                            id="exam_center_name"
                            name="exam_center_name"
                            options={
                              Array.isArray(examCenterOption)
                                ? examCenterOption.filter(
                                    (option) =>
                                      ModelExamValue.id ===
                                      option.exam_detail_id
                                  )
                                : []
                            }
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            placeholder="Please select exam center"
                            onChange={(e) =>
                              handleExamDistrict(e, "exam_center_name")
                            }
                            value={
                              Object.keys(selectedExamCenter).length === 0
                                ? null
                                : selectedExamCenter
                            }
                            styles={customStyles} // Apply custom styles her
                          />
                          {error.exam_center_name && (
                            <small className="error_filter">
                              {error.exam_center_name}
                            </small>
                          )}
                        </div>
                      </div>
                    </>
                  )}
              </div>
              <div className="col-md-12 d-flex justify-content-end">
                <span
                  onClick={
                    clearFilterDisabled.districtsec === true
                      ? () => handleClearSection("centerD")
                      : () => {}
                  }
                  // onClick={() => handleClearSection("centerD")}
                  className={`ClearFilterModel ${
                    clearFilterDisabled.districtsec === false &&
                    "DisabledFilter"
                  }`}
                  disabled={
                    clearFilterDisabled.districtsec === true ? true : false
                  }
                >
                  Clear Filter
                </span>
              </div>
            </div>
          </div>
        </section>

        <section className="StepOne_modelFilter">
          <div className="row box">
            <div className="col-md-12">
              <p className="StepTitle">Filter by Gender</p>
              <div className="col-md-12">
                <div className="forms ">
                  <label htmlFor="model_exam_name">Gender</label>
                  <div className="d-flex  col-md-12" id="radiobuttons">
                    <div className="form-check form-check-inline Mobileform-check-inline p-0 ">
                      <input
                        className="checkbox_radio"
                        type="checkbox"
                        name="gender"
                        onChange={(e) => handleExamRecordsFilter(e, "gender")}
                        checked={genderOption.includes("Male") ? true : false}
                        id="Male"
                        value="Male"
                      />
                      <label className="checkbox-label mt-1" htmlFor="Male">
                        Male
                      </label>
                    </div>
                    <div className="form-check form-check-inline Mobileform-check-inline p-0 ">
                      <input
                        className="checkbox_radio"
                        type="checkbox"
                        name="gender"
                        id="Female"
                        onChange={(e) => handleExamRecordsFilter(e, "gender")}
                        checked={genderOption.includes("Female") ? true : false}
                        value="Female"
                      />
                      <label className="checkbox-label mt-1" htmlFor="Female">
                        Female
                      </label>
                    </div>
                    <div className="form-check form-check-inline Mobileform-check-inline p-0 ">
                      <input
                        className="checkbox_radio"
                        type="checkbox"
                        name="gender"
                        id="Transgender"
                        onChange={(e) => handleExamRecordsFilter(e, "gender")}
                        checked={
                          genderOption.includes("Transgender") ? true : false
                        }
                        value="Transgender"
                      />
                      <label
                        className="checkbox-label mt-1"
                        htmlFor="Transgender"
                      >
                        Transgender
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-end">
                <span
                  onClick={() => handleClearSection("gender")}
                  className={`ClearFilterModel ${
                    clearFilterDisabled.gendersec === false && "DisabledFilter"
                  }`}
                  disabled={
                    clearFilterDisabled.gendersec === true ? true : false
                  }
                >
                  Clear Filter
                </span>
              </div>
            </div>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Link
          data-bs-dismiss="modal"
          onClick={handleAllClearFilter}
          disabled={
            Object.values(clearFilterDisabled).some((value) => value === true)
              ? false
              : true
          }
          className={`DashboardCancelButton ${
            Object.values(clearFilterDisabled).some((value) => value === true)
              ? ""
              : "DisabledFilter"
          }`}
        >
          Clear All Filters
        </Link>
        <button
          type="button"
          onClick={handleApplyFilterAll}
          disabled={
            Object.values(clearFilterDisabled).some(
              (value) => value === true
            ) || ClearAllFilterBut === true
              ? false
              : true
          }
          className={`DashboardButton ${
            Object.values(clearFilterDisabled).some(
              (value) => value === true
            ) || ClearAllFilterBut === true
              ? ""
              : "DisabledFilter"
          }`}
        >
          Apply Filters
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ExamAdvancedFilterModel;
