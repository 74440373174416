import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserDetails";
// import banner from '../../img/banner/ban1.jpg';
import "../../Themes/themes.css";
import "./Top10squad.css";
import Top10squad from "../dashboard/Top10squad";
import CourseCategory from "../dashboard/CourseCategory";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import ModelCompontent from "./ModelCompontent";
import Footer from "../../features/Core/Footer/Footer";
import PaymentDueModel from "../PaymentDueModel";
import { AxiosInstance } from "../../axios";
import Swal from "sweetalert2";
import ModelComForP from "../ModelComForP";
// import PublicResult from "../../shared/Component/BackOffice/TestAdminWorkflow/PublicResult";
// import WarningPopUp from "../../shared/Component/BackOffice/StudentTestWorkflow/WarningPopUp";
// import RaiseQuery from "../../shared/Component/BackOffice/StudentTestWorkflow/RaiseQuery";
// import ViewQuery from "../../shared/Component/BackOffice/StudentTestWorkflow/ViewQuery";
// import ViewExplaination from "../../shared/Component/BackOffice/StudentTestWorkflow/ViewExplaination";
import { ImageUrlPrefix } from "../../labels";

function IndexPage() {
  const { user } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);

  const [showModalData, setShowModalData] = useState({});
  const [images, setImages] = useState([]);

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;
  let role_id = null;
  let user_type = null;
  let menuData = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
      role_id = user.role_id;
      user_type = user.user_type;
      menuData = user.menu_list;
    }
  } else {
    console.log("User Id cannot available in session storage");
  }
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setShowModal(true); // Show the modal when the component loads
    if (userid) {
      AxiosInstance.post("api/user/reminder/byId", { user_id: userid })
        .then((res) => {
          if (res.data.status === true) {
            const dueDetailsData = res.data.data;
            // console.log(res.data.data)
            const dueDetailsString = JSON.stringify(dueDetailsData);
            sessionStorage.setItem("DueDetails", dueDetailsString);
            setShowModalData(dueDetailsData);
          }
        })
        .catch((error) => {
          return error;
        });
    }
    AxiosInstance.get("api/all/get_banner_images")
      .then((res) => {
        if (res.data.data.length > 0) {
          setImages(res.data.data);
        }
      })
      .catch((error) => {
        return error;
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(nextSlide, 2000); // Slide every minute
    return () => {
      clearInterval(interval);
    };
  }, [activeIndex]);

  const nextSlide = () => {
    setActiveIndex((prevIndex) => {
      if (prevIndex === images.length - 1) {
        return prevIndex;
      }
      return prevIndex + 1;
    });
    if (activeIndex === images.length - 1) {
      setTimeout(() => {
        setActiveIndex(0);
      }, 2000);
    }
  };
  // main exam model box close

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="AdmissionForm FooterLink ">
      <div className="row">
        <div className="col-md-12  p-0">
          {user.user_id ? (
            <section className=" StepOne headerwel">
              <h2 className="text-center">Welcome To Dashboard</h2>

              {user_type === 2 &&
                showModalData.res_ids &&
                showModalData.res_ids.length > 0 &&
                showModalData.res_ids.map((el, index) => (
                  <PaymentDueModel
                    showModalData={el}
                    show={el.status !== false ? true : false}
                    admission_type={el.admission_type}
                    adm_pk={el.adm_pk}
                    user_id={userid}
                    section={el.section}
                    index={index}
                    key={index} // Don't forget to add a unique key for each item in the array
                  />
                ))}
              {user_type === 2 &&
                showModalData.res_idss &&
                showModalData.res_idss.length > 0 &&
                showModalData.res_idss.map((el, index) => {
                  <PaymentDueModel
                    showModalData={el}
                    show={el.status !== false ? true : false}
                    adm_pk={el.adm_pk}
                    admission_type={el.admission_type}
                    section={el.section}
                    user_id={userid}
                    index={index}
                  />;
                })}
            </section>
          ) : (
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                {images.map((_, index) => (
                  <li
                    key={index}
                    // title={activeIndex}
                    data-target="#carouselExampleIndicators"
                    data-slide-to={index}
                    className={index === activeIndex ? "active" : ""}
                  ></li>
                ))}
              </ol>
              <div className="carousel-inner">
                {images.map((sd, index) => (
                  <div
                    key={index}
                    // title={sd}
                    titleone={activeIndex}
                    className={`carousel-item ${
                      index === activeIndex ? "active" : ""
                    }`}
                  >
                    <img
                      className="d-block w-100"
                      src={`${ImageUrlPrefix}/${sd}`}
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <CourseCategory />
      <Top10squad /> */}
    </div>
  );
}
export default IndexPage;
