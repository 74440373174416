import React from "react";
import "./Question.css";
import parse from "html-react-parser";
import LatexProcessorComponent from "../../../../utils/LatexProcessorComponent";

//display type 1 means - multiple option  mcq design
// sample design
// .பபாருத்ைமான இழணழயத் பைர்க .
// a)அணங்கு - பைய்வம்
// b)ஓவு - ஓவியம்
// c)நியமம் - மதில்
// d)மாகால் - கைல்
// பபாருத்ைமான இழணழயத் பைர்க
//  (A) (a) மற்றும் (b) மட்டும் ரி  //  (B) (b) மற்றும் (c) மட்டும் ரி
//  (C) (c )மற்றும் (d) மட்டும் ரி  //  (D) அழனத்தும் ரி

function QtypeTwo(props) {
  const { index } = props;

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  const splitLines = props.list.tm_question.split("\n");
  const EsplitLines = props.list.en_question.split("\n");

  const generateOptionsEnglish = (optionType, index, props) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];

    for (let i = 0; i < optionType; i++) {
      const option = options[i];
      const valueKey = `options_en_${option}`;
      const checked = props.userInput.some(
        (item) => item.pk === props.list.pk && item.UserOptionSec === option
      );

      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input d-flex">
            <input
              type="radio"
              className="radioInput"
              name={`q_answer_${props.index}_en`}
              checked={checked}
              value={props.list[valueKey]}
              onChange={(e) =>
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  1,
                  option,
                  props.list.subject_type
                )
              }
            />{" "}
            <span
              className="answer_label"
              onClick={(e) => {
                console.log("Span clicked for option:", options[i]);
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  option,
                  props.list.subject_type
                );
              }}
            >
              ({option})
            </span>
          </div>
          <div>
            <span
              className="answer_label"
              onClick={(e) => {
                console.log("Span clicked for option:", options[i]);
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  option,
                  props.list.subject_type
                );
              }}
            >
              {/* {parse(props.list[valueKey])}{" "} */}
              <LatexProcessorComponent originalString={props.list[valueKey]} />
            </span>
          </div>
        </div>
      );
    }

    return elements;
  };

  const generateOptionsTamil = (optionType, index, props) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];

    for (let i = 0; i < optionType; i++) {
      const option = options[i];
      const valueKey = `options_tm_${option}`;
      const checked = props.userInput.some(
        (item) => item.pk === props.list.pk && item.UserOptionSec === option
      );

      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input d-flex">
            <input
              type="radio"
              className="radioInput"
              name={`q_answer_${props.index}_tm`}
              checked={checked}
              value={props.list[valueKey]}
              onChange={(e) =>
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  option,
                  props.list.subject_type
                )
              }
            />{" "}
            <span
              className="answer_label_tamil"
              onClick={(e) => {
                console.log("Span clicked for option:", options[i]);
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  option,
                  props.list.subject_type
                );
              }}
            >
              ({option})
            </span>
          </div>
          <div>
            <span
              className="answer_label_tamil"
              onClick={(e) => {
                console.log("Span clicked for option:", options[i]);
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  option,
                  props.list.subject_type
                );
              }}
            >
              {/* {parse(props.list[valueKey])}{" "} */}
              <LatexProcessorComponent originalString={props.list[valueKey]} />
            </span>
          </div>
        </div>
      );
    }

    return elements;
  };

  const optionType = props.list.options_type;
  return (
    <div className=" Question_temp">
      {props.list.en_question !== null &&
        props.list.en_question !== "" &&
        (props.list.lang_type === 1 ||
          (props.list.lang_type === 2 &&
            (props.Lang === 1 || props.Lang === 2))) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <div className="col-md-12">
                <span className="question_labels_english">
                  {EsplitLines.length > 0 ? (
                    <LatexProcessorComponent originalString={EsplitLines[0]} />
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>

            {EsplitLines.map((line, index) => {
              if (
                line.trim() !== "" &&
                index !== 0
                // &&
                // index !== EsplitLines.length - 1
              ) {
                return (
                  <div className="col-md-12 mt-2" key={index}>
                    <span className="question_labels_english">
                      {/* {parse(line)} */}
                      <LatexProcessorComponent originalString={line} />
                    </span>
                  </div>
                );
              } else {
                return null;
              }
            })}

            <div className="col-md-12 mt-1">
              <div className="row">
                {generateOptionsEnglish(optionType, index, props)}
              </div>
            </div>
          </div>
        )}

      {props.list.tm_question !== null &&
        props.list.tm_question !== "" &&
        (props.list.lang_type === 0 ||
          (props.list.lang_type === 2 &&
            (props.Lang === 0 || props.Lang === 2))) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <span className="question_labels_tamil">
                {splitLines.length > 0 ? (
                  <LatexProcessorComponent originalString={splitLines[0]} />
                ) : (
                  ""
                )}
              </span>
            </div>
            {splitLines.map((line, index) => {
              if (
                line.trim() !== "" &&
                index !== 0
                // &&
                // index !== splitLines.length - 1
              ) {
                return (
                  <div className="col-md-12 mt-1" key={index}>
                    <span className="question_labels_tamil">
                      {/* {parse(line)} */}
                      <LatexProcessorComponent originalString={line} />
                    </span>
                  </div>
                );
              } else {
                return null;
              }
            })}
            <div className="col-md-12 mt-1">
              <div className="row">
                {generateOptionsTamil(optionType, index, props)}
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default QtypeTwo;
